import { JobType, JobAttributeEnum, WorkflowType } from "../api/api";

import type {
  ActionCB,
  SelectNode,
  CondtItemDef,
  WorkflowStepData,
  CompanyData,
  JobWorkflow,
  NewRequestData,
  MailRemindData,
} from "./type_defs";
import type { Job, Workflow } from "../api/api";

const defs = {
  kKeyRememberName: "store.key.rememberName",
  // process result
  kJobStat_Created: "created", // 作成した
  kJobStat_DataInit: "data_init", // 基本データの設定
  kJobStat_ActReq: "NOT_START", // 作成した
  kJobStat_InProgress: "IN_PROGRESS", // 対応中
  kJobStat_Done: "DONE",
  kJobStat_Deleted: "deleted", // 削除した

  // workflow result
  kWsStat_Init: "init", // 初始状态
  kWsStat_InProcessing: "in-processing", // 対応中
  kWsStat_Done: "done",
  kWsStat_Deleted: "deleted", // 削除した

  // action string
  kAct_Apply: "common-apply", //
  kAct_Reset: "common-reset", //
  kAct_JobDataInit: "job_data_init", //
  kAct_JobCheckData: "job_check_data", //
  kAct_JobProcess: "job_process", //
  kAct_JobView: "job-view", //
  kAct_JobRemind: "job-remind", //
  kAct_ViewCheckResult: "view-check-result", //
  kAct_ViewPeStatus: "view-pe-status", //
  kAct_ExecAuthAppSetup: "exec-auth-app-setup", //
  kAct_ExecJobInit: "exec-job-init", //
  kAct_ExecSendMail: "exec-send-mail", //
  kAct_ExecCreateWorkflow: "exec-create-workflow", //
  kAct_ExecOpenWorkflow: "exec-open-workflow", //
  kAct_ExecCloseWorkflow: "exec-close-workflow", //
  kAct_ExecFixVerCreate: "exec-create-fix-version", //
  kAct_ExecFixVerDelete: "exec-delete-fix-version", //
  kAct_DownloadFile: "download-file",
};

const kOpt_SelNode_None: SelectNode = {
  value: "",
  label: "Please Select Items",
};
const kOpts_FileCategory: SelectNode[] = [
  {
    value: "1pe",
    label: "1PE1ファイル",
  },
  {
    value: "summary",
    label: "サマリーファイル",
  },
];

const kOpts_FileType: SelectNode[] = [
  {
    value: "1CE_STANDARD_TEMPLATE",
    label: "1CE Standard Template",
  },
  {
    value: "SUMMARY_SMALL_STANDARD_TEMPLATE",
    label: "Summary Standard Template (small)",
  },
  {
    value: "SUMMARY_LARGE_STANDARD_TEMPLATE",
    label: "Summary Standard Template (large)",
  },
];

const kOpts_WF_Type: SelectNode[] = [
  {
    value: "gto-template",
    label: "GTOテンプレート",
  },
  {
    value: "new-report",
    label: "New Report",
  },
  {
    value: "tango-data",
    label: "Tangoデータ",
  },
  {
    value: "top-site-journal",
    label: "TS仕訳",
  },
  {
    value: "file-esg",
    label: "添付ファイル(ESG)",
  },
  {
    value: "file-tax-haven",
    label: "添付ファイル(タックスヘイブン)",
  },
];
const kOpts_WF_Step: SelectNode[] = [
  {
    value: "first_input",
    label: "1次入力にとどまる",
  },
  {
    value: "second_input",
    label: "2次入力へ進む",
  },
  {
    value: "individual_approval",
    label: "個者承認へ進む",
  },
];
const kOpts_ReportDownloadType = [
  { value: "xlsx", label: "Excel ファイル" },
  { value: "zip", label: "Zip ファイル" },
];
const kOpts_CheckResult = [
  { value: "", label: "　" },
  { value: "OK", label: "OK" },
  { value: "WARNING", label: "WARNING" },
  { value: "NG", label: "NG" },
];
const kOpts_CheckInfo = [
  { value: "", label: "　" },
  { value: "INVALID_CE", label: "INVALID_CE" },
  { value: "INVALID_DP", label: "INVALID_DP" },
  { value: "INVALID_VERSION", label: "INVALID_VERSION" },
  { value: "INVALID_FILE_TYPE", label: "INVALID_FILE_TYPE" },
  { value: "SUBMIT_WAITING", label: "SUBMIT_WAITING" },
];

const kOpts_WF_Limit = [
  { value: "lv_01", label: "標準テンプレートマスタ管理" },
  { value: "lv_02", label: "PEマスタ管理" },
  { value: "lv_03", label: "担当者マスタ管理" },
  { value: "lv_04", label: "Item定義マスタ管理" },
  { value: "lv_05", label: "メールマスタ管理" },
  { value: "lv_06", label: "一括メール送信" },
  { value: "lv_07", label: "Tango連携" },
  { value: "lv_08", label: "1次入力担当" },
  { value: "lv_09", label: "2次入力担当" },
  { value: "lv_10", label: "個者承認" },
  { value: "lv_11", label: "Tango最終照合担当" },
  { value: "lv_12", label: "Tango最終照合承認" },
  { value: "lv_13", label: "進捗確認" },
  { value: "lv_14", label: "レポート出力" },
  { value: "lv_15", label: "年度バージョン管理" },
  { value: "lv_16", label: "前年度繰越処理" },
  { value: "lv_17", label: "Topside仕訳担当" },
  { value: "lv_18", label: "Topside仕訳承認" },
  { value: "lv_19", label: "New Report担当" },
  { value: "lv_20", label: "New Report承認" },
  { value: "lv_21", label: "添付ファイル担当" },
  { value: "lv_22", label: "添付ファイル承認" },
];
const kOpts_Limit: Record<string, boolean> = {
  new_request: true,
  view: true,
  notify: true,
  check: true,
  open: true,
  reopen: true,
  close: true,
  report: true,
  delete: true,
  download: true,
  upload: true,
};
const kOpt_NullCondtItem: CondtItemDef = {
  id: "",
  name: "",
  vtype: "str",
};
const kOpts_CondtItems: CondtItemDef[] = [
  {
    id: "fiscal_year",
    name: "Fiscal Year",
    vtype: "str",
  },
  {
    id: "pe_code",
    name: "PE Code",
    vtype: "str",
  },
  {
    id: "pe_name",
    name: "PE Name",
    vtype: "str",
  },
  {
    id: "job_status",
    name: "Job Status",
    vtype: "str",
  },
];
const kOpts_CondtItemSelNodes: SelectNode[] = kOpts_CondtItems.map((node) => ({
  value: node.id,
  label: node.name,
}));
const kDic_CondtItem: Record<string, CondtItemDef> = {};
for (const node of kOpts_CondtItems) {
  kDic_CondtItem[node.id] = node;
}
const findCondtItem = (key: string): CondtItemDef => {
  let rst = kDic_CondtItem[key];
  if (!rst) {
    rst = kOpt_NullCondtItem;
  }
  return rst;
};

const kOpts_CondtExpressions: SelectNode[] = [
  {
    value: "less_than",
    label: "<",
  },
  {
    value: "equal",
    label: "=",
  },
  {
    value: "more_than",
    label: ">",
  },
  {
    value: "between",
    label: "between",
  },
];
const findSelNode = (key: string, ary: SelectNode[]): SelectNode => {
  for (const node of ary) {
    if (node.value === key) {
      return { ...node };
    }
  }
  return { ...kOpt_SelNode_None };
};

const kOpt_NullCompany: CompanyData = {
  pe_code: "",
  com_name: "",
  com_type: 0,
  pe_name: "",
  parent: [],
  currency: "",
  etc: "",
};
const kObj_NullWorkflowDef = {
  id: "",
  name: "",
};
const kObj_NullJobWorkflow: JobWorkflow = {
  def: kObj_NullWorkflowDef,
  status: "",
  cur_step: 3,
  steps: [],
};
const kObj_NullWorkflow: Workflow = {
  workflowType: WorkflowType.NewReport,
  fileTypes: [],
};

const kObj_NullJob: Job = {
  id: "",
  name: "",
  type: JobType.InProgress,
  fiscalYear: 2023,
  fixVersion: "original",
  JobDetails: [],
  workflowType: WorkflowType.NewReport,
  workflowStep: "",
  completedTime: "",
  attribute: JobAttributeEnum.Approve,
};
const kObj_NullNewReq: NewRequestData = {
  wf_type: "",
  target_pe: "",
  target_group: "",
  target_status: "",
  mail_type: "",
  mail_subject: "",
  mail_content: "",
  remind_count: 0,
  remind_freq: 0,
};
const kObj_NullMailRemind: MailRemindData = {
  workflowType: WorkflowType.StandardTemplate,
  targetPE: [],
  targetGroup: [],
  targetStep: "",
  targetStatus: "",
  subject: "",
  content: "",
  remindType: 0,
  remindCount: 0,
  remindInterval: 0,
  fiscalYear: "2023",
};
const getWfStepLabel = (ary: WorkflowStepData[], idx: number): string => {
  if (idx < 0 || idx >= ary.length) {
    return "";
  }

  return ary[idx].title;
};
const makeFiscalYear = (): SelectNode[] => {
  const kFiscalYearCount = 10;
  const ary: SelectNode[] = [];
  const curYear = new Date().getFullYear();
  for (let i = 0; i < kFiscalYearCount; i++) {
    ary.push({
      value: `${curYear - i}`,
      label: `Year ${curYear - i}`,
    });
  }
  return ary;
};
const kOpts_FiscalYear = makeFiscalYear();

export default defs;
export type { ActionCB };
export {
  defs,
  kOpt_SelNode_None,
  kOpts_FileCategory,
  kOpts_FileType,
  kOpts_WF_Step,
  // kOpts_FiscalYear,
  kOpts_WF_Type,
  kOpts_CheckResult,
  kOpts_CheckInfo,
  kOpts_Limit,
  kOpts_WF_Limit,
  kOpts_ReportDownloadType,
  kOpt_NullCondtItem,
  kOpts_CondtItems,
  kOpts_CondtExpressions,
  kOpts_CondtItemSelNodes,
  kDic_CondtItem,
  findCondtItem,
  findSelNode,
  kOpts_FiscalYear,
  kOpt_NullCompany,
  kObj_NullWorkflow,
  kObj_NullJobWorkflow,
  kObj_NullJob,
  kObj_NullNewReq,
  kObj_NullMailRemind,
  getWfStepLabel,
};

export const POLLING_INTERVAL = 20000;
