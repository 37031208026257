import { LoadingButton } from "@mui/lab";
import { Typography, Button, Grid, Paper } from "@mui/material";
import ModalDialog from "components/molecular/ModalDialog";
import CompanyFilterPanel from "components/organisms/CompanyFilterPanel";
import { MaterialReactTable } from "material-react-table";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useLazySearchPEQuery } from "services/pe";

import type { OnChangeFn, RowSelectionState } from "@tanstack/react-table";
import type { PE } from "api";
import type { MRT_ColumnDef } from "material-react-table";
import type { FC } from "react";
import type { CEFilter, CEFilterOptions } from "types/filter";

const getDataTableColumns = (): MRT_ColumnDef<PE>[] => [
  {
    accessorKey: "PECode",
    header: "CE Code",
    size: 120,
  },
  {
    accessorKey: "country",
    header: "Country",
    size: 120,
  },
  {
    accessorKey: "companyName",
    header: "Company Name",
    size: 120,
  },
  {
    accessorKey: "PEName",
    header: "Branch Name",
    size: 120,
  },
  {
    accessorKey: "companyCategoryDisplay",
    header: "Company Category",
    size: 120,
  },
];

interface TableComponentProps {
  data: PE[];
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  rowSelection: RowSelectionState;
}

const DataTable: FC<TableComponentProps> = ({ data, onRowSelectionChange, rowSelection }) => {
  const columns = useMemo<MRT_ColumnDef<PE>[]>(() => getDataTableColumns(), []);
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      initialState={{ density: "compact" }}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableRowSelection
      getRowId={(row) => row.PECode}
      onRowSelectionChange={onRowSelectionChange}
      enableSorting
      enableTopToolbar={false}
      enableRowVirtualization
      state={{ rowSelection }}
      muiTableContainerProps={{ sx: { height: 300 } }}
      muiTableBodyRowProps={{ hover: true }}
      muiTableBodyCellProps={{ sx: { whiteSpace: "normal", overflowWrap: "anywhere" } }}
    />
  );
};

/**
 * 文字列の companyCategory を number へ変換する関数。
 * parseInt を使用した結果を返す。NaN の場合は undefined を返す。
 */
const companyCategoryToNumber = (value: string | undefined): number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  const parsed = parseInt(value, 10);

  if (Number.isNaN(parsed)) {
    return undefined;
  }

  return parsed;
};

const getSelectedTableColumns = (): MRT_ColumnDef<PE>[] => [
  {
    accessorKey: "PECode",
    header: "CE Code",
    size: 120,
  },
];

const SelectedTable: FC<TableComponentProps> = ({ data, onRowSelectionChange, rowSelection }) => {
  const columns = useMemo<MRT_ColumnDef<PE>[]>(() => getSelectedTableColumns(), []);
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      initialState={{ density: "compact" }}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableRowSelection
      getRowId={(row) => row.PECode}
      onRowSelectionChange={onRowSelectionChange}
      enableSorting
      enableTopToolbar={false}
      state={{ rowSelection }}
      muiTableContainerProps={{ sx: { height: 300 } }}
      muiTableBodyRowProps={{ hover: true }}
      muiTableBodyCellProps={{ sx: { whiteSpace: "normal", overflowWrap: "anywhere" } }}
    />
  );
};

export const useSelectCEDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<CEFilterOptions>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [ceList, setCEList] = useState<PE[]>([]);
  const [perviousCECodeList, setPerviousCECodeList] = useState<string[]>([]);

  const [resolvePromise, setResolvePromise] = useState<undefined | ((result?: string[]) => void)>();

  const [searchCE, { isLoading: isSearchCELoading }] = useLazySearchPEQuery();

  const requestSearchCE = useCallback(
    async (filter: CEFilter) => {
      try {
        const searchCEResponse = await searchCE({
          ...filter,
          companyCategory: companyCategoryToNumber(filter.companyCategory),
        }).unwrap();
        setCEList(searchCEResponse.PEList);
      } catch (error) {
        setCEList([]);
        enqueueSnackbar("Failed search ce.", { variant: "error" });
      }
    },
    [searchCE, setCEList, enqueueSnackbar]
  );

  const selectedData: PE[] = useMemo(
    () => ceList?.filter(({ PECode }) => Object.keys(rowSelection).includes(PECode)) ?? [],
    [ceList, rowSelection]
  );

  const initializeState = (params?: {
    filterOptions?: CEFilterOptions;
    defaultCodeList?: string[];
  }) => {
    setCEList([]);
    setPerviousCECodeList(params?.defaultCodeList ?? []);
    setFilterOptions(params?.filterOptions ?? {});
    setRowSelection(
      params?.defaultCodeList?.reduce((obj, code) => ({ ...obj, [code]: true }), {}) ?? {}
    );
  };

  const openSelectCEDialog = async (params?: {
    options?: CEFilterOptions;
    defaultCodeList?: string[];
  }): Promise<string[]> => {
    initializeState({
      filterOptions: params?.options,
      defaultCodeList: params?.defaultCodeList,
    });
    setOpen(true);

    console.log("TRACE openSelectCEDialog");
    requestSearchCE(params?.options ?? {});

    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  };

  const closeSelectCEDialog = (ceCodeList?: string[]) => {
    initializeState();
    setOpen(false);

    resolvePromise?.(ceCodeList ?? []);
  };

  const handleOnOK = () => {
    closeSelectCEDialog(selectedData.map(({ PECode }) => PECode));
  };

  const handleOnCancel = () => {
    closeSelectCEDialog(perviousCECodeList);
  };

  const handleOnSearch = (filter: CEFilter) => {
    console.log("TRACE handleOnSearch");
    requestSearchCE(filter);
  };

  const renderSelectCEDialog = () => (
    <ModalDialog
      open={open}
      title="Company Search"
      content={
        <Grid container spacing={2} sx={{ alignItems: "stretch" }}>
          <Grid xs={12} sm={9}>
            <CompanyFilterPanel onSearch={handleOnSearch} options={filterOptions} />
          </Grid>
          <Grid xs={12} sm={9} sx={{ height: 360 }}>
            <Typography variant="body2">
              * CE Code: Group Code + Company Code + Branch Code
            </Typography>
            <Paper elevation={0} sx={{ pl: 0, pr: 0 }}>
              <DataTable
                data={ceList ?? []}
                onRowSelectionChange={setRowSelection}
                rowSelection={rowSelection}
              />
            </Paper>
          </Grid>
          <Grid sm={3}>
            <Paper elevation={0} sx={{ pl: 0, pr: 0 }}>
              <SelectedTable
                data={selectedData}
                onRowSelectionChange={setRowSelection}
                rowSelection={rowSelection}
              />
            </Paper>
          </Grid>
        </Grid>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={handleOnCancel}>
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleOnOK}
            variant="contained"
            loading={isSearchCELoading}
          >
            OK
          </LoadingButton>
        </>
      }
      onClose={handleOnCancel}
      size="large"
    />
  );

  return {
    renderSelectCEDialog,
    openSelectCEDialog,
    closeSelectCEDialog,
  };
};
