import { TextField, Stack, Typography } from "@mui/material";

import type { ChangeEvent, FC } from "react";

type LabelInputProps = {
  label?: string;
  onInputChange?: (vs: string) => void;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onChanged?: (txt: string) => void;
  [k: string]: unknown;
};
const LabelInput: FC<LabelInputProps> = ({
  label,
  onInputChange,
  onChange,
  onChanged,
  ...props
}) => {
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(ev.target.value);
    } else if (onInputChange) {
      onInputChange(ev.target.value);
    } else if (onChange) {
      onChange(ev);
    }
  };
  return (
    <Stack direction="column">
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {label}
      </Typography>
      <TextField
        size="small"
        {...props}
        inputProps={{
          style: {
            height: "21px",
          },
        }}
        onChange={handleChange}
      />
    </Stack>
  );
};

/*
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: '18px',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#FfFfFf' : '#1A2027',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#d0d0d0' : '#2D3843',
    fontSize: 16,
    width: 'auto',
    padding: '3px 5px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const LabelInput: FC<{ label?: string;[k: string]: unknown }> = ({ label, ...props }) => {
  return (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel>
      <BootstrapInput value="react-bootstrap" id="bootstrap-input" {...props} />
    </FormControl>
  );
}
*/
export { LabelInput };
