/* eslint-disable react/jsx-props-no-spreading */
import { Stack, Typography, TextField } from "@mui/material";
import { useController } from "react-hook-form";

import type { TextFieldProps } from "@mui/material";
import type { ReactElement } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

type AdcLabelInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  label?: string;
  /** Additional settings */
  config?: {
    /**
     * Whether to display error messages below the input field.
     * If `true`, message displays.
     * @defaultValue `false`
     */
    displayErrorMessage?: boolean;
  };
  /** Settings for MUI elements */
  muiProps?: {
    /**
     * Settings for TextField inside AdcTextField
     *
     * API: {@link https://mui.com/material-ui/api/text-field/}
     */
    textFieldProps?: TextFieldProps;
  };
};

/**
 * MUI TextField component linked to React Hook Form.
 * Type arguments are optional, but specifying them provides powerful type checking and type inference.
 * @typeParam TFieldValues - Type of the form.
 * @typeParam TName - Field name.
 * @example
 * ```
 * type FormData = {
 *   username: string;
 * };
 *
 * <AdcLabelInput<FormData, 'username'>
 *   name='username'
 *   control={control}
 *   rules={{
 *     mandatory_field: 'Enter your username.',
 *     maxLength: {
 *       value: 10,
 *       message: 'Enter up to 10 characters.'
 *     }
 *   }}
 *   config={{
 *     displayErrorMessage: true
 *   }}
 *   muiProps={{
 *     textFieldProps: {
 *       label: 'Username',
 *       fullWidth: true
 *     }
 *   }}
 * />
 * ```
 */
export const AdcLabelInput: <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: AdcLabelInputProps<TFieldValues, TName>
) => ReactElement = (props) => {
  const { muiProps, config, label, ...others } = props;
  const { textFieldProps } = muiProps ?? {};
  const { field, fieldState } = useController(others);

  // <FormControl variant="standard">
  //   <InputLabel htmlFor="component-helper">Name</InputLabel>
  //   <Input
  //     id="component-helper"
  //     name={field.name}
  //     value={field.value}
  //     inputRef={field.ref}
  //     defaultValue="Composed TextField"
  //     aria-describedby="component-helper-text"
  //     onChange={field.onChange}
  //     onBlur={field.onBlur}
  //     sx={textFieldProps}
  //   />
  //   {!!fieldState.error ?
  //     <FormHelperText id="component-helper-text">
  //       {config?.displayErrorMessage && fieldState.error?.message}
  //     </FormHelperText>
  //     : <></>
  //   }
  // </FormControl>

  return (
    <Stack direction="column">
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {label}
      </Typography>
      <TextField
        // Attention to ther order of parameters passing
        {...textFieldProps}
        inputRef={field.ref}
        id={field.name}
        name={field.name}
        value={field.value}
        error={!!fieldState.error}
        helperText={config?.displayErrorMessage && fieldState.error?.message}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
    </Stack>
  );
};
