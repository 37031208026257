import { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { Paper, Container, Alert, Link, Typography, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "redux-modules/auth";
import { setInfo } from "redux-modules/info";
import { useLoginMutation } from "services/auth";
import { isAuthResponseError } from "services/common/type-guard";
import * as yup from "yup";

import { msg, msgAuth } from "../../common/msg_defs";
import { NoneWrapper } from "../../components/atoms/NoneWrapper";
import { AdcLabelInput } from "../../components/molecular/AdcLabelInput";

import type { SubmitHandler } from "react-hook-form";

type SignInFormInput = {
  username: string;
  password: string;
};

const schema = yup.object({
  username: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .email(msg.validation.kMsg_NotProperEmail)
    .max(128, "Max length is 128."),
  password: yup.string().required(msg.validation.kMsg_MandatoryField).max(99, "Max length is 99."),
});

export const SignInAdcV2Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alertMessage, setErrorAlertMessage] = useState<string>("");
  const { control, handleSubmit } = useForm<SignInFormInput>({
    defaultValues: { username: "", password: "" },
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();

  const onValid: SubmitHandler<SignInFormInput> = async ({ username, password }) => {
    try {
      const loginResponse = await login({ username, password }).unwrap();
      console.log("loginResponse", loginResponse);

      if (loginResponse.AuthenticationResult !== undefined) {
        const { AccessToken, IdToken, RefreshToken } = loginResponse.AuthenticationResult;
        const { role } = loginResponse;

        dispatch(setInfo({ username, role }));
        dispatch(
          setLogin({
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            authType: "ADC",
          })
        );

        // registerUser(username, IdToken, AccessToken, RefreshToken, "", role);

        navigate("/");
      } else if (loginResponse.ChallengeName === ChallengeNameType.NEW_PASSWORD_REQUIRED) {
        const { Session } = loginResponse;
        navigate(`/login/change-password?username=${username}&session=${Session}`);
      } else if (
        loginResponse.ChallengeName === ChallengeNameType.SMS_MFA ||
        loginResponse.ChallengeName === ChallengeNameType.SOFTWARE_TOKEN_MFA
      ) {
        const { ChallengeName, Session } = loginResponse;
        navigate(
          `/login/mfa?username=${username}&challengeName=${ChallengeName}&session=${Session}`
        );
      } else if (loginResponse.ChallengeName === ChallengeNameType.MFA_SETUP) {
        const { ChallengeName, Session } = loginResponse;
        navigate(
          `/login/mfa-setup?username=${username}&challengeName=${ChallengeName}&session=${Session}`
        );
      }
    } catch (error) {
      if (isAuthResponseError(error)) {
        if (error.code === "UserNotFoundException" || error.code === "NotAuthorizedException") {
          setErrorAlertMessage(msgAuth.kMsg_LoginError);
          return;
        }
        setErrorAlertMessage(error.code);
      }
      console.error("Unexpected login error", error);
      setErrorAlertMessage(
        "An unexpected login error has occurred. Please check the console for details."
      );
    }
  };

  const onInValid = () => {
    setErrorAlertMessage("");
  };

  return (
    <Container component="main" fixed sx={{ my: 2, py: 1 }}>
      <Paper sx={{ mx: "auto", p: 8, maxWidth: 560 }}>
        <form id="form-login" onSubmit={handleSubmit(onValid, onInValid)}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                ■ ADC ID Sign In
              </Typography>
            </Grid>
            <NoneWrapper show={!!alertMessage}>
              <Grid xs={12} sm={12} sx={{ ml: 2 }}>
                <Alert id="alert-msg" severity="error" sx={{ mt: 2 }}>
                  {alertMessage}
                </Alert>
              </Grid>
            </NoneWrapper>
            <Grid container spacing={2} sx={{ ml: 1 }}>
              <Grid xs={12} sm={12}>
                <AdcLabelInput<SignInFormInput, "username">
                  name="username"
                  label="E-mail"
                  control={control}
                  config={{
                    displayErrorMessage: true,
                  }}
                  muiProps={{
                    textFieldProps: {
                      fullWidth: true,
                      size: "small",
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12}>
                <AdcLabelInput<SignInFormInput, "password">
                  name="password"
                  label="Password"
                  control={control}
                  config={{
                    displayErrorMessage: true,
                  }}
                  muiProps={{
                    textFieldProps: {
                      fullWidth: true,
                      size: "small",
                      type: "password",
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12}>
                {isLoginLoading ? (
                  <CircularProgress size={28} />
                ) : (
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ my: 2 }}
                  >
                    Sign In
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} sx={{ ml: 2 }}>
              <Typography sx={{ mt: 2 }}>
                … or continue with <Link href="/login/corporate">Corporate Sign In</Link>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                If you forgot your password, please contact to the system administrator.
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};
