import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { infoState } from "redux-modules/info";
import { useGenerateDownloadURLMutation, usePrepareFileMutation } from "services/file";
import { useSearchPEQuery } from "services/pe";
import { v4 as uuid } from "uuid";

import { defs } from "../../common/const_defs";
import { AdcTitle1, AdcTitle2 } from "../../components/atoms/AdcTitle";
import ModalDialog from "../../components/molecular/ModalDialog";
import { useDlgCheckResult, useDlgMailSender, useDlgPeWfStatus } from "../../hooks/DialogContext";
import { kMD_LimitFull } from "../../mock/mock_data";

import { WfStatusNodeList, WsStatusFilterPanel } from "./components/WfStatusPanel";

import type { PE } from "../../api/api";
import type { ActionCB } from "../../common/type_defs";
import type { FC } from "react";

type WorkflowStatusProps = {
  open: boolean;
  wfType: string;
  fiscalYear: string;
  version: string;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const WorkflowStatusDialog: FC<WorkflowStatusProps> = ({
  open,
  wfType,
  fiscalYear,
  version,
  onClose,
  onAction,
}) => {
  const { openCheckResult } = useDlgCheckResult();
  const { openPeWfStatus } = useDlgPeWfStatus();
  const { openMailSender } = useDlgMailSender();
  const [limit] = useState<Record<string, boolean>>(kMD_LimitFull);
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const { enqueueSnackbar } = useSnackbar();
  const [prepareFile] = usePrepareFileMutation();
  const [generateDownloadURL] = useGenerateDownloadURLMutation();
  const [downloadFilePath, setDownloadFilePath] = useState<string>("");
  const [isEnableDownload, setIsEnableDownload] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const { role } = useSelector(infoState);

  useEffect(() => {
    setDownloadFilePath("");
    setIsEnableDownload(false);
  }, [open, filters]);

  const { data: peSearchData, isError: isPESearchError } = useSearchPEQuery(
    {
      ...filters,
      fiscalYear: Number(fiscalYear),
      fixVersion: version,
      workflowType: wfType,
    },
    { skip: open === false }
  );

  const prepareFiles = useCallback(
    async (isFirstPreparing: boolean) => {
      let reqId = requestId;
      if (isFirstPreparing) {
        reqId = uuid();
        setRequestId(reqId);
      }
      const prepareResult = await prepareFile({
        fileType: "WORKFLOW_ALL_FILE",
        fiscalYear: Number(fiscalYear),
        fixVersion: version,
        workflowType: wfType,
        requestId: reqId,
        presetType: isFirstPreparing ? "NEW" : undefined,
      }).unwrap();

      if (prepareResult.success) {
        enqueueSnackbar("Prepare all files success!", { variant: "success" });
        const { filePath } = prepareResult;
        setDownloadFilePath((prev) => filePath ?? prev);
        setIsEnableDownload(true);
        return filePath;
      }
      enqueueSnackbar("Prepare all files failed!", { variant: "error" });

      return undefined;
    },
    [requestId, prepareFile, fiscalYear, version, wfType, enqueueSnackbar]
  );

  const downloadAll = useCallback(async () => {
    try {
      const filePath = await prepareFiles(false);
      if (!filePath) {
        enqueueSnackbar("File download failed!", { variant: "error" });
        return;
      }
      const downloadURL = await generateDownloadURL({
        category: "WORKFLOW",
        filePath,
        fileType: "WORKFLOW_ALL_FILE",
      }).unwrap();

      if (!downloadURL) {
        enqueueSnackbar("Generate download url failed!", { variant: "error" });
        return;
      }

      const fileName = downloadURL.url.split("/")[-1];
      const link = document.createElement("a");
      link.download = fileName;
      link.href = downloadURL.url;
      link.click();
      link.remove();
      enqueueSnackbar("Succeeded to download file!", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("File download failed!", { variant: "error" });
    }
  }, [prepareFiles, generateDownloadURL, enqueueSnackbar]);

  const downloadWorkflowStatus = async () => {
    try {
      const prepareResult = await prepareFile({
        ...filters,
        fileType: "WORKFLOW_STATUS",
        fiscalYear: Number(fiscalYear),
        fixVersion: version,
        workflowType: wfType,
      }).unwrap();

      if (prepareResult.success) {
        const { filePath } = prepareResult;
        const downloadURL = await generateDownloadURL({
          category: "WORKFLOW",
          filePath,
          fileType: "WORKFLOW_STATUS",
        }).unwrap();

        if (!downloadURL) {
          enqueueSnackbar("Generate download url failed!", { variant: "error" });
          return;
        }

        const fileName = downloadURL.url.split("/")[-1];
        const link = document.createElement("a");
        link.download = fileName;
        link.href = downloadURL.url;
        link.click();
        link.remove();
        enqueueSnackbar("Succeeded to download file!", { variant: "success" });
      } else {
        enqueueSnackbar("Prepare file failed!", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("File download failed!", { variant: "error" });
    }
  };

  const handleApply = async (joken: Record<string, unknown>) => {
    setFilters(joken);
    // refreshData();
  };

  const handleAction = useCallback(
    async (act: string, vd: unknown) => {
      if (act === defs.kAct_ViewCheckResult) {
        const jobId = vd as string;
        openCheckResult(jobId, handleAction);
      } else if (act === "prepare-all-files") {
        await prepareFiles(true);
      } else if (act === "download-all-files") {
        await downloadAll();
      } else if (act === defs.kAct_ViewPeStatus) {
        const peCode = vd as string;
        openPeWfStatus(peCode, handleAction);
      } else if (act === "pe-remind") {
        const pe = vd as PE;
        const params = {
          PECode: pe.PECode,
          workflowType: wfType as string,
          fiscalYear,
          version,
        };
        openMailSender(params, handleAction);
      } else {
      }
    },
    [
      prepareFiles,
      downloadAll,
      openCheckResult,
      openPeWfStatus,
      openMailSender,
      wfType,
      fiscalYear,
      version,
    ]
  );

  return (
    <ModalDialog
      open={open}
      title="Workflow CE Status"
      content={
        <Grid container spacing={1}>
          <Grid xs={12} sm={12} sx={{}}>
            <AdcTitle1>{`Workflow_${wfType}(${fiscalYear})`}</AdcTitle1>
          </Grid>
          <Grid xs={12} sm={12} sx={{ pl: 2 }}>
            <AdcTitle2>Filter</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={12} sx={{ mx: 2 }}>
            <WsStatusFilterPanel
              filters={filters}
              onChanged={setFilters}
              onApply={handleApply}
              wfType={wfType}
            />
          </Grid>
          <Grid xs={12} sm={12} sx={{ pl: 2 }}>
            <AdcTitle2>Items</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={12} sx={{ mr: 2, mb: 1, pl: 3, textAlign: "left" }}>
            <Stack direction="row" sx={{ gap: "0.5rem", width: "100%" }}>
              <Button
                variant="outlined"
                disabled={isPESearchError || !role?.hasWorkflowManagement}
                onClick={() => handleAction("prepare-all-files", null)}
              >
                Prepare all files
              </Button>
              <Button
                variant="outlined"
                disabled={isPESearchError || !isEnableDownload}
                onClick={() => handleAction("download-all-files", null)}
              >
                Download all files
              </Button>
              <Button variant="outlined" onClick={downloadWorkflowStatus}>
                Download Workflow Status
              </Button>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <WfStatusNodeList
              peList={peSearchData?.PEList ?? []}
              limit={limit}
              onAction={handleAction}
              isLoading={false}
            />
          </Grid>
        </Grid>
      }
      buttons={
        <Grid id="xxx" container sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={() => onClose("cancel")}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="large"
    />
  );
};

export { WorkflowStatusDialog };
