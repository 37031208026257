import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

import type { FC, ReactElement } from "react";

type FileDragDropUploadProps = {
  maxOnce?: number;
  typeFilter: string[] | undefined;
  onUpload: (files: File[]) => void;
  children: ReactElement | ReactElement[] | string;
  [key: string]: unknown;
};

const FileDragDropUpload: FC<FileDragDropUploadProps> = ({
  maxOnce,
  typeFilter,
  onUpload,
  children,
}) => {
  const [dragging, setDragging] = useState(false);
  const [message, setMessage] = useState<{
    show: boolean;
    text: string;
    type: string;
  }>({ show: false, text: "", type: "" });
  const refDrop = useRef<HTMLDivElement | null>(null);
  const refDrag = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // useRef 的 drop.current 取代了 ref 的 this.drop
    refDrop.current?.addEventListener("dragover", handleDragOver);
    refDrop.current?.addEventListener("drop", handleDrop);
    refDrop.current?.addEventListener("dragenter", handleDragEnter);
    refDrop.current?.addEventListener("dragleave", handleDragLeave);

    const inst = refDrop.current;
    return () => {
      inst?.removeEventListener("dragover", handleDragOver);
      inst?.removeEventListener("drop", handleDrop);
      inst?.removeEventListener("dragenter", handleDragEnter);
      inst?.removeEventListener("dragleave", handleDragLeave);
    };
  });
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = Array.from(e.dataTransfer?.files ?? []);

    if (maxOnce && maxOnce < files.length) {
      showMessage(`You should upload only ${maxOnce} files by once`, "error", 2000);
      return;
    }

    if (
      typeFilter &&
      files.some(
        (file) =>
          !typeFilter.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))
      )
    ) {
      showMessage(`Only ${typeFilter.join(", ")} files can be uploaded.`, "error", 2000);
      return;
    }

    if (files && files.length) {
      showMessage("file was been selected.", "success", 1000);
      onUpload(files);
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== refDrag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target === refDrag.current) {
      setDragging(false);
    }
  };

  const showMessage = (text: string, type: string, timeout: number) => {
    setMessage({ show: true, text, type });
    setTimeout(() => setMessage({ show: false, text: "", type: "" }), timeout);
  };

  const holderStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column nowrap",
    backgroundColor: "#f7f7f7",
    border: "2px dashed #a0a0ff",
    borderRadius: "10px",
    color: "#7f8e99",
    fontSize: "16px",
    opacity: 1,
    textAlign: "center",
    lineHeight: 1.4,
  };

  return (
    <Box ref={refDrop} sx={{ position: "relative" }}>
      {message.show && (
        <Box sx={holderStyle}>
          {message.text}
          <Box aria-label="emoji" sx={{ fontSize: "64px", mt: "20px" }}>
            {message.type === "error" ? <>❓</> : <>👌</>}
          </Box>
        </Box>
      )}
      {dragging && (
        <Box ref={refDrag} sx={holderStyle}>
          Please release the mouse to select the file.
          <Box aria-label="emoji" sx={{ fontSize: "48px", mt: "20px" }}>
            🙂
          </Box>
        </Box>
      )}
      {children}
    </Box>
  );
};

export { FileDragDropUpload };
