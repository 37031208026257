import { Paper, Link, Button, Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { msg } from "../../common/msg_defs";
import { AdcLabelInput } from "../../components/molecular/AdcLabelInput";
import { useYupResolver } from "../../util/yup-helper";

import type { FieldErrors, SubmitHandler } from "react-hook-form";

type ForgetPwdData = {
  email: string;
};
const schema = yup.object({
  email: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .email(msg.validation.kMsg_NotProperEmail)
    .max(128, "Max length is 128."),
});

const ForgetPasswordPage = () => {
  const { control, watch, getValues, handleSubmit } = useForm<ForgetPwdData>({
    defaultValues: { email: "" },
    // onSubmit modeの場合、formStateのisValidは適切な値とならないことに注意
    mode: "onSubmit",
    resolver: useYupResolver(schema),
  });
  const navigate = useNavigate();
  // const handleSignIn = async () => {
  //   navigate("/login/change-password")
  // }
  const onSubmit: SubmitHandler<ForgetPwdData> = useCallback(async (fd: ForgetPwdData) => {
    try {
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onError = useCallback((errors: FieldErrors<ForgetPwdData>) => {}, []);

  return (
    <Container component="main" fixed sx={{ my: 2, py: 1 }}>
      <Paper sx={{ mx: "auto", p: 5, maxWidth: 560 }}>
        <form id="form-signin" onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="h5">■ Password Reset</Typography>
            </Grid>
            <Grid xs={12} sm={12} sx={{ ml: 2, mt: 2 }}>
              <Typography variant="body2">Enter your E-mail to set a new password</Typography>
            </Grid>
            <Grid xs={12} sm={12} sx={{ ml: 2 }}>
              <AdcLabelInput<ForgetPwdData, "email">
                name="email"
                label="E-mail"
                control={control}
                config={{
                  displayErrorMessage: true,
                }}
                muiProps={{
                  textFieldProps: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} sx={{ ml: 2, display: "flex", justifyContent: "center" }}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Reset Password
              </Button>
            </Grid>
            <Grid xs={12} sm={12}>
              <Typography sx={{ mt: 5, ml: 2 }}>
                A new password has been emailed to you. If you do not receive an email, your account
                does not exist.
              </Typography>
            </Grid>
            <Grid xs={12} sm={12}>
              <Typography sx={{ mt: 5, textAlign: "right" }}>
                Return to <Link href="/login/adc">Sign In Page</Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ForgetPasswordPage;
