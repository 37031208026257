/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

type UserRole = {
  hasWorkflowManagement: boolean;
};

type InfoState = {
  username?: string;
  role?: UserRole;
};

const initialState: InfoState = {
  username: undefined,
  role: undefined,
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<InfoState>) => {
      state.username = action.payload.username;
      state.role = action.payload.role;
    },
  },
});

export const { setInfo } = infoSlice.actions;

export const infoState = (state: RootState) => state.info;
