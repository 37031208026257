import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { defs } from "../../common/const_defs";
import { AdcTitle1, AdcTitle2 } from "../../components/atoms/AdcTitle";
import ModalDialog from "../../components/molecular/ModalDialog";

import type { ActionCB } from "../../common/type_defs";
import type { FC } from "react";

type OpenWorkflowProps = {
  open: boolean;
  wfType: string;
  fiscalYear: string;
  version: string;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const OpenWorkflowDialog: FC<OpenWorkflowProps> = ({
  open,
  wfType,
  fiscalYear,
  version,
  onClose,
  onAction,
}) => {
  const handleCancel = () => {
    onAction("open-workflow-cancel", null);
  };
  const handleCreate = () => {
    onAction(defs.kAct_ExecOpenWorkflow, null);
  };

  return (
    <ModalDialog
      open={open}
      title="Open Workflow"
      content={
        <Grid container flexDirection={{ xs: "column", sm: "row" }} sx={{ p: 3 }} spacing={2}>
          <Grid xs={12} sm={12} sx={{ mb: 2 }}>
            <AdcTitle1>Are you sure to open this “workflow type”?</AdcTitle1>
          </Grid>
          <Grid xs={12} sm={5} sx={{ pl: 5, alignSelf: "center" }}>
            <AdcTitle2>Fiscal year</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={7} sx={{ pr: 5 }}>
            <TextField
              id="fiscalYear"
              value={fiscalYear}
              size="small"
              disabled
              sx={{ maxWidth: 360 }}
            />
          </Grid>
          <Grid xs={12} sm={5} sx={{ pl: 5, alignSelf: "center" }}>
            <AdcTitle2>Workflow type</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={7} sx={{ pr: 5 }}>
            <TextField id="wfType" value={wfType} size="small" disabled sx={{ maxWidth: 360 }} />
          </Grid>
          <Grid xs={12} sm={5} sx={{ pl: 5, alignSelf: "center" }}>
            <AdcTitle2>Version</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={7} sx={{ pr: 5 }}>
            <TextField id="version" value={version} size="small" disabled sx={{ maxWidth: 360 }} />
          </Grid>
        </Grid>
      }
      buttons={
        <Grid id="xxx" container sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" onClick={handleCreate}>
              Create
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="medium"
    />
  );
};

export { OpenWorkflowDialog };
