import { useSnackbar } from "notistack";

import { mainApi } from "./base";

import type { CondtItemData, FileDownloadItem } from "common/type_defs";

export type PrepareFileRequest = {
  fileType: string;
  CECodeList?: string[];
  filePathList?: string[];
  fiscalYear?: number;
  fixVersion?: string;
  workflowType?: string;
  workflowStep?: string;
  presetType?: string;
  jobId?: string;
  name?: string;
  requestId?: string;

  // new report dialog の API がキャメルではない
  report_type?: string;
  preset_type?: string;
  filter_data?: CondtItemData[];
};

export type GenerateDownloadURLRequest = {
  methodType: "download";
  category: "MASTER" | "WORKFLOW";
  fileType: string;
  filePath: string;
  fileName?: string;
};

export type GenerateUploadURLRequest = {
  methodType: "upload";
  category: "MASTER" | "WORKFLOW";
  fileName: string;
  fileType?: string;
  jobId?: string;
  workflowType?: string;
  workflowStep?: string;
};

export const fileApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFileList: builder.query<
      FileDownloadItem[],
      {
        filter: Record<string, unknown>;
      }
    >({
      query: ({ filter }) => ({
        url: "file/list",
        params: filter,
        providesTags: ["File"],
      }),
    }),
    prepareFile: builder.mutation<
      { success: boolean; filePath: string; message?: string },
      PrepareFileRequest
    >({
      query: (body) => ({
        url: `file/prepare`,
        method: "POST",
        body,
      }),
      transformResponse: (response: {
        // キャメルじゃないレスポンスがあるため変換する
        file_path?: string;
        filePath?: string;
        success: boolean;
      }) => ({
        success: response.success,
        filePath: response.filePath ?? response.file_path ?? "",
      }),
    }),
    generateDownloadURL: builder.mutation<
      {
        url: string;
      },
      Omit<GenerateDownloadURLRequest, "methodType">
    >({
      query: (params) => ({
        url: `file/generatePresignedURL`,
        params: {
          ...params,
          methodType: "download",
        },
        method: "GET",
      }),
    }),
    generateUploadURL: builder.mutation<
      {
        url: string;
        fields: Record<string, string>;
      },
      Omit<GenerateUploadURLRequest, "methodType">
    >({
      query: (params) => ({
        url: `file/generatePresignedURL`,
        params: {
          ...params,
          methodType: "upload",
        },
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFileListQuery,
  usePrepareFileMutation,
  useGenerateDownloadURLMutation,
  useGenerateUploadURLMutation,
} = fileApi;

type PrepareAndDownloadFile = {
  prepareRequest: PrepareFileRequest;
  generateDownloadURLRequest: Omit<GenerateDownloadURLRequest, "filePath" | "methodType">;
};

export const usePrepareAndDownloadFileMutation = (): [
  (requests: PrepareAndDownloadFile) => Promise<void>,
  { isLoading: boolean },
  {
    prepareResult: typeof prepareResult;
    generateDownloadURLResult: typeof generateDownloadURLResult;
  },
] => {
  const { enqueueSnackbar } = useSnackbar();

  const [prepareFile, prepareResult] = usePrepareFileMutation();
  const [generateDownloadURL, generateDownloadURLResult] = useGenerateDownloadURLMutation();

  const customResult = {
    isLoading: prepareResult.isLoading || generateDownloadURLResult.isLoading,
  };

  const mutationTrigger = async ({
    prepareRequest,
    generateDownloadURLRequest,
  }: PrepareAndDownloadFile): Promise<void> => {
    let filePath: string;
    try {
      const res = await prepareFile(prepareRequest).unwrap();
      filePath = res.filePath;
    } catch (error) {
      enqueueSnackbar("Failed to prepare file.", { variant: "error" });
      throw error;
    }

    let url: string;
    try {
      const res = await generateDownloadURL({
        ...generateDownloadURLRequest,
        filePath,
      }).unwrap();
      url = res.url;
    } catch (error) {
      enqueueSnackbar("Failed to download file.", { variant: "error" });
      throw error;
    }

    const fileName = filePath.split("/")[-1];

    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.click();
    link.remove();
    enqueueSnackbar("Succeeded to download file.", { variant: "success" });
  };

  return [mutationTrigger, customResult, { prepareResult, generateDownloadURLResult }];
};
