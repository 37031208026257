import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Typography } from "@mui/material";
import ModalDialog from "components/molecular/ModalDialog";
import { MaterialReactTable } from "material-react-table";
import { useCallback, useMemo, useState } from "react";
import { usePrepareAndDownloadFileMutation } from "services/file";
import { useGetMasterHistoryQuery } from "services/master";

import type { MRT_ColumnDef } from "material-react-table";
import type { FC } from "react";
import type { MasterHistory } from "services/master";

type GetColumnDefParameter = {
  onDownload: (param: { path: string }) => void;
};
const getColumnDef = ({ onDownload }: GetColumnDefParameter): MRT_ColumnDef<MasterHistory>[] => [
  {
    header: "Filename",
    id: "filename",
    accessorFn: (row) => row.path.split("/").at(-1) ?? "",
    size: 250,
  },
  {
    header: "Username",
    accessorKey: "createdBy",
    size: 200,
  },
  {
    header: "Upload Date",
    accessorKey: "createdAt",
    size: 200,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 100,
  },
  {
    header: "Action",
    size: 120,
    Cell: ({ row }) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => onDownload({ path: row.original.path })}
      >
        Download
      </Button>
    ),
  },
];

interface MasterHistoryDialogProps {
  open: boolean;
  onClose: () => void;
  masterType: string;
}

const MasterHistoryDialog: FC<MasterHistoryDialogProps> = ({ open, onClose, masterType }) => {
  const { data: masterHistoryData, isLoading: isLoadingMasterHistoryData } =
    useGetMasterHistoryQuery(masterType, {
      skip: open === false || masterType === "",
    });
  const [prepareAndDownloadFile, { isLoading }] = usePrepareAndDownloadFileMutation();

  const onDownload = useCallback(
    ({ path }: { path: string }) => {
      prepareAndDownloadFile({
        prepareRequest: {
          filePathList: [path],
          fileType: masterType,
        },
        generateDownloadURLRequest: {
          category: "MASTER",
          fileType: masterType,
        },
      }).catch();
    },
    [prepareAndDownloadFile, masterType]
  );

  const columns = useMemo(() => getColumnDef({ onDownload }), [onDownload]);

  return (
    <ModalDialog
      content={
        <MaterialReactTable
          columns={columns}
          data={masterHistoryData?.histories ?? []}
          initialState={{ density: "compact" }}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting
          enableBottomToolbar={false}
          enableTopToolbar={false}
          enableRowVirtualization
          muiTableContainerProps={{ sx: { height: 500 } }}
          muiTableBodyRowProps={{ hover: true }}
        />
      }
      onClose={onClose}
      open={open}
      title={
        <>
          <Typography>
            Master History
            <CircularProgress
              sx={{
                display: isLoadingMasterHistoryData ? "inline-flex" : "none",
                verticalAlign: "bottom",
                padding: "5px",
                color: (theme) => theme.palette.text.secondary,
              }}
              size={30}
            />
          </Typography>
        </>
      }
      buttons={
        <LoadingButton variant="contained" loading={isLoading} onClick={onClose}>
          Close
        </LoadingButton>
      }
      size="medium"
      // defHeight={550}
      // defWidth={800}
    />
  );
};

export const useMasterHistoryDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [masterType, setMasterType] = useState<string>("");

  const openMasterHistoryDialog = useCallback((masterType: string) => {
    setOpen(true);
    setMasterType(masterType);
  }, []);

  const closeMasterHistoryDialog = useCallback(() => {
    setOpen(false);
    setMasterType("");
  }, []);

  const renderMasterHistoryDialog = () => (
    <MasterHistoryDialog open={open} onClose={closeMasterHistoryDialog} masterType={masterType} />
  );

  return {
    openMasterHistoryDialog,
    closeMasterHistoryDialog,
    renderMasterHistoryDialog,
  };
};
