import { Button, Box, Paper, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useMemo } from "react";

import { defs } from "../../../common/const_defs";
import { NoneWrapper } from "../../../components/atoms/NoneWrapper";

import type { ActionCB, WorkflowMgmtData } from "../../../common/type_defs";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC, ReactNode } from "react";

type WorkflowActionCellProps = {
  data: WorkflowMgmtData;
  onAction: ActionCB;
};
const WfMgmtActionCell: FC<WorkflowActionCellProps> = ({ data, onAction }) => {
  const handleAction = (act: string) => {
    onAction(act, data);
  };

  const getStatusNode = (node: WorkflowMgmtData): ReactNode => {
    const { actions } = node;
    return (
      <Box sx={{ color: "gray" }}>
        <NoneWrapper show={actions?.detailAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("wf-mgmt-detail")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Detail
          </Button>
        </NoneWrapper>
        <NoneWrapper show={actions?.notifyAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("wf-mgmt-notify")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Notify
          </Button>
        </NoneWrapper>
        <NoneWrapper show={actions?.reopenAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("workflow-reopen")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            ReOpen
          </Button>
        </NoneWrapper>
        <NoneWrapper show={actions?.openAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("open-workflow-open")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Open
          </Button>
        </NoneWrapper>
        <NoneWrapper show={actions?.closeAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("exec-close-workflow")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Close
          </Button>
        </NoneWrapper>
        <NoneWrapper show={actions?.reportAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("new-report")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Report
          </Button>
        </NoneWrapper>
        {/* <NoneWrapper show={actions?.deleteAction ?? false}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="error information"
            onClick={() => handleAction("file-delete")}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Delete
          </Button>
        </NoneWrapper> */}
      </Box>
    );
  };
  return (
    <Typography component="div" variant="body2">
      {getStatusNode(data)}
    </Typography>
  );
};

const FixDataActionCell: FC<WorkflowActionCellProps> = ({ data, onAction }) => {
  const handleAction = (act: string) => {
    onAction(act, data);
  };
  const actions = data.actions as Record<string, boolean>;

  return (
    <Typography component="div" variant="body2">
      <Box sx={{ color: "gray" }}>
        <NoneWrapper show={actions?.fixDataAction ?? false}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleAction("fix-data-open")}
            sx={{ minWidth: 120 }}
          >
            Fix Data
          </Button>
        </NoneWrapper>
        <NoneWrapper show={actions?.deleteAction ?? false}>
          <Button
            color="error"
            variant="outlined"
            aria-label="delete fix version"
            onClick={() => handleAction(defs.kAct_ExecFixVerDelete)}
            sx={{ minWidth: 120 }}
          >
            Delete Version
          </Button>
        </NoneWrapper>
      </Box>
    </Typography>
  );
};

const getColumnDef = (onAction: ActionCB): MRT_ColumnDef<WorkflowMgmtData>[] => [
  {
    accessorKey: "workflowType",
    header: "Workflow Name",
    size: 120,
  },
  {
    accessorKey: "version",
    header: "Version",
    size: 60,
  },
  {
    accessorKey: "createdAt",
    header: "Created at",
    size: 60,
  },
  {
    accessorKey: "workflowStatus",
    header: "Status",
    size: 120,
  },
  {
    accessorKey: "workflowType",
    header: "Action",
    size: 160,
    Cell: ({ cell, row }) => <WfMgmtActionCell data={row.original} onAction={onAction} />,
  },
  {
    accessorKey: "actions",
    header: "Fix Data",
    size: 100,
    Cell: ({ cell, row }) => <FixDataActionCell data={row.original} onAction={onAction} />,
  },
];
type WfMgmtListProps = {
  isLoading: boolean;
  maxHeight: number;
  data: WorkflowMgmtData[];
  onAction: ActionCB;
};

const WfMgmtList: FC<WfMgmtListProps> = ({ data, onAction, maxHeight, isLoading }) => {
  const columns = useMemo<MRT_ColumnDef<WorkflowMgmtData>[]>(
    () => getColumnDef(onAction),
    [onAction]
  );

  return (
    <Paper elevation={0} sx={{ px: 2 }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        getRowId={(ws, index: number, parentRow: MRT_Row<WorkflowMgmtData>) =>
          (ws.workflowType as string) + (ws.version ?? "")
        }
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        state={{ isLoading }}
        muiTableContainerProps={{ sx: { maxHeight: maxHeight ?? 800 } }}
        muiTableBodyRowProps={{ hover: true }}
        initialState={{ sorting: [{ id: "workflowType", desc: false }] }}
      />
    </Paper>
  );
};

export { WfMgmtActionCell, WfMgmtList };
