import { Inbox as InboxIcon } from "@mui/icons-material";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// styles
import Dot from "../Dot";

import useStyles from "./styles";

const isPathMatch = (path, matchTo) => {
  const aryPath = path.split("/");
  const aryMatchTo = matchTo.split("/");
  if (aryPath.length < aryMatchTo.length) {
    return false;
  }
  for (let i = 0; i < aryMatchTo.length; ++i) {
    if (aryMatchTo[i] !== aryPath[i]) {
      return false;
    }
  }
  return true;
};
const SidebarLink = ({ link, icon, label, children, location, isSidebarOpened, nested, type }) => {
  const classes = useStyles();

  // local
  const [isOpen, setIsOpen] = useState(false);
  const isLinkActive = link && isPathMatch(location.pathname, link);

  if (type === "title") {
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );
  }
  if (type === "divider") {
    return <Divider className={classes.divider} />;
  }

  if (link && link.includes("http")) {
    return (
      <ListItem
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        sx={{
          pl: 0,
          pr: isSidebarOpened ? 2 : 0,
        }}
      >
        <a className={classes.externalLink} href={link}>
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              display: isSidebarOpened ? "block" : "none",
              fontWeight: isLinkActive ? 800 : 400,
            }}
            className={classnames({ [classes.linkTextActive]: isLinkActive })}
            primary={label}
          />
        </a>
      </ListItem>
    );
  }
  if (!children) {
    return (
      <ListItem
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        sx={{
          pl: 0,
          pr: isSidebarOpened ? 2 : 0,
        }}
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            display: isSidebarOpened ? "block" : "none",
            fontWeight: isLinkActive ? 800 : 400,
          }}
          className={classnames({ [classes.linkTextActive]: isLinkActive })}
          primary={label}
        />
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        to={link}
        sx={{
          pl: 0,
          pr: isSidebarOpened ? 2 : 0,
        }}
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon || <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          sx={{
            display: isSidebarOpened ? "block" : "none",
            fontWeight: isLinkActive ? 800 : 400,
          }}
          className={classnames({ [classes.linkTextActive]: isLinkActive })}
          primary={label}
        />
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
};

export default SidebarLink;
