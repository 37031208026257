import { createSelectionListFromStringArray } from "util/create-selection";

import { Button, Box, Paper, Typography, Stack } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useState, useMemo } from "react";
import { useGetCountryListQuery, useGetWorkflowStatusListQuery } from "services/const";
import { useGetWorkflowStepQuery } from "services/workflow";

import { defs } from "../../../common/const_defs";
import { CommonSelect } from "../../../components/molecular/CommonSelect";
import { LabelInput } from "../../../components/molecular/LabelInput";
import { LabelSelect2 } from "../../../components/molecular/LabelSelect";

import type { PE } from "../../../api/api";
import type { ActionCB, SelectNode } from "../../../common/type_defs";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC } from "react";

type WsStatusFilterProps = {
  filters: Record<string, unknown>;
  onChanged: (v: Record<string, unknown>) => void;
  onApply: (d: Record<string, unknown>) => void;
  wfType: string;
};

const WsStatusFilterPanel: FC<WsStatusFilterProps> = ({ filters, onChanged, onApply, wfType }) => {
  const [country, setCountry] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [groupCode, setGroupCode] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [branchCode, setBranchCode] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [wfStep, setWfStep] = useState<string>("");
  const [wfStatus, setWfStatus] = useState<string>("");
  const [companyCategory, setCompanyCategory] = useState<string>("");

  const { data: countryListData } = useGetCountryListQuery();
  const { data: workflowStatusList } = useGetWorkflowStatusListQuery();
  const { data: workflowStepList } = useGetWorkflowStepQuery({
    workflowType: wfType,
  });

  const countrySelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(countryListData?.countryList) ?? [],
    [countryListData?.countryList]
  );
  const workflowStatusSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowStatusList) ?? [],
    [workflowStatusList]
  );
  const workflowStepSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowStepList) ?? [],
    [workflowStepList]
  );

  const handleTextChanged = (code: string, txt: string) => {
    if (code === "groupCode") {
      setGroupCode(txt);
    } else if (code === "branchCode") {
      setBranchCode(txt);
    } else if (code === "companyCode") {
      setCompanyCode(txt);
    } else if (code === "companyName") {
      setCompanyName(txt);
    } else if (code === "companyCategory") {
      setCompanyCategory(txt);
    } else if (code === "branchName") {
      setBranchName(txt);
    }
  };
  const handleCountryChanged = (nv: SelectNode) => {
    setCountry(nv.value);
  };
  const handleWfStatusChanged = (v: SelectNode) => {
    setWfStatus(v.value);
  };
  const handleWfStepChanged = (v: SelectNode) => {
    setWfStep(v.value);
  };

  const collectFilters = (): Record<string, unknown> => ({
    country,
    groupCode,
    branchCode,
    branchName,
    companyCode,
    companyName,
    WorkflowType: wfType,
    workflowStep: wfStep,
    status: wfStatus,
    companyCategory,
  });
  const handleReset = () => {
    setCountry("");
    setGroupCode("");
    setBranchCode("");
    setBranchName("");
    setCompanyCode("");
    setCompanyName("");
    setCompanyCategory("");
    setWfStep("");
    setWfStatus("");
  };
  const handleApply = () => {
    onApply(collectFilters());
  };

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", gap: 1, justifyContent: "flex-start" }}>
        <LabelInput
          key="groupCode"
          name="groupCode"
          label="Group Code"
          value={groupCode}
          onChanged={(vs) => handleTextChanged("groupCode", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="companyCode"
          name="companyCode"
          label="Company Code"
          value={companyCode}
          onChanged={(vs) => handleTextChanged("companyCode", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="branchCode"
          name="branchCode"
          label="Branch Code"
          value={branchCode}
          onChanged={(vs) => handleTextChanged("branchCode", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="branchName"
          name="branchName"
          label="Branch Name"
          value={branchName}
          onChanged={(vs) => handleTextChanged("branchName", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Country
          </Typography>
          <CommonSelect
            value={country}
            options={countrySelectionList}
            onChanged={handleCountryChanged}
            sxx={{ minWidth: 150 }}
          />
        </Stack>
        <LabelInput
          key="companyName"
          name="companyName"
          label="Company Name"
          value={companyName}
          onChanged={(vs) => handleTextChanged("companyName", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="companyCategory"
          name="companyCategory"
          label="Company Category"
          value={companyCategory}
          onChanged={(vs) => handleTextChanged("companyCategory", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        {/* <Stack direction="column">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Workflow Status
          </Typography>
          <CommonSelect value={wfStatus} options={optsWfStatus} onChanged={handleWfStatusChanged} sxx={{ minWidth: 180 }} />
        </Stack> */}
      </Stack>
      <Stack direction="row" sx={{ mb: 0, width: "100%", gap: 1 }}>
        <LabelSelect2
          label="Workflow Step"
          value={wfStep}
          options={workflowStepSelectionList}
          onChanged={handleWfStepChanged}
        />
        <LabelSelect2
          label="Workflow Status"
          value={wfStatus}
          options={workflowStatusSelectionList}
          onChanged={handleWfStatusChanged}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{
          mt: 1,
          width: "100%",
          gap: "0.5rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Button variant="outlined" onClick={() => handleApply()}>
          Apply
        </Button>
        <Button variant="outlined" onClick={() => handleReset()}>
          Reset
        </Button>
      </Stack>
    </Box>
  );
};

const getColumnDef = (onAction: ActionCB, limit: Record<string, boolean>): MRT_ColumnDef<PE>[] => [
  {
    accessorKey: "companyName",
    header: "Company",
    size: 120,
  },
  {
    accessorKey: "PEName",
    header: "Branch Name",
    size: 150,
  },
  {
    accessorKey: "PECode",
    header: "CE Code",
    size: 120,
  },
  {
    accessorKey: "country",
    header: "Country",
    size: 150,
  },
  {
    accessorKey: "companyCategoryDisplay",
    header: "Company Category",
    size: 280,
  },
  {
    accessorKey: "workflowStatus",
    header: "Job Status",
    size: 250,
  },
  {
    accessorKey: "PECode",
    header: "Action",
    size: 160,
    Cell: ({ cell, row }) => (
      <>
        <Button
          size="small"
          color="primary"
          variant="text"
          aria-label="error information"
          onClick={() => onAction(defs.kAct_ViewPeStatus, row.original.PECode)}
          sx={{ m: 0, minWidth: 35 }}
        >
          CE Status
        </Button>
        <Button
          size="small"
          color="primary"
          variant="text"
          aria-label="error information"
          onClick={() => onAction("pe-remind", row.original)}
          sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
        >
          Remind
        </Button>
      </>
    ),
  },
];
type WfStatusNodeListProps = {
  isLoading: boolean;
  peList: PE[];
  limit: Record<string, boolean>;
  onAction: ActionCB;
};

const WfStatusNodeList: FC<WfStatusNodeListProps> = ({ peList, limit, onAction, isLoading }) => {
  const columns = useMemo<MRT_ColumnDef<PE>[]>(
    () => getColumnDef(onAction, limit),
    [onAction, limit]
  );

  return (
    <Paper elevation={0} sx={{ px: 2 }}>
      <MaterialReactTable
        columns={columns}
        data={peList}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        getRowId={(pe, index: number, parentRow: MRT_Row<PE>) => pe.PECode}
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        state={{ isLoading }}
        muiTableContainerProps={{ sx: { maxHeight: 500 } }}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

export { WsStatusFilterPanel, WfStatusNodeList };
