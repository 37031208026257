import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { isApiFailedError } from "services/common/type-guard";
import { usePrepareAndDownloadFileMutation, usePrepareFileMutation } from "services/file";

import ModalDialog from "../../components/molecular/ModalDialog";

import ReportExtractPanel from "./components/ReportExtractPanel";

import type { ActionCB, CondtItemData } from "../../common/type_defs";
import type { FC } from "react";

type NewReportProps = {
  open: boolean;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const NewReportDialog: FC<NewReportProps> = ({ open, onClose, onAction }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [reportType, setReportType] = useState<string>("");
  const [items, setItems] = useState<CondtItemData[]>([]);

  const [prepareFile] = usePrepareFileMutation();
  const [prepareAndDownloadFile] = usePrepareAndDownloadFileMutation();

  useEffect(() => {
    setReportType("");
  }, [open]);

  const validateFilter = (items: CondtItemData[]) => {
    const targetItems = items.filter((item) => item.key === "fiscal_year_and_version");
    if (targetItems.length === 0) {
      return "Fiscal Year is Required";
    }
    return "";
  };

  const handleCancel = () => {
    onAction("new-report-cancel", null);
  };
  const handleCreate = async () => {
    const err = validateFilter(items);
    if (err) {
      enqueueSnackbar(err, { variant: "error" });
      return;
    }

    try {
      const prepareResponse = await prepareFile({
        report_type: reportType,
        fileType: "REPORT",
        preset_type: "NEW",
        filter_data: items,
      }).unwrap();
      enqueueSnackbar(prepareResponse.message ?? `Succeeded to prepare file`, {
        variant: "success",
      });
    } catch (error) {
      if (isApiFailedError(error)) {
        enqueueSnackbar(error.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to prepare file.", { variant: "error" });
      }
    }
  };

  const handlerDownload = () => {
    prepareAndDownloadFile({
      prepareRequest: {
        report_type: reportType,
        fileType: "REPORT",
        preset_type: "existing",
        filter_data: items,
      },
      generateDownloadURLRequest: {
        fileType: "REPORT",
        category: "WORKFLOW",
      },
    }).catch();
  };

  return (
    <ModalDialog
      open={open}
      title="Report"
      content={
        <ReportExtractPanel
          setReportType={setReportType}
          items={items}
          setItems={setItems}
          reportType={reportType}
        />
      }
      buttons={
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" onClick={handleCreate}>
              Generate Report
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" onClick={handlerDownload}>
              Download Report
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="medium"
    />
  );
};

export default NewReportDialog;
export { NewReportDialog };
