import { mainApi } from "./base";

import type { MasterInfo } from "common/type_defs";

export type MasterHistory = {
  createdAt: string;
  createdBy: string;
  status: string;
  path: string;
};

type MasterHistoryResponse = {
  histories: MasterHistory[];
};

const masterApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMasterList: builder.query<MasterInfo[], void>({
      query: () => ({
        url: "master/list",
      }),
      providesTags: ["Master"],
    }),
    getMasterHistory: builder.query<MasterHistoryResponse, string>({
      query: (masterType) => ({
        url: `master/history/${masterType}`,
      }),
      providesTags: ["Master"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetMasterListQuery, useGetMasterHistoryQuery } = masterApi;
