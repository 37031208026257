/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip, TextField } from "@mui/material";
import { forwardRef } from "react";
import { useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import type { TextFieldProps, InputBaseComponentProps } from "@mui/material";
import type { ReactElement } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import type { NumericFormatProps } from "react-number-format";

type AdcNumericFieldConfig = {
  /**
   * Whether to display error messages with tooltip.
   * If `true`, message tooltip.
   * @defaultValue `false`
   */
  displayErrorTip?: boolean;
  /**
   * Whether to display error messages below the input field.
   * If `true`, message displays.
   * @defaultValue `false`
   */
  displayErrorMessage?: boolean;
  /**
   * Type of value to be managed in the form.
   * @exmaple
   * If you input 1000,
   * - FLOAT: `1000`
   * - STRING: `'1000'`
   * - FORMATTED_STRING: `'1,000'` (depends on your format setting.)
   * @defaultValue `'FLOAT'`
   */
  valueType?: "FLOAT" | "STRING" | "FORMATTED_STRING";
};

type AdcNumericFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  /** Additional settings */
  config?: NumericFormatProps<unknown> & AdcNumericFieldConfig;
  /** Settings for MUI elements */
  muiProps?: {
    /**
     * Settings for TextField inside AdcTextField
     *
     * API: {@link https://mui.com/material-ui/api/text-field/}
     */
    textFieldProps?: TextFieldProps;
  };
};

/**
 * Input of react-number-format
 */
const NumberFormatCustom = forwardRef<
  HTMLInputElement,
  InputBaseComponentProps & AdcNumericFieldConfig
>((props, ref) => {
  // 不要なプロパティを除くためdestructureする
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { displayErrorMessage, valueType, defaultValue, onChange, ...others } = props;

  return (
    <NumericFormat
      {...others}
      getInputRef={ref}
      onValueChange={(values, sourceInfo) => {
        if (!onChange || !sourceInfo.event) return;
        let value: string | number | undefined | null;
        switch (valueType) {
          case "STRING":
            value = values.value;
            break;
          case "FORMATTED_STRING":
            value = values.formattedValue;
            break;
          default:
            value = values.floatValue ?? null;
        }
        const event = {
          ...(sourceInfo.event as React.SyntheticEvent<HTMLInputElement>),
          target: {
            ...sourceInfo.event.target,
            name: props.name,
            value,
          },
        };
        onChange(event);
      }}
    />
  );
});

/**
 * MUI TextField component linked to React Hook Form and react-number-format.
 * Type arguments are optional, but specifying them provides powerful type checking and type inference.
 * @typeParam TFieldValues - Type of the form.
 * @typeParam TName - Field name.
 * @example
 * ```
 * type FormData = {
 *   assets: number;
 * };
 *
 * <AdcNumericField<FormData, 'assets'>
 *   name='assets'
 *   control={control}
 *   rules={{
 *     mandatory_field: 'Enter your assets.',
 *     max: {
 *       value: 1000000,
 *       message: 'Enter $1,000,000 or less.'
 *     }
 *   }}
 *   config={{
 *     displayErrorMessage: true,
 *     thousandSeparator: true,
 *     prefix: '$'
 *   }}
 *   muiProps={{
 *     textFieldProps: {
 *       label: 'Assets',
 *       fullWidth: true
 *     }
 *   }}
 * />
 * ```
 */
export const AdcNumericField: <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: AdcNumericFieldProps<TFieldValues, TName>
) => ReactElement = (props) => {
  const { muiProps, config, ...others } = props;
  const { textFieldProps } = muiProps ?? {};
  const { field, fieldState } = useController(others);

  if (config?.displayErrorTip) {
    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={!!fieldState.error}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={fieldState.error?.message}
      >
        <TextField
          {...textFieldProps}
          name={field.name}
          value={field.value}
          error={!!fieldState.error}
          helperText={config?.displayErrorMessage && fieldState.error?.message}
          onChange={field.onChange}
          onBlur={field.onBlur}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              ...field,
              ...config,
              defaultValue: config?.defaultValue !== null ? config?.defaultValue : undefined,
            },
          }}
        />
      </Tooltip>
    );
  }
  return (
    <TextField
      {...textFieldProps}
      name={field.name}
      value={field.value}
      error={!!fieldState.error}
      helperText={config?.displayErrorMessage && fieldState.error?.message}
      onChange={field.onChange}
      onBlur={field.onBlur}
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: {
          ...field,
          ...config,
          defaultValue: config?.defaultValue !== null ? config?.defaultValue : undefined,
        },
      }}
    />
  );
};
