const msgAuth = {
  kMsg_SignError_ADC: "This email is not registered or is incorrect.",
  kMsg_SignError_Corp: "Your authentication information is incorrect. Please try again.",
  kMsg_LoginError: "Your authentication information is incorrect. Please try again.",
  kMsg_MfaError_SMS: "Errors were encountered. Please contact the system administrator.",
};
const msgValidation = {
  kMsg_MandatoryField: "This field is mandatory_field.",
  kMsg_NotProperEmail: "Not a proper email.",
  kMsg_NotProperPhoneNumber: "Not a proper phone number.",
  kMsg_MustCodeLength6: "Code length is 6.",
};
const msg = {
  auth: msgAuth,
  validation: msgValidation,
};

export default msg;
export { msg, msgAuth, msgValidation };
