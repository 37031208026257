import { mainApi } from "./base";

import type { PE } from "api";
import type { PEHistory } from "common/type_defs";

export const peApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    searchPE: builder.query<
      { PEList: PE[] },
      Partial<{
        country: string;
        groupCode: string;
        companyCode: string;
        branchCode: string;
        branchName: string;
        companyName: string;
        companyCategory: number;
        workflowType: string;
        workflowStep: string;
        status: string;
        fiscalYear: number;
        fixVersion: string;
      }>
    >({
      query: (params) => ({
        url: "PE/search",
        params,
        invalidatesTags: ["PE"],
        method: "GET",
      }),
    }),
    getPEInfo: builder.query<{ PEList: PE[] }, { PECodeList: string[]; fiscalYear: number }>({
      query: (body) => ({
        url: "PE/info",
        method: "POST",
        body,
      }),
    }),
    getPEHistory: builder.query<PEHistory[], { peCode: string }>({
      query: ({ peCode }) => ({
        url: `PE/${peCode}/history`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchPEQuery,
  useLazySearchPEQuery,
  useLazyGetPEInfoQuery,
  useGetPEHistoryQuery,
} = peApi;
