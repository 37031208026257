import type { SelectNode } from "common/type_defs";

/**
 * string[] から SelectNode[]を作成します。
 * @example
 * ```
 * const values = ["value1", "value2"];
 * const selectionList = createSelectionListFromStringArray(values)
 * // [{ label: "value1", value: "value1" }, { label: "value2", value: "value2" }]
 *
 * const values = undefined
 * const selectionList = createSelectionListFromStringArray(values)
 * // undefined
 *
 * const values = []
 * const selectionList = createSelectionListFromStringArray(values)
 * // []
 * ```
 */
// export const createSelectionListFromStringArray = (items?: string[]): SelectNode[] | undefined =>
//   items?.map((value) => ({ label: value, value }));

export const createSelectionListFromStringArray = (items?: string[]): SelectNode[] | undefined => {
  if (!items) {
    return undefined;
  }
  return [{ label: "　", value: "" }, ...items.map((value) => ({ label: value, value }))];
};
