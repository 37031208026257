import { createSelectionListFromStringArray } from "util/create-selection";

import { Box, Button, Stack } from "@mui/material";
import { useState, useMemo } from "react";
import { useGetCountryListQuery, useGetWorkflowStatusListQuery } from "services/const";
import { useGetWorkflowStepQuery, useGetWorkflowTypeListQuery } from "services/workflow";

import { LabelInput } from "../molecular/LabelInput";
import { LabelSelect2 } from "../molecular/LabelSelect";

import type { SelectNode } from "../../common/type_defs";
import type { FC } from "react";
import type { CEFilterOptions } from "types/filter";

type CEFilter = {
  groupCode: string;
  companyCode: string;
  branchCode: string;
  branchName: string;
  country: string;
  companyName: string;
  companyCategory: string;
  workflowType: string;
  workflowStep: string;
  status: string;
};

type CompanyFilterProps = {
  onSearch: (filter: CEFilter) => void;
  options?: CEFilterOptions;
};

const CompanyFilterPanel: FC<CompanyFilterProps> = ({ onSearch, options }) => {
  const [country, setCountry] = useState<string>("");
  const [wfType, setWfType] = useState<string>(options?.workflowType ?? "");
  const [wfStep, setWfStep] = useState<string>(options?.workflowStep ?? "");
  const [wfStatus, setWfStatus] = useState<string>(options?.jobStatus ?? "");
  const [groupCode, setGroupCode] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [branchCode, setBranchCode] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyCategory, setCompanyCategory] = useState<string>("");

  const { data: countryListData } = useGetCountryListQuery();
  const { data: workflowStatusList } = useGetWorkflowStatusListQuery();
  const { data: workflowTypeList } = useGetWorkflowTypeListQuery({
    // wfType,
    status: "OPEN",
  });
  const { data: workflowStepList } = useGetWorkflowStepQuery({
    workflowType: wfType,
  });

  const countrySelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(countryListData?.countryList) ?? [],
    [countryListData?.countryList]
  );
  const workflowStatusSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowStatusList) ?? [],
    [workflowStatusList]
  );
  const workflowTypeSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowTypeList) ?? [],
    [workflowTypeList]
  );
  const workflowStepSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowStepList) ?? [],
    [workflowStepList]
  );

  const collectFilters: CEFilter = useMemo(
    () => ({
      groupCode,
      companyCode,
      branchCode,
      branchName,
      country,
      companyName,
      companyCategory,
      workflowType: wfType,
      workflowStep: wfStep,
      status: wfStatus,
      fixVersion: options?.fixVersion ?? undefined,
      fiscalYear: options?.fiscalYear ?? undefined,
    }),
    [
      branchCode,
      branchName,
      companyCategory,
      companyCode,
      companyName,
      country,
      groupCode,
      wfStatus,
      wfStep,
      wfType,
      options,
    ]
  );

  const handleCountryChanged = (nv: SelectNode) => {
    setCountry(nv.value);
  };
  const handleWfStatusChanged = (v: SelectNode) => {
    setWfStatus(v.value);
  };
  const handleWfTypeChanged = (v: SelectNode) => {
    setWfType(v.value);
  };
  const handleWfStepChanged = (v: SelectNode) => {
    setWfStep(v.value);
  };
  const handleTextChanged = (code: string, txt: string) => {
    if (code === "groupCode") {
      setGroupCode(txt);
    } else if (code === "branchCode") {
      setBranchCode(txt);
    } else if (code === "companyCode") {
      setCompanyCode(txt);
    } else if (code === "companyName") {
      setCompanyName(txt);
    } else if (code === "companyCategory") {
      setCompanyCategory(txt);
    } else if (code === "branchName") {
      setBranchName(txt);
    }
  };

  const handleReset = () => {
    setGroupCode("");
    setBranchCode("");
    setBranchName("");
    setCompanyCode("");
    setCompanyName("");
    setCompanyCategory("");
    setCountry("");
    setWfType("");
    setWfStep("");
    setWfStatus("");
  };
  const handleSearch = () => {
    onSearch(collectFilters);
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Stack direction="row" sx={{ width: "100%", gap: 1, justifyContent: "flex-start" }}>
        <LabelInput
          key="groupCode"
          name="groupCode"
          label="Group Code"
          value={groupCode}
          onChanged={(vs) => handleTextChanged("groupCode", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="companyCode"
          name="companyCode"
          label="Company Code"
          value={companyCode}
          onChanged={(vs) => handleTextChanged("companyCode", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="branchCode"
          name="branchCode"
          label="Branch Code"
          value={branchCode}
          onChanged={(vs) => handleTextChanged("branchCode", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="branchName"
          name="branchName"
          label="Branch Name"
          value={branchName}
          onChanged={(vs) => handleTextChanged("branchName", vs)}
          sx={{ minWidth: "120px" }}
          // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          size="small"
        />
        <LabelSelect2
          label="Country"
          value={country}
          options={countrySelectionList}
          onChanged={handleCountryChanged}
        />
        <LabelInput
          key="companyName"
          name="companyName"
          label="Company Name"
          value={companyName}
          onChanged={(vs) => handleTextChanged("companyName", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
      </Stack>
      <Stack direction="row" sx={{ mb: 0, width: "100%", gap: 1, justifyContent: "space-between" }}>
        <LabelInput
          key="companyCategory"
          name="companyCategory"
          label="Company Category"
          value={companyCategory}
          onChanged={(vs) => handleTextChanged("companyCategory", vs)}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelSelect2
          label="Workflow Type"
          disabled={!!options?.workflowType}
          value={
            options?.workflowType
              ? ({
                  value: options.workflowType,
                  label: options.workflowType,
                } as SelectNode)
              : wfType
          }
          options={workflowTypeSelectionList}
          onChanged={handleWfTypeChanged}
        />
        <LabelSelect2
          label="Workflow Step"
          value={
            options?.workflowStep
              ? ({
                  value: options.workflowStep,
                  label: options.workflowStep,
                } as SelectNode)
              : wfStep
          }
          options={
            options?.workflowStep
              ? ([{ value: options.workflowStep, label: options.workflowStep }] as SelectNode[])
              : workflowStepSelectionList
          }
          disabled={!!(!wfType || options?.workflowStep)}
          onChanged={handleWfStepChanged}
        />
        <LabelSelect2
          label="Workflow Status"
          value={
            options?.jobStatus
              ? ({
                  value: options?.jobStatus,
                  label: options?.jobStatus,
                } as SelectNode)
              : wfStatus
          }
          options={workflowStatusSelectionList}
          disabled={!!options?.jobStatus}
          onChanged={handleWfStatusChanged}
        />
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          onClick={handleReset}
          sx={{ maxHeight: 36, alignSelf: "flex-end" }}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ maxHeight: 36, alignSelf: "flex-end" }}
        >
          Search
        </Button>
      </Stack>
    </Box>
  );
};

export default CompanyFilterPanel;
