import { createSelectionListFromStringArray } from "util/create-selection";

import { Box, Button, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState, useMemo } from "react";
import { useGetCountryListQuery } from "services/const";
import { useGetWorkflowListQuery, useGetWorkflowTypeListQuery } from "services/workflow";

import { kOpts_FiscalYear } from "../../../common/const_defs";
import { LabelInput } from "../../../components/molecular/LabelInput";
import { LabelSelect2 } from "../../../components/molecular/LabelSelect";

import type { SelectNode } from "../../../common/type_defs";
import type { Dayjs } from "dayjs";
import type { FC } from "react";

type FileFilterProps = {
  onFilterReset: (v: Record<string, unknown>) => void;
  onSearch: (d: Record<string, unknown>) => void;
};

const FileFilterPanel: FC<FileFilterProps> = ({ onFilterReset, onSearch }) => {
  const [fiscalYear, setFiscalYear] = useState<string>(kOpts_FiscalYear[0].value);
  const [country, setCountry] = useState<string>("");
  const [wfType, setWfType] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [groupCode, setGroupCode] = useState<string>("");
  const [branchCode, setBranchCode] = useState<string>("");
  const [dateFr, setDateFr] = useState<Dayjs | null>(null);
  const [dateTo, setDateTo] = useState<Dayjs | null>(null);

  const { data: countryListData } = useGetCountryListQuery();
  const { data: workflowTypeList } = useGetWorkflowTypeListQuery({
    wfType: "SUBMITTING_FILE_",
    status: "OPEN",
  });
  const { data: workflowList } = useGetWorkflowListQuery({
    fiscalYear: parseInt(fiscalYear, 10),
    workflowType: wfType,
  });

  const countrySelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(countryListData?.countryList) ?? [],
    [countryListData?.countryList]
  );
  const workflowTypeSelectionList: SelectNode[] = useMemo(
    // () => workflowTypeList?.map((type) => ({ label: type, value: type })) ?? [],
    () => createSelectionListFromStringArray(workflowTypeList) ?? [],
    [workflowTypeList]
  );
  const fileTypeSelectionList: SelectNode[] = useMemo(
    // () => workflowList?.at(0)?.fileTypes?.map((type) => ({ label: type, value: type })) ?? [],
    () => createSelectionListFromStringArray(workflowList?.at(0)?.fileTypes) ?? [],
    [workflowList]
  );

  const handleFiscalYearChanged = (nv: SelectNode) => {
    setFiscalYear(nv.value);
  };
  const handleCountryChanged = (nv: SelectNode) => {
    setCountry(nv.value);
  };
  const handleWfTypeChanged = (v: SelectNode) => {
    setWfType(v.value);
  };
  const handleFileTypeChanged = (v: SelectNode) => {
    setFileType(v.value);
  };
  const handleCompanyNameChanged = (txt: string) => {
    setCompanyName(txt);
  };
  const handleCompanyCodeChanged = (txt: string) => {
    setCompanyCode(txt);
  };
  const handleGroupCodeChanged = (txt: string) => {
    setGroupCode(txt);
  };
  const handleBranchCodeChanged = (txt: string) => {
    setBranchCode(txt);
  };
  const collectFilter = (): Record<string, unknown> => ({
    fiscalYear,
    groupCode,
    companyCode,
    branchCode,
    country,
    companyName,
    workflowType: wfType,
    fileType,
    updateDateFrom: dateFr?.format("YYYY-MM-DD"),
    updateDateTo: dateTo?.format("YYYY-MM-DD"),
  });
  const handleReset = () => {
    setFiscalYear("");
    setCountry("");
    setWfType("");
    setFileType("");
    setCompanyName("");
    setCompanyCode("");
    setGroupCode("");
    setBranchCode("");
    setDateFr(null);
    setDateTo(null);
    if (onFilterReset) {
      onFilterReset(collectFilter());
    }
  };
  const handleApply = () => {
    if (onSearch) {
      onSearch(collectFilter());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" sx={{ width: "100%", gap: 1, justifyContent: "flex-start" }}>
        <LabelSelect2
          label="Fiscal Year"
          value={fiscalYear}
          options={kOpts_FiscalYear}
          onChanged={handleFiscalYearChanged}
        />
        <LabelInput
          key="company_name"
          name="company_name"
          label="Company Name"
          value={companyName}
          onChanged={handleCompanyNameChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="group_code"
          name="group_code"
          label="Group Code"
          value={groupCode}
          onChanged={handleGroupCodeChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="company_code"
          name="company_code"
          label="Company Code"
          value={companyCode}
          onChanged={handleCompanyCodeChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="branch_code"
          name="branch_code"
          label="Branch Code"
          value={branchCode}
          onChanged={handleBranchCodeChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelSelect2
          label="Country"
          value={country}
          options={countrySelectionList}
          onChanged={handleCountryChanged}
        />
      </Stack>
      <Stack direction="row" sx={{ mt: 1, width: "100%", gap: 1, justifyContent: "space-between" }}>
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Upload date
          </Typography>
          <Stack sx={{ color: "grey.500" }} direction="row">
            <DatePicker
              format="YYYY 年 M 月 DD 日"
              views={["year", "month", "day"]}
              minDate={dayjs("2021-04-01")}
              value={dateFr}
              onChange={setDateFr}
              // renderInput={(params) => (
              //   <TextField size="small" {...params} helperText={null} sx={{ minWidth: 185 }} />
              // )}
              slotProps={{ textField: { size: "small", helperText: null, sx: { minWidth: 185 } } }}
            />
            <Typography variant="h6">~</Typography>
            <DatePicker
              format="YYYY 年 M 月 DD 日"
              views={["year", "month", "day"]}
              minDate={dayjs("2021-04-01")}
              value={dateTo}
              onChange={setDateTo}
              // renderInput={(params) => (
              //   <TextField size="small" {...params} helperText={null} sx={{ minWidth: 185 }} />
              // )}
              slotProps={{ textField: { size: "small", helperText: null, sx: { minWidth: 185 } } }}
            />
          </Stack>
        </Stack>
        <LabelSelect2
          label="Workflow Type"
          value={wfType}
          options={workflowTypeSelectionList}
          onChanged={handleWfTypeChanged}
        />
        <LabelSelect2
          label="File Type"
          value={fileType}
          options={fileTypeSelectionList}
          disabled={!wfType}
          onChanged={handleFileTypeChanged}
        />
        <Box sx={{ flex: 1 }} />
        <Button
          variant="contained"
          onClick={handleApply}
          sx={{ maxHeight: 36, alignSelf: "flex-end" }}
        >
          Apply
        </Button>
        <Button
          variant="outlined"
          onClick={handleReset}
          sx={{ maxHeight: 36, alignSelf: "flex-end" }}
        >
          Reset
        </Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default FileFilterPanel;
