import { Button } from "@mui/material";
import ModalDialog from "components/molecular/ModalDialog";
import { useState } from "react";

import type { ReactElement } from "react";

export const useConfirm = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string | ReactElement>("");

  const [resolvePromise, setResolvePromise] = useState<undefined | ((result?: boolean) => void)>();

  const initializeState = (params?: { title: string; content: string | ReactElement }) => {
    setTitle(params?.title ?? "");
    setContent(params?.content ?? "");
  };

  const openConfirmDialog = async (params: {
    title: string;
    content: string | ReactElement;
  }): Promise<boolean> => {
    initializeState(params);
    setOpen(true);

    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  };

  const closeConfirmDialog = (result: boolean) => {
    initializeState();
    setOpen(false);

    resolvePromise?.(result);
  };

  const handleOnOK = () => {
    closeConfirmDialog(true);
  };

  const handleOnCancel = () => {
    closeConfirmDialog(false);
  };

  const renderConfirmDialog = () => (
    <ModalDialog
      open={open}
      title={title}
      content={content}
      buttons={
        <>
          <Button variant="outlined" onClick={handleOnCancel}>
            No
          </Button>
          <Button color="primary" onClick={handleOnOK} variant="contained">
            Yes
          </Button>
        </>
      }
      onClose={handleOnCancel}
      size="small"
    />
  );

  return {
    renderConfirmDialog,
    openConfirmDialog,
    closeConfirmDialog,
  };
};
