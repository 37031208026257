import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";

import { kOpt_SelNode_None, kOpts_WF_Step } from "../../../common/const_defs";
import { CommonSelect } from "../../../components/molecular/CommonSelect";

import type { SelectNode } from "../../../common/type_defs";
import type { FC } from "react";

// const kClr_bg = "#FFF3E6";
const kClr_bg = "#FFFFFF";

type JobActionSubmitProps = {
  onAction: (act: string, data: unknown) => void;
};

const JobActionSubmitPanel: FC<JobActionSubmitProps> = ({ onAction }) => {
  const [approver, setApprover] = useState<SelectNode>(kOpt_SelNode_None);
  const [nextStep, setNextStep] = useState<SelectNode>(kOpt_SelNode_None);
  const handleApproverChanged = (nv: SelectNode) => {};
  const handleNextStepChanged = (nv: SelectNode) => {
    setNextStep(nv);
  };
  const handleCancel = () => {
    onAction("cancel", null);
  };
  const handleProcess = () => {
    onAction("process", { approver, next_step: nextStep });
  };

  return (
    <Paper elevation={0} sx={{ p: 2, bgcolor: kClr_bg }}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ p: 2, fontSize: "12px" }}
        spacing={2}
      >
        <Grid xs={12} sm={12} sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
            ■ Approver
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <CommonSelect
            value={approver}
            options={[]}
            onChanged={handleApproverChanged}
            sxx={{ minWidth: 250 }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
            ■ Next Step
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <CommonSelect
            value={nextStep}
            options={kOpts_WF_Step}
            onChanged={handleNextStepChanged}
            sxx={{ minWidth: 250 }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobActionSubmitPanel;
