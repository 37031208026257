import { isString } from "util/type-guard";

import { mainApi } from "./base";

import type { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider";

type TokenResponse = {
  ChallengeParameters: never;
  AuthenticationResult: {
    AccessToken: string;
    ExpiresIn: number;
    TokenType: string;
    RefreshToken: string;
    IdToken: string;
  };
  ResponseMetadata: unknown;
};

type RequiredMFAChallengeResponse = {
  ChallengeName: "SMS_MFA" | "SOFTWARE_TOKEN_MFA";
  Session: string;
};

type RequiredChangePasswordResponse = {
  ChallengeName: "NEW_PASSWORD_REQUIRED";
  Session: string;
};

type LoginResponse = {
  ChallengeName?: ChallengeNameType;
  Session?: string;
  AuthenticationResult?: Record<string, string>;
  role: { hasWorkflowManagement: boolean };
} & (TokenResponse | RequiredMFAChallengeResponse | RequiredChangePasswordResponse);

export type AuthApiErrorResponse = {
  code: string;
};

export type EnabledMfa = {
  sms: boolean;
  software: boolean;
};

type GetMfaSettingRequest = {
  accessToken: string;
};

type GetMfaSettingResponse = {
  phoneNumber?: string;
  phoneNumberVerified?: string;
  enabledMfa: EnabledMfa;
};

type UpdateMfaSoftwareResponse = {
  secret: string;
  url: string;
  session?: string;
};

export const MFA_TYPE = {
  SMS: "SMS",
  SOFTWARE: "SOFTWARE",
} as const;
export const MFA_TYPE_OPTIONS = {
  SMS: { label: "SMS", value: MFA_TYPE.SMS },
  SOFTWARE: { label: "AUTHENTICATION APP", value: MFA_TYPE.SOFTWARE },
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MFA_TYPE = (typeof MFA_TYPE)[keyof typeof MFA_TYPE];

const authApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, { username: string; password: string }>({
      query: (body) => ({
        url: "login",
        body,
        method: "POST",
      }),
      transformErrorResponse: (response): AuthApiErrorResponse => {
        // API が json を返してこないため transform を使用
        if (isString(response.data)) {
          return { code: response.data };
        }
        return { code: "UnexpectedError" };
      },
    }),
    getOAuth2Token: builder.mutation<LoginResponse, { code: string; redirect_uri: string }>({
      query: (body) => ({
        url: "oauth2/token",
        body,
        method: "POST",
      }),
    }),
    mfaChallenge: builder.mutation<
      LoginResponse,
      { challengeName: string; username: string; session: string; code: string }
    >({
      query: (body) => ({
        url: "login/mfa",
        body,
        method: "POST",
      }),
      transformErrorResponse: (response): AuthApiErrorResponse => {
        // API が json を返してこないため transform を使用
        if (isString(response.data)) {
          return { code: response.data };
        }
        return { code: "UnexpectedError" };
      },
    }),
    changePassword: builder.mutation<
      LoginResponse,
      { username: string; currentPassword: string; newPassword: string; session: string }
    >({
      query: ({ currentPassword, newPassword, session, username }) => ({
        url: "login/changePassword",
        body: {
          userName: username,
          password: currentPassword,
          newPassword,
          session,
        },
        method: "POST",
      }),
    }),
    getMfaSetting: builder.query<GetMfaSettingResponse, GetMfaSettingRequest>({
      query: (body) => ({
        url: `mfaSetting`,
        method: "POST",
        body,
      }),
      providesTags: ["MfaSetting"],
    }),
    updateMfaPhoneNumber: builder.mutation<void, { accessToken: string; phoneNumber: string }>({
      query: (body) => ({
        url: `mfaSetting/updatePhoneNumber`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["MfaSetting"],
    }),
    updateMfaSoftware: builder.mutation<
      UpdateMfaSoftwareResponse,
      { username: string; accessToken?: string; session?: string }
    >({
      query: (body) => ({
        url: `mfaSetting/updateSoftware`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["MfaSetting"],
    }),
    verifyMfaCode: builder.mutation<
      { enabledMfa: EnabledMfa },
      { mfaType: MFA_TYPE; code: string; accessToken?: string; session?: string }
    >({
      query: (body) => ({
        url: `mfaSetting/verifyMfaCode`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["MfaSetting"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOAuth2TokenMutation,
  useLoginMutation,
  useMfaChallengeMutation,
  useChangePasswordMutation,
  useGetMfaSettingQuery,
  useUpdateMfaPhoneNumberMutation,
  useUpdateMfaSoftwareMutation,
  useVerifyMfaCodeMutation,
} = authApi;
