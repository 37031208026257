import { FileType } from "../common/type_defs";

import type { MailSenderData } from "../common/data_defs";
import type { SelectNode, FileUploadNode } from "../common/type_defs";

const kOptions_WfType: SelectNode[] = [
  {
    value: "wf_1",
    label: "wf_1",
  },
  {
    value: "wf_2",
    label: "wf_2",
  },
  {
    value: "wf_3",
    label: "wf_3",
  },
  {
    value: "wf_4",
    label: "wf_4",
  },
  {
    value: "wf_5",
    label: "wf_5",
  },
];
const kOptions_TargetPE: SelectNode[] = [
  {
    value: "tgt_pe_1",
    label: "tgt_pe_1",
  },
  {
    value: "tgt_pe_2",
    label: "tgt_pe_2",
  },
  {
    value: "tgt_pe_3",
    label: "tgt_pe_3",
  },
  {
    value: "tgt_pe_4",
    label: "tgt_pe_4",
  },
  {
    value: "tgt_pe_5",
    label: "tgt_pe_5",
  },
];
const kOptions_TargetGroup: SelectNode[] = [
  {
    value: "tgt_grp_1",
    label: "tgt_grp_1",
  },
  {
    value: "tgt_grp_2",
    label: "tgt_grp_2",
  },
  {
    value: "tgt_grp_3",
    label: "tgt_grp_3",
  },
  {
    value: "tgt_grp_4",
    label: "tgt_grp_4",
  },
  {
    value: "tgt_grp_5",
    label: "tgt_grp_5",
  },
];
const kOptions_TargetStatus: SelectNode[] = [
  {
    value: "tgt_st_1",
    label: "tgt_st__1",
  },
  {
    value: "tgt_st_2",
    label: "tgt_st_2",
  },
  {
    value: "tgt_st_3",
    label: "tgt_st_3",
  },
  {
    value: "tgt_st_4",
    label: "tgt_st_4",
  },
  {
    value: "tgt_st_5",
    label: "tgt_st_5",
  },
];
const kOptions_MailType: SelectNode[] = [
  {
    value: "mail_type_1",
    label: "mail_type_1",
  },
  {
    value: "mail_type_2",
    label: "mail_type_2",
  },
  {
    value: "mail_type_3",
    label: "mail_type_3",
  },
];
const kMD_MailSender: MailSenderData = {
  wf_type: { cur_sel: kOptions_WfType[0], options: kOptions_WfType },
  target_pe: { cur_sel: kOptions_TargetPE[0], options: kOptions_TargetPE },
  target_group: {
    cur_sel: kOptions_TargetGroup[0],
    options: kOptions_TargetGroup,
  },
  target_status: {
    cur_sel: kOptions_TargetStatus[0],
    options: kOptions_TargetStatus,
  },
  mail_type: { cur_sel: kOptions_MailType[0], options: kOptions_MailType },
  mail_subject: "mail aaaa",
  mail_content: "mail content bbbbb",
  remind_count: 10,
  remind_freq: 10,
};
const kMD_MailTemplates = [
  {
    mailType: "001",
    subject: "mail-t1",
    content: "mail-content-1",
  },
  {
    mailType: "002",
    subject: "mail-t2",
    content: "mail-content-2",
  },
  {
    mailType: "003",
    subject: "mail-t3",
    content: "mail-content-3",
  },
  {
    mailType: "004",
    subject: "mail-t4",
    content: "mail-content-4",
  },
  {
    mailType: "005",
    subject: "mail-t5",
    content: "mail-content-5",
  },
];

const kMD_FileUploadNodeList: FileUploadNode[] = [
  {
    id: "file-1",
    name: "file-1",
    path: "/aaa/bbb",
    fileType: FileType.master,
    peList: [],
    nextAction: "",
  },
  {
    id: "file-2",
    name: "file-2",
    type: "summary",
    path: "/aaa/bbb",
    fileType: FileType.master,
    peList: [],
    nextAction: "",
  },
  {
    id: "file-3",
    name: "file-3",
    type: "1pe",
    path: "/aaa/bbb",
    fileType: FileType.workflow,
    peList: [],
    nextAction: "",
  },
];

const kMD_LimitFull: Record<string, boolean> = {
  new_request: true,
  fix_data: true,
  view: true,
  notify: true,
  check: true,
  open: true,
  reopen: true,
  close: true,
  report: true,
  delete: true,
  download: true,
  upload: true,
};
const kMD_LimitOnly: Record<string, boolean> = {
  new_request: false,
  fix_data: false,
  view: true,
  notify: false,
  check: false,
  open: false,
  reopen: false,
  close: false,
  report: false,
  delete: false,
  download: false,
  upload: false,
};
export { kMD_MailSender, kMD_FileUploadNodeList, kMD_MailTemplates, kMD_LimitFull, kMD_LimitOnly };
