import { LoadingButton } from "@mui/lab";
import { Typography, Button, TextField, Stack } from "@mui/material";
import { WorkflowType } from "api";
import { CommonSelect } from "components/molecular/CommonSelect";
import ModalDialog from "components/molecular/ModalDialog";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useLazyGetPrevActionListQuery, useSendBackJobMutation } from "services/job";

import type { SelectNode } from "common/type_defs";

const initNode: SelectNode = { label: "", value: "" };
const defaultStepList: SelectNode[] = [{ label: "INPUT", value: "INPUT" }];

export const useSendBackJobDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>("");
  const [ceList, setCEList] = useState<string[]>([]);

  const [step, setStep] = useState<SelectNode>(initNode);
  const [stepList, setStepList] = useState<SelectNode[]>([]);
  const [comment, setComment] = useState<string>("");

  const [sendBackJob, { isLoading: isSendBackJobLoading, isSuccess: isSendBackJobSuccess }] =
    useSendBackJobMutation();
  const [getPrevActionList, { isLoading: isGetPrevActionListLoading }] =
    useLazyGetPrevActionListQuery();

  const initializeState = (params?: {
    jobId: string;
    ceList: string[];
    stepList: SelectNode[];
  }) => {
    setJobId(params?.jobId ?? "");
    setCEList(params?.ceList ?? []);
    setStep(initNode);
    setStepList(params?.stepList ?? []);
    setComment("");
  };

  const openSendBackDialog = async ({
    targetJobId,
    targetCEList,
    workflowType,
  }: {
    targetJobId: string;
    targetCEList: string[];
    workflowType: string;
  }) => {
    initializeState({
      ceList: targetCEList,
      jobId: targetJobId,
      stepList: workflowType === WorkflowType.StandardTemplate ? [] : defaultStepList,
    });
    setOpen(true);

    if (workflowType === WorkflowType.StandardTemplate) {
      try {
        const getPrevActionResponse = await getPrevActionList({
          jobId: targetJobId,
        }).unwrap();
        setStepList(
          getPrevActionResponse.map(({ action, displayName }) => ({
            value: action,
            label: displayName,
          }))
        );
      } catch (error) {
        enqueueSnackbar("Get step list error. unable to send back.", { variant: "error" });
      }
    }
  };

  const closeSendBackDialog = () => {
    initializeState();
    setOpen(false);
  };

  const handleOnExecute = async () => {
    try {
      await sendBackJob({
        jobId,
        PECodeList: ceList,
        returnStep: step.value,
        comment,
      }).unwrap();
      enqueueSnackbar("send back success.", { variant: "success" });
      closeSendBackDialog();
    } catch (error) {
      enqueueSnackbar("send back failed.", { variant: "error" });
    }
  };

  const renderSendBackDialog = () => (
    <ModalDialog
      open={open}
      title="Send back to previous step"
      content={
        <Stack direction="column">
          <Typography variant="h5">■ Send back to</Typography>
          <CommonSelect
            isDisabled={isGetPrevActionListLoading}
            value={step}
            options={stepList}
            onChanged={(selectNode) => setStep(selectNode)}
            sxx={{ minWidth: 250 }}
          />
          <Typography variant="h5" sx={{ mt: "1rem" }}>
            Send back comment
          </Typography>
          <TextField
            multiline
            rows={5}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </Stack>
      }
      buttons={
        <>
          <Button variant="contained" onClick={closeSendBackDialog}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={comment === "" || step.value === initNode.value}
            onClick={handleOnExecute}
            loading={isSendBackJobLoading}
          >
            Execute
          </LoadingButton>
        </>
      }
      onClose={closeSendBackDialog}
      size="small"
    />
  );

  return { renderSendBackDialog, openSendBackDialog, closeSendBackDialog, isSendBackJobSuccess };
};
