import { downloadFile } from "util/utils";

import { Typography, Link, Button, Paper, Stack, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useGenerateDownloadURLMutation, usePrepareFileMutation } from "services/file";
import { useGetJobHistoryQuery } from "services/job";

import { defs } from "../../common/const_defs";
import { AdcTitle1, AdcTitle2 } from "../../components/atoms/AdcTitle";
import { useDlgCheckResult, useDlgPeWfStatus } from "../../hooks/DialogContext";

import { JobHistoryFilterPanel, JobHistoryList } from "./components/JobHistoryPanel";

import type { JobHistoryFilter } from "./components/JobHistoryPanel";
import type { JobDetail } from "../../api/api";
import type { ActionCB } from "../../common/const_defs";

type FilterJobDetailsParams = {
  jobDetails: JobDetail[];
  filters: JobHistoryFilter;
};

const filterJobDetails = ({
  jobDetails,
  filters: { branchCode, companyCode, companyName, groupCode },
}: FilterJobDetailsParams): JobDetail[] =>
  jobDetails.filter(({ PECode: code, companyName: name }) => {
    const [group, company, branch] = code.split("-");

    const isGroupMatch = groupCode === undefined || groupCode === "" || group === groupCode;
    const isCompanyMatch =
      companyCode === undefined || companyCode === "" || company === companyCode;
    const isBranchMatch = branchCode === undefined || branchCode === "" || branch === branchCode;
    const isNameMatch =
      companyName === undefined ||
      companyName === "" ||
      name?.toLowerCase().includes(companyName.toLocaleLowerCase());

    return isGroupMatch && isCompanyMatch && isBranchMatch && isNameMatch;
  });

const JobHistoryPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { openCheckResult } = useDlgCheckResult();
  const { openPeWfStatus } = useDlgPeWfStatus();
  const [filters, setFilters] = useState<JobHistoryFilter>({});
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId") ?? "";
  const [prepareFile] = usePrepareFileMutation();
  const [generateDownloadURL] = useGenerateDownloadURLMutation();

  const { data: jobHistoryData, isLoading: isGetJobHistoryLoading } = useGetJobHistoryQuery({
    jobId,
  });
  const jobDetails: JobDetail[] = useMemo(
    () => filterJobDetails({ jobDetails: jobHistoryData?.JobDetails ?? [], filters }),
    [jobHistoryData, filters]
  );

  const handleAction: ActionCB = async (act, data) => {
    if (act === defs.kAct_ViewCheckResult) {
      const jobId = data as string;
      openCheckResult(jobId, handleAction);
    } else if (act === "pe-status") {
      const detail: JobDetail = data as JobDetail;
      openPeWfStatus(detail.PECode, handleAction);
    } else if (act === defs.kAct_DownloadFile) {
      const jobId = data as string;
      const fileType = "JOB_FILES";
      try {
        const { filePath } = await prepareFile({
          fileType,
          jobId,
        }).unwrap();
        const { url } = await generateDownloadURL({
          category: "WORKFLOW",
          fileType,
          filePath,
        }).unwrap();
        const fileName = url.split("/").at(-1);
        downloadFile(url, fileName ?? "file-name");
      } catch (error) {
        enqueueSnackbar("file download failed!", { variant: "warning" });
      }
    } else {
      alert(act);
    }
  };

  const onFilterApply = (filters: JobHistoryFilter) => {
    setFilters(filters);
  };

  const onFilterRest = () => {
    setFilters({});
  };

  return (
    <Paper elevation={0} sx={{ px: 2 }}>
      <Grid container flexDirection={{ xs: "column", sm: "row" }}>
        <Grid xs={12} sm={12} sx={{ mt: -1, mb: 2, ml: -1 }}>
          <Link href="/job-list">Job List</Link>
          <Typography component="span"> / History</Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ ml: -1 }}>
          <Stack
            direction="row"
            sx={{
              mb: 1,
              width: "100%",
              gap: "0.5rem",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <AdcTitle1>{jobHistoryData?.name ?? ""}</AdcTitle1>
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              onClick={() => handleAction(defs.kAct_ViewCheckResult, jobHistoryData?.id)}
              sx={{ textTransform: "none" }}
            >
              View file check result
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mr: 2, mb: 1 }}>
          <AdcTitle2>Summary</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mr: 2, mb: 1, ml: 2 }}>
          <Typography>Workflow Type: {jobHistoryData?.workflowType ?? ""}</Typography>
          <Typography>Workflow Step: {jobHistoryData?.workflowStep ?? ""}</Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mr: 2, mb: 1 }}>
          <AdcTitle2>Filter</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 2 }}>
          <JobHistoryFilterPanel onApply={onFilterApply} onReset={onFilterRest} />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mr: 2, mb: 1 }}>
          <Stack
            direction="row"
            sx={{
              mt: 1,
              width: "100%",
              gap: "0.5rem",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <AdcTitle2>Items</AdcTitle2>
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              onClick={() => handleAction(defs.kAct_DownloadFile, jobHistoryData?.id)}
              sx={{ textTransform: "none" }}
            >
              Download
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 2 }}>
          <JobHistoryList
            data={jobDetails}
            onAction={handleAction}
            minHeight={300}
            maxHeight={800}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobHistoryPage;
