import axios from "axios";
// import MaterialReactTable, { MRT_TableInstance, MRT_ColumnDef, MRT_Row } from 'material-react-table';
// import { CompanyData, CheckResultNode, CheckResultDetailNode } from '../common/data_defs';

import type { ActionResult } from "../api/api";

// const CheckResultCell: FC<{ value: boolean, errType: boolean }> = ({ value, errType }) => {
//   return value ? (<>OK</>) : (
//     <Chip label="NG" size="small" sx={{ px: 1, bgcolor: errType ? '#fa3030' : '#fa9a29' }} />
//   );
// }

// const getCheckTypes = (workflowType:string):  MRT_ColumnDef<CheckResultDetailNode>[] => {
//   let result: MRT_ColumnDef<CheckResultDetailNode>[] = []

//   switch (workflowType) {
//     case "STANDARD_TEPMLATE":

//     // case "1":
//     //   break
//     default:
//       result = [
//         ...result,
//         {
//           accessorKey: 'type_chk',
//           header: 'Type',
//           size: 90,
//           Cell: ({ cell, row }) => (
//             <CheckResultCell value={row.original.type_chk} errType={true} />
//           )
//         },
//       ]
//       break
//   }

//   return result
// }

/**
 * 拡張子を取得する
 */
const getFileExt = (filename: string): string => {
  const pos = filename.lastIndexOf(".");
  if (pos === -1) {
    return "";
  }
  return filename.slice(pos + 1);
};
const makeWeekStr = (w: number): string => {
  let str = "";
  switch (w) {
    case 1:
      str = "月";
      break;
    case 2:
      str = "火";
      break;
    case 3:
      str = "水";
      break;
    case 4:
      str = "木";
      break;
    case 5:
      str = "金";
      break;
    case 6:
      str = "土";
      break;
    case 0:
      str = "日";
      break;
    default:
    // none
  }
  return str;
};

const makeUuid = (): string => crypto.randomUUID();
const utc2local = (str: string): string => {
  const dt = new Date(str);
  return dt.toLocaleString();
};
const obj2query = (obj: Record<string, unknown>): string => {
  const ary = [];
  for (const key in obj) {
    if (key in obj) {
      ary.push(`${key}="${obj[key] as string}"`);
    }
  }
  return ary.join("&");
};
const attachQueryParam = (url: string, filter: Record<string, unknown>): string => {
  const ary = [];
  for (const key in filter) {
    if (key in filter) {
      ary.push(`${key}=${filter[key] as string}`);
    }
  }
  const queryParam = ary.join("&");
  if (queryParam) {
    return `${url}?${queryParam}`;
  }
  return url;
};

const uploadFile = async (
  url: string,
  file: File,
  fields: Record<string, string>
): Promise<ActionResult> => {
  const rst: ActionResult = {
    success: false,
    message: "",
  };
  if (!url || !file) {
    return rst;
  }
  const config = {
    headers: {
      "content-type": "application/octet-stream",
      // "content-type": file.type,
      // "content-type": "text/plain",
      // "x-amz-acl": "public-read-write",
    },
  };

  const formData = new FormData();
  for (const key of Object.keys(fields)) {
    formData.append(key, fields[key]);
  }
  formData.append("file", file);

  try {
    const resp = await axios.post(url, formData);
    // rst = !!resp.data;
    rst.success = resp.status >= 200 && resp.status < 300;
    rst.success = resp.status >= 200 && resp.status < 300;
  } catch (e) {
    rst.message = (e as Error).message;
  }
  return rst;
};

const uploadFilePut = async (urlForPut: string, file: File): Promise<string> => {
  let rst = "";
  if (!urlForPut || !file) {
    return "";
  }

  try {
    const resp = await axios.put(urlForPut, file, {
      headers: {
        "content-type": "application/octet-stream",
        // "content-type": file.type,
        // "content-type": "text/plain",
        // "x-amz-acl": "public-read-write",
      },
    });
    rst = resp.data.toString();
  } catch (e) {}
  return rst;
};
const downloadFile = (url: string, fileName: string) => {
  // for Non-IE
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 1000);
};
export {
  getFileExt,
  makeWeekStr,
  makeUuid,
  utc2local,
  obj2query,
  attachQueryParam,
  uploadFilePut,
  uploadFile,
  downloadFile,
};
