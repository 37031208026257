import { downloadFile } from "util/utils";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import OpenLinkIcon from "@mui/icons-material/DownloadOutlined";
import UploadIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { MaterialReactTable } from "material-react-table";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useSelectCEDialog } from "render-hooks/useSelectCEDialog";
import { useGenerateDownloadURLMutation, useGenerateUploadURLMutation } from "services/file";
import { useGetNextActionListQuery, useUpdateJobFilesMutation } from "services/job";
import { useGetWorkflowListQuery } from "services/workflow";

import { JobAttributeEnum } from "../../../api/api";
import { FileType } from "../../../common/type_defs";
import { CommonSelect } from "../../../components/molecular/CommonSelect";
import FileUpload from "../../../components/molecular/FileBrowseUpload";
import { FileDragDropUpload } from "../../../components/molecular/FileDragDropUpload";
import { uploadFile, makeUuid } from "../../../util/utils";

import type { Job } from "../../../api/api";
import type { SelectNode, FileUploadNode, ActionCB } from "../../../common/type_defs";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC } from "react";

const getColumnDef = (
  job: any,
  optsFileType: SelectNode[],
  optsNextTo: SelectNode[],
  handleVerb: ActionCB,
  handleDownload: (filePath: string) => void
): MRT_ColumnDef<FileUploadNode>[] => {
  const handleFileTypeChanged = (rowData: FileUploadNode, v: SelectNode) => {
    const newNode = { ...rowData, fileType: v.value };
    handleVerb("update-file-node", newNode);
  };
  const handleNextActionChanged = (rowData: FileUploadNode, v: SelectNode) => {
    const newNode = { ...rowData, nextAction: v.value };
    handleVerb("update-file-node", newNode);
  };
  const handlePeSelect = async (rowData: FileUploadNode) => {
    handleVerb("select-pe", rowData);
  };

  const makePEButtonLabel = (rowData: FileUploadNode): string => {
    const cnt = rowData.peList?.length;
    if (cnt === 0) {
      return "- Please Select -";
    }
    if (cnt === 1) {
      return rowData.peList[0];
    }
    return `${cnt} CE selected`;
  };

  const checkPECheckDisabled = (row: any): boolean => {
    if (!job.workflowType.startsWith("SUBMITTING_FILE")) {
      return true;
    }
    if (row.original.fileType === "zip") {
      return true;
    }
    return false;
  };

  return [
    {
      accessorKey: "name",
      header: "Name",
      size: 230,
    },
    {
      accessorKey: "peList",
      header: "CE",
      size: 210,
      Cell: ({ cell, row }) => (
        <Button
          variant="outlined"
          disabled={checkPECheckDisabled(row)}
          onClick={() => handlePeSelect(row.original)}
        >
          {makePEButtonLabel(row.original)}
        </Button>
      ),
    },
    {
      accessorKey: "fileType",
      header: "File type",
      size: 150,
      Cell: ({ cell, row }) => (
        <CommonSelect
          value={row.original.fileType}
          isDisabled={!job.workflowType.startsWith("SUBMITTING_FILE")}
          options={optsFileType}
          onChanged={(v) => handleFileTypeChanged(row.original, v)}
          sxx={{ minWidth: 120 }}
        />
      ),
    },
    {
      accessorKey: "nextAction",
      header: "Next action",
      size: 230,
      Cell: ({ cell, row }) => (
        <CommonSelect
          value={row.original.nextAction}
          options={optsNextTo}
          isDisabled={optsNextTo.length === 1}
          onChanged={(v) => handleNextActionChanged(row.original, v)}
          sxx={{ minWidth: 120 }}
        />
      ),
    },
    {
      accessorKey: "path",
      header: "Action",
      size: 120,
      enableColumnOrdering: false,
      Cell: ({ cell, row }) => (
        <>
          <IconButton
            aria-label="information"
            onClick={() => handleVerb("delete-file-node", row.original)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="information" onClick={() => handleDownload(row.original.path)}>
            <OpenLinkIcon />
          </IconButton>
        </>
      ),
    },
  ];
};

type JobActionUploadProps = {
  job: Job;
  setJob: (job: Job) => void;
  onAction: ActionCB;
};

const JobActionUploadPanel: FC<JobActionUploadProps> = ({ job, setJob, onAction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openSelectCEDialog, renderSelectCEDialog } = useSelectCEDialog();

  const [generateDownloadURL] = useGenerateDownloadURLMutation();
  const [generateUploadURL] = useGenerateUploadURLMutation();
  const [updateJobFiles] = useUpdateJobFilesMutation();
  const { data: workflowList } = useGetWorkflowListQuery({
    fiscalYear: job.fiscalYear ?? -1,
    workflowType: job.workflowType,
  });
  const { data: nextActionListData } = useGetNextActionListQuery({
    jobId: job.id,
  });

  const fileTypeSelectionList: SelectNode[] = useMemo(
    () => workflowList?.at(0)?.fileTypes?.map((type) => ({ label: type, value: type })) ?? [],
    [workflowList]
  );
  const nextActionSelectionList: SelectNode[] = useMemo(
    () =>
      nextActionListData?.map(({ action, displayName }) => ({
        label: displayName,
        value: action,
      })) ?? [],
    [nextActionListData]
  );

  const handlerUpdateFileList = useCallback(
    async (newFileList: FileUploadNode[]) => {
      try {
        await updateJobFiles({ jobId: job.id, fileList: newFileList }).unwrap();
      } catch (error) {
        enqueueSnackbar("Failed to update job files.", { variant: "error" });
        return;
      }
      enqueueSnackbar("update file list success!", { variant: "success" });
      setJob({
        ...job,
        fileList: newFileList,
      });
    },
    [enqueueSnackbar, job, setJob, updateJobFiles]
  );

  const handleDownload = useCallback(
    async (filePath: string) => {
      try {
        const { url } = await generateDownloadURL({
          category: "WORKFLOW",
          fileType: "JOB_FILES",
          filePath,
        }).unwrap();
        const fileName = url.split("/").at(-1);
        downloadFile(url, fileName ?? "file-name");
      } catch (error) {
        enqueueSnackbar("file download failed!", { variant: "warning" });
      }
    },
    [enqueueSnackbar, generateDownloadURL]
  );

  const handleAction: ActionCB = useCallback(
    async (act: string, vd: unknown) => {
      if (act === "delete") {
        // onAction('download', jobsSelected)
      } else if (act === "link") {
      } else if (act === "select-pe") {
        const newNode = vd as FileUploadNode;
        const aryPE = await openSelectCEDialog({
          defaultCodeList: newNode.peList,
          options: {
            workflowType: job.workflowType,
            workflowStep: job.workflowStep,
            fiscalYear: job.fiscalYear,
            fixVersion: job.fixVersion,
            jobStatus:
              job.attribute === JobAttributeEnum.Submit ? "INPUT_WAITING" : "APPROVE_WAITING",
          },
        });
        const newFileAry: FileUploadNode[] = job.fileList?.map((node) =>
          node.id === newNode.id ? { ...newNode, peList: aryPE } : node
        ) ?? [{ ...newNode, peList: aryPE }];
        handlerUpdateFileList(newFileAry);
      } else if (act === "update-file-node") {
        const newNode = vd as FileUploadNode;
        const newFileAry: FileUploadNode[] = job.fileList?.map((node) =>
          node.id === newNode.id ? newNode : node
        ) ?? [newNode];
        // for (const fileNode of job.fileList) {
        //   if (fileNode.id === newNode.id) {
        //     newFileAry.push(newNode);
        //   } else {
        //     newFileAry.push(fileNode);
        //   }
        // }
        handlerUpdateFileList(newFileAry);
      } else if (act === "delete-file-node") {
        const theNode = vd as FileUploadNode;
        const newFileAry: FileUploadNode[] =
          job.fileList?.filter((node) => node.id !== theNode.id) ?? [];
        // for (const fileNode of job.fileList) {
        //   if (fileNode.id !== theNode.id) {
        //     newFileAry.push(fileNode);
        //   }
        // }
        handlerUpdateFileList(newFileAry);
        // setAryFile(newFileAry);
      }
    },
    [
      handlerUpdateFileList,
      job.attribute,
      job.fileList,
      job.workflowStep,
      job.workflowType,
      job.fiscalYear,
      job.fixVersion,
      openSelectCEDialog,
    ]
  );
  const columns = useMemo<MRT_ColumnDef<FileUploadNode>[]>(
    () =>
      getColumnDef(
        job,
        fileTypeSelectionList,
        nextActionSelectionList,
        handleAction,
        handleDownload
      ),
    [job, fileTypeSelectionList, nextActionSelectionList, handleAction, handleDownload]
  );

  const getFileFilterType = (workflowType: string): string[] | undefined => {
    if (workflowType === "STANDARD_TEMPLATE") {
      return ["xls", "xlsx", "zip"];
    }
    if (workflowType in ["CSV_DATA_UPLOADER", "TANGO", "TOP_SIDE"]) {
      return ["csv"];
    }
    return undefined;
  };

  const handleCancel = () => {};
  const handleNext = () => {};
  const handleAbort = () => {};

  const checkSingleNextActionOption = useMemo(
    () => nextActionListData && nextActionListData.length === 1,
    [nextActionListData]
  );

  const onFileSelected = async (files: File[]) => {
    const newFiles: FileUploadNode[] = structuredClone(job.fileList) ?? [];
    // for (const file of files) {
    //   newFiles.push({
    //     id: makeUuid(),
    //     name: file.name,
    //     path: file.name,
    //     peList: [],
    //     fileType: FileType.master,
    //     nextAction: ''
    //   });
    // }
    // setAryFile(newFiles);
    try {
      for (const file of files) {
        const { url, fields } = await generateUploadURL({
          fileName: file.name,
          category: "WORKFLOW",
          jobId: job.id,
          workflowType: job.workflowType,
          workflowStep: job.workflowStep,
        }).unwrap();

        if (url) {
          const rst = await uploadFile(url, file, fields);
          if (!rst.success) {
            // enqueueSnackbar("upload file error!", { variant: "error" });
          } else {
            // for (const file of files) {
            newFiles.push({
              id: makeUuid(),
              name: file.name,
              path: fields!.key!,
              peList: [],
              fileType: FileType.workflow,
              nextAction: checkSingleNextActionOption ? nextActionListData![0].action : "",
            });
            // }
            // setAryFile(newFiles);
            // enqueueSnackbar("upload file success!", { variant: "success" });
          }
        } else {
          // enqueueSnackbar("presign upload url error!", { variant: "error" });
        }

        // await put_upload_file(urlInfo.url, file);
      }
    } catch (err) {}
    handlerUpdateFileList(newFiles);
  };
  return (
    <Paper elevation={0}>
      <Grid container flexDirection={{ xs: "column", sm: "row" }} spacing={2}>
        <Grid xs={12} sm={12} sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
            ■ Upload
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <FileDragDropUpload
            onUpload={onFileSelected}
            typeFilter={getFileFilterType(job.workflowType ?? "")}
          >
            <Box
              sx={{
                width: "100%",
                height: 135,
                border: "1px dashed #a0a0a0",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="body2" sx={{ mt: 2 }}>
                Drag and drop a file you want to upload here,{" "}
              </Typography>
              <Typography variant="body2" sx={{ my: 1 }}>
                or
              </Typography>
              <FileUpload
                multi
                color="primary"
                variant="contained"
                startIcon={<UploadIcon />}
                onFileSelected={onFileSelected}
                // disabled={job.attribute !== "SUBMIT"}
              >
                Choose a file
              </FileUpload>
            </Box>
          </FileDragDropUpload>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
            ■ Files to upload
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{}}>
          <Paper elevation={0} sx={{ px: 2 }}>
            <MaterialReactTable
              columns={columns}
              data={job.fileList ?? []}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              getRowId={(fn, index: number, parentRow: MRT_Row<FileUploadNode>) => fn.id}
              enableSorting
              enableBottomToolbar={false}
              enableTopToolbar={false}
              enableRowVirtualization
              muiTableBodyRowProps={{ hover: true }}
              muiTableContainerProps={{ sx: { height: 300 } }}
            />
          </Paper>
        </Grid>
      </Grid>
      {renderSelectCEDialog()}
    </Paper>
  );
};

export default JobActionUploadPanel;
