import { Box, Button, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { kOpts_FiscalYear } from "common/const_defs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFileListQuery, usePrepareAndDownloadFileMutation } from "services/file";

import { AdcTitle2 } from "../../components/atoms/AdcTitle";
import { kMD_LimitFull } from "../../mock/mock_data";

import { FileDownloadList } from "./components/FileDownloadList";
import FileFilterPanel from "./components/FileFilterPanel";
import { FileAttachmentDialog } from "./FileAttachmentDialog";
import { FileDownloadDialog } from "./FileDownloadDialog";

import type { FileDownloadItem, FileInfo } from "../../common/type_defs";
import type { RowSelectionState } from "@tanstack/react-table";

const FileDownloadPage = () => {
  const [filters, setFilters] = useState<Record<string, unknown>>({
    fiscalYear: kOpts_FiscalYear[0].value,
  });
  const [limit] = useState<Record<string, boolean>>(kMD_LimitFull);
  const [curSel, setCurSel] = useState<RowSelectionState>({});
  const [isDlgOpenFileAtt, setDlgOpenFileAtt] = useState<boolean>(false);
  const [isDlgOpenFileDnld, setDlgOpenFileDnld] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: fileList, isLoading: isGetFileListLoading } = useGetFileListQuery({
    filter: filters,
  });
  const [prepareAndDownloadFile] = usePrepareAndDownloadFileMutation();

  const handleAction = (act: string, data: unknown) => {
    if (act === "file-attachment-open") {
      setDlgOpenFileAtt(true);
    } else if (act === "file-attachment-create") {
      setDlgOpenFileAtt(false);
    } else if (act === "file-attachment-cancel") {
      setDlgOpenFileAtt(false);
    } else if (act === "file-download") {
      setDlgOpenFileDnld(true);
    } else if (act === "file-download-cancel") {
      setDlgOpenFileDnld(false);
    } else if (act === "file-download-create") {
      setDlgOpenFileDnld(false);
    } else if (act === "cell-view") {
      navigate("/wf-status");
    } else if (act === "cell-report") {
      navigate("/wf-status");
    } else if (act === "file-delete") {
      alert(act);
    } else {
      alert(act);
      // toast(
      //   `This is a big toast for ${act}.\n\nIt's larger than you expected.`,
      //   {
      //     duration: 3000,
      //   }
      // );
    }
  };

  const handleSearch = async (v: Record<string, unknown>) => {
    setFilters(v);
  };
  const handleDownload = () => {
    if (fileList === undefined) {
      return;
    }

    const aryCurSel: FileDownloadItem[] = [];
    const aryFileInfo: FileInfo[] = [];
    const filePathList: string[] = [];
    console.log(curSel);
    for (const f of fileList) {
      if (curSel[`${f.PECode ?? ""}_${f.path ?? ""}`]) {
        aryCurSel.push(f);
        aryFileInfo.push(f);
        filePathList.push(f.path!);
      }
    }

    if (aryCurSel.length === 0) {
      console.error("CurSelectedFile is empty when Download button being clicked");
      return;
    }

    prepareAndDownloadFile({
      prepareRequest: {
        fileType: "SUBMITTING_FILE",
        filePathList,
      },
      generateDownloadURLRequest: {
        fileType: "SUBMITTING_FILE",
        category: "WORKFLOW",
      },
    }).catch();
  };

  const handleSelChanged = (sel: RowSelectionState) => {
    setCurSel({ ...sel });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          container
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ fontSize: "12px" }}
        />
        <Grid xs={12}>
          <AdcTitle2>■ Filter</AdcTitle2>
        </Grid>
        <Grid spacing={2} xs={12} sm={12} sx={{ mx: 2, my: 0, px: 1, zIndex: 3 }}>
          <FileFilterPanel onFilterReset={(v) => setFilters(v)} onSearch={handleSearch} />
        </Grid>
        <Grid xs={12} sm={12}>
          <Stack
            direction="row"
            sx={{
              mt: 1,
              width: "100%",
              gap: 1,
              justifyContent: "space-between",
            }}
          >
            <AdcTitle2>■ Items</AdcTitle2>
            <Box sx={{ flex: 1 }} />
            <Button
              variant="contained"
              disabled={Object.entries(curSel).length === 0 || fileList === undefined}
              onClick={handleDownload}
              sx={{ maxHeight: 36, alignSelf: "flex-end", mr: 2 }}
            >
              Download
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} sm={12}>
          <FileDownloadList
            files={fileList ?? []}
            onAction={handleAction}
            onSelChanged={handleSelChanged}
            isLoading={isGetFileListLoading}
          />
        </Grid>
      </Grid>
      <FileAttachmentDialog
        open={isDlgOpenFileAtt}
        onClose={() => setDlgOpenFileAtt(false)}
        onAction={handleAction}
      />
      <FileDownloadDialog
        open={isDlgOpenFileDnld}
        onClose={() => setDlgOpenFileDnld(false)}
        onAction={handleAction}
      />
    </>
  );
};

export default FileDownloadPage;
