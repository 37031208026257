import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authState } from "redux-modules/auth";
import { infoState } from "redux-modules/info";
import {
  MFA_TYPE,
  MFA_TYPE_OPTIONS,
  useGetMfaSettingQuery,
  useUpdateMfaPhoneNumberMutation,
  useUpdateMfaSoftwareMutation,
  useVerifyMfaCodeMutation,
} from "services/auth";
import { isApiFailedError } from "services/common/type-guard";

import ModalDialog from "./ModalDialog";

import type { FC } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  selectedTab: string;
}
const CustomTabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, selectedTab, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      flexGrow={1}
      sx={{ height: "100%", width: "100%", p: 3 }}
      {...other}
    >
      {value === selectedTab && <>{children}</>}
    </Box>
  );
};

interface SMSSettingProps {
  phoneNumber?: string;
  phoneNumberVerified?: string;
}
const SMSSetting: FC<SMSSettingProps> = ({ phoneNumber, phoneNumberVerified }) => {
  const { accessToken } = useSelector(authState);
  const { enqueueSnackbar } = useSnackbar();

  const [isSetting, setIsSetting] = useState<boolean>(false);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>("");
  const [isPhoneNumberError, setIsPhoneNumberError] = useState<boolean>(false);
  const [mfaCode, setMfaCode] = useState<string>("");
  const [isMfaCodeError, setIsMfaCodeError] = useState<boolean>(false);

  const [updateMfaPhoneNumber, { isLoading: isUpdatingPhoneNumber }] =
    useUpdateMfaPhoneNumberMutation();
  const [verifyMfaCode, { isLoading: isVerifyingMfaCode }] = useVerifyMfaCodeMutation();

  const updatePhoneNumber = async () => {
    if (newPhoneNumber === "" || accessToken === undefined) {
      setIsPhoneNumberError(true);
      return;
    }

    try {
      await updateMfaPhoneNumber({
        accessToken,
        phoneNumber: newPhoneNumber,
      }).unwrap();
      setIsPhoneNumberError(false);
      setIsCodeSent(true);
    } catch (error) {
      if (isApiFailedError(error)) {
        enqueueSnackbar(error.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to update phone number.", { variant: "error" });
      }
      setIsPhoneNumberError(true);
    }
  };

  const verifySMSMfa = async () => {
    if (mfaCode === "" || accessToken === undefined) {
      setIsMfaCodeError(true);
      return;
    }

    try {
      await verifyMfaCode({
        mfaType: MFA_TYPE.SMS,
        code: mfaCode,
        accessToken,
      }).unwrap();
      setIsSetting(false);
      enqueueSnackbar("Set up successfully.", { variant: "success" });
    } catch (error) {
      if (isApiFailedError(error)) {
        enqueueSnackbar(error.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Authentication code is not valid.", { variant: "error" });
      }
      setIsMfaCodeError(true);
    }
  };

  useEffect(() => {
    if (!isSetting) {
      setNewPhoneNumber("");
      setIsPhoneNumberError(false);
      setIsCodeSent(false);
      setMfaCode("");
      setIsMfaCodeError(false);
    }
  }, [isSetting]);

  if (!isSetting) {
    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Stack direction="column" alignItems="flex-start" spacing={2}>
          <Typography>Use SMS to set up MFA.</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {phoneNumber && <Typography>Phone number currently set: {phoneNumber}</Typography>}
            {phoneNumberVerified !== undefined && (
              <Chip
                size="small"
                variant="filled"
                color={phoneNumberVerified === "true" ? "success" : "warning"}
                label={phoneNumberVerified === "true" ? "Verified" : "Unverified"}
              />
            )}
          </Stack>
          <Stack direction="row">
            <Typography variant="body2">
              If you are using SMS authentication, phone number for the countries listed in the
              below link(“Sender ID registration by country ”) generally cannot be used. Please
              select AUTHENTICATION APP. And there are some cases where you cannot use SMS
              authentication due to mobile carriers in countries not listed. If you cannot receive
              any notice, please select AUTHENTICATION APP.
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body2">
              <a
                href="https://docs.aws.amazon.com/sns/latest/dg/channels-sms-originating-identities-sender-ids.html"
                target="blank"
              >
                Sender IDs - Amazon Simple Notification Service
              </a>
              <br />
              Please see “Sender ID registration by country” section.
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: "100%" }}>
          <Button variant="contained" onClick={() => setIsSetting(true)}>
            Set up
          </Button>
        </Stack>
      </Stack>
    );
  }

  if (!isCodeSent) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Stack direction="column" alignItems="flex-start" spacing={2} sx={{ width: "100%" }}>
          <Typography>Please enter your phone number including country code.</Typography>
          <TextField
            label="Phone number"
            variant="outlined"
            size="small"
            value={newPhoneNumber}
            name="phoneNumber"
            onChange={(e) => setNewPhoneNumber(e.target.value)}
            error={isPhoneNumberError}
            helperText={isPhoneNumberError && "Invalid phone number."}
            placeholder="+818012345678"
            sx={{ width: "70%" }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Button variant="text" onClick={() => setIsSetting(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => updatePhoneNumber()}
            loading={isUpdatingPhoneNumber}
          >
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="column" alignItems="flex-start" spacing={2} sx={{ width: "100%" }}>
        <TextField
          label="MFA Code"
          variant="outlined"
          size="small"
          value={mfaCode}
          name="mfaCode"
          onChange={(e) => setMfaCode(e.target.value)}
          error={isMfaCodeError}
          helperText={isMfaCodeError && "Invalid authentication code."}
          sx={{ width: "70%" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Button variant="text" onClick={() => setIsCodeSent(false)}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => verifySMSMfa()}
          loading={isVerifyingMfaCode}
        >
          Verify
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const SoftwareSetting: FC = () => {
  const { username } = useSelector(infoState);
  const { accessToken } = useSelector(authState);
  const { enqueueSnackbar } = useSnackbar();

  const [isSetting, setIsSetting] = useState<boolean>(false);
  const [isSecretVisible, setIsSecretVisible] = useState<boolean>(false);
  const [secret, setSecret] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [mfaCode, setMfaCode] = useState<string>("");
  const [isMfaCodeError, setIsMfaCodeError] = useState<boolean>(false);

  const [updateMfaSoftware, { isLoading: isUpdatingMfaSoftware }] = useUpdateMfaSoftwareMutation();
  const [verifyMfaCode, { isLoading: isVerifyingMfaCode }] = useVerifyMfaCodeMutation();

  const startSoftwareSetup = async () => {
    if (username === undefined || accessToken === undefined) {
      return;
    }

    try {
      const res = await updateMfaSoftware({
        username,
        accessToken,
      }).unwrap();
      setIsSetting(true);
      setSecret(res.secret);
      setUrl(res.url);
    } catch (error) {
      if (isApiFailedError(error)) {
        enqueueSnackbar(error.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to get secret.", { variant: "error" });
      }
    }
  };

  const verifySoftwareMfa = async () => {
    if (accessToken === undefined) {
      return;
    }
    if (mfaCode === "") {
      setIsMfaCodeError(true);
      return;
    }

    try {
      await verifyMfaCode({
        mfaType: MFA_TYPE.SOFTWARE,
        code: mfaCode,
        accessToken,
      }).unwrap();
      setIsSetting(false);
      enqueueSnackbar("Set up successfully.", { variant: "success" });
    } catch (error) {
      if (isApiFailedError(error)) {
        enqueueSnackbar(error.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Authentication code is not valid.", { variant: "error" });
      }
      setIsMfaCodeError(true);
    }
  };

  useEffect(() => {
    if (!isSetting) {
      setSecret("");
      setUrl("");
      setIsSecretVisible(false);
      setMfaCode("");
      setIsMfaCodeError(false);
    }
  }, [isSetting]);

  if (!isSetting) {
    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Stack direction="column" alignItems="flex-start" spacing={2}>
          <Typography>Use an authenticator app to set up MFA.</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: "100%" }}>
          <LoadingButton
            variant="contained"
            onClick={() => startSoftwareSetup()}
            loading={isUpdatingMfaSoftware}
          >
            Set up
          </LoadingButton>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <QRCodeCanvas value={url} level="M" size={130} />
        {isSecretVisible ? (
          <Box sx={{ maxWidth: "45%", wordBreak: "break-all" }}>
            <Typography align="center" variant="caption">
              {secret}
            </Typography>
            <Tooltip title="Copy" placement="right">
              <IconButton
                size="small"
                /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                onClick={() => navigator.clipboard.writeText(secret)}
              >
                <ContentCopyIcon fontSize="inherit" color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Button size="small" onClick={() => setIsSecretVisible(true)}>
            Show secret
          </Button>
        )}
        <TextField
          label="MFA Code"
          variant="outlined"
          size="small"
          value={mfaCode}
          name="mfaCode"
          onChange={(e) => setMfaCode(e.target.value)}
          error={isMfaCodeError}
          helperText={
            isMfaCodeError
              ? "Invalid authentication code. Scan the QR code with your MFA device and enter the code."
              : "Scan the QR code with your MFA device and enter the code."
          }
          sx={{ width: "70%" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Button variant="text" onClick={() => setIsSetting(false)}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => verifySoftwareMfa()}
          loading={isVerifyingMfaCode}
        >
          Verify
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

interface MfaSettingDialogProps {
  open: boolean;
  onClose: () => void;
}

export const MfaSettingDialog: FC<MfaSettingDialogProps> = ({ onClose, open }) => {
  const [currentSetting, setCurrentSetting] = useState<MFA_TYPE | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<MFA_TYPE>(currentSetting ?? MFA_TYPE.SMS);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState<string | undefined>(undefined);

  const { accessToken } = useSelector(authState);

  const { data: mfaSetting } = useGetMfaSettingQuery(
    { accessToken: accessToken ?? "" },
    { skip: open === false }
  );
  useEffect(() => {
    if (mfaSetting === undefined) return;
    if (mfaSetting.enabledMfa.sms) {
      setCurrentSetting(MFA_TYPE.SMS);
    } else if (mfaSetting.enabledMfa.software) {
      setCurrentSetting(MFA_TYPE.SOFTWARE);
    } else {
      setCurrentSetting(undefined);
    }
    setPhoneNumber(mfaSetting.phoneNumber);
    setPhoneNumberVerified(mfaSetting.phoneNumberVerified);
  }, [mfaSetting]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="MFA Setting"
      size="small"
      content={
        <Stack direction="column" height="100%">
          <Typography variant="h6">
            Current Setting:{" "}
            {currentSetting ? MFA_TYPE_OPTIONS[currentSetting].label : "Set up required"}
          </Typography>
          <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)} variant="fullWidth">
            {Object.values(MFA_TYPE_OPTIONS).map((data) => (
              <Tab label={data.label} value={data.value} />
            ))}
          </Tabs>
          <CustomTabPanel value="SMS" selectedTab={selectedTab}>
            <SMSSetting phoneNumber={phoneNumber} phoneNumberVerified={phoneNumberVerified} />
          </CustomTabPanel>
          <CustomTabPanel value={MFA_TYPE.SOFTWARE} selectedTab={selectedTab}>
            <SoftwareSetting />
          </CustomTabPanel>
          <Typography variant="caption">
            Only one type of MFA can be used. If it has already been set, it will be overwritten.
          </Typography>
        </Stack>
      }
      buttons={<></>}
    />
  );
};
