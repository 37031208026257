import { JobType } from "api";

import { mainApi } from "./base";

import type { Job, JobListResponse } from "api";
import type { FileUploadNode } from "common/type_defs";

export type InitiateJobRequest = {
  name: string;
  workflowType: string;
  workflowStep: string;
  attribute: string;
  fiscalYear: number | string;
  fixVersion: string;
  JobDetails: { PECode: string }[];
  fileType?: string;
};

export const jobApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getJob: builder.query<Job, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `job/${jobId}`,
      }),
      providesTags: ["Job"],
    }),
    listJobs: builder.query<JobListResponse, { type: JobType }>({
      query: ({ type }) => ({
        url: `job/list`,
        params: {
          // FIXME: wrong type
          type: type === JobType.NotStarted ? "NOT_START" : type,
        },
      }),
      providesTags: ["Job"],
      extraOptions: { maxRetries: 8 },
    }),
    getJobHistory: builder.query<Job, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `job/history`,
        params: {
          jobId,
        },
      }),
    }),
    approveJob: builder.mutation<{ success: boolean }, { jobId: string; PEList: string[] }>({
      query: ({ jobId, ...body }) => ({
        url: `job/${jobId}/approve`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Job", "ProcessResult"],
    }),
    sendBackJob: builder.mutation<
      { success: boolean; message: string },
      { jobId: string; returnStep: string; PECodeList: string[]; comment: string }
    >({
      query: ({ jobId, ...body }) => ({
        url: `job/${jobId}/sendback`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Job", "ProcessResult"],
    }),
    abortJob: builder.mutation<
      { success: boolean; message: string },
      { jobId: string; PECode: string[] }
    >({
      query: ({ jobId, ...body }) => ({
        url: `job/${jobId}/abort`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Job", "ProcessResult"],
    }),
    initiateJob: builder.mutation<
      { success: boolean; job: Job; message: string },
      InitiateJobRequest
    >({
      query: (body) => ({
        url: `job/initiate`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Job"],
    }),
    updateJobFiles: builder.mutation<
      { success: boolean },
      { jobId: string; fileList: FileUploadNode[] }
    >({
      query: ({ jobId, ...body }) => ({
        url: `job/${jobId}/updateFiles`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Job"],
    }),
    getPrevActionList: builder.query<{ action: string; displayName: string }[], { jobId: string }>({
      query: ({ jobId }) => ({
        url: `job/${jobId}/prevActionList`,
      }),
    }),
    getNextActionList: builder.query<{ action: string; displayName: string }[], { jobId: string }>({
      query: ({ jobId }) => ({
        url: `job/${jobId}/nextActionList`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetJobQuery,
  useLazyGetJobQuery,
  useListJobsQuery,
  useGetJobHistoryQuery,
  useApproveJobMutation,
  useSendBackJobMutation,
  useAbortJobMutation,
  useInitiateJobMutation,
  useUpdateJobFilesMutation,
  useLazyGetPrevActionListQuery,
  useGetNextActionListQuery,
} = jobApi;
