import { LoadingButton } from "@mui/lab";
import { Typography, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useEffect, useCallback, useImperativeHandle, forwardRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { isTimeoutError } from "services/common/type-guard";
import { useGetFixVersionsQuery, useCreateFixWorkflowVersionMutation } from "services/workflow";
import * as yup from "yup";

import { defs } from "../../common/const_defs";
import { msg } from "../../common/msg_defs";
import { AdcTitle2 } from "../../components/atoms/AdcTitle";
import { AdcSelect } from "../../components/molecular/AdcSelect";
import { AdcTextField } from "../../components/molecular/AdcTextField";
import ModalDialog from "../../components/molecular/ModalDialog";
import { createSelectionListFromStringArray } from "../../util/create-selection";
import { useYupResolver } from "../../util/yup-helper";

import type { ActionCB, FixDataInfo } from "../../common/type_defs";
import type { VerbInstance } from "../../components/atoms/CommonInstance";
import type { FC } from "react";
import type { FieldErrors, SubmitHandler } from "react-hook-form";

const schema = yup.object({
  version: yup.string().required(msg.validation.kMsg_MandatoryField),
  sourceVersion: yup.string().required(msg.validation.kMsg_MandatoryField),
});
const FixDataPanel = forwardRef<VerbInstance, { fiscalYear: number; onAction: ActionCB }>(
  ({ fiscalYear, onAction }, ref) => {
    const { control, watch, getValues, handleSubmit } = useForm<FixDataInfo>({
      defaultValues: { fiscalYear, version: "", sourceVersion: "" },
      // onSubmit modeの場合、formStateのisValidは適切な値とならないことに注意
      mode: "onSubmit",
      resolver: useYupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      doVerb,
    }));
    const doVerb = (act: string, vd: unknown): unknown => null;
    const onSubmit: SubmitHandler<FixDataInfo> = useCallback(
      (fd: FixDataInfo) => {
        const newData: FixDataInfo = { fiscalYear: 0, version: "", sourceVersion: "original" };
        newData.fiscalYear = fd.fiscalYear;
        newData.version = fd.version;
        newData.sourceVersion = fd.sourceVersion;
        onAction(defs.kAct_ExecFixVerCreate, newData);
        // console.log('✅Submitted', data);
      },
      [onAction]
    );

    const { data } = useGetFixVersionsQuery({ fiscalYear: getValues().fiscalYear });
    const fixVersionSelectList = useMemo(() => createSelectionListFromStringArray(data), [data]);

    const onError = useCallback((errors: FieldErrors<FixDataInfo>) => {
      console.log("❌Error", errors);
    }, []);

    useEffect(() => {
      const f = async () => {};
      f();
    }, []);

    return (
      <form id="form-fix-data" onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container flexDirection={{ xs: "column", sm: "row" }} sx={{ p: 2 }} spacing={2}>
          <Grid xs={12} sm={6} sx={{}}>
            <AdcTitle2>Fiscal Year</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={6} sx={{}}>
            <TextField
              id="fiscalYear"
              value={getValues().fiscalYear}
              fullWidth
              size="small"
              disabled
            />
          </Grid>
          <Grid xs={12} sm={6} sx={{}}>
            <AdcTitle2 component="p">Source Version</AdcTitle2>
            <Typography component="p" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>
              - mandatory_field
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} sx={{}}>
            <AdcSelect<FixDataInfo, "sourceVersion">
              name="sourceVersion"
              control={control}
              config={{
                displayErrorMessage: true,
                options: fixVersionSelectList!,
              }}
              muiProps={{
                selectProps: {
                  fullWidth: true,
                  size: "small",
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} sx={{}}>
            <AdcTitle2 component="p">Version name</AdcTitle2>
            <Typography component="p" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>
              - mandatory_field
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} sx={{}}>
            <AdcTextField<FixDataInfo>
              name="version"
              control={control}
              config={{
                displayErrorMessage: true,
              }}
              muiProps={{
                textFieldProps: {
                  fullWidth: true,
                  size: "small",
                },
              }}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
);

type FixDataProps = {
  open: boolean;
  fiscalYear: number;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const FixDataDialog: FC<FixDataProps> = ({ open, fiscalYear, onClose, onAction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createFixWorkflowVersion, { isLoading: isCreating, isSuccess: isProceedSuccess }] =
    useCreateFixWorkflowVersionMutation();
  const handleSearch = (v: Record<string, unknown>) => {
    // toast('search');
    toast.error(
      "This toast is super big. I don't think anyone could eat it in one bite.\n\nIt's larger than you expected. You eat it but it does not seem to get smaller.",
      {
        duration: 3000,
      }
    );
    // alert("search")
    // onAction('search', v)
  };
  const handleAction: ActionCB = async (act, vd) => {
    try {
      const info: FixDataInfo = vd as FixDataInfo;
      await createFixWorkflowVersion(info).unwrap();
      enqueueSnackbar("Succeeded to create version.", { variant: "success" });
    } catch (error) {
      if (isTimeoutError(error)) {
        enqueueSnackbar("Please wait for a while", { variant: "success" });
      } else {
        enqueueSnackbar("Failed to create version.", { variant: "error" });
      }
    }
    onAction(defs.kAct_ExecFixVerCreate, vd);
  };

  const handleCancel = () => {
    onAction("fix-data-cancel", null);
  };
  const handleCreate = () => {
    onAction("fix-data-create", null);
  };

  return (
    <ModalDialog
      open={open}
      title="Create Fix Version"
      content={<FixDataPanel fiscalYear={fiscalYear} onAction={handleAction} />}
      buttons={
        <Grid id="xxx" container sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <LoadingButton
              loading={isCreating}
              variant="contained"
              form="form-fix-data"
              type="submit"
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="small"
    />
  );
};

export default FixDataDialog;
export { FixDataDialog };
