import React from "react";
// import {
//   CognitoUserPool,
//   CognitoUser,
//   AuthenticationDetails,
//   CognitoUserAttribute
// } from 'amazon-cognito-identity-js';
// import config from '../config/config';

// const userPool = new CognitoUserPool({
//   UserPoolId: config.kCognitoUserPoolId,
//   ClientId: config.kCognitoClientId
// });

const UserContext = React.createContext();
// var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  let rst = {};
  switch (action.type) {
    case "LOGIN_SUCCESS":
      rst = {
        ...state,
        isAuthenticated: true,
        loginUser: action.payload.user,
        rawAuth: action.payload.rawAuth,
        idToken: action.payload.idToken,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        role: action.payload.role,
      };
      break;
    case "SET_INFO":
      rst = { ...state, extraInfo: { ...action.info } };
      break;
    case "LOGIN_FAILURE":
      rst = { ...state, isAuthenticated: false, loginUser: null };
      break;
    case "SIGN_OUT_SUCCESS":
      rst = { ...state, isAuthenticated: false, loginUser: {} };
      break;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  return rst;
}

function loadStateFromSessionStorage() {
  const loginUser = sessionStorage.getItem("loginUser");
  const idToken = sessionStorage.getItem("idToken") ?? "";
  const refreshToken = sessionStorage.getItem("refreshToken") ?? "";
  const rawAuth = sessionStorage.getItem("rawAuth") ?? {};
  const state = {
    isAuthenticated: !!loginUser,
    loginUser,
    rawAuth,
    idToken,
    refreshToken,
  };
  return state;
}
const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, loadStateFromSessionStorage());

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

function useUser() {
  const ctx = React.useContext(UserContext);
  if (ctx === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  const registerUser = (userName, idToken, accessToken, refreshToken, rawAuth, role) => {
    const user = {
      name: userName,
      // name: data.idToken.payload.Username(),
    };
    sessionStorage.setItem("loginUser", user);
    sessionStorage.setItem("idToken", idToken);
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("rawAuth", rawAuth);
    sessionStorage.setItem("role", role);
    ctx.dispatch({
      type: "LOGIN_SUCCESS",
      payload: { user, idToken, refreshToken, rawAuth, role },
    });
  };
  const logout = (cbAfterLogout) => {
    // const user = userPool.getCurrentUser();
    // if (user) {
    //   user.signOut();
    // }
    sessionStorage.removeItem("loginUser");
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("rawAuth");
    ctx.dispatch({ type: "SIGN_OUT_SUCCESS" });
    if (typeof cbAfterLogout === "function") {
      cbAfterLogout();
    }
  };

  return {
    // userState: ctx.state,
    userDispatch: ctx.dispatch,
    loginUser: ctx.state.loginUser,
    logout,
    registerUser,
    info: ctx.state.extraInfo,
    setGlobalInfo: (info) => {
      ctx.dispatch({ type: "SET_INFO", info });
    },
    // group: ctx.state.loginUser?.group,
    // isAdmin: ctx.state.loginUser?.group !== "user",
    // token: ctx.state.idToken,
    rawAuth: ctx.state.rawAuth,
    isAuthenticated: ctx.state.isAuthenticated,
    role: ctx.state.role,
  };
}

// ###########################################################
async function login(dispatch, name, password) {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: "", msg: "" });
    // const user = new CognitoUser({ Username: name, Pool: userPool });
    // const authDetails = new AuthenticationDetails({ Username: name, Password: password });

    // user.authenticateUser(authDetails, {
    //   // login Success
    //   onSuccess: data => {
    //     console.log('onSuccess:', data);
    //     const user = {
    //       name: data.idToken.payload["cognito:username"],
    //       // name: data.idToken.payload.Username(),
    //       email: data.idToken.payload.email,
    //       status: data.idToken.payload["custom:status"],
    //       group: data.idToken.payload["custom:group"],
    //     };
    //     sessionStorage.setItem("loginUser", user);
    //     dispatch({ type: 'LOGIN_SUCCESS', user: { ...user }, rawAuth: data })
    //     resolve({ ok: true, data: user, msg: '' });
    //   },

    //   // login Failed
    //   onFailure: err => {
    //     console.error('onFailure:', err);
    //     resolve({ ok: false, data: {}, msg: err.message });
    //   },

    //   // Change Password
    //   newPasswordmandatory_field: data => {
    //     console.log('newPasswordmandatory_field:', data);
    //     resolve({ ok: false, data: data, msg: 'Need New Password' });
    //   }
    // });
  });
}
export { UserProvider, useUser, login };
