import { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { Paper, Button, Typography, Container, Alert } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { NoneWrapper } from "components/atoms/NoneWrapper";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLogin } from "redux-modules/auth";
import { setInfo } from "redux-modules/info";
import { useChangePasswordMutation } from "services/auth";
import * as yup from "yup";

import { msg } from "../../common/msg_defs";
import { AdcLabelInput } from "../../components/molecular/AdcLabelInput";

import type { SubmitHandler } from "react-hook-form";

type ChangePasswordFormInput = {
  // currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const schema = yup.object({
  // currentPassword: yup
  //   .string()
  //   .required(msg.validation.kMsg_MandatoryField)
  //   .max(99, "Max length is 99."),
  newPassword: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .max(99, "Max length is 99."),
  confirmPassword: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alertMessage, setErrorAlertMessage] = useState<string>("");
  const { control, handleSubmit } = useForm<ChangePasswordFormInput>({
    defaultValues: { newPassword: "", confirmPassword: "" },
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") ?? "";
  const session = searchParams.get("session") ?? "";

  const [changePassword, { isLoading: isChangePasswordLoading }] = useChangePasswordMutation();

  const onValid: SubmitHandler<ChangePasswordFormInput> = async ({
    // currentPassword,
    newPassword,
  }) => {
    try {
      const changePasswordResponse = await changePassword({
        username,
        currentPassword: "",
        newPassword,
        session,
      }).unwrap();
      if (changePasswordResponse.AuthenticationResult !== undefined) {
        const { AccessToken, IdToken, RefreshToken } = changePasswordResponse.AuthenticationResult;
        const { role } = changePasswordResponse;

        dispatch(setInfo({ username, role }));
        dispatch(
          setLogin({
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            authType: "ADC",
          })
        );
        navigate("/");
      } else if (
        changePasswordResponse.ChallengeName === ChallengeNameType.SMS_MFA ||
        changePasswordResponse.ChallengeName === ChallengeNameType.SOFTWARE_TOKEN_MFA
      ) {
        const { ChallengeName, Session } = changePasswordResponse;
        navigate(
          `/login/mfa?userName=${username}&challengeName=${ChallengeName}&session=${Session}`
        );
      }
    } catch (error) {
      setErrorAlertMessage("Failed to change password.");
    }
  };

  const onInValid = () => {
    setErrorAlertMessage("");
  };

  return (
    <Container component="main" fixed sx={{ my: 2, py: 1 }}>
      <Paper sx={{ mx: "auto", p: 5, maxWidth: 560 }}>
        <form id="form-change-password" onSubmit={handleSubmit(onValid, onInValid)}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="h5">■ New Password Setting</Typography>
            </Grid>
            <NoneWrapper show={!!alertMessage}>
              <Grid xs={12} sm={12} sx={{ ml: 2 }}>
                <Alert id="alert-msg" severity="error" sx={{ mt: 2 }}>
                  {alertMessage}
                </Alert>
              </Grid>
            </NoneWrapper>
            <Grid xs={12} sm={12} sx={{ ml: 2, mt: 2 }}>
              <Typography variant="body2">Please renew your password.</Typography>
            </Grid>
            {/* <Grid xs={12} sm={12} sx={{ ml: 2 }}>
              <AdcLabelInput<ChangePasswordFormInput, "currentPassword">
                name="currentPassword"
                label="Current password"
                control={control}
                config={{
                  displayErrorMessage: true,
                }}
                muiProps={{
                  textFieldProps: {
                    fullWidth: true,
                    size: "small",
                    type: "password",
                  },
                }}
              />
            </Grid> */}
            <Grid xs={12} sm={12} sx={{ ml: 2 }}>
              <AdcLabelInput<ChangePasswordFormInput, "newPassword">
                name="newPassword"
                label="New password"
                control={control}
                config={{
                  displayErrorMessage: true,
                }}
                muiProps={{
                  textFieldProps: {
                    fullWidth: true,
                    size: "small",
                    type: "password",
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} sx={{ ml: 2 }}>
              <AdcLabelInput<ChangePasswordFormInput, "confirmPassword">
                name="confirmPassword"
                label="Confirm password"
                control={control}
                config={{
                  displayErrorMessage: true,
                }}
                muiProps={{
                  textFieldProps: {
                    fullWidth: true,
                    size: "small",
                    type: "password",
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12}>
              <Typography variant="body2" sx={{ mt: 3, ml: 2 }}>
                Password conditions
              </Typography>
            </Grid>
            <Grid xs={12} sm={12}>
              <Typography variant="body2" sx={{ mt: 0, ml: 2, textAlign: "left" }}>
                Password cannot be same as E-mail.
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} sx={{ ml: 2, mt: 2, display: "flex", justifyContent: "center" }}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ChangePasswordPage;
