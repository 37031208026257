import React from "react";

const LayoutContext = React.createContext();

const kActToggleSidebar = "TOGGLE_SIDEBAR";
function layoutReducer(state, action) {
  switch (action.type) {
    case kActToggleSidebar:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const LayoutProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true,
  });
  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>;
};

function useLayout() {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  context.isSidebarOpened = context.state.isSidebarOpened;
  context.toggleSidebar = () => {
    context.dispatch({ type: kActToggleSidebar });
  };
  return context;
}

export { LayoutProvider, useLayout };
