import { LoadingButton } from "@mui/lab";
import { Paper, Stack, Typography, Container, Alert } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { NoneWrapper } from "components/atoms/NoneWrapper";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLogin } from "redux-modules/auth";
import { setInfo } from "redux-modules/info";
import { useMfaChallengeMutation } from "services/auth";
import { isAuthResponseError } from "services/common/type-guard";
import * as yup from "yup";

import { msg, msgAuth } from "../../common/msg_defs";
import { AdcTextField } from "../../components/molecular/AdcTextField";
import { useYupResolver } from "../../util/yup-helper";

import type { SubmitHandler } from "react-hook-form";

type MfaData = {
  code: string;
};
const schema = yup.object({
  code: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .length(6, msg.validation.kMsg_MustCodeLength6),
});

const MfaSignInPage = () => {
  const refForm = useRef<HTMLFormElement>(null);
  const { control, handleSubmit } = useForm<MfaData>({
    defaultValues: { code: "" },
    mode: "onSubmit",
    resolver: useYupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") ?? "";
  const challengeName = searchParams.get("challengeName") ?? "";
  const session = searchParams.get("session") ?? "";

  const [alertMessage, setErrorAlertMessage] = useState<string>("");

  const [mfaChallenge, { isLoading }] = useMfaChallengeMutation();

  const onValid: SubmitHandler<MfaData> = async ({ code }) => {
    try {
      const challengeResponse = await mfaChallenge({
        challengeName,
        username,
        session,
        code,
      }).unwrap();

      if (challengeResponse.AuthenticationResult !== undefined) {
        const { AccessToken, IdToken, RefreshToken } = challengeResponse.AuthenticationResult;
        const { role } = challengeResponse;

        dispatch(setInfo({ username, role }));

        dispatch(
          setLogin({
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            authType: "ADC",
          })
        );

        navigate("/");
      } else {
        throw new Error("Unexpected challenge response.");
      }
    } catch (error) {
      if (isAuthResponseError(error)) {
        if (error.code === "UserNotFoundException" || error.code === "NotAuthorizedException") {
          setErrorAlertMessage(msgAuth.kMsg_LoginError);
        } else {
          setErrorAlertMessage(error.code);
        }
      }
      console.error("Unexpected login error", error);
      setErrorAlertMessage(
        "An unexpected login error has occurred. Please check the console for details"
      );
    }
  };

  const onInValid = () => {
    setErrorAlertMessage("");
  };

  return (
    <Container component="main" fixed sx={{ my: 2, py: 1 }}>
      <Paper sx={{ mx: "auto", p: 8, maxWidth: 560 }}>
        <form id="form-mfa" ref={refForm} onSubmit={handleSubmit(onValid, onInValid)}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                ■ Multi-factor Authentication
              </Typography>
            </Grid>
            <NoneWrapper show={!!alertMessage}>
              <Grid xs={12} sm={12} sx={{ ml: 2 }}>
                <Alert id="alert-msg" severity="error" sx={{ mt: 2 }}>
                  {alertMessage}
                </Alert>
              </Grid>
            </NoneWrapper>
            <Grid xs={12} sm={12} sx={{ mx: 2 }}>
              <Typography sx={{ mt: 2 }}>Enter an MFA code to complete sign in.</Typography>
            </Grid>
            <Grid xs={12} sm={12} sx={{ mx: 2 }}>
              <Stack
                direction="row"
                sx={{
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ width: "100px" }}>MFA code</Typography>
                <AdcTextField<MfaData, "code">
                  name="code"
                  control={control}
                  config={{
                    displayErrorMessage: true,
                  }}
                  muiProps={{
                    textFieldProps: {
                      fullWidth: true,
                      size: "small",
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} sx={{ mx: 2 }}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{ my: 2 }}
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default MfaSignInPage;