import { AddCircle, RemoveCircle } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  Box,
  Divider,
  IconButton,
  Typography,
  Paper,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState, useEffect, useCallback } from "react";
import { useForm, useController } from "react-hook-form";
import * as yup from "yup";

import { defs } from "../../common/const_defs";
import { msg } from "../../common/msg_defs";
import { AdcTitle2 } from "../../components/atoms/AdcTitle";
import { AdcTextField } from "../../components/molecular/AdcTextField";
import ModalDialog from "../../components/molecular/ModalDialog";
import { makeUuid } from "../../util/utils";
import { useYupResolver } from "../../util/yup-helper";

import type { ActionCB, WorkflowAddData } from "../../common/type_defs";
import type { FC, ReactElement } from "react";
import type {
  FieldErrors,
  SubmitHandler,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";

type FileItemData = {
  id: string;
  type: string;
};

const FileItemsPanel: <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName> & {
    items: string[];
    onChanged: (ary: string[]) => void;
    [key: string]: unknown;
  }
) => ReactElement = ({ items, onChanged, ...others }) => {
  const [aryItem, setAryItem] = useState<FileItemData[]>([]);
  const { field, fieldState } = useController(others);
  useEffect(() => {
    const ary: FileItemData[] = [];
    for (const str of items) {
      ary.push({ id: makeUuid(), type: str });
    }
    setAryItem(ary);
  }, [items]);
  useEffect(() => {
    onChanged(items);
  }, [items, onChanged]);

  const collectData = (ary: FileItemData[]): string[] => ary.map((item) => item.type);
  const onDelete = (item: FileItemData) => {
    const aryNew: FileItemData[] = [];
    for (const node of aryItem) {
      if (node.id !== item.id) {
        aryNew.push(node);
      }
    }
    setAryItem(aryNew);
    onChanged(collectData(aryNew));
  };
  const onAdd = () => {
    const aryNew: FileItemData[] = [...aryItem];
    aryNew.push({ id: makeUuid(), type: "" });
    setAryItem(aryNew);
    onChanged(collectData(aryNew));
  };

  const handleTxtChanged = (nv: string, rowId: string) => {
    const aryNew: FileItemData[] = [...aryItem];
    for (const node of aryNew) {
      if (node.id === rowId) {
        node.type = nv;
        break;
      }
    }
    setAryItem(aryNew);
    onChanged(collectData(aryNew));
  };
  const getErrText = () => {
    let hasNull = false;
    for (const node of aryItem ?? []) {
      if (!node.type) {
        hasNull = true;
        break;
      }
    }
    if (!hasNull) {
      return fieldState.error?.message;
    }
    return "File Type cannot be empty.";
  };

  return (
    <>
      {aryItem.map((node) => (
        <Box
          key={`key${node.id}`}
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            mb: 1,
            pl: 2,
          }}
          component="ul"
        >
          <IconButton onClick={() => onDelete(node)}>
            <RemoveCircle />
          </IconButton>
          <TextField
            sx={{ mx: 1, my: 0, minWidth: 230 }}
            size="small"
            label="Value                                "
            value={node.type}
            onChange={(evt) => handleTxtChanged(evt.target.value, node.id)}
          />
        </Box>
      ))}
      <Divider variant="middle" sx={{ my: 2 }} />
      <FormControl error fullWidth>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            m: 0,
            pl: 1,
          }}
          component="ul"
        >
          <IconButton
            data-testid="filter_item_add"
            onClick={onAdd}
            sx={{ ml: 1, mt: -1 }}
            color="success"
          >
            <AddCircle />
          </IconButton>
          <FormHelperText>{getErrText()}</FormHelperText>
        </Box>
      </FormControl>
    </>
  );
};
const schema = yup.object({
  fileTypes: yup
    .array()
    .min(1, msg.validation.kMsg_MandatoryField)
    .of(yup.string().required("File Type cannot be empty.")),
  submittingFileCategory: yup.string().required(msg.validation.kMsg_MandatoryField),
  // submitter: yup.string().required(msg.validation.kMsg_MandatoryField),
  // approver: yup.string().required(msg.validation.kMsg_MandatoryField),
});

const NewWorkflowPanel: FC<{ onAction: ActionCB }> = ({ onAction }) => {
  const { control, watch, getValues, setValue, handleSubmit } = useForm<WorkflowAddData>({
    defaultValues: {},
    // onSubmit modeの場合、formStateのisValidは適切な値とならないことに注意
    mode: "onSubmit",
    resolver: useYupResolver(schema),
  });

  const onSubmit: SubmitHandler<WorkflowAddData> = useCallback(
    (fd: WorkflowAddData) => {
      onAction(defs.kAct_ExecCreateWorkflow, fd);
      // console.log('✅Submitted', data);
    },
    [onAction]
  );

  const onError = useCallback((errors: FieldErrors<WorkflowAddData>) => {
    console.log("❌Error", errors);
  }, []);

  const onItemChanged = (ary: string[]) => {
    setValue("fileTypes", ary);
  };

  const handleCancel = () => {};
  const handleCreate = () => {
    alert("handleCreate");
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <form id="form-create-workflow" onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid
          container
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ p: 2, fontSize: "12px" }}
          spacing={2}
        >
          <Grid xs={12} sm={12} sx={{ mt: 0, ml: 0 }}>
            <AdcTitle2 component="span">■ Workflow type name</AdcTitle2>
            <Typography component="span" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>
              - mandatory_field
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} sx={{ px: 3 }}>
            <Stack
              direction="row"
              sx={{
                mb: 0,
                width: "100%",
                gap: 1,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography component="span" sx={{ mx: 1, width: 70 }}>
                Submit
              </Typography>
              <AdcTextField<WorkflowAddData>
                name="submittingFileCategory"
                control={control}
                config={{
                  displayErrorMessage: true,
                }}
                muiProps={{
                  textFieldProps: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </Stack>
          </Grid>
          {/* <Grid xs={12} sm={12} sx={{ mt: 0, ml: 0 }}>
            <AdcTitle2 component="span">
              ■ Action policy
            </AdcTitle2>
            <Typography component="span" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>- mandatory_field</Typography>
          </Grid>
          <Grid xs={12} sm={12} sx={{ px: 3 }}>
            <Stack direction="row" sx={{ mb: 0, width: '100%', gap: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography component="span" sx={{ mx: 1, width: 70 }}>Submitter</Typography>
              <AdcSelect<WorkflowAddData, 'submitter'>
                name='submitter'
                control={control}
                config={{
                  displayErrorMessage: true,
                  options: optsAction
                }}
                muiProps={{
                  selectProps: {
                    fullWidth: true,
                    size: 'small',
                  }
                }}
              />
              <Typography component="span" sx={{ mx: 1, width: 70 }}>Approver</Typography>
              <AdcSelect<WorkflowAddData, 'approver'>
                name='approver'
                control={control}
                config={{
                  displayErrorMessage: true,
                  options: optsAction
                }}
                muiProps={{
                  selectProps: {
                    fullWidth: true,
                    size: 'small',
                  }
                }}
              />
            </Stack>
          </Grid> */}
          <Grid xs={12} sm={12} sx={{ mt: 3 }}>
            <AdcTitle2 component="span">■ File type</AdcTitle2>
            <Typography component="span" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>
              - mandatory_field
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} sx={{ px: 3 }}>
            <FileItemsPanel<WorkflowAddData, "fileTypes">
              name="fileTypes"
              control={control}
              items={getValues("fileTypes") ?? []}
              onChanged={onItemChanged}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

type NewWorkflowProps = {
  open: boolean;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const NewWorkflowDialog: FC<NewWorkflowProps> = ({ open, onClose, onAction }) => {
  // const refPanel = useRef<FormInstance | null>(null);

  const handleAction: ActionCB = (act, vd) => {
    onAction(act, vd);
  };

  const handleCancel = () => {
    onAction("new-workflow-cancel", null);
  };
  const handleCreate = () => {
    // if (refPanel.current?.handleSubmit()) {
    onAction("new-workflow-create", null);
    // }
  };

  return (
    <ModalDialog
      open={open}
      title="Add submitting file workflow"
      content={<NewWorkflowPanel onAction={handleAction} />}
      buttons={
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" type="submit" form="form-create-workflow">
              Add new workflow
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="medium"
    />
  );
};

export { NewWorkflowDialog };
