/* eslint-disable react/jsx-props-no-spreading */

import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Stack,
  Chip,
} from "@mui/material";
import { useController } from "react-hook-form";

import type { SelectNode } from "../../common/type_defs";
import type { SelectProps, SelectChangeEvent } from "@mui/material";
import type { FC, ReactElement } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

type SelectItemType = string | number | SelectNode;
type MultiSelectProp = {
  label?: string;
  arySelected: string[];
  options: SelectNode[];
  onChanged: (aryVal: SelectNode[]) => void;
  [key: string]: unknown;
};

const MultiSelect: FC<MultiSelectProp> = ({ label, arySelected, options, onChanged, ...props }) => {
  // const [arySelected, setArySelected] = useState(value);
  const handleChanged = (e: SelectChangeEvent<string[]>) => {
    const aryKeySel = e.target.value as string[];
    const aryCurSel: SelectNode[] = [];
    for (const pair of options) {
      if (aryKeySel.includes(pair.value)) {
        aryCurSel.push(pair);
      }
    }

    onChanged(aryCurSel);
  };
  const handleDelete = (keyDel: string) => {
    const aryCurSel: SelectNode[] = [];
    for (const item of options) {
      if (item.value !== keyDel && arySelected.includes(item.value)) {
        aryCurSel.push(item);
      }
    }
    onChanged(aryCurSel);
  };
  const getLabel = (key: string): string => {
    for (const pair of options) {
      if (pair.value === key) {
        return pair.label;
      }
    }
    return "";
  };
  return (
    <FormControl sx={{ m: 0, maxWidth: 500 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        fullWidth
        size="small"
        value={arySelected}
        onChange={handleChanged}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((keySel: string) => (
              <Chip
                key={keySel}
                label={getLabel(keySel)}
                onDelete={() => handleDelete(keySel)}
                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                sx={{ my: 0, py: 0 }}
              />
            ))}
          </Stack>
        )}
      >
        {options.map((item: SelectNode) => (
          <MenuItem key={item.value} value={item.value} sx={{ justifyContent: "space-between" }}>
            {item.label}
            {arySelected.includes(item.value) ? <CheckIcon color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type AdcMultiSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  /** Additional settings */
  config?: {
    /**
     * Whether to display error messages below the input field.
     * If `true`, message displays.
     * @defaultValue `false`
     */
    displayErrorMessage?: boolean;
    /**
     * An array of Selectable options. Each option should be an object that has `value`, and shold have `label` property.
     * However an array of string or number also acceptable. In that case, `value` and `label` match the value of each element.
     * Note that each value must be unique.
     */
    options: Array<{ value: string; label: string }>;
  };
  /** Settings for MUI elements */
  muiProps?: {
    /**
     * Settings for Select inside AdcSelect
     *
     * API: {@link https://mui.com/material-ui/api/select/}
     */
    selectProps?: SelectProps;
  };
};

export const AdcMultiSelect: <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: AdcMultiSelectProps<TFieldValues, TName>
) => ReactElement = (props) => {
  const { muiProps, config, ...others } = props;
  const { selectProps } = muiProps ?? {};
  const { field, fieldState } = useController(others);

  const handleMultiSelectChange = (v: SelectNode[]) => {
    if (!v) {
      field.onChange([] as any);
    } else {
      const ary = v.map((d) => d.value);
      field.onChange(ary as any);
    }
  };
  const handleChanged = (e: SelectChangeEvent<string[]>) => {
    const aryKeySel = e.target.value as string[];
    const aryCurSel: SelectNode[] = [];
    for (const pair of config?.options ?? []) {
      if (aryKeySel.includes(pair.value as string)) {
        aryCurSel.push(pair);
      }
    }

    // onChanged(aryCurSel)
  };
  const handleDelete = (keyDel: string) => {
    const aryCurSel: SelectNode[] = [];
    for (const item of config?.options ?? []) {
      if (item.value !== keyDel && field.value.includes(item.value)) {
        aryCurSel.push(item);
      }
    }
    // onChanged(aryCurSel);
  };
  const getLabel = (key: string): string => {
    for (const pair of config?.options ?? []) {
      if (pair.value === key) {
        return pair.label;
      }
    }
    return "";
  };

  return (
    <FormControl
      error={!!fieldState.error}
      style={{ width: selectProps?.fullWidth ? "100%" : undefined }}
    >
      <InputLabel id={`mui-select-label-${selectProps?.label}`}>{selectProps?.label}</InputLabel>
      <MultiSelect
        id={field.name}
        inputRef={field.ref}
        name={field.name}
        arySelected={field.value}
        options={config?.options ?? []}
        onChanged={handleMultiSelectChange}
      />
      <FormHelperText id={`${field.name}-helper-text`}>
        {config?.displayErrorMessage && fieldState.error?.message}
      </FormHelperText>
    </FormControl>
  );
};
