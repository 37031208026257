import { Box, Button, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import toast from "react-hot-toast";

import { AdcTitle2 } from "../../components/atoms/AdcTitle";
import ModalDialog from "../../components/molecular/ModalDialog";
import { kMD_LimitFull } from "../../mock/mock_data";

import FileFilterPanel from "./components/FileFilterPanel";

import type { FileDownloadData } from "../../common/data_defs";
import type { ActionCB } from "../../common/type_defs";
import type { RowSelectionState } from "@tanstack/react-table";
import type { FC } from "react";

type FileDownloadProps = {
  open: boolean;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const FileDownloadDialog: FC<FileDownloadProps> = ({ open, onClose, onAction }) => {
  const [selList, setSelList] = useState<RowSelectionState[]>([]);
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const [limit] = useState<Record<string, boolean>>(kMD_LimitFull);
  const [aryFile, setAryFile] = useState<FileDownloadData[]>([]);

  const handleSearch = (v: Record<string, unknown>) => {
    // toast('search');
    toast.error(
      "This toast is super big. I don't think anyone could eat it in one bite.\n\nIt's larger than you expected. You eat it but it does not seem to get smaller.",
      {
        duration: 3000,
      }
    );
    // alert("search")
    // onAction('search', v)
  };
  const handleAction: ActionCB = (act, vd) => {
    onAction(act, vd);
  };

  const handleCancel = () => {
    onAction("file-download-cancel", null);
  };
  const handleDownload = () => {
    onAction("file-download-download", null);
  };

  const makeContent = () => (
    <Grid
      container
      flexDirection={{ xs: "column", sm: "row" }}
      sx={{ p: 2, fontSize: "12px" }}
      spacing={1}
    >
      <Grid xs={12} sm={12}>
        <AdcTitle2>■ Filter</AdcTitle2>
      </Grid>
      <Grid spacing={2} xs={12} sm={12} sx={{ mx: 2, my: 0, px: 1, zIndex: 3 }}>
        <FileFilterPanel onFilterReset={(v) => setFilters(v)} onSearch={handleSearch} />
      </Grid>
      <Grid xs={12} sm={12}>
        <Stack
          direction="row"
          sx={{ mt: 1, width: "100%", gap: 1, justifyContent: "space-between" }}
        >
          <AdcTitle2>■ Items</AdcTitle2>
          <Box sx={{ flex: 1 }} />
          <Button
            variant="contained"
            onClick={handleDownload}
            sx={{ maxHeight: 36, alignSelf: "flex-end", mr: 2 }}
          >
            Download
          </Button>
        </Stack>
      </Grid>
      <Grid xs={12} sm={12}>
        {
          // <FileDownloadList files={aryFile} onAction={handleAction} maxHeight={800} isLoading={false} />
        }
      </Grid>
    </Grid>
  );

  return (
    <ModalDialog
      open={open}
      title="Download"
      content={makeContent()}
      buttons={
        <Grid container sx={{ mt: 0, justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" onClick={handleDownload}>
              Download
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="large"
    />
  );
};

export default FileDownloadDialog;
export { FileDownloadDialog };
