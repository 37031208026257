import axios, { AxiosError } from "axios";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  InitiateAuthResponse,
  AssociateSoftwareTokenCommand,
  VerifySoftwareTokenCommand,
  SetUserMFAPreferenceCommand,
  SetUserMFAPreferenceRequest,
  AuthFlowType,
} from "@aws-sdk/client-cognito-identity-provider";

import { LoginSuccessResult } from "./api";
import { getServerUrl } from "./conn";
import { msgAuth } from "../common/msg_defs";

const COGNITO_API_VERSION = "2016-04-18";
const REGION = "ap-northeast-1";
const COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID!;

export const post_access_attempt = async (
  email: string,
  authType: string
): Promise<Record<string, unknown>> => {
  let rst: Record<string, unknown> = { success: false };
  try {
    const body = {
      email: email,
      authType: authType,
    };

    const url = `${getServerUrl()}/accessTest/attempt`;
    const resp = await axios.post(url, body);
    rst.success = resp.status === 200;
    rst.response = resp.data;
    // hanjie:test
  } catch (err) {
    console.log(err);
    if (axios.isAxiosError(err)) {
      const resp = err.response?.data as Record<string, unknown>;
      rst.response = resp;
      rst.status = resp.status;
      rst.success = resp.success;
      rst.message = resp.message;
    }
  }
  return rst;
};
export const post_access_complete = async (): Promise<Record<string, unknown>> => {
  let rst: Record<string, unknown> = { success: false };
  try {
    const cfg = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      },
    };
    const body = {};

    const url = `${getServerUrl()}/accessTest/complete`;
    const resp = await axios.post(url, body, cfg);
    rst.success = resp.status === 200 && resp.data.success;
    rst.status = resp.status;
    rst.response = resp;
    //rst.success = true;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const resp = err.response?.data as Record<string, unknown>;
      rst.response = resp;
      rst.status = resp.status;
      rst.success = resp.success;
      rst.message = resp.message;
    }
  }
  return rst;
};

export const post_login = async (
  username: string,
  password: string
): Promise<Record<string, unknown>> => {
  let rst: Record<string, unknown> = { success: false };
  try {
    const cfg = {
      headers: {},
    };
    const body = {
      username: username,
      password: password,
    };

    const url = `${getServerUrl()}/login`;
    const resp = await axios.post(url, body, cfg);
    console.log("resp", resp);
    rst.success = resp.status === 200;
    if (!rst.success) {
      rst.message = resp?.statusText;
    }
    rst.data = resp.data;
  } catch (err) {
    console.log(err);
    rst.success = false;
    if (axios.isAxiosError(err)) {
      if (
        err.response?.data === "UserNotFoundException" ||
        err.response?.data === "NotAuthorizedException"
      ) {
        rst.status = err.response.status;
        rst.success = false;
        rst.message = msgAuth.kMsg_LoginError;
      } else if (err.response?.data) {
        const resp = err.response?.data as Record<string, unknown>;
        rst.response = resp;
        rst.status = resp.status;
        rst.success = resp.success;
        rst.message = resp.message;
      }
    }
  }
  return rst;
};

export const post_auth = async (req: Record<string, string>): Promise<Record<string, unknown>> => {
  let rst: Record<string, unknown> = { success: false };
  try {
    const cfg = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const body: { [key: string]: string } = { ...req };
    let formBodyArr = [];
    for (let property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBodyArr.push(encodedKey + "=" + encodedValue);
    }
    let formBody = formBodyArr.join("&");

    const url = `${process.env.REACT_APP_COGNITO_USERPOOL_ENDPOINT}/oauth2/token`;
    const resp = await axios.post(url, formBody, cfg);
    rst.success = resp.status === 200;
    rst.status = resp.status;
    rst.statusText = resp.statusText;
    rst.data = resp.data;
    //rst.success = true;
  } catch (err) {
    rst.success = false;
    if (axios.isAxiosError(err)) {
      const resp = err.response?.data as Record<string, unknown>;
      rst.response = resp;
      rst.status = resp.status;
      rst.success = resp.success;
      rst.message = resp.message;
    }
  }
  return rst;
};
