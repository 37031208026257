import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useRef, useEffect, useState } from "react";
import { useSendMailMutation } from "services/mail";

import { defs, kObj_NullMailRemind } from "../../common/const_defs";
import { NoneWrapper } from "../../components/atoms/NoneWrapper";
import ModalDialog from "../../components/molecular/ModalDialog";

import { MailSendInfoPanel, MailSendMsgPanel } from "./components/MailSendPanel";

import type { WorkflowType } from "../../api/api";
import type { ActionCB, MailRemindData } from "../../common/type_defs";
import type { VerbInstance } from "../../components/atoms/CommonInstance";
import type { FC } from "react";
import type { SendMailRequest } from "services/mail";

const getButtons = (step: string, onAction: ActionCB, curData: MailRemindData) => {
  console.log("curData", curData);
  if (step === "info") {
    return (
      <Grid container justifyContent="center" sx={{ bkcolor: "red" }} spacing={2}>
        <Grid>
          <Button variant="contained" onClick={() => onAction("cancel")}>
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            type="submit"
            form="form-mail-info"
            disabled={!curData.targetPE || curData.targetPE?.length < 1}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (step === "msg") {
    return (
      <Grid container sx={{ justifyContent: "center" }} spacing={2}>
        <Grid>
          <Button variant="contained" onClick={() => onAction("back")}>
            Back
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            type="submit"
            form="form-mail-msg"
            onClick={() => {
              onAction("send");
            }}
            disabled={
              !curData.targetPE ||
              curData.targetPE?.length < 1 ||
              !curData.subject ||
              !curData.content
            }
          >
            Send
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <Button variant="contained" onClick={() => onAction("cancel")}>
      Cancel
    </Button>
  );
};
type MailSendProps = {
  open: boolean;
  params: Record<string, string>;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const MailSendDialog: FC<MailSendProps> = ({ open, params, onClose, onAction }) => {
  const { enqueueSnackbar } = useSnackbar();

  const refInfo = useRef<VerbInstance | null>(null);
  const refMsg = useRef<VerbInstance | null>(null);
  const [step, setStep] = useState<string>("info");
  const [curData, setCurData] = useState<MailRemindData>(kObj_NullMailRemind);

  const [sendMail] = useSendMailMutation();

  useEffect(() => {
    if (params.workflowType !== curData.workflowType) {
      const newData = {
        ...curData,
        workflowType: params.workflowType as WorkflowType,
      };
      setCurData(newData);
    }
  }, [curData, params.workflowType]);

  const resetState = () => {
    setCurData(kObj_NullMailRemind);
    setStep("info");
  };

  const handleAction: ActionCB = async (act, vd) => {
    if (act === "cancel") {
      onClose("cancel");
    } else if (act === "back") {
      // if (vd) {
      //   setCurData(vd as MailRemindData);
      // }
      refMsg.current?.doVerb("back", null);
    } else if (act === "goto-info") {
      if (vd) {
        setCurData(vd as MailRemindData);
      }
      setStep("info");
    } else if (act === "goto-msg") {
      setCurData(vd as MailRemindData);
      setStep("msg");
    } else if (act === defs.kAct_ExecSendMail) {
      try {
        await sendMail(vd as SendMailRequest).unwrap();
        resetState();
        enqueueSnackbar("Succeeded to send mail.", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Failed to send mail.", { variant: "error" });
        return;
      }
      onAction(defs.kAct_ExecSendMail, vd);
      onClose("close");
    } else {
      onAction(act, vd);
    }
  };

  return (
    <ModalDialog
      open={open}
      title="Send Notification"
      content={
        <>
          <NoneWrapper show={step === "info"}>
            <MailSendInfoPanel
              ref={refInfo}
              data={curData}
              onAction={handleAction}
              setCurData={setCurData}
            />
          </NoneWrapper>
          <NoneWrapper show={step === "msg"}>
            <MailSendMsgPanel
              ref={refMsg}
              data={curData}
              onAction={handleAction}
              setCurData={setCurData}
            />
          </NoneWrapper>{" "}
        </>
      }
      buttons={getButtons(step, handleAction, curData)}
      onClose={() => onClose("close")}
      size="medium"
    />
  );
};

export default MailSendDialog;
export { MailSendDialog };
