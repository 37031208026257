import { yupResolver } from "@hookform/resolvers/yup";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Paper,
  Typography,
  Container,
  Alert,
  Box,
  Tooltip,
  IconButton,
  Button,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { NoneWrapper } from "components/atoms/NoneWrapper";
import { QRCodeCanvas } from "qrcode.react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { MFA_TYPE, useUpdateMfaSoftwareMutation, useVerifyMfaCodeMutation } from "services/auth";
import { isApiFailedError } from "services/common/type-guard";
import * as yup from "yup";

import { msg } from "../../common/msg_defs";
import { AdcLabelInput } from "../../components/molecular/AdcLabelInput";

import type { SubmitHandler } from "react-hook-form";

type MfaFormInput = {
  code: string;
};

const validationSchema = yup.object({
  code: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .length(6, msg.validation.kMsg_MustCodeLength6),
});
type MfaCodeData = {
  verficationCode: string;
};
// 電話番号正規表現
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schemaMobil = yup.object({
  mobilNumber: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .matches(phoneRegExp, { message: msg.validation.kMsg_NotProperPhoneNumber }),
});
const schemaCode = yup.object({
  verficationCode: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .length(6, msg.validation.kMsg_MustCodeLength6),
});

const MfaSetupPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") ?? "";
  const challengeName = searchParams.get("challengeName") ?? "";
  const session = searchParams.get("session") ?? "";

  const { control, handleSubmit } = useForm<MfaFormInput>({
    defaultValues: { code: "" },
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const [alertMessage, setErrorAlertMessage] = useState<string>("");
  const [isSecretVisible, setIsSecretVisible] = useState<boolean>(false);

  const [
    updateMfaSoftware,
    {
      isLoading: isUpdateMfaSoftwareLoading,
      isSuccess: isUpdateMfaSoftwareSuccess,
      data: updateMfaSoftwareData,
    },
  ] = useUpdateMfaSoftwareMutation();
  const [verifyMfaCode, { isLoading: isVerifyMfaCodeLoading, isSuccess: isVerifyMfaCodeSuccess }] =
    useVerifyMfaCodeMutation();

  if (username === "" || challengeName === "" || session === "") {
    return <Navigate to="/login/adc" />;
  }

  const handleOnGenerateQR = async () => {
    try {
      await updateMfaSoftware({ username, session }).unwrap();
      setErrorAlertMessage("");
    } catch (error) {
      if (isApiFailedError(error)) {
        setErrorAlertMessage(error.data.message);
      } else {
        setErrorAlertMessage("Failed to generate QR code.");
      }
    }
  };

  const onValid: SubmitHandler<MfaFormInput> = async ({ code }) => {
    try {
      await verifyMfaCode({
        mfaType: MFA_TYPE.SOFTWARE,
        code,
        session: updateMfaSoftwareData?.session,
      }).unwrap();
      setErrorAlertMessage("");
    } catch (error) {
      if (isApiFailedError(error)) {
        setErrorAlertMessage(error.data.message);
      } else {
        setErrorAlertMessage("Failed to verify MFA code.");
      }
    }
  };

  const onInValid = () => {
    setErrorAlertMessage("");
  };

  return (
    <Container component="main" fixed sx={{ my: 2, py: 1 }}>
      <Paper sx={{ mx: "auto", p: 8, maxWidth: 560 }}>
        <form id="form-login" onSubmit={handleSubmit(onValid, onInValid)}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                ■ MFA Setup
              </Typography>
            </Grid>
            <NoneWrapper show={!!alertMessage}>
              <Grid xs={12} sm={12} sx={{ ml: 2 }}>
                <Alert id="alert-msg" severity="error" sx={{ mt: 2 }}>
                  {alertMessage}
                </Alert>
              </Grid>
            </NoneWrapper>
            <NoneWrapper show={!isUpdateMfaSoftwareSuccess}>
              <Grid xs={12} sm={12}>
                <Typography>Scan the QR code to set up MFA.</Typography>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ my: 2 }}
                  loading={isUpdateMfaSoftwareLoading}
                  onClick={handleOnGenerateQR}
                >
                  Generate QR
                </LoadingButton>
              </Grid>
            </NoneWrapper>
            <NoneWrapper show={isUpdateMfaSoftwareSuccess}>
              <Grid container spacing={2} sx={{ ml: 1 }}>
                <Grid xs={12} sm={12}>
                  <Stack direction="column" alignItems="center" spacing={2}>
                    <QRCodeCanvas value={updateMfaSoftwareData?.url ?? ""} level="M" size={130} />
                    {isSecretVisible ? (
                      <Box sx={{ maxWidth: "45%", wordBreak: "break-all" }}>
                        <Typography align="center" variant="caption">
                          {updateMfaSoftwareData?.secret ?? ""}
                        </Typography>
                        <Tooltip title="Copy" placement="right">
                          <IconButton
                            size="small"
                            /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                            onClick={() =>
                              navigator.clipboard.writeText(updateMfaSoftwareData?.secret ?? "")
                            }
                          >
                            <ContentCopyIcon fontSize="inherit" color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Button size="small" onClick={() => setIsSecretVisible(true)}>
                        Show secret
                      </Button>
                    )}
                  </Stack>
                  <AdcLabelInput<MfaFormInput, "code">
                    name="code"
                    label="Verification Code"
                    control={control}
                    config={{
                      displayErrorMessage: true,
                    }}
                    muiProps={{
                      textFieldProps: {
                        fullWidth: true,
                        size: "small",
                        autoComplete: "one-time-code",
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={12}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ my: 2 }}
                    loading={isUpdateMfaSoftwareLoading}
                  >
                    Verify
                  </LoadingButton>
                </Grid>
              </Grid>
            </NoneWrapper>
            <NoneWrapper show={isVerifyMfaCodeSuccess}>
              <Grid xs={12} sm={12} sx={{ ml: 2 }}>
                <Typography>Successfully set up MFA. You can now sign in.</Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ my: 2 }}
                  onClick={() => navigate("/login/adc")}
                >
                  Sign In
                </Button>
              </Grid>
            </NoneWrapper>
            <NoneWrapper show={!isVerifyMfaCodeSuccess}>
              <Grid xs={12} sm={12} sx={{ ml: 2 }}>
                <Typography>
                  Go back to <Link to="/login/adc">Sign In</Link>
                </Typography>
              </Grid>
            </NoneWrapper>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default MfaSetupPage;