import {
  // Home as HomeIcon,
  //  BlurLinear as AutopayHistoryIcon,
  // BarChart as ReportIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import { Drawer, Divider, IconButton, List } from "@mui/material";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// styles
import { useLayout } from "../../../hooks/LayoutContext";

import SidebarLink from "./components/SidebarLink/SidebarLink";
import useStyles from "./styles";

const items = [
  {
    id: 1,
    label: "Job List",
    link: "/job-list",
    icon: <HomeIcon />,
  },
  {
    id: 2,
    label: "Workflow List",
    link: "/workflow-list",
    icon: <HomeIcon />,
  },
  {
    id: 3,
    label: "File Download",
    link: "/file-download",
    icon: <HomeIcon />,
  },
  {
    id: 4,
    label: "Master Data Management",
    link: "/master-management",
    icon: <HomeIcon />,
  },
  {
    id: 5,
    label: "Sign In",
    link: "/login",
    icon: <HomeIcon />,
  },
];
if (process.env.REACT_APP_ENV === "ACCESS_TEST") {
  items.push({
    id: 0,
  });
  items.push({
    id: 6,
    label: "Sign-in Test Start",
    link: "/login",
    icon: <HomeIcon />,
  });
  items.push({
    id: 7,
    label: "Sign-in Result",
    link: "/result",
    icon: <HomeIcon />,
  });
}
//   {
//     id: 0,
//   },
//   {
//     id: 10,
//     label: "StoryBooks",
//     link: "/stories",
//     icon: <HomeIcon />
//   },
//   {
//     id: 11,
//     label: "検証",
//     link: "/task",
//     icon: <HomeIcon />
//   },
//   {
//     id: 12,
//     label: "検証(Excel)",
//     link: "/task-excel",
//     icon: <GridOnIcon />
//   },
//   {
//     id: 13,
//     label: "検証(Athena)",
//     link: "/task-athena",
//     icon: <StorageIcon />
//   },
//   {
//     id: 14,
//     label: "検証(zip-upload)",
//     link: "/task-zip",
//     icon: <StorageIcon />
//   },
// ];

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const SideDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const Sidebar = () => {
  const location = useLocation();
  const classes = useStyles();

  // global

  const { isSidebarOpened, toggleSidebar } = useLayout();

  // local
  const [isPermanent] = useState(true);

  return (
    <SideDrawer variant={isPermanent ? "permanent" : "temporary"} open={isSidebarOpened}>
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={toggleSidebar} size="large">
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List>
        {items.map((item) => {
          if (item.id === 0) {
            return <Divider />;
          }
          return (
            <SidebarLink
              key={item.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...item}
            />
          );
        })}
      </List>
    </SideDrawer>
  );
};
function withRouter(Component) {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentWithRouterProp;
}

export default withRouter(Sidebar);
