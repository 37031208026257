import type {
  MailTemplate,
  WorkflowStep,
  Job,
  JobDetail,
  PEHistoryResponsePEHistoryInner as PEHistory,
  Mail,
  WorkflowStatus,
  Step,
  WorkflowManagementResponseInner as WorkflowMgmtData,
  WorkflowStatusResponse as WfStatusNode,
  FileListResponseInner as FileDownloadItem,
  ProcessResultResponseInner as CheckResult,
  ProcessResultResponseInnerCheckStatusInner as CheckResultItem,
  DataGenerateZipPostRequestFileListInner as FileInfo,
  MasterListResponseInner as MasterInfo,
} from "../api/api";

type VType = "str" | "bool" | "int" | "float";
// type JobStatus =
//   | "created"
//   | "init"
//   | "NOT_START"
//   | "IN_PROGRESS"
//   | "DONE";
export const FileType = {
  master: "MASTER",
  workflow: "WORKFLOW",
} as const;

type ActionCB = (act: string, vd?: unknown) => void;

type SelectNode = {
  value: string;
  label: string;
  [key: string]: any;
};
type CondtItemDef = {
  id: string;
  name: string;
  vtype: VType;
};
type CondtItemData = {
  id: string;
  key: string;
  displayName: string;
  // def: CondtItemDef;
  value: unknown;
  expression: string;
  type?: string;
};
type User = {
  id: string;
  name: string;
};
type CompanyParentInfo = {
  pe_id: string; // PE code
  equity_ratio: number;
};
type CompanyData = {
  com_name: string;
  com_type: number; // 1＝サブ連親;  2＝サブ連子;  3=サブ連外個社;  4＝非連子;  5=JV親; 6＝JV子
  pe_code: string; // （例）  506500ーIO975-000
  pe_name: string;
  parent: CompanyParentInfo[];
  currency: string;
  [key: string]: unknown;
};
type FileUploadNode = {
  id: string;
  name: string;
  path: string;
  peList: string[];
  fileType: string;
  nextAction: string;
  [key: string]: unknown;
};

interface JobData {
  id: string;
  name: string;
  job_description?: string;
  job_status: string;
  job_date: string;
  company?: CompanyData;
  // files: FileNode[];
  wf: JobWorkflow;
  [key: string]: unknown;
}
type WorkflowStepData = {
  id: string;
  title: string;
  incharge: string;
  date: string;
  status: string;
  [key: string]: unknown;
};
type WorkflowDef = {
  id: string;
  name: string;
  [key: string]: unknown;
};
type JobWorkflow = {
  def: WorkflowDef;
  status: string;
  cur_step: number;
  steps: WorkflowStepData[];
  [key: string]: unknown;
};
type NewRequestData = {
  wf_type: string;
  target_pe: string;
  target_group: string;
  target_status: string;
  mail_type: string;
  mail_subject: string;
  mail_content: string;
  remind_count: number;
  remind_freq: number;
};
interface MailRemindData extends Mail {
  targetStatus: string;
  targetStep: string;
  remindType: number;
  fiscalYear: string;
}
type WorkflowAddData = {
  submittingFileCategory: string;
  submitter: string;
  approver: string;
  fileTypes: string[];
};
type WorkflowDelVerData = {
  workflowType: string;
  fiscalYear: string;
  version: string;
};
type WorkflowStatusData = {
  workflowType: string;
  fiscalYear: string;
  version: string;
  workflowStatus: WorkflowStatus;
};
type FixDataInfo = {
  fiscalYear: number;
  version: string;
  sourceVersion: string;
};

type WorkflowFileType = {
  name: string;
  displayName: string;
};

type CsvRequestType = {
  fiscalYear: number;
  ceCodeList: string[];
};

type CsvResponseType = {
  data: any[];
  status: string;
  message: string;
};

export type {
  Job,
  Mail,
  /// /////////////////////////////
  VType,
  ActionCB,
  CompanyData,
  CompanyParentInfo,
  PEHistory,
  MailTemplate,
  FileUploadNode,
  User,
  // JobStatus,
  JobData,
  JobDetail,
  Step,
  WorkflowStep,
  WorkflowStepData,
  WorkflowDef,
  WorkflowMgmtData,
  WfStatusNode,
  WorkflowAddData,
  WorkflowDelVerData,
  WorkflowStatusData,
  WorkflowFileType,
  CsvRequestType,
  CsvResponseType,
  JobWorkflow,
  SelectNode,
  CondtItemDef,
  CondtItemData,
  MasterInfo,
  NewRequestData,
  MailRemindData,
  FileDownloadItem,
  CheckResult,
  CheckResultItem,
  FileInfo,
  FixDataInfo,
};
