import { Paper, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { post_access_complete } from "../../api/login";
// import { OAuth2TokenRequestBody } from "../../api/api";

const Test2Page = () => {
  const [isRefuse, setRefuse] = useState(true);
  const [msg, setMsg] = useState("Waiting response.");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const respCode = searchParams.get("code") ?? "";

  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === 'ACCESS_TEST' && process.env.REACT_APP_STAGE_FLAG !== 'UNIT_TEST') {
  //     if (info?.transfer === "Result") {
  //       setRefuse(false);
  //     } else if (isRefuse) {
  //       navigate("/login");
  //     }
  //     setGlobalInfo({ transfer: "" });
  //   }
  // }, [info?.transfer, isRefuse, navigate, setGlobalInfo])
  // 森口追記: CloudFrontと相性が悪いため外した

  useEffect(() => {
    if (sessionStorage.getItem("idToken")) {
      accessComplete();
    } else {
      alert("Please login.");
      navigate("/login");
    }

    // if (!!respCode) {
    //   console.log(`queryStringParameters("code") = ${respCode}`);

    //   const req: OAuth2TokenRequest = {
    //     grant_type: "aaa",
    //     client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    //     redirect_uri: "aaa",
    //     code: respCode,
    //     // refresh_token: "aaa",
    //   };
    //   post_auth(req).then(rst => {
    //     if (rst.status === 200) {
    //       accessComplete();
    //     } else {
    //       setMsg("Access Test Failed.")
    //     }
    //   });
    // } else {
    //   accessComplete();
    // }
  }, [navigate]);
  const accessComplete = async () => {
    const rst = await post_access_complete();
    if (rst.status === 200) {
      setMsg("Access Test Complete.");
    } else {
      const message = (rst.message || "Unexpected Error") as string;
      setMsg(message);
    }
  };

  return (
    <Paper elevation={1} sx={{ m: 5, p: 2 }}>
      <Box id="access_test_result" sx={{ minHeight: 300 }}>
        {msg}
      </Box>
    </Paper>
  );
};

export default Test2Page;
