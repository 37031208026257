import { Button, Box, Typography } from "@mui/material";

import { JobType } from "../../../api/api";
import { defs } from "../../../common/const_defs";

import type { Job } from "../../../api/api";
import type { FC, ReactNode } from "react";

type JobActionCellProps = {
  job: Job;
  onAction?: (verb: string, job: Job) => void;
};
const JobActionCell: FC<JobActionCellProps> = ({ job, onAction }) => {
  const handleAction = (verb: string) => {
    if (onAction) {
      onAction(verb, job);
    }
  };

  const getStatusNode = (job: Job): ReactNode => {
    switch (job.type) {
      case JobType.InProgress:
        return (
          <Box sx={{ color: "gray" }}>
            <Button
              size="small"
              color="primary"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobProcess)}
              sx={{ m: 0, p: 0, minWidth: 50, width: 30 }}
              role="button"
            >
              Continue
            </Button>
          </Box>
        );
      case JobType.NotStarted:
        return (
          <Box sx={{ color: "gray" }}>
            <Button
              size="small"
              color="primary"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobDataInit)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
              role="button"
            >
              Start
            </Button>
          </Box>
        );
      // case defs.kJobStat_Created:
      //   return (
      //     <Box sx={{ color: "gray" }}>
      //       <Button
      //         size="small"
      //         color="primary"
      //         variant="text"
      //         aria-label="error information"
      //         onClick={() => handleAction(defs.kAct_JobDataInit)}
      //         sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
      //       >
      //         対応する
      //       </Button>
      //     </Box>
      //   );
      case JobType.Done:
        return (
          <Box sx={{ color: "success" }}>
            <Button
              size="small"
              color="warning"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobCheckData)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
              role="button"
            >
              History
            </Button>
          </Box>
        );
      default:
        return <Typography variant="caption">その他</Typography>;
    }
  };
  return (
    <Typography component="div" variant="body2">
      {getStatusNode(job)}
    </Typography>
  );
};
const WfJobActionCell: FC<JobActionCellProps> = ({ job, onAction }) => {
  const handleAction = (verb: string) => {
    if (onAction) {
      onAction(verb, job);
    }
  };

  const getStatusNode = (job: Job): ReactNode => {
    switch (job.type) {
      case defs.kJobStat_InProgress:
        return (
          <Box sx={{ color: "gray" }}>
            <Button
              size="small"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobView)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              View
            </Button>
            <Button
              size="small"
              color="warning"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobRemind)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              Remind
            </Button>
          </Box>
        );
      case defs.kJobStat_DataInit:
        return (
          <Box sx={{ color: "gray" }}>
            <Button
              size="small"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobView)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              View
            </Button>
            <Button
              size="small"
              color="warning"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobRemind)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              Remind
            </Button>
          </Box>
        );
      case defs.kJobStat_Created:
        return (
          <Box sx={{ color: "gray" }}>
            <Button
              size="small"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobView)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              View
            </Button>
            <Button
              size="small"
              color="warning"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobRemind)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              Remind
            </Button>
          </Box>
        );
      case defs.kJobStat_Done:
        return (
          <Box sx={{ color: "success" }}>
            <Button
              size="small"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobView)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              View
            </Button>
            <Button
              size="small"
              color="warning"
              variant="text"
              aria-label="error information"
              onClick={() => handleAction(defs.kAct_JobRemind)}
              sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
            >
              Remind
            </Button>
          </Box>
        );
      default:
        return <Typography variant="caption">その他</Typography>;
    }
  };
  return (
    <Typography component="div" variant="body2">
      {getStatusNode(job)}
    </Typography>
  );
};
export default JobActionCell;
export { WfJobActionCell };
