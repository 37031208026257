/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

type AuthState = {
  isAuthenticated: boolean;
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  authType?: string;
};

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: undefined,
  idToken: undefined,
  refreshToken: undefined,
  authType: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<Required<Omit<AuthState, "isAuthenticated">>>) => ({
      isAuthenticated: true,
      ...action.payload,
    }),
    setSignOut: () => initialState,
    setTokens: (
      state,
      action: PayloadAction<{ accessToken?: string; idToken?: string; refreshToken?: string }>
    ) => {
      if (action.payload.accessToken) {
        state.accessToken = action.payload.accessToken;
      }
      if (action.payload.idToken) {
        state.idToken = action.payload.idToken;
      }
      if (action.payload.refreshToken) {
        state.refreshToken = action.payload.refreshToken;
      }
    },
  },
});

export const { setLogin, setSignOut, setTokens } = authSlice.actions;

export const authState = (state: RootState) => state.auth;
