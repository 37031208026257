import { Box, Button } from "@mui/material";
import { useRef } from "react";

const FileUploadButton = ({ onFileSelected, multi, children, ...props }) => {
  const inputRef = useRef(null);

  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileSelected) {
      onFileSelected(multi ? event.target.files : file);
    }
  };

  const clickFileUploadButton = () => {
    inputRef.current.value = "";
    inputRef.current.click();
  };

  return (
    <Box>
      <Button onClick={clickFileUploadButton} {...props}>
        {children}
      </Button>
      {multi ? (
        <input hidden multiple ref={inputRef} type="file" onChange={onFileInputChange} />
      ) : (
        <input hidden ref={inputRef} type="file" onChange={onFileInputChange} />
      )}
    </Box>
  );
};

export default FileUploadButton;
export {
  FileUploadButton,
  // FileUploadZone
};
