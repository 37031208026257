import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { kObj_NullJob } from "common/const_defs";
import { useSnackbar } from "notistack";
import { useState, useImperativeHandle, forwardRef } from "react";
import toast from "react-hot-toast";
import { isInitiateJobRequest } from "services/common/type-guard";
import { useInitiateJobMutation } from "services/job";

import { JobType, JobAttributeEnum } from "../../api/api";
import ModalDialog from "../../components/molecular/ModalDialog";

import JobPanelInit from "./components/JobActionInitPanel";
import JobPanelSubmit from "./components/JobActionSubmitPanel";
import JobPanelUpload from "./components/JobActionUploadPanel";

import type { Job } from "../../api/api";
import type { ActionCB, FileUploadNode } from "../../common/type_defs";
import type { RowSelectionState } from "@tanstack/react-table";

export const JobDialogType = {
  kStart: "start",
  kContinue: "continue",
} as const;
const JobDlgStep = {
  kInit: "init",
  kUpload: "upload",
} as const;

const getCaption = (step: string, job: Job): string => {
  if (step === JobDlgStep.kInit) {
    return "Task Management";
  }
  if (step === JobDlgStep.kUpload) {
    return "Workflow Job Step1";
  }
  return "Job Dialog";
};
const getPanel = (step: string, job: Job, setJob: (job: Job) => void, onAction: ActionCB) => {
  if (!job) {
    return <>Please close dialog</>;
  }
  if (step === JobDlgStep.kInit) {
    return <JobPanelInit job={job} setJob={setJob} onAction={onAction} />;
  }
  if (step === "upload") {
    return <JobPanelUpload job={job} setJob={setJob} onAction={onAction} />;
  }
  if (step === JobDlgStep.kUpload) {
    return <JobPanelSubmit onAction={onAction} />;
  }
  return <></>;
};

const isJobFileListValid = (job: Job): boolean => {
  if (job?.fileList === undefined) {
    return false;
  }

  if (job?.fileList.length === 0) {
    return false;
  }

  return job?.fileList.every((node: FileUploadNode) => {
    if (job?.workflowType?.startsWith("SUBMITTING_FILE")) {
      // 必ず入力が必要な項目
      if (node?.nextAction === "" || node?.fileType === "") {
        return false;
      }

      // zipの場合はCEの状態を無視する
      if (node?.fileType === "zip") {
        return true;
      }

      // CEが選択されている
      if (node?.peList === undefined || node?.peList?.length < 1) {
        return false;
      }

      return true;
    }

    return node?.nextAction !== "";
  });
};

const getButtons = (
  step: string,
  job: Job,
  onAction: ActionCB,
  requestInitiateJob: (filterSelectedCE?: boolean) => Promise<Job>,
  isInitiateJobLoading: boolean
) => {
  if (!job) {
    return (
      <Button variant="contained" onClick={() => onAction("init-cancel")}>
        Close
      </Button>
    );
  }
  const processBtnLabel = `Go to ${
    job?.attribute === JobAttributeEnum.Submit ? "File Upload" : "Approve"
  }`;

  const showCheckResultButton = () =>
    job?.type === JobType.InProgress && job?.attribute === JobAttributeEnum.Submit;
  const showGoToFileUploadButon = () => job?.attribute === JobAttributeEnum.Submit;
  const showApproveButton = () => job?.attribute === JobAttributeEnum.Approve;

  const checkDisabled = (job: Job): boolean => {
    /* 
    Disabledにする条件
    */
    if (job?.workflowType === "STANDARD_TEMPLATE" && job?.type === JobType.NotStarted) {
      // テンプレWFの場合、initiateされるまでGo to Approve/File Uploadボタンを押せない
      return true;
    }
    if (
      job?.workflowType?.startsWith("SUBMITTING_FILE_") &&
      job?.type === JobType.NotStarted &&
      job?.attribute === JobAttributeEnum.Approve
    ) {
      // 添付ファイルWFの承認の場合はGo to Approveボタンは押させず、ダウンロードボタンからinitiateさせる
      return true;
    }
    if (
      job?.workflowType !== "STANDARD_TEMPLATE" &&
      (job?.name === undefined || job?.name === null || job?.name === "")
    ) {
      // テンプレWF以外の場合、Job nameが入力されたらGo to Approve/File Uploadボタンを押せる
      return true;
    }

    return false;
  };

  if (step === JobDlgStep.kInit) {
    return (
      <Grid container justifyContent="center" sx={{ bkcolor: "red" }} spacing={2}>
        <Grid>
          <Button variant="contained" onClick={() => onAction("init-cancel")}>
            Cancel
          </Button>
        </Grid>
        {showGoToFileUploadButon() && (
          <Grid>
            <LoadingButton
              variant="contained"
              disabled={checkDisabled(job)}
              loading={isInitiateJobLoading}
              onClick={async () => {
                if (
                  !(
                    job?.attribute === JobAttributeEnum.Submit &&
                    job?.workflowType === "STANDARD_TEMPLATE"
                  ) &&
                  job?.type === JobType.NotStarted
                ) {
                  try {
                    await requestInitiateJob();
                  } catch (error) {
                    return;
                  }
                }
                onAction("init-goto-upload");
              }}
            >
              {processBtnLabel}
            </LoadingButton>
          </Grid>
        )}
        {showCheckResultButton() && (
          <Grid>
            <Button
              variant="contained"
              onClick={() => {
                onAction("open-check-result", job);
              }}
            >
              Go to Check result
            </Button>
          </Grid>
        )}
        {showApproveButton() && (
          <Grid>
            <LoadingButton
              variant="contained"
              disabled={checkDisabled(job)}
              loading={isInitiateJobLoading}
              onClick={async () => {
                let targetJob = job;
                if (job?.type === JobType.NotStarted) {
                  try {
                    targetJob = await requestInitiateJob(true);
                  } catch (error) {
                    return;
                  }
                }
                onAction("open-check-result", targetJob);
              }}
            >
              Go to Approve
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  }
  if (step === JobDlgStep.kUpload) {
    return (
      <Grid container sx={{ justifyContent: "center" }} spacing={2}>
        <Grid>
          <Button variant="contained" onClick={() => onAction("upload-goto-init")}>
            Back
          </Button>
        </Grid>
        <Grid>
          <Button
            disabled={!isJobFileListValid(job)}
            variant="contained"
            onClick={() => onAction("start-check-process", job)}
          >
            Start Check Process
          </Button>
        </Grid>
      </Grid>
    );
  }
  return <></>;
};

export interface JobDialogInstance {
  openJobDialog: (step: string, job: Job) => void;
}
type JobDialogProps = {
  onClose?: (flag: string) => void;
  onAction: ActionCB;
};
const JobDialog = forwardRef<JobDialogInstance, JobDialogProps>(({ onClose, onAction }, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>("start");
  const [step, setStep] = useState<string>(JobDlgStep.kInit);
  const { enqueueSnackbar } = useSnackbar();

  const [workJob, setWorkJob] = useState<Job>(kObj_NullJob);
  const [initiateJob, { isLoading: isInitiateJobLoading }] = useInitiateJobMutation();

  // duplicated code
  const getSelectedItem = (selList: RowSelectionState): string[] => {
    const peList: string[] = [];
    for (const key in selList) {
      if (key in selList && selList[key]) {
        peList.push(key);
      }
    }
    return peList;
  };

  const requestInitiateJob = async (filterSelectedCE = false) => {
    let details = workJob.JobDetails;
    if (filterSelectedCE) {
      const { selList } = workJob;
      const peList = getSelectedItem(selList!);
      details = workJob.JobDetails!.filter((item) => peList.includes(item.PECode));
    }

    const initiateData = {
      name: workJob.name,
      workflowType: workJob.workflowType,
      workflowStep: workJob.workflowStep,
      attribute: workJob.attribute,
      JobDetails: details,
      fiscalYear: workJob.fiscalYear,
      fixVersion: workJob.fixVersion,
      fileType: workJob.fileType,
    };

    if (!isInitiateJobRequest(initiateData)) {
      enqueueSnackbar("Cannot initiate. Check each field.", { variant: "error" });
      throw new Error("initiate vali field");
    }

    const initiateJobResponse = await initiateJob(initiateData).unwrap();
    setWorkJob(initiateJobResponse.job);
    enqueueSnackbar(initiateJobResponse.message, { variant: "success" });
    return initiateJobResponse.job;
  };

  useImperativeHandle(ref, () => ({
    openJobDialog: (type, job) => {
      setOpen(true);
      setType(type);
      setWorkJob(job);
      if (type === JobDialogType.kStart) {
        setStep(JobDlgStep.kInit);
      } else if (type === JobDialogType.kContinue) {
        setStep(JobDlgStep.kUpload);
      }
    },
  }));

  const handleSearch = (v: Record<string, unknown>) => {
    // toast('search');
    toast.error(
      "This toast is super big. I don't think anyone could eat it in one bite.\n\nIt's larger than you expected. You eat it but it does not seem to get smaller.",
      {
        duration: 3000,
      }
    );
    // alert("search")
    // onAction('search', v)
  };

  const handleAction: ActionCB = (act, vd) => {
    if (act === "init-cancel" || act === "upload-cancel" || act === "check-result-close") {
      setOpen(false);
      if (onClose) {
        onClose("cancel");
      }
      // } else if (act === "job-changed") {
      //   setWorkJob(vd as Job);
    } else if (act === "init-goto-upload") {
      setStep("upload");
    } else if (act === "upload-goto-init") {
      setStep("init");
    } else if (act === "init-goto-check_result") {
      setStep("init");
    } else {
      onAction(act, vd);
    }
  };

  const handleClose = (flag: string) => {
    setOpen(false);
    setWorkJob(kObj_NullJob);
    if (onClose) {
      onClose(flag);
    }
  };

  return (
    <ModalDialog
      open={open}
      title={getCaption(step, workJob)}
      content={getPanel(step, workJob, setWorkJob, handleAction)}
      buttons={getButtons(step, workJob, handleAction, requestInitiateJob, isInitiateJobLoading)}
      onClose={handleClose}
      size="large"
    />
  );
});

export { JobDialog };
