import { createContext, useContext, useReducer } from "react";

const initialState = {
  checkResult: {
    show: false,
    jobId: "",
    onAction: (act, vd) => {},
  },
  checkResultWmRole: {
    show: false,
    jobId: "",
    onAction: (act, vd) => {},
  },
  peWfStatus: {
    show: false,
    peCode: "",
    onAction: (act, vd) => {},
  },
  mailSender: {
    show: false,
    params: {},
    onAction: (act, vd) => {},
  },
};
const DialogContext = createContext(initialState);

// check result
const DLG_CHK_RST_OPEN = "DLG_CHK_RST_OPEN";
const DLG_CHK_RST_CANCEL = "DLG_CHK_RST_CANCEL";
const DLG_CHK_RST_OK = "DLG_CHK_RST_OK";

// check result for WORKFLOW_MANAGEMENT user
const DLG_CHK_RST_WM_ROLE_OPEN = "DLG_CHK_RST_WM_ROLE_OPEN";
const DLG_CHK_RST_WM_ROLE_CANCEL = "DLG_CHK_RST_WM_ROLE_CANCEL";
const DLG_CHK_RST_WM_ROLE_OK = "DLG_CHK_RST_WM_ROLE_OK";

// pe workflow status
const DLG_PEWF_STATUS_OPEN = "DLG_PEWF_STATUS_OPEN";
const DLG_PEWF_STATUS_CLOSE = "DLG_PEWF_STATUS_CLOSE";

// mail sender
const DLG_MAIL_SENDER_OPEN = "DLG_MAIL_SENDER_OPEN";
const DLG_MAIL_SENDER_CLOSE = "DLG_MAIL_SENDER_CLOSE";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DLG_CHK_RST_OPEN:
      return {
        ...state,
        checkResult: {
          show: true,
          jobId: action.payload.jobId,
          onAction: action.payload.onAction,
        },
      };
    case DLG_CHK_RST_CANCEL:
      return {
        ...state,
        checkResult: {
          show: false,
          verb: "cancel",
        },
      };
    case DLG_CHK_RST_OK:
      return {
        ...state,
        checkResult: {
          show: false,
          verb: "ok",
        },
      };
    case DLG_CHK_RST_WM_ROLE_OPEN:
      return {
        ...state,
        checkResultWmRole: {
          show: true,
          jobId: action.payload.jobId,
          onAction: action.payload.onAction,
        },
      };
    case DLG_CHK_RST_WM_ROLE_CANCEL:
      return {
        ...state,
        checkResultWmRole: {
          show: false,
          verb: "cancel",
        },
      };
    case DLG_CHK_RST_WM_ROLE_OK:
      return {
        ...state,
        checkResultWmRole: {
          show: false,
          verb: "ok",
        },
      };
    case DLG_PEWF_STATUS_OPEN:
      return {
        ...state,
        peWfStatus: {
          show: true,
          peCode: action.payload.peCode,
          onAction: action.payload.onAction,
        },
      };
    case DLG_PEWF_STATUS_CLOSE:
      return {
        ...state,
        peWfStatus: { ...state.peWfStatus, show: false },
      };
    case DLG_MAIL_SENDER_OPEN:
      return {
        ...state,
        mailSender: {
          show: true,
          params: { ...action.payload.params },
          onAction: action.payload.onAction,
        },
      };
    case DLG_MAIL_SENDER_CLOSE:
      return {
        ...state,
        mailSender: { ...state.mailSender, show: false },
      };
    default:
      return initialState;
  }
};
const DialogContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <DialogContext.Provider value={[state, dispatch]}>{children}</DialogContext.Provider>;
};

// check result
let resolveCB_CheckResult;
function useDlgCheckResult() {
  const [state, dispatch] = useContext(DialogContext);
  const closeCheckResult = () => {
    closeDlg();
  };

  const openCheckResult = (jobId, handleAction) => {
    dispatch({
      type: DLG_CHK_RST_OPEN,
      payload: { jobId, onAction: handleAction },
    });
    return new Promise((resolve, rej) => {
      resolveCB_CheckResult = resolve;
    });
  };

  const closeDlg = () => {
    dispatch({
      type: DLG_CHK_RST_OK,
      payload: {},
    });
  };

  const closeCheckResultWmRole = () => {
    closeDlgWmRole();
  };

  const openCheckResultWmRole = (jobId, handleAction) => {
    dispatch({
      type: DLG_CHK_RST_WM_ROLE_OPEN,
      payload: { jobId, onAction: handleAction },
    });
    return new Promise((resolve, rej) => {
      resolveCB_CheckResult = resolve;
    });
  };

  const closeDlgWmRole = () => {
    dispatch({
      type: DLG_CHK_RST_WM_ROLE_OK,
      payload: {},
    });
  };
  return {
    openCheckResult,
    closeCheckResult,
    state: state.checkResult,
    openCheckResultWmRole,
    closeCheckResultWmRole,
    stateWmRole: state.checkResultWmRole,
  };
}

// pe workflow status
let resolveCB_PeWfStatus;
function useDlgPeWfStatus() {
  const [state, dispatch] = useContext(DialogContext);
  const closePeWfStatus = () => {
    closeDlg();
  };

  const openPeWfStatus = (peCode, handleAction) => {
    dispatch({
      type: DLG_PEWF_STATUS_OPEN,
      payload: { peCode, onAction: handleAction },
    });
    return new Promise((resolve, rej) => {
      resolveCB_PeWfStatus = resolve;
    });
  };

  const closeDlg = (ryCompanyId) => {
    dispatch({
      type: DLG_PEWF_STATUS_CLOSE,
      payload: {},
    });
  };

  return { openPeWfStatus, closePeWfStatus, state: state.peWfStatus };
}

// mail sender
let resolveCB_MailSender;
function useDlgMailSender() {
  const [state, dispatch] = useContext(DialogContext);
  const closeMailSender = () => {
    closeDlg();
  };

  const openMailSender = (params, handleAction) => {
    dispatch({
      type: DLG_MAIL_SENDER_OPEN,
      payload: { params: { ...params }, onAction: handleAction },
    });
    return new Promise((resolve, rej) => {
      resolveCB_MailSender = resolve;
    });
  };

  const closeDlg = (ryCompanyId) => {
    dispatch({
      type: DLG_MAIL_SENDER_CLOSE,
      payload: {},
    });
  };

  return { openMailSender, closeMailSender, state: state.mailSender };
}

export { useDlgCheckResult, useDlgPeWfStatus, useDlgMailSender, DialogContextProvider };
