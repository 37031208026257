import { createSelectionListFromStringArray } from "util/create-selection";

import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Button,
  FormControl,
  FormHelperText,
  Radio,
  FormControlLabel,
  Typography,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { useConfirm } from "render-hooks/useConfirm";
import { useSelectCEDialog } from "render-hooks/useSelectCEDialog";
import { useGetActionListQuery, useGetWorkflowStatusListQuery } from "services/const";
import { useGetMailTemplateListQuery, useSaveMailTemplateMutation } from "services/mail";
import { useGetWorkflowStepQuery } from "services/workflow";
import * as yup from "yup";

import { defs } from "../../../common/const_defs";
import { msg } from "../../../common/msg_defs";
import { AdcTitle1, AdcTitle2 } from "../../../components/atoms/AdcTitle";
import { AdcLabelInput } from "../../../components/molecular/AdcLabelInput";
import { AdcMultiSelect } from "../../../components/molecular/AdcMultiSelect";
import { AdcNumericField } from "../../../components/molecular/AdcNumericField";
import { AdcRadioGroup } from "../../../components/molecular/AdcRadioGroup";
import { AdcSelect } from "../../../components/molecular/AdcSelect";
import { CommonSelect } from "../../../components/molecular/CommonSelect";
import { useYupResolver } from "../../../util/yup-helper";

import type { ActionCB, SelectNode, MailRemindData } from "../../../common/type_defs";
import type { VerbInstance } from "../../../components/atoms/CommonInstance";
import type { FieldErrors, SubmitHandler } from "react-hook-form";
import type { MailTemplate } from "services/mail";

const schemaInfo = yup.object({
  targetGroup: yup.array().min(0, msg.validation.kMsg_MandatoryField),
  targetPE: yup.array().min(0, msg.validation.kMsg_MandatoryField),
  targetStep: yup.string(), // .required(msg.validation.kMsg_MandatoryField),
  targetStatus: yup.string(), // .required(msg.validation.kMsg_MandatoryField),
  remindType: yup.number().required(msg.validation.kMsg_MandatoryField),
  remindCount: yup.number().when("remindType", {
    is: 1,
    then: (schema) =>
      schema
        .required(msg.validation.kMsg_MandatoryField)
        .min(1, "Value must be greater than 0.")
        .max(99, "Value must be less than 100."),
  }),
  remindInterval: yup.number().when("remindType", {
    is: 1,
    then: (schema) =>
      schema
        .required(msg.validation.kMsg_MandatoryField)
        .min(1, "Value must be greater than 0.")
        .max(99, "Value must be less than 100."),
  }),
});
const schemaMsg = yup.object({
  subject: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .min(1, "The minimum length is 1 characters.")
    .max(120, "The maximum length is 120 characters."),
  content: yup
    .string()
    .required(msg.validation.kMsg_MandatoryField)
    .max(9999, "The maximum length is 9,999 characters."),
});

const kTitleFlex = { xs: 12, sm: 5, md: 5 };
const kValueFlexF = { xs: 12, sm: 7, md: 7 };
const kTitleSxx = { fontSize: "12px", fontWeight: 700, pl: 6, pt: 2 };

const MailSendInfoPanel = forwardRef<
  VerbInstance,
  { data: MailRemindData; onAction: ActionCB; setCurData: Dispatch<SetStateAction<MailRemindData>> }
>(({ data, onAction, setCurData }, ref) => {
  const { control, watch, getValues, setValue, handleSubmit } = useForm<MailRemindData>({
    defaultValues: data,
    // onSubmit modeの場合、formStateのisValidは適切な値とならないことに注意
    mode: "onSubmit",
    resolver: useYupResolver(schemaInfo),
  });
  const { openSelectCEDialog, renderSelectCEDialog } = useSelectCEDialog();

  const watchRemindType = watch("remindType", 0);
  const watchTargetPE = watch("targetPE", []);

  const { data: workflowStatusList } = useGetWorkflowStatusListQuery();
  const { data: workflowStepList } = useGetWorkflowStepQuery(
    {
      workflowType: data.workflowType as string,
    },
    {
      skip: !data.workflowType,
    }
  );
  const { data: actionListData } = useGetActionListQuery();

  const workflowStatusSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowStatusList) ?? [],
    [workflowStatusList]
  );
  const workflowStepSelectionList: SelectNode[] = useMemo(
    () => createSelectionListFromStringArray(workflowStepList) ?? [],
    [workflowStepList]
  );
  const actionSelectionList: SelectNode[] = useMemo(
    () =>
      actionListData?.actionList.map(({ displayName, name }) => ({
        label: displayName,
        value: name,
      })) ?? [],
    [actionListData?.actionList]
  );

  useImperativeHandle(ref, () => ({
    doVerb,
  }));
  const doVerb = (act: string, vd: unknown): unknown => null;

  const onSubmit: SubmitHandler<MailRemindData> = useCallback(
    (fd: MailRemindData) => {
      const newData = { ...data };
      newData.targetGroup = fd.targetGroup;
      newData.targetStep = fd.targetStep;
      newData.targetPE = fd.targetPE;
      newData.targetStatus = fd.targetStatus;
      newData.remindType = fd.remindType;
      newData.remindCount = fd.remindCount;
      newData.remindInterval = fd.remindInterval;
      onAction("goto-msg", newData);
      // console.log('✅Submitted', data);
    },
    [data, onAction]
  );

  const onError = useCallback((errors: FieldErrors<MailRemindData>) => {
    console.log("❌Error", errors);
  }, []);

  const handleOnDetailedSearchClick = async () => {
    const selectCEList = await openSelectCEDialog({
      defaultCodeList: watchTargetPE,
    });
    setCurData({ ...data, targetPE: selectCEList });
    setValue("targetPE", selectCEList);
  };

  const makeTargetPeLabel = useCallback(() => {
    const targetPE = watchTargetPE;
    if (!targetPE || !Array.isArray(targetPE)) {
      return "0 CE is selected";
    }
    if (targetPE.length === 1) {
      return `1 CE is selected`;
    }
    if (targetPE.length > 1) {
      return `${targetPE.length} CEs are selected`;
    }
    return "0 CE is selected";
  }, [watchTargetPE]);

  return (
    <form id="form-mail-info" onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ px: 3, fontSize: "12px" }}
        spacing={2}
      >
        <Grid xs={12} sm={12} sx={{ mx: 2 }}>
          <AdcTitle2>Workflow Type</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 5 }}>
          <TextField
            id="workflowType"
            value={data.workflowType}
            fullWidth
            size="small"
            disabled
            sx={{ maxWidth: 360 }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 2 }}>
          <AdcTitle2>Send to</AdcTitle2>
        </Grid>
        <Grid {...kTitleFlex} sx={kTitleSxx}>
          <Typography component="span">{makeTargetPeLabel()}</Typography>
        </Grid>
        <Grid {...kValueFlexF} sx={{ pr: 3 }}>
          <FormControl error={!!control.getFieldState("targetPE").error} fullWidth>
            <Button
              variant="outlined"
              onClick={handleOnDetailedSearchClick}
              fullWidth
              sx={{ maxWidth: 500 }}
            >
              Detailed Search
            </Button>
            <FormHelperText>{control.getFieldState("targetPE").error?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid {...kTitleFlex} sx={kTitleSxx}>
          <Typography component="span">Target Step</Typography>
        </Grid>
        <Grid {...kValueFlexF} sx={{ pr: 3 }}>
          <AdcSelect<MailRemindData, "targetStep">
            name="targetStep"
            control={control}
            config={{
              displayErrorMessage: true,
              options: workflowStepSelectionList,
            }}
            muiProps={{
              selectProps: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid {...kTitleFlex} sx={kTitleSxx}>
          <Typography component="span">Target Status</Typography>
        </Grid>
        <Grid {...kValueFlexF} sx={{ pr: 3 }}>
          <AdcSelect<MailRemindData, "targetStatus">
            name="targetStatus"
            control={control}
            config={{
              displayErrorMessage: true,
              options: workflowStatusSelectionList,
            }}
            muiProps={{
              selectProps: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid xs={12}>
          <Typography component="span" sx={{ ml: 8 }}>
            And
          </Typography>
        </Grid>
        <Grid {...kTitleFlex} sx={kTitleSxx}>
          <Typography component="span">Target Group</Typography>
        </Grid>
        <Grid {...kValueFlexF} sx={{ pr: 3 }}>
          <AdcMultiSelect<MailRemindData, "targetGroup">
            name="targetGroup"
            control={control}
            config={{
              displayErrorMessage: true,
              options: actionSelectionList,
            }}
            muiProps={{
              selectProps: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 2 }}>
          <AdcTitle2>Reminder</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ ml: 5 }}>
          <AdcRadioGroup<MailRemindData, "remindType"> name="remindType" control={control}>
            <Stack sx={{}}>
              <FormControlLabel value={0} control={<Radio />} label="No send" />
              <Stack
                direction="row"
                sx={{
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <FormControlLabel value={1} control={<Radio />} label="Send" />
                <AdcNumericField<MailRemindData>
                  name="remindCount"
                  control={control}
                  config={{
                    displayErrorMessage: false,
                    displayErrorTip: true,
                  }}
                  muiProps={{
                    textFieldProps: {
                      size: "small",
                      disabled: watchRemindType !== 1,
                      sx: { maxWidth: "80px" },
                    },
                  }}
                />
                <Typography>reminder emails every</Typography>
                <AdcNumericField<MailRemindData>
                  name="remindInterval"
                  control={control}
                  config={{
                    displayErrorMessage: false,
                    displayErrorTip: true,
                  }}
                  muiProps={{
                    textFieldProps: {
                      size: "small",
                      disabled: watchRemindType !== 1,
                      sx: { maxWidth: "80px" },
                    },
                  }}
                />
                <Typography>days</Typography>
              </Stack>
            </Stack>
          </AdcRadioGroup>
        </Grid>
      </Grid>
      {renderSelectCEDialog()}
    </form>
  );
});

type MailTemplateSelection = SelectNode & MailTemplate;

const initMailTemplateSelection: MailTemplateSelection = {
  content: "",
  label: "",
  mailType: "",
  subject: "",
  value: "",
};

const createSelectionFromTemplate = (template: MailTemplate): MailTemplateSelection => ({
  ...template,
  value: template.mailType,
  label: template.mailType,
});

const MailSendMsgPanel = forwardRef<
  VerbInstance,
  { data: MailRemindData; onAction: ActionCB; setCurData: Dispatch<SetStateAction<MailRemindData>> }
>(({ data, onAction, setCurData }, ref) => {
  const { control, watch, getValues, setValue, handleSubmit } = useForm<MailRemindData>({
    defaultValues: data,
    // onSubmit modeの場合、formStateのisValidは適切な値とならないことに注意
    mode: "onSubmit",
    resolver: useYupResolver(schemaMsg),
  });

  const subject = watch("subject");
  const content = watch("content");

  useEffect(() => {
    setCurData({ ...data, subject });
  }, [data, setCurData, subject]);
  useEffect(() => {
    setCurData({ ...data, content });
  }, [data, setCurData, content]);

  const { openConfirmDialog, renderConfirmDialog } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [templateName, setTemplateName] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] =
    useState<MailTemplateSelection>(initMailTemplateSelection);

  const { data: mailTemplateData } = useGetMailTemplateListQuery();
  const mailTemplateSelectionList: SelectNode[] = useMemo(
    () => [
      {
        label: "　",
        value: "",
      },
      ...(mailTemplateData?.mailTypeList.map(createSelectionFromTemplate) ?? []),
    ],
    // () =>
    //   createSelectionListFromStringArray(mailTemplateData?.mailTypeList.map((d) => d.mailType)) ??
    //   [],
    [mailTemplateData]
  );
  const [saveMailTemplate, { isLoading: isSaveMailTemplateLoading }] =
    useSaveMailTemplateMutation();

  const onSubmit: SubmitHandler<MailRemindData> = useCallback(
    (data: MailRemindData) => {
      onAction(defs.kAct_ExecSendMail, data);
      // send_mail_send(token, data);
      // console.log('✅Submitted', data);
    },
    [onAction]
  );

  const onError = useCallback((errors: FieldErrors<MailRemindData>) => {
    // console.log('❌Error', errors);
  }, []);

  useImperativeHandle(ref, () => ({
    doVerb,
  }));
  const doVerb = (act: string, vd: unknown): unknown => {
    if (act === "back") {
      const newData = { ...data };
      newData.subject = getValues().subject;
      newData.content = getValues().content;
      onAction("goto-info", newData);
    }
    return null;
  };

  const handleMailTemplateChanged = async (value: SelectNode) => {
    const selectItem = value as MailTemplateSelection;

    if (selectedTemplate.value === selectItem.value) {
      return;
    }

    const isConfirmed = await openConfirmDialog({
      title: "Do you want to discard and overwrite the current contents?",
      content: (
        <div>
          <Typography variant="h5">Template name</Typography>
          <TextField
            value={selectItem.mailType}
            fullWidth
            InputProps={{ readOnly: true }}
            size="small"
          />
          <Typography variant="h5">Subject</Typography>
          <TextField
            value={selectItem.subject}
            fullWidth
            InputProps={{ readOnly: true }}
            size="small"
          />
          <Typography variant="h5">Content</Typography>
          <TextField
            multiline
            rows={6}
            fullWidth
            value={selectItem.content}
            InputProps={{ readOnly: true }}
          />
        </div>
      ),
    });
    if (!isConfirmed) {
      return;
    }

    setValue("content", selectItem.content);
    setValue("subject", selectItem.subject);
    setCurData({ ...data, content: selectItem.content, subject: selectItem.subject });
    setSelectedTemplate(selectItem);
    setTemplateName("");
  };

  const handleSaveAsTemplate = async () => {
    const template: MailTemplate = {
      mailType: templateName,
      subject: getValues().subject!,
      content: getValues().content!,
    };

    try {
      await saveMailTemplate(template).unwrap();
      enqueueSnackbar(`Succeeded save mail template.`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Failed to save mail template.`, { variant: "error" });
      return;
    }

    setSelectedTemplate(createSelectionFromTemplate(template));
    setTemplateName("");
  };

  return (
    <form id="form-mail-msg" onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ px: 1, fontSize: "12px" }}
        spacing={2}
      >
        <Grid xs={12} sm={12}>
          <AdcTitle1>Send notification</AdcTitle1>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 2 }}>
          <AdcTitle2>Edit Message</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 5 }}>
          <CommonSelect
            value={selectedTemplate}
            options={mailTemplateSelectionList}
            onChanged={handleMailTemplateChanged}
            sxx={{ minWidth: 250 }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 5 }}>
          <AdcLabelInput<MailRemindData>
            name="subject"
            label="Mail subject - mandatory_field"
            control={control}
            config={{
              displayErrorMessage: true,
            }}
            muiProps={{
              textFieldProps: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 5 }}>
          <AdcLabelInput<MailRemindData>
            name="content"
            label="Mail body - mandatory_field"
            control={control}
            config={{
              displayErrorMessage: true,
            }}
            muiProps={{
              textFieldProps: {
                fullWidth: true,
                size: "small",
                multiline: true,
                rows: 6,
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mx: 5 }}>
          <Stack
            direction="row"
            sx={{
              gap: "0.5rem",
              alignItems: "center",
              width: "100%",
              display: "flex",
            }}
          >
            <Typography>Save this message as template with the name</Typography>
            <TextField
              id="template_name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              size="small"
              fullWidth
              sx={{ minWidth: 80, flex: 1 }}
            />
            <LoadingButton
              variant="contained"
              disabled={!templateName}
              onClick={handleSaveAsTemplate}
              loading={isSaveMailTemplateLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      {renderConfirmDialog()}
    </form>
  );
});

export { MailSendInfoPanel, MailSendMsgPanel };
