import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useImperativeHandle, forwardRef } from "react";

import ModalDialog from "../../components/molecular/ModalDialog";

import { CheckResultPanel } from "./components/CheckResultPanel";

import type { ActionCB } from "../../common/type_defs";
import type { VerbInstance } from "../../components/atoms/CommonInstance";

type CheckResultDialogProps = {
  open: boolean;
  jobId: string;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const CheckResultDialog = forwardRef<VerbInstance, CheckResultDialogProps>(
  ({ open, jobId, onClose, onAction }, ref) => {
    useImperativeHandle(ref, () => ({
      doVerb,
    }));
    const doVerb = (act: string, vd: unknown): unknown => {
      if (act === "get-selected") {
        return null;
      }
      if (act === "update-sel") {
        return null;
      }
      return undefined;
    };

    const handleAction: ActionCB = (act, vd) => {
      onAction(act, vd);
    };

    return (
      <ModalDialog
        open={open}
        title="Item Check Result"
        content={<CheckResultPanel jobId={jobId} onAction={onAction} />}
        buttons={
          <Grid container spacing={2} sx={{ pl: 7, pr: 5, gap: 2 }}>
            <Grid>
              <Button variant="contained" onClick={() => onAction("check-result-close")}>
                Close
              </Button>
            </Grid>
          </Grid>
        }
        onClose={() => onClose("close")}
        size="large"
      />
    );
  }
);

export default CheckResultDialog;
export { CheckResultDialog };
