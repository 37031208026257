/* tslint:disable */
/* eslint-disable */
/**
 * ADC BEPS 2.0
 * This is the ADC for beps 2.0 API documentation.
 *
 * The version of the OpenAPI document: 0.0.2.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setSearchParams,
  toPathString,
} from "./common";
// @ts-ignore
import type { RowSelectionState } from "@tanstack/react-table";
import { FileUploadNode } from "common/type_defs";
import { BASE_PATH, BaseAPI, MandatoryFieldError, RequestArgs } from "./base";

/**
 *
 * @export
 * @interface AccessTestAttemptPost200Response
 */
export interface AccessTestAttemptPost200Response {
  /**
   *
   * @type {ActionResult}
   * @memberof AccessTestAttemptPost200Response
   */
  result?: ActionResult;
}
/**
 *
 * @export
 * @interface AccessTestAttemptPostRequest
 */
export interface AccessTestAttemptPostRequest {
  /**
   * Email
   * @type {string}
   * @memberof AccessTestAttemptPostRequest
   */
  email?: string;
  /**
   * Auth Type(ADC or AzureAD)
   * @type {string}
   * @memberof AccessTestAttemptPostRequest
   */
  authType?: AccessTestAttemptPostRequestAuthTypeEnum;
}

export const AccessTestAttemptPostRequestAuthTypeEnum = {
  Adc: "ADC",
  AzureAd: "AzureAD",
} as const;

export type AccessTestAttemptPostRequestAuthTypeEnum =
  (typeof AccessTestAttemptPostRequestAuthTypeEnum)[keyof typeof AccessTestAttemptPostRequestAuthTypeEnum];

/**
 *
 * @export
 * @interface AccessTestCompletePostRequest
 */
export interface AccessTestCompletePostRequest {
  /**
   * code
   * @type {boolean}
   * @memberof AccessTestCompletePostRequest
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ActionListResponse
 */
export interface ActionListResponse {
  /**
   *
   * @type {Array<ActionListResponseActionListInner>}
   * @memberof ActionListResponse
   */
  ActionList?: Array<ActionListResponseActionListInner>;
}
/**
 *
 * @export
 * @interface ActionListResponseActionListInner
 */
export interface ActionListResponseActionListInner {
  /**
   * Action Name
   * @type {string}
   * @memberof ActionListResponseActionListInner
   */
  name?: string;
  /**
   * Action Name
   * @type {string}
   * @memberof ActionListResponseActionListInner
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface ActionResult
 */
export interface ActionResult {
  /**
   * Result
   * @type {boolean}
   * @memberof ActionResult
   */
  success?: boolean;
  /**
   * Status
   * @type {string}
   * @memberof ActionResult
   */
  status?: string;
  /**
   * Message
   * @type {string}
   * @memberof ActionResult
   */
  message?: string;
}
/**
 *
 * @export
 * @interface CheckStatusFilter
 */
export interface CheckStatusFilter {
  /**
   *
   * @type {CheckType}
   * @memberof CheckStatusFilter
   */
  checkType?: CheckType;
  /**
   * Check Status
   * @type {string}
   * @memberof CheckStatusFilter
   */
  checkStatus?: CheckStatusFilterCheckStatusEnum;
}

export const CheckStatusFilterCheckStatusEnum = {
  Ok: "OK",
  Ng: "NG",
} as const;

export type CheckStatusFilterCheckStatusEnum =
  (typeof CheckStatusFilterCheckStatusEnum)[keyof typeof CheckStatusFilterCheckStatusEnum];

/**
 * Check Type
 * @export
 * @enum {string}
 */

export const CheckType = {
  CorrectFileNameFormat: "CORRECT_FILE_NAME_FORMAT",
  CorrectFileContentFormat: "CORRECT_FILE_CONTENT_FORMAT",
  NoInvalidPeContained: "NO_INVALID_PE_CONTAINED",
  NoInvalidDpContained: "NO_INVALID_DP_CONTAINED",
  NoOldDpContained: "NO_OLD_DP_CONTAINED",
  CorrectDataType: "CORRECT_DATA_TYPE",
  MandatoryField: "Mandatory_FIELD",
  NoInvalidRewrites: "NO_INVALID_REWRITES",
  MatchingResultWithTango: "MATCHING_RESULT_WITH_TANGO",
} as const;

export type CheckType = (typeof CheckType)[keyof typeof CheckType];

/**
 * Company Category
 * @export
 * @enum {string}
 */

export const CompanyCategory = {
  JvParent: "JV_PARENT",
  JvChild: "JV_CHILD",
  ParentCompany: "PARENT_COMPANY",
  ConsolidatedSubsidiary: "CONSOLIDATED_SUBSIDIARY",
  UnconsolidatedEntity: "UNCONSOLIDATED_ENTITY",
  UnconsolidatedSubsidiary: "UNCONSOLIDATED_SUBSIDIARY",
} as const;

export type CompanyCategory = (typeof CompanyCategory)[keyof typeof CompanyCategory];

/**
 *
 * @export
 * @interface CompanyCategoryListResponseInner
 */
export interface CompanyCategoryListResponseInner {
  /**
   * ID
   * @type {string}
   * @memberof CompanyCategoryListResponseInner
   */
  id?: string;
  /**
   *
   * @type {CompanyCategory}
   * @memberof CompanyCategoryListResponseInner
   */
  companyCategory?: CompanyCategory;
}
/**
 *
 * @export
 * @interface CountryListResponse
 */
export interface CountryListResponse {
  /**
   *
   * @type {Array<CountryListResponseCountryListInner>}
   * @memberof CountryListResponse
   */
  CountryList?: Array<CountryListResponseCountryListInner>;
}
/**
 *
 * @export
 * @interface CountryListResponseCountryListInner
 */
export interface CountryListResponseCountryListInner {
  /**
   * Country
   * @type {string}
   * @memberof CountryListResponseCountryListInner
   */
  country?: string;
  /**
   * Country Name
   * @type {string}
   * @memberof CountryListResponseCountryListInner
   */
  countryName?: string;
}
/**
 *
 * @export
 * @interface CreateSubmittingFileWorkflowRequest
 */
export interface CreateSubmittingFileWorkflowRequest {
  /**
   * 提出ファイル種別
   * @type {string}
   * @memberof CreateSubmittingFileWorkflowRequest
   */
  submittingFileCategory?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateSubmittingFileWorkflowRequest
   */
  fileTypes?: Array<string>;
}
/**
 *
 * @export
 * @interface CreateWorkflowFixVersionRequest
 */
export interface CreateWorkflowFixVersionRequest {
  /**
   * Fiscal Year
   * @type {number}
   * @memberof CreateWorkflowFixVersionRequest
   */
  fiscalYear?: number;
  /**
   * Version
   * @type {string}
   * @memberof CreateWorkflowFixVersionRequest
   */
  version?: string;
}
/**
 *
 * @export
 * @interface DataGenerateReportPostRequest
 */
export interface DataGenerateReportPostRequest {
  /**
   * Output Type
   * @type {string}
   * @memberof DataGenerateReportPostRequest
   */
  outputType?: string;
  /**
   * Report Type
   * @type {string}
   * @memberof DataGenerateReportPostRequest
   */
  reportType?: string;
  /**
   *
   * @type {Array<DataGenerateReportPostRequestFilterParametersInner>}
   * @memberof DataGenerateReportPostRequest
   */
  filterParameters?: Array<DataGenerateReportPostRequestFilterParametersInner>;
  /**
   * Filter Name
   * @type {string}
   * @memberof DataGenerateReportPostRequest
   */
  filterName?: string;
}
/**
 *
 * @export
 * @interface DataGenerateReportPostRequestFilterParametersInner
 */
export interface DataGenerateReportPostRequestFilterParametersInner {
  /**
   * Key
   * @type {string}
   * @memberof DataGenerateReportPostRequestFilterParametersInner
   */
  key?: string;
  /**
   * Value
   * @type {string}
   * @memberof DataGenerateReportPostRequestFilterParametersInner
   */
  value?: string;
  /**
   * Operation
   * @type {string}
   * @memberof DataGenerateReportPostRequestFilterParametersInner
   */
  operation?: string;
}
/**
 *
 * @export
 * @interface DataGenerateZipPostRequest
 */
export interface DataGenerateZipPostRequest {
  /**
   * Workflow JobID(ULID)
   * @type {string}
   * @memberof DataGenerateZipPostRequest
   */
  jobId?: string;
  /**
   * if `jobId` is not specified, this is required.
   * @type {Array<DataGenerateZipPostRequestFileListInner>}
   * @memberof DataGenerateZipPostRequest
   */
  fileList?: Array<DataGenerateZipPostRequestFileListInner>;
}
/**
 *
 * @export
 * @interface DataGenerateZipPostRequestFileListInner
 */
export interface DataGenerateZipPostRequestFileListInner {
  /**
   * PE Code
   * @type {string}
   * @memberof DataGenerateZipPostRequestFileListInner
   */
  PECode?: string;
  /**
   *
   * @type {WorkflowType}
   * @memberof DataGenerateZipPostRequestFileListInner
   */
  workflowType?: WorkflowType;
  /**
   * File Code list
   * @type {string}
   * @memberof DataGenerateZipPostRequestFileListInner
   */
  fileType?: string;
  /**
   * Fiscal Year
   * @type {number}
   * @memberof DataGenerateZipPostRequestFileListInner
   */
  fiscalYear?: number;
  /**
   * Version
   * @type {string}
   * @memberof DataGenerateZipPostRequestFileListInner
   */
  version?: string;
}
/**
 *
 * @export
 * @interface DataPresetPESheetPostRequest
 */
export interface DataPresetPESheetPostRequest {
  /**
   * Workflow JobID(ULID)
   * @type {string}
   * @memberof DataPresetPESheetPostRequest
   */
  jobId?: string;
  /**
   * PE Code
   * @type {string}
   * @memberof DataPresetPESheetPostRequest
   */
  PECode?: string;
}
/**
 *
 * @export
 * @interface DataPresetSummarySheetPostRequest
 */
export interface DataPresetSummarySheetPostRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof DataPresetSummarySheetPostRequest
   */
  PECodeList?: Array<string>;
  /**
   * Workflow JobID(ULID)
   * @type {string}
   * @memberof DataPresetSummarySheetPostRequest
   */
  jobId?: string;
}
/**
 *
 * @export
 * @interface DataReportUpdateFilterPostRequest
 */
export interface DataReportUpdateFilterPostRequest {
  /**
   * Filter Name
   * @type {string}
   * @memberof DataReportUpdateFilterPostRequest
   */
  filterName?: string;
  /**
   *
   * @type {Array<DataGenerateReportPostRequestFilterParametersInner>}
   * @memberof DataReportUpdateFilterPostRequest
   */
  filterParameters?: Array<DataGenerateReportPostRequestFilterParametersInner>;
}
/**
 *
 * @export
 * @interface DataStartCheckProcessPostRequest
 */
export interface DataStartCheckProcessPostRequest {
  /**
   * Workflow JobID(ULID)
   * @type {string}
   * @memberof DataStartCheckProcessPostRequest
   */
  jobId?: string;
}
/**
 *
 * @export
 * @interface FileListResponseInner
 */
export interface FileListResponseInner {
  /**
   * Fiscal Year
   * @type {number}
   * @memberof FileListResponseInner
   */
  fiscalYear?: number;
  /**
   * Company Name
   * @type {string}
   * @memberof FileListResponseInner
   */
  companyName?: string;
  /**
   * PE Code
   * @type {string}
   * @memberof FileListResponseInner
   */
  PECode?: string;
  /**
   * Country
   * @type {string}
   * @memberof FileListResponseInner
   */
  country?: string;
  /**
   * Upload Date
   * @type {string}
   * @memberof FileListResponseInner
   */
  uploadDate?: string;
  /**
   *
   * @type {WorkflowType}
   * @memberof FileListResponseInner
   */
  workflowType?: WorkflowType;
  /**
   * File Type
   * @type {string}
   * @memberof FileListResponseInner
   */
  fileType?: string;
  /**
   * File path in Amazon S3.
   * @type {string}
   * @memberof FileListResponseInner
   */
  filePath?: string;
  path?: string;
}
/**
 *
 * @export
 * @interface InitiateJobRequest
 */
export interface InitiateJobRequest {
  /**
   * Job name
   * @type {string}
   * @memberof InitiateJobRequest
   */
  name?: string;
  /**
   *
   * @type {WorkflowType}
   * @memberof InitiateJobRequest
   */
  workflowType?: WorkflowType;
  /**
   *
   * @type {WorkflowStep}
   * @memberof InitiateJobRequest
   */
  workflowStep?: string;
  /**
   *
   * @type {Array<InitiateJobRequestJobDetailsInner>}
   * @memberof InitiateJobRequest
   */
  JobDetails?: Array<InitiateJobRequestJobDetailsInner>;
  attribute?: string;
  fiscalYear?: number;
}
/**
 *
 * @export
 * @interface InitiateJobRequestJobDetailsInner
 */
export interface InitiateJobRequestJobDetailsInner {
  /**
   * PE Code
   * @type {string}
   * @memberof InitiateJobRequestJobDetailsInner
   */
  PECode?: string;
  /**
   * File Code list
   * @type {string}
   * @memberof InitiateJobRequestJobDetailsInner
   */
  fileType?: InitiateJobRequestJobDetailsInnerFileTypeEnum;
}

export const InitiateJobRequestJobDetailsInnerFileTypeEnum = {
  _1Pe1File: "1PE1FILE",
  Zipped1Pe1File: "ZIPPED_1PE1FILE",
  SummarySheet: "SUMMARY_SHEET",
} as const;

export type InitiateJobRequestJobDetailsInnerFileTypeEnum =
  (typeof InitiateJobRequestJobDetailsInnerFileTypeEnum)[keyof typeof InitiateJobRequestJobDetailsInnerFileTypeEnum];

/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   * ULID of the job. if `type` is `NOT_STARTED`, this will be null.
   * @type {string}
   * @memberof Job
   */
  id: string;
  /**
   * name of the job. if `type` is `NOT_STARTED`, this will be null.
   * @type {string}
   * @memberof Job
   */
  name?: string;
  /**
   *
   * @type {JobType}
   * @memberof Job
   */
  type?: JobType;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  userId?: string;
  /**
   *
   * @type {WorkflowType}
   * @memberof Job
   */
  workflowType?: WorkflowType;
  /**
   *
   * @type {WorkflowStep}
   * @memberof Job
   */
  workflowStep?: string;
  /**
   * Fiscal Year
   * @type {number}
   * @memberof Job
   */
  fiscalYear?: number;
  /**
   * Version
   * @type {string}
   * @memberof Job
   */
  fixVersion?: string;
  /**
   *
   * @type {Array<JobDetail>}
   * @memberof Job
   */
  JobDetails?: Array<JobDetail>;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  completedTime?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  attribute?: JobAttributeEnum;
  fileList?: FileUploadNode[];
  /**
   * `WorkflowFileType` の `name` を想定
   * STANDARD_TEMPLATEでのみ利用
   */
  fileType?: string;
  selList?: RowSelectionState;
  /**
   * sendback可能かどうか
   */
  sendbackable?: boolean;

  CEStatusInfo?: any;
}

export const JobAttributeEnum = {
  Submit: "SUBMIT",
  Approve: "APPROVE",
} as const;

export type JobAttributeEnum = (typeof JobAttributeEnum)[keyof typeof JobAttributeEnum];

/**
 *
 * @export
 * @interface JobDetail
 */
export interface JobDetail {
  /**
   * PE Code
   * @type {string}
   * @memberof JobDetail
   */
  PECode: string;
  /**
   * Company Name
   * @type {string}
   * @memberof JobDetail
   */
  companyName?: string;
  /**
   * File Code list
   * @type {string}
   * @memberof JobDetail
   */
  fileType?: JobDetailFileTypeEnum;
  /**
   *
   * @type {string}
   * @memberof JobDetail
   */
  fileName?: string;
  /**
   * File path in Amazon S3.
   * @type {string}
   * @memberof JobDetail
   */
  filePath?: string;
  /**
   * Next Action
   * @type {string}
   * @memberof JobDetail
   */
  nextAction?: JobDetailNextActionEnum;
  /**
   *
   * @type {PEStatus}
   * @memberof JobDetail
   */
  PEStatus?: PEStatus;
  /**
   * Completed Time
   * @type {string}
   * @memberof JobDetail
   */
  completedTime?: string;
  /**
   * Country
   * @type {string}
   * @memberof JobDetail
   */
  country?: string;
  /**
   *
   * @type {CompanyCategory}
   * @memberof JobDetail
   */
  companyCategory?: CompanyCategory;
}

export const JobDetailFileTypeEnum = {
  _1Pe1File: "1PE1FILE",
  Zipped1Pe1File: "ZIPPED_1PE1FILE",
  SummarySheet: "SUMMARY_SHEET",
} as const;

export type JobDetailFileTypeEnum =
  (typeof JobDetailFileTypeEnum)[keyof typeof JobDetailFileTypeEnum];
export const JobDetailNextActionEnum = {
  StayCurrentStep: "STAY_CURRENT_STEP",
  ProceedNextStep: "PROCEED_NEXT_STEP",
} as const;

export type JobDetailNextActionEnum =
  (typeof JobDetailNextActionEnum)[keyof typeof JobDetailNextActionEnum];

/**
 *
 * @export
 * @interface JobJobIdAbortPostRequest
 */
export interface JobJobIdAbortPostRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof JobJobIdAbortPostRequest
   */
  PECode?: Array<string>;
}
/**
 *
 * @export
 * @interface JobJobIdApprovePostRequest
 */
export interface JobJobIdApprovePostRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof JobJobIdApprovePostRequest
   */
  PEList?: Array<string>;
}
/**
 *
 * @export
 * @interface JobJobIdSendbackPostRequest
 */
export interface JobJobIdSendbackPostRequest {
  /**
   *
   * @type {WorkflowStep}
   * @memberof JobJobIdSendbackPostRequest
   */
  returnStep?: WorkflowStep;
  /**
   *
   * @type {Array<string>}
   * @memberof JobJobIdSendbackPostRequest
   */
  PEList?: Array<string>;
}
/**
 *
 * @export
 * @interface JobListResponse
 */
export interface JobListResponse {
  /**
   *
   * @type {JobType}
   * @memberof JobListResponse
   */
  jobType?: JobType;
  /**
   *
   * @type {Array<Job>}
   * @memberof JobListResponse
   */
  jobs?: Array<Job>;
  /**
   * Result
   * @type {boolean}
   * @memberof JobListResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const JobType = {
  InProgress: "IN_PROGRESS",
  NotStarted: "NOT_STARTED",
  Done: "DONE",
} as const;

export type JobType = (typeof JobType)[keyof typeof JobType];

/**
 *
 * @export
 * @interface LoginInitiateAuthRequest
 */
export interface LoginInitiateAuthRequest {
  /**
   * Email
   * @type {string}
   * @memberof LoginInitiateAuthRequest
   */
  username?: string;
  /**
   * Password
   * @type {string}
   * @memberof LoginInitiateAuthRequest
   */
  password?: string;
}
/**
 *
 * @export
 * @interface LoginSuccessResult
 */
export interface LoginSuccessResult {
  /**
   *
   * @type {TokenResponse}
   * @memberof LoginSuccessResult
   */
  AuthenticationResult: TokenResponse;
  /**
   *
   * @type {object}
   * @memberof LoginSuccessResult
   */
  ResponseMetadata: object;
}
/**
 *
 * @export
 * @interface Mail
 */
export interface Mail {
  /**
   *
   * @type {WorkflowType}
   * @memberof Mail
   */
  workflowType?: WorkflowType;
  /**
   * 権限アクション
   * @type {Array<string>}
   * @memberof Mail
   */
  targetGroup?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Mail
   */
  targetPE?: Array<string>;
  /**
   * リマインド回数
   * @type {number}
   * @memberof Mail
   */
  remindCount?: number;
  /**
   * リマインド間隔(営業日)
   * @type {number}
   * @memberof Mail
   */
  remindInterval?: number;
  /**
   * 件名
   * @type {string}
   * @memberof Mail
   */
  subject?: string;
  /**
   * 本文
   * @type {string}
   * @memberof Mail
   */
  content?: string;
}
/**
 *
 * @export
 * @interface MailTemplate
 */
export interface MailTemplate {
  /**
   * メール種別
   * @type {string}
   * @memberof MailTemplate
   */
  mailType?: string;
  /**
   * 件名
   * @type {string}
   * @memberof MailTemplate
   */
  subject?: string;
  /**
   * 本文
   * @type {string}
   * @memberof MailTemplate
   */
  content?: string;
}
/**
 *
 * @export
 * @interface MailTemplateListResponse
 */
export interface MailTemplateListResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof MailTemplateListResponse
   */
  mailTypeList?: Array<string>;
}
/**
 *
 * @export
 * @interface MasterListResponseInner
 */
export interface MasterListResponseInner {
  /**
   * ID
   * @type {string}
   * @memberof MasterListResponseInner
   */
  id?: string;
  /**
   * Name
   * @type {string}
   * @memberof MasterListResponseInner
   */
  name?: string;
  /**
   * Last Modified
   * @type {string}
   * @memberof MasterListResponseInner
   */
  lastModified?: string;
  /**
   * File Type
   * @type {string}
   * @memberof MasterListResponseInner
   */
  masterType?: MasterListResponseInnerMasterTypeEnum;
  /**
   * File Type
   * @type {string}
   * @memberof MasterListResponseInner
   */
  fileType?: string;
  /**
   * File Type
   * @type {object}
   * @memberof MasterListResponseInner
   */
  file?: File;
  latestUpdated?: string;
}

export const MasterListResponseInnerMasterTypeEnum = {
  PeMaster: "PE_MASTER",
  ItemDefinitionMaster: "ITEM_DEFINITION_MASTER",
  UserActionMaster: "USER_ACTION_MASTER",
} as const;

export type MasterListResponseInnerMasterTypeEnum =
  (typeof MasterListResponseInnerMasterTypeEnum)[keyof typeof MasterListResponseInnerMasterTypeEnum];

/**
 *
 * @export
 * @interface NextActionListResponseInner
 */
export interface NextActionListResponseInner {
  /**
   * Next Action
   * @type {string}
   * @memberof NextActionListResponseInner
   */
  action?: NextActionListResponseInnerActionEnum;
  /**
   * Next Action
   * @type {string}
   * @memberof NextActionListResponseInner
   */
  displayName?: string;
}

export const NextActionListResponseInnerActionEnum = {
  StayCurrentStep: "STAY_CURRENT_STEP",
  ProceedNextStep: "PROCEED_NEXT_STEP",
  ProceedConfirmactionStep: "PROCEED_CONFIRMACTION_STEP",
} as const;

export type NextActionListResponseInnerActionEnum =
  (typeof NextActionListResponseInnerActionEnum)[keyof typeof NextActionListResponseInnerActionEnum];

/**
 *
 * @export
 * @interface OAuth2ErrorResponse
 */
export interface OAuth2ErrorResponse {
  /**
   * Error code
   * @type {string}
   * @memberof OAuth2ErrorResponse
   */
  error?: OAuth2ErrorResponseErrorEnum;
}

export const OAuth2ErrorResponseErrorEnum = {
  InvalidRequest: "invalid_request",
  InvalidClient: "invalid_client",
  InvalidGrant: "invalid_grant",
  UnauthorizedClient: "unauthorized_client",
  UnsupportedGrantType: "unsupported_grant_type",
} as const;

export type OAuth2ErrorResponseErrorEnum =
  (typeof OAuth2ErrorResponseErrorEnum)[keyof typeof OAuth2ErrorResponseErrorEnum];

/**
 *
 * @export
 * @interface PE
 */
export interface PE {
  /**
   * PE Code
   * @type {string}
   * @memberof PE
   */
  PECode: string;
  /**
   * Country
   * @type {string}
   * @memberof PE
   */
  country?: string;
  /**
   * Company Name
   * @type {string}
   * @memberof PE
   */
  companyName?: string;
  /**
   *
   * @type {CompanyCategory}
   * @memberof PE
   */
  companyCategory?: CompanyCategory;
  companyCategoryDisplay?: string;
  /**
   *
   * @type {Array<PEWorkflowStatusInner>}
   * @memberof PE
   */
  workflowStatus?: Array<PEWorkflowStatusInner>;
  PEName?: string;
}
/**
 *
 * @export
 * @interface PEHistoryResponse
 */
export interface PEHistoryResponse {
  /**
   *
   * @type {Array<PEHistoryResponsePEHistoryInner>}
   * @memberof PEHistoryResponse
   */
  PEHistory?: Array<PEHistoryResponsePEHistoryInner>;
}
/**
 *
 * @export
 * @interface PEHistoryResponsePEHistoryInner
 */
export interface PEHistoryResponsePEHistoryInner {
  /**
   * Workflow JobID(ULID)
   * @type {string}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  jobId: string;
  /**
   *
   * @type {Step}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  workflowStep: Step;
  /**
   *
   * @type {string}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  attribute?: PEHistoryResponsePEHistoryInnerAttributeEnum;
  /**
   * User
   * @type {string}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  user?: string;
  /**
   * Last Modified
   * @type {string}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  lastModified?: string;
  /**
   *
   * @type {PEStatus}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  status: PEStatus;
  /**
   * File path in Amazon S3.
   * @type {string}
   * @memberof PEHistoryResponsePEHistoryInner
   */
  filePath?: string;
  workflowStatus?: string;
  currentStatus?: string;
  workflowType?: string;
  fiscalYear?: number;
  name?: string;
  PECode?: string;
  fixVersion?: string;
}

export const PEHistoryResponsePEHistoryInnerAttributeEnum = {
  Submit: "SUBMIT",
  Approve: "APPROVE",
} as const;

export type PEHistoryResponsePEHistoryInnerAttributeEnum =
  (typeof PEHistoryResponsePEHistoryInnerAttributeEnum)[keyof typeof PEHistoryResponsePEHistoryInnerAttributeEnum];

/**
 *
 * @export
 * @interface PEResponse
 */
export interface PEResponse {
  /**
   *
   * @type {PE}
   * @memberof PEResponse
   */
  PE?: PE;
}
/**
 *
 * @export
 * @interface PESearchResponse
 */
export interface PESearchResponse {
  /**
   *
   * @type {Array<PE>}
   * @memberof PESearchResponse
   */
  PEList?: Array<PE>;
}
/**
 * Status
 * @export
 * @enum {string}
 */

export const PEStatus = {
  NotStarted: "NOT_STARTED",
  InProgress: "IN_PROGRESS",
  PendingApproved: "PENDING_APPROVED",
  Done: "DONE",
  Abort: "ABORT",
} as const;

export type PEStatus = (typeof PEStatus)[keyof typeof PEStatus];

/**
 *
 * @export
 * @interface PEStatusListResponse
 */
export interface PEStatusListResponse {
  /**
   *
   * @type {Array<PEStatusListResponsePEStatusListInner>}
   * @memberof PEStatusListResponse
   */
  PEStatusList?: Array<PEStatusListResponsePEStatusListInner>;
}
/**
 *
 * @export
 * @interface PEStatusListResponsePEStatusListInner
 */
export interface PEStatusListResponsePEStatusListInner {
  /**
   *
   * @type {PEStatus}
   * @memberof PEStatusListResponsePEStatusListInner
   */
  status?: PEStatus;
  /**
   * Display Name
   * @type {string}
   * @memberof PEStatusListResponsePEStatusListInner
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface PEWorkflowStatusInner
 */
export interface PEWorkflowStatusInner {
  /**
   *
   * @type {WorkflowType}
   * @memberof PEWorkflowStatusInner
   */
  workflowType: WorkflowType;
  /**
   *
   * @type {WorkflowStep}
   * @memberof PEWorkflowStatusInner
   */
  workflowStep?: WorkflowStep;
  /**
   *
   * @type {PEStatus}
   * @memberof PEWorkflowStatusInner
   */
  status?: PEStatus;
}
/**
 *
 * @export
 * @interface PrepareResponse
 */
export interface PrepareResponse {
  /**
   * Result
   * @type {boolean}
   * @memberof PrepareResponse
   */
  success?: boolean;
  /**
   * Status
   * @type {string}
   * @memberof PrepareResponse
   */
  status?: PrepareResponseStatusEnum;
  /**
   * Message
   * @type {string}
   * @memberof PrepareResponse
   */
  message?: string;
  /**
   *
   * @type {PrepareResponseFileInfo}
   * @memberof PrepareResponse
   */
  fileInfo?: PrepareResponseFileInfo;
}

export const PrepareResponseStatusEnum = {
  Prepared: "PREPARED",
  NotPrepared: "NOT_PREPARED",
  Error: "ERROR",
} as const;

export type PrepareResponseStatusEnum =
  (typeof PrepareResponseStatusEnum)[keyof typeof PrepareResponseStatusEnum];

/**
 *
 * @export
 * @interface PrepareResponseFileInfo
 */
export interface PrepareResponseFileInfo {
  /**
   * File Name
   * @type {string}
   * @memberof PrepareResponseFileInfo
   */
  fileName?: string;
}
/**
 *
 * @export
 * @interface PresignedURLResponse
 */
export interface PresignedURLResponse {
  /**
   *
   * @type {string}
   * @memberof PresignedURLResponse
   */
  url?: string;
  /**
   *
   * @type {PresignedURLResponseFields}
   * @memberof PresignedURLResponse
   */
  fields?: PresignedURLResponseFields;
}
/**
 *
 * @export
 * @interface PresignedURLResponseFields
 */
export interface PresignedURLResponseFields {
  /**
   *
   * @type {string}
   * @memberof PresignedURLResponseFields
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof PresignedURLResponseFields
   */
  value?: string;
}
/**
 *
 * @export
 * @interface ProcessResultFileResponse
 */
export interface ProcessResultFileResponse {
  /**
   *
   * @type {ActionResult}
   * @memberof ProcessResultFileResponse
   */
  result?: ActionResult;
  /**
   * ファイルパス
   * @type {string}
   * @memberof ProcessResultFileResponse
   */
  filePath?: string;
}
/**
 *
 * @export
 * @interface ProcessResultResponseInner
 */
export interface ProcessResultResponseInner {
  /**
   * ULID
   * @type {string}
   * @memberof ProcessResultResponseInner
   */
  id?: string;
  /**
   * Workflow JobID(ULID)
   * @type {string}
   * @memberof ProcessResultResponseInner
   */
  jobId?: string;
  /**
   * PE Code
   * @type {string}
   * @memberof ProcessResultResponseInner
   */
  PECode?: string;
  /**
   *
   * @type {string}
   * @memberof ProcessResultResponseInner
   */
  dpName?: string;
  /**
   *
   * @type {string}
   * @memberof ProcessResultResponseInner
   */
  dpValue?: string;
  /**
   *
   * @type {Array<ProcessResultResponseInnerCheckStatusInner>}
   * @memberof ProcessResultResponseInner
   */
  checkStatus?: Array<ProcessResultResponseInnerCheckStatusInner>;
}
/**
 *
 * @export
 * @interface ProcessResultResponseInnerCheckStatusInner
 */
export interface ProcessResultResponseInnerCheckStatusInner {
  /**
   *
   * @type {CheckType}
   * @memberof ProcessResultResponseInnerCheckStatusInner
   */
  checkType?: CheckType;
  /**
   *
   * @type {string}
   * @memberof ProcessResultResponseInnerCheckStatusInner
   */
  checkResult?: string;
  /**
   *
   * @type {string}
   * @memberof ProcessResultResponseInnerCheckStatusInner
   */
  checkMessage?: string;
}
/**
 *
 * @export
 * @interface ReportFilterListResponseInner
 */
export interface ReportFilterListResponseInner {
  /**
   * フィルタ名
   * @type {string}
   * @memberof ReportFilterListResponseInner
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ReportListResponseInner
 */
export interface ReportListResponseInner {
  /**
   * レポート名
   * @type {string}
   * @memberof ReportListResponseInner
   */
  name?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const Step = {
  Step1: "STEP1",
  Step2: "STEP2",
  ComfirmStep: "COMFIRM_STEP",
  ReconcileStep: "RECONCILE_STEP",
} as const;

export type Step = (typeof Step)[keyof typeof Step];

/**
 *
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
  /**
   * Access Token (Un used)
   * @type {string}
   * @memberof TokenResponse
   */
  AccessToken?: string;
  /**
   * 有効期限
   * @type {number}
   * @memberof TokenResponse
   */
  ExpiresIn?: number;
  /**
   * Token type
   * @type {string}
   * @memberof TokenResponse
   */
  TokenType?: string;
  /**
   * Refresh Token (Used for fetch new ID and Access Token)
   * @type {string}
   * @memberof TokenResponse
   */
  RefreshToken?: string;
  /**
   * ID Token (Used for API Authentication)
   * @type {string}
   * @memberof TokenResponse
   */
  IdToken?: string;
}
/**
 *
 * @export
 * @interface UpdateJobResponse
 */
export interface UpdateJobResponse {
  /**
   *
   * @type {Job}
   * @memberof UpdateJobResponse
   */
  job?: Job;
  /**
   * Result
   * @type {boolean}
   * @memberof UpdateJobResponse
   */
  success?: boolean;
  message?: string;
}
/**
 *
 * @export
 * @interface UpdateWorkflowStatusRequest
 */
export interface UpdateWorkflowStatusRequest {
  /**
   *
   * @type {WorkflowType}
   * @memberof UpdateWorkflowStatusRequest
   */
  workflowType: WorkflowType;
  /**
   * Fiscal Year
   * @type {number}
   * @memberof UpdateWorkflowStatusRequest
   */
  fiscalYear: number;
  /**
   * Version
   * @type {string}
   * @memberof UpdateWorkflowStatusRequest
   */
  version?: string;
  /**
   *
   * @type {WorkflowStatus}
   * @memberof UpdateWorkflowStatusRequest
   */
  workflowStatus?: WorkflowStatus;
}
/**
 *
 * @export
 * @interface Workflow
 */
export interface Workflow {
  /**
   *
   * @type {WorkflowType}
   * @memberof Workflow
   */
  workflowType: WorkflowType;
  /**
   * 提出ファイル種別
   * @type {string}
   * @memberof Workflow
   */
  submittingFileCategory?: string;
  /**
   *
   * @type {Array<WorkflowStep>}
   * @memberof Workflow
   */
  workflowSteps?: Array<WorkflowStep>;
  /**
   *
   * @type {Array<string>}
   * @memberof Workflow
   */
  fileTypes?: Array<string>;
}
/**
 *
 * @export
 * @interface WorkflowListResponse
 */
export interface WorkflowListResponse {
  /**
   *
   * @type {Array<Workflow>}
   * @memberof WorkflowListResponse
   */
  workflowList?: Array<Workflow>;
}
/**
 *
 * @export
 * @interface WorkflowManagementResponseInner
 */
export interface WorkflowManagementResponseInner {
  /**
   *
   * @type {WorkflowType}
   * @memberof WorkflowManagementResponseInner
   */
  workflowType: WorkflowType;
  /**
   * Version
   * @type {string}
   * @memberof WorkflowManagementResponseInner
   */
  version?: string;
  /**
   *
   * @type {WorkflowStatus}
   * @memberof WorkflowManagementResponseInner
   */
  workflowStatus?: WorkflowStatus;
  /**
   * 提出ファイル種別
   * @type {string}
   * @memberof WorkflowManagementResponseInner
   */
  submittingFileCategory?: string;
  /**
   *
   * @type {WorkflowManagementResponseInnerActions}
   * @memberof WorkflowManagementResponseInner
   */
  actions?: WorkflowManagementResponseInnerActions;
  /**
   * 作成日時
   * @type {Date}
   * @memberof WorkflowManagementResponseInner
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface WorkflowManagementResponseInnerActions
 */
export interface WorkflowManagementResponseInnerActions {
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  detailAction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  notifyAction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  openAction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  closeAction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  reopenAction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  deleteAction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkflowManagementResponseInnerActions
   */
  reportAction?: boolean;
}
/**
 *
 * @export
 * @interface WorkflowResponse
 */
export interface WorkflowResponse {
  /**
   *
   * @type {Workflow}
   * @memberof WorkflowResponse
   */
  workflow?: Workflow;
}
/**
 * ワークフロー状態
 * @export
 * @enum {string}
 */

export const WorkflowStatus = {
  BeforeOpen: "BEFORE_OPEN",
  Open: "OPEN",
  Started: "STARTED",
  Close: "CLOSE",
  Deleted: "DELETED",
} as const;

export type WorkflowStatus = (typeof WorkflowStatus)[keyof typeof WorkflowStatus];

/**
 *
 * @export
 * @interface WorkflowStatusResponse
 */
export interface WorkflowStatusResponse {
  /**
   *
   * @type {Workflow}
   * @memberof WorkflowStatusResponse
   */
  workflow?: Workflow;
  /**
   *
   * @type {WorkflowStatus}
   * @memberof WorkflowStatusResponse
   */
  workflowStatus?: WorkflowStatus;
}
/**
 *
 * @export
 * @interface WorkflowStep
 */
export interface WorkflowStep {
  /**
   *
   * @type {Step}
   * @memberof WorkflowStep
   */
  step?: Step;
  /**
   *
   * @type {WorkflowStepActions}
   * @memberof WorkflowStep
   */
  actions?: WorkflowStepActions;
}
/**
 *
 * @export
 * @interface WorkflowStepActions
 */
export interface WorkflowStepActions {
  /**
   *
   * @type {string}
   * @memberof WorkflowStepActions
   */
  submitAction?: string;
  /**
   *
   * @type {string}
   * @memberof WorkflowStepActions
   */
  approveAction?: string;
}
/**
 * ワークフロー種別
 * @export
 * @enum {string}
 */

export const WorkflowType = {
  StandardTemplate: "STANDARD_TEMPLATE",
  NewReport: "CSV_DATA_UPLOADER",
  Tango: "TANGO",
  TopSide: "TOP_SIDE",
} as const;

export type WorkflowType = (typeof WorkflowType)[keyof typeof WorkflowType];

/**
 * AccessTestApi - axios parameter creator
 * @export
 */
export const AccessTestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AccessTestAttemptPostRequest} [accessTestAttemptPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    accessTestAttemptPost: async (
      accessTestAttemptPostRequest?: AccessTestAttemptPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accessTest/attempt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accessTestAttemptPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {AccessTestCompletePostRequest} [accessTestCompletePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    accessTestCompletePost: async (
      authorization: string,
      accessTestCompletePostRequest?: AccessTestCompletePostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("accessTestCompletePost", "authorization", authorization);
      const localVarPath = `/accessTest/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accessTestCompletePostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccessTestApi - functional programming interface
 * @export
 */
export const AccessTestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccessTestApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AccessTestAttemptPostRequest} [accessTestAttemptPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async accessTestAttemptPost(
      accessTestAttemptPostRequest?: AccessTestAttemptPostRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTestAttemptPost200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accessTestAttemptPost(
        accessTestAttemptPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {AccessTestCompletePostRequest} [accessTestCompletePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async accessTestCompletePost(
      authorization: string,
      accessTestCompletePostRequest?: AccessTestCompletePostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accessTestCompletePost(
        authorization,
        accessTestCompletePostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccessTestApi - factory interface
 * @export
 */
export const AccessTestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccessTestApiFp(configuration);
  return {
    /**
     *
     * @param {AccessTestAttemptPostRequest} [accessTestAttemptPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    accessTestAttemptPost(
      accessTestAttemptPostRequest?: AccessTestAttemptPostRequest,
      options?: any
    ): AxiosPromise<AccessTestAttemptPost200Response> {
      return localVarFp
        .accessTestAttemptPost(accessTestAttemptPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {AccessTestCompletePostRequest} [accessTestCompletePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    accessTestCompletePost(
      authorization: string,
      accessTestCompletePostRequest?: AccessTestCompletePostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .accessTestCompletePost(authorization, accessTestCompletePostRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccessTestApi - object-oriented interface
 * @export
 * @class AccessTestApi
 * @extends {BaseAPI}
 */
export class AccessTestApi extends BaseAPI {
  /**
   *
   * @param {AccessTestAttemptPostRequest} [accessTestAttemptPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof AccessTestApi
   */
  public accessTestAttemptPost(
    accessTestAttemptPostRequest?: AccessTestAttemptPostRequest,
    options?: AxiosRequestConfig
  ) {
    return AccessTestApiFp(this.configuration)
      .accessTestAttemptPost(accessTestAttemptPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {AccessTestCompletePostRequest} [accessTestCompletePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof AccessTestApi
   */
  public accessTestCompletePost(
    authorization: string,
    accessTestCompletePostRequest?: AccessTestCompletePostRequest,
    options?: AxiosRequestConfig
  ) {
    return AccessTestApiFp(this.configuration)
      .accessTestCompletePost(authorization, accessTestCompletePostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModuleAuthApi - axios parameter creator
 * @export
 */
export const ModuleAuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary ログイン
     * @param {LoginInitiateAuthRequest} [loginInitiateAuthRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    loginInitiateAuth: async (
      loginInitiateAuthRequest?: LoginInitiateAuthRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginInitiateAuthRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModuleAuthApi - functional programming interface
 * @export
 */
export const ModuleAuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModuleAuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary ログイン
     * @param {LoginInitiateAuthRequest} [loginInitiateAuthRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async loginInitiateAuth(
      loginInitiateAuthRequest?: LoginInitiateAuthRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginSuccessResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginInitiateAuth(
        loginInitiateAuthRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModuleAuthApi - factory interface
 * @export
 */
export const ModuleAuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModuleAuthApiFp(configuration);
  return {
    /**
     *
     * @summary ログイン
     * @param {LoginInitiateAuthRequest} [loginInitiateAuthRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    loginInitiateAuth(
      loginInitiateAuthRequest?: LoginInitiateAuthRequest,
      options?: any
    ): AxiosPromise<LoginSuccessResult> {
      return localVarFp
        .loginInitiateAuth(loginInitiateAuthRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModuleAuthApi - object-oriented interface
 * @export
 * @class ModuleAuthApi
 * @extends {BaseAPI}
 */
export class ModuleAuthApi extends BaseAPI {
  /**
   *
   * @summary ログイン
   * @param {LoginInitiateAuthRequest} [loginInitiateAuthRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleAuthApi
   */
  public loginInitiateAuth(
    loginInitiateAuthRequest?: LoginInitiateAuthRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleAuthApiFp(this.configuration)
      .loginInitiateAuth(loginInitiateAuthRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModuleDataProcessApi - axios parameter creator
 * @export
 */
export const ModuleDataProcessApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataGenerateReportPostRequest} [dataGenerateReportPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGenerateReportPost: async (
      authorization: string,
      dataGenerateReportPostRequest?: DataGenerateReportPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataGenerateReportPost", "authorization", authorization);
      const localVarPath = `/data/generateReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataGenerateReportPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataGenerateZipPostRequest} [dataGenerateZipPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGenerateZipPost: async (
      authorization: string,
      dataGenerateZipPostRequest?: DataGenerateZipPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataGenerateZipPost", "authorization", authorization);
      const localVarPath = `/data/generateZip`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataGenerateZipPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId
     * @param {'PE' | 'DP'} level
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {CheckStatusFilter} [checkType] checkType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGetProcessResultFileGet: async (
      authorization: string,
      jobId: string,
      level: "PE" | "DP",
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      checkType?: CheckStatusFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataGetProcessResultFileGet", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("dataGetProcessResultFileGet", "jobId", jobId);
      // verify required parameter 'level' is not null or undefined
      assertParamExists("dataGetProcessResultFileGet", "level", level);
      const localVarPath = `/data/getProcessResultFile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      if (level !== undefined) {
        localVarQueryParameter["level"] = level;
      }

      if (groupCode !== undefined) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (companyCode !== undefined) {
        localVarQueryParameter["companyCode"] = companyCode;
      }

      if (branchCode !== undefined) {
        localVarQueryParameter["branchCode"] = branchCode;
      }

      if (checkType !== undefined) {
        localVarQueryParameter["checkType"] = checkType;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId
     * @param {'PE' | 'DP'} level
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {CheckStatusFilter} [checkType] checkType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGetProcessResultGet: async (
      authorization: string,
      jobId: string,
      level: "PE" | "DP",
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      checkType?: CheckStatusFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataGetProcessResultGet", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("dataGetProcessResultGet", "jobId", jobId);
      // verify required parameter 'level' is not null or undefined
      assertParamExists("dataGetProcessResultGet", "level", level);
      const localVarPath = `/data/getProcessResult`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      if (level !== undefined) {
        localVarQueryParameter["level"] = level;
      }

      if (groupCode !== undefined) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (companyCode !== undefined) {
        localVarQueryParameter["companyCode"] = companyCode;
      }

      if (branchCode !== undefined) {
        localVarQueryParameter["branchCode"] = branchCode;
      }

      if (checkType !== undefined) {
        localVarQueryParameter["checkType"] = checkType;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataPresetPESheetPostRequest} [dataPresetPESheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataPresetPESheetPost: async (
      authorization: string,
      dataPresetPESheetPostRequest?: DataPresetPESheetPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataPresetPESheetPost", "authorization", authorization);
      const localVarPath = `/data/presetPESheet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataPresetPESheetPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataPresetSummarySheetPostRequest} [dataPresetSummarySheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataPresetSummarySheetPost: async (
      authorization: string,
      dataPresetSummarySheetPostRequest?: DataPresetSummarySheetPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataPresetSummarySheetPost", "authorization", authorization);
      const localVarPath = `/data/presetSummarySheet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataPresetSummarySheetPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataReportFilterListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataReportFilterListGet", "authorization", authorization);
      const localVarPath = `/data/report/filterList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataReportListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataReportListGet", "authorization", authorization);
      const localVarPath = `/data/reportList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataReportUpdateFilterPostRequest} [dataReportUpdateFilterPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataReportUpdateFilterPost: async (
      authorization: string,
      dataReportUpdateFilterPostRequest?: DataReportUpdateFilterPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataReportUpdateFilterPost", "authorization", authorization);
      const localVarPath = `/data/report/updateFilter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataReportUpdateFilterPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataStartCheckProcessPostRequest} [dataStartCheckProcessPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataStartCheckProcessPost: async (
      authorization: string,
      dataStartCheckProcessPostRequest?: DataStartCheckProcessPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("dataStartCheckProcessPost", "authorization", authorization);
      const localVarPath = `/data/startCheckProcess`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataStartCheckProcessPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModuleDataProcessApi - functional programming interface
 * @export
 */
export const ModuleDataProcessApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModuleDataProcessApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataGenerateReportPostRequest} [dataGenerateReportPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataGenerateReportPost(
      authorization: string,
      dataGenerateReportPostRequest?: DataGenerateReportPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataGenerateReportPost(
        authorization,
        dataGenerateReportPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataGenerateZipPostRequest} [dataGenerateZipPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataGenerateZipPost(
      authorization: string,
      dataGenerateZipPostRequest?: DataGenerateZipPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrepareResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataGenerateZipPost(
        authorization,
        dataGenerateZipPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId
     * @param {'PE' | 'DP'} level
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {CheckStatusFilter} [checkType] checkType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataGetProcessResultFileGet(
      authorization: string,
      jobId: string,
      level: "PE" | "DP",
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      checkType?: CheckStatusFilter,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataGetProcessResultFileGet(
        authorization,
        jobId,
        level,
        groupCode,
        companyCode,
        branchCode,
        checkType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId
     * @param {'PE' | 'DP'} level
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {CheckStatusFilter} [checkType] checkType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataGetProcessResultGet(
      authorization: string,
      jobId: string,
      level: "PE" | "DP",
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      checkType?: CheckStatusFilter,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProcessResultResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataGetProcessResultGet(
        authorization,
        jobId,
        level,
        groupCode,
        companyCode,
        branchCode,
        checkType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataPresetPESheetPostRequest} [dataPresetPESheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataPresetPESheetPost(
      authorization: string,
      dataPresetPESheetPostRequest?: DataPresetPESheetPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataPresetPESheetPost(
        authorization,
        dataPresetPESheetPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataPresetSummarySheetPostRequest} [dataPresetSummarySheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataPresetSummarySheetPost(
      authorization: string,
      dataPresetSummarySheetPostRequest?: DataPresetSummarySheetPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataPresetSummarySheetPost(
        authorization,
        dataPresetSummarySheetPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataReportFilterListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ReportFilterListResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataReportFilterListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataReportListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportListResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataReportListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataReportUpdateFilterPostRequest} [dataReportUpdateFilterPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataReportUpdateFilterPost(
      authorization: string,
      dataReportUpdateFilterPostRequest?: DataReportUpdateFilterPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataReportUpdateFilterPost(
        authorization,
        dataReportUpdateFilterPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataStartCheckProcessPostRequest} [dataStartCheckProcessPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async dataStartCheckProcessPost(
      authorization: string,
      dataStartCheckProcessPostRequest?: DataStartCheckProcessPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dataStartCheckProcessPost(
        authorization,
        dataStartCheckProcessPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModuleDataProcessApi - factory interface
 * @export
 */
export const ModuleDataProcessApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModuleDataProcessApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataGenerateReportPostRequest} [dataGenerateReportPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGenerateReportPost(
      authorization: string,
      dataGenerateReportPostRequest?: DataGenerateReportPostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .dataGenerateReportPost(authorization, dataGenerateReportPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataGenerateZipPostRequest} [dataGenerateZipPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGenerateZipPost(
      authorization: string,
      dataGenerateZipPostRequest?: DataGenerateZipPostRequest,
      options?: any
    ): AxiosPromise<PrepareResponse> {
      return localVarFp
        .dataGenerateZipPost(authorization, dataGenerateZipPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId
     * @param {'PE' | 'DP'} level
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {CheckStatusFilter} [checkType] checkType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGetProcessResultFileGet(
      authorization: string,
      jobId: string,
      level: "PE" | "DP",
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      checkType?: CheckStatusFilter,
      options?: any
    ): AxiosPromise<ProcessResultFileResponse> {
      return localVarFp
        .dataGetProcessResultFileGet(
          authorization,
          jobId,
          level,
          groupCode,
          companyCode,
          branchCode,
          checkType,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId
     * @param {'PE' | 'DP'} level
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {CheckStatusFilter} [checkType] checkType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataGetProcessResultGet(
      authorization: string,
      jobId: string,
      level: "PE" | "DP",
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      checkType?: CheckStatusFilter,
      options?: any
    ): AxiosPromise<Array<ProcessResultResponseInner>> {
      return localVarFp
        .dataGetProcessResultGet(
          authorization,
          jobId,
          level,
          groupCode,
          companyCode,
          branchCode,
          checkType,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataPresetPESheetPostRequest} [dataPresetPESheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataPresetPESheetPost(
      authorization: string,
      dataPresetPESheetPostRequest?: DataPresetPESheetPostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .dataPresetPESheetPost(authorization, dataPresetPESheetPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataPresetSummarySheetPostRequest} [dataPresetSummarySheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataPresetSummarySheetPost(
      authorization: string,
      dataPresetSummarySheetPostRequest?: DataPresetSummarySheetPostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .dataPresetSummarySheetPost(authorization, dataPresetSummarySheetPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataReportFilterListGet(
      authorization: string,
      options?: any
    ): AxiosPromise<Array<ReportFilterListResponseInner>> {
      return localVarFp
        .dataReportFilterListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataReportListGet(
      authorization: string,
      options?: any
    ): AxiosPromise<Array<ReportListResponseInner>> {
      return localVarFp
        .dataReportListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataReportUpdateFilterPostRequest} [dataReportUpdateFilterPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataReportUpdateFilterPost(
      authorization: string,
      dataReportUpdateFilterPostRequest?: DataReportUpdateFilterPostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .dataReportUpdateFilterPost(authorization, dataReportUpdateFilterPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {DataStartCheckProcessPostRequest} [dataStartCheckProcessPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    dataStartCheckProcessPost(
      authorization: string,
      dataStartCheckProcessPostRequest?: DataStartCheckProcessPostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .dataStartCheckProcessPost(authorization, dataStartCheckProcessPostRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModuleDataProcessApi - object-oriented interface
 * @export
 * @class ModuleDataProcessApi
 * @extends {BaseAPI}
 */
export class ModuleDataProcessApi extends BaseAPI {
  /**
   *
   * @param {string} authorization ID Token
   * @param {DataGenerateReportPostRequest} [dataGenerateReportPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataGenerateReportPost(
    authorization: string,
    dataGenerateReportPostRequest?: DataGenerateReportPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataGenerateReportPost(authorization, dataGenerateReportPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {DataGenerateZipPostRequest} [dataGenerateZipPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataGenerateZipPost(
    authorization: string,
    dataGenerateZipPostRequest?: DataGenerateZipPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataGenerateZipPost(authorization, dataGenerateZipPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} jobId
   * @param {'PE' | 'DP'} level
   * @param {string} [groupCode] groupCode
   * @param {string} [companyCode] companyCode
   * @param {string} [branchCode] branchCode
   * @param {CheckStatusFilter} [checkType] checkType
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataGetProcessResultFileGet(
    authorization: string,
    jobId: string,
    level: "PE" | "DP",
    groupCode?: string,
    companyCode?: string,
    branchCode?: string,
    checkType?: CheckStatusFilter,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataGetProcessResultFileGet(
        authorization,
        jobId,
        level,
        groupCode,
        companyCode,
        branchCode,
        checkType,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} jobId
   * @param {'PE' | 'DP'} level
   * @param {string} [groupCode] groupCode
   * @param {string} [companyCode] companyCode
   * @param {string} [branchCode] branchCode
   * @param {CheckStatusFilter} [checkType] checkType
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataGetProcessResultGet(
    authorization: string,
    jobId: string,
    level: "PE" | "DP",
    groupCode?: string,
    companyCode?: string,
    branchCode?: string,
    checkType?: CheckStatusFilter,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataGetProcessResultGet(
        authorization,
        jobId,
        level,
        groupCode,
        companyCode,
        branchCode,
        checkType,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {DataPresetPESheetPostRequest} [dataPresetPESheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataPresetPESheetPost(
    authorization: string,
    dataPresetPESheetPostRequest?: DataPresetPESheetPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataPresetPESheetPost(authorization, dataPresetPESheetPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {DataPresetSummarySheetPostRequest} [dataPresetSummarySheetPostRequest] Either &#x60;jobId&#x60; or &#x60;PECode&#x60; is required.
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataPresetSummarySheetPost(
    authorization: string,
    dataPresetSummarySheetPostRequest?: DataPresetSummarySheetPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataPresetSummarySheetPost(authorization, dataPresetSummarySheetPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataReportFilterListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataReportFilterListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataReportListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataReportListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {DataReportUpdateFilterPostRequest} [dataReportUpdateFilterPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataReportUpdateFilterPost(
    authorization: string,
    dataReportUpdateFilterPostRequest?: DataReportUpdateFilterPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataReportUpdateFilterPost(authorization, dataReportUpdateFilterPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {DataStartCheckProcessPostRequest} [dataStartCheckProcessPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleDataProcessApi
   */
  public dataStartCheckProcessPost(
    authorization: string,
    dataStartCheckProcessPostRequest?: DataStartCheckProcessPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleDataProcessApiFp(this.configuration)
      .dataStartCheckProcessPost(authorization, dataStartCheckProcessPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModuleFileManagementApi - axios parameter creator
 * @export
 */
export const ModuleFileManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} filePath
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    fileFilePathDelete: async (
      authorization: string,
      filePath: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("fileFilePathDelete", "authorization", authorization);
      // verify required parameter 'filePath' is not null or undefined
      assertParamExists("fileFilePathDelete", "filePath", filePath);
      const localVarPath = `/file/{filePath}`.replace(
        `{${"filePath"}}`,
        encodeURIComponent(String(filePath))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {'download' | 'upload'} methodType
     * @param {string} fileName file name
     * @param {'MASTER' | 'WORKFLOW'} category Category
     * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_EXTRACTION' | 'PENDING'} [extractType] if methodType is &#x60;upload&#x60;, extractType is required.
     * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_GENERATION'} [generateType] if methodType is &#x60;download&#x60;, generateType is required.
     * @param {WorkflowType} [workflowType] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowType is required
     * @param {Step} [workflowStep] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowStep is required
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    fileGeneratePresignedURLGet: async (
      authorization: string,
      methodType: "download" | "upload",
      fileName: string,
      category: "MASTER" | "WORKFLOW",
      extractType?:
        | "PE_MASTER"
        | "ITEM_DEFINITION_MASTER"
        | "USER_ACTION_MASTER"
        | "1PE1FILE_TEMPLATE"
        | "SUMMARY_SHEET_TEMPLATE"
        | "ZIPPED_1PE1FILE_TEMPLATE"
        | "STANDARD_CSV"
        | "NO_EXTRACTION"
        | "PENDING",
      generateType?:
        | "PE_MASTER"
        | "ITEM_DEFINITION_MASTER"
        | "USER_ACTION_MASTER"
        | "1PE1FILE_TEMPLATE"
        | "SUMMARY_SHEET_TEMPLATE"
        | "ZIPPED_1PE1FILE_TEMPLATE"
        | "STANDARD_CSV"
        | "NO_GENERATION",
      workflowType?: WorkflowType,
      workflowStep?: Step,
      jobId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("fileGeneratePresignedURLGet", "authorization", authorization);
      // verify required parameter 'methodType' is not null or undefined
      assertParamExists("fileGeneratePresignedURLGet", "methodType", methodType);
      // verify required parameter 'fileName' is not null or undefined
      assertParamExists("fileGeneratePresignedURLGet", "fileName", fileName);
      // verify required parameter 'category' is not null or undefined
      assertParamExists("fileGeneratePresignedURLGet", "category", category);
      const localVarPath = `/file/generatePresignedURL`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (methodType !== undefined) {
        localVarQueryParameter["methodType"] = methodType;
      }

      if (extractType !== undefined) {
        localVarQueryParameter["extractType"] = extractType;
      }

      if (generateType !== undefined) {
        localVarQueryParameter["generateType"] = generateType;
      }

      if (fileName !== undefined) {
        localVarQueryParameter["fileName"] = fileName;
      }

      if (category !== undefined) {
        localVarQueryParameter["category"] = category;
      }

      if (workflowType !== undefined) {
        localVarQueryParameter["workflowType"] = workflowType;
      }

      if (workflowStep !== undefined) {
        localVarQueryParameter["workflowStep"] = workflowStep;
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {number} [fiscalYear]
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {WorkflowType} [workflowType]
     * @param {string} [updateDateFrom]
     * @param {string} [updateDateTo]
     * @param {string} [fileType]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    fileListGet: async (
      authorization: string,
      fiscalYear?: number,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      workflowType?: WorkflowType,
      updateDateFrom?: string,
      updateDateTo?: string,
      fileType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("fileListGet", "authorization", authorization);
      const localVarPath = `/file/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fiscalYear !== undefined) {
        localVarQueryParameter["fiscalYear"] = fiscalYear;
      }

      if (groupCode !== undefined) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (companyCode !== undefined) {
        localVarQueryParameter["companyCode"] = companyCode;
      }

      if (branchCode !== undefined) {
        localVarQueryParameter["branchCode"] = branchCode;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (companyName !== undefined) {
        localVarQueryParameter["companyName"] = companyName;
      }

      if (workflowType !== undefined) {
        localVarQueryParameter["workflowType"] = workflowType;
      }

      if (updateDateFrom !== undefined) {
        localVarQueryParameter["updateDateFrom"] =
          (updateDateFrom as any) instanceof Date
            ? (updateDateFrom as any).toISOString()
            : updateDateFrom;
      }

      if (updateDateTo !== undefined) {
        localVarQueryParameter["updateDateTo"] =
          (updateDateTo as any) instanceof Date
            ? (updateDateTo as any).toISOString()
            : updateDateTo;
      }

      if (fileType !== undefined) {
        localVarQueryParameter["fileType"] = fileType;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {'1PE1FILE' | 'SUMMARY_SHEET' | 'ZIPPED_1PE1FILE' | 'CHECK_RESULT'} fileType file type
     * @param {string} jobId
     * @param {string} [pECode]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    filePrepareGet: async (
      authorization: string,
      fileType: "1PE1FILE" | "SUMMARY_SHEET" | "ZIPPED_1PE1FILE" | "CHECK_RESULT",
      jobId: string,
      pECode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("filePrepareGet", "authorization", authorization);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("filePrepareGet", "fileType", fileType);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("filePrepareGet", "jobId", jobId);
      const localVarPath = `/file/prepare`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fileType !== undefined) {
        localVarQueryParameter["fileType"] = fileType;
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      if (pECode !== undefined) {
        localVarQueryParameter["PECode"] = pECode;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModuleFileManagementApi - functional programming interface
 * @export
 */
export const ModuleFileManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModuleFileManagementApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} filePath
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async fileFilePathDelete(
      authorization: string,
      filePath: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileFilePathDelete(
        authorization,
        filePath,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {'download' | 'upload'} methodType
     * @param {string} fileName file name
     * @param {'MASTER' | 'WORKFLOW'} category Category
     * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_EXTRACTION' | 'PENDING'} [extractType] if methodType is &#x60;upload&#x60;, extractType is required.
     * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_GENERATION'} [generateType] if methodType is &#x60;download&#x60;, generateType is required.
     * @param {WorkflowType} [workflowType] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowType is required
     * @param {Step} [workflowStep] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowStep is required
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async fileGeneratePresignedURLGet(
      authorization: string,
      methodType: "download" | "upload",
      fileName: string,
      category: "MASTER" | "WORKFLOW",
      extractType?:
        | "PE_MASTER"
        | "ITEM_DEFINITION_MASTER"
        | "USER_ACTION_MASTER"
        | "1PE1FILE_TEMPLATE"
        | "SUMMARY_SHEET_TEMPLATE"
        | "ZIPPED_1PE1FILE_TEMPLATE"
        | "STANDARD_CSV"
        | "NO_EXTRACTION"
        | "PENDING",
      generateType?:
        | "PE_MASTER"
        | "ITEM_DEFINITION_MASTER"
        | "USER_ACTION_MASTER"
        | "1PE1FILE_TEMPLATE"
        | "SUMMARY_SHEET_TEMPLATE"
        | "ZIPPED_1PE1FILE_TEMPLATE"
        | "STANDARD_CSV"
        | "NO_GENERATION",
      workflowType?: WorkflowType,
      workflowStep?: Step,
      jobId?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedURLResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileGeneratePresignedURLGet(
        authorization,
        methodType,
        fileName,
        category,
        extractType,
        generateType,
        workflowType,
        workflowStep,
        jobId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {number} [fiscalYear]
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {WorkflowType} [workflowType]
     * @param {string} [updateDateFrom]
     * @param {string} [updateDateTo]
     * @param {string} [fileType]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async fileListGet(
      authorization: string,
      fiscalYear?: number,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      workflowType?: WorkflowType,
      updateDateFrom?: string,
      updateDateTo?: string,
      fileType?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileListResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fileListGet(
        authorization,
        fiscalYear,
        groupCode,
        companyCode,
        branchCode,
        country,
        companyName,
        workflowType,
        updateDateFrom,
        updateDateTo,
        fileType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {'1PE1FILE' | 'SUMMARY_SHEET' | 'ZIPPED_1PE1FILE' | 'CHECK_RESULT'} fileType file type
     * @param {string} jobId
     * @param {string} [pECode]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async filePrepareGet(
      authorization: string,
      fileType: "1PE1FILE" | "SUMMARY_SHEET" | "ZIPPED_1PE1FILE" | "CHECK_RESULT",
      jobId: string,
      pECode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrepareResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filePrepareGet(
        authorization,
        fileType,
        jobId,
        pECode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModuleFileManagementApi - factory interface
 * @export
 */
export const ModuleFileManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModuleFileManagementApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} filePath
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    fileFilePathDelete(
      authorization: string,
      filePath: string,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .fileFilePathDelete(authorization, filePath, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {'download' | 'upload'} methodType
     * @param {string} fileName file name
     * @param {'MASTER' | 'WORKFLOW'} category Category
     * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_EXTRACTION' | 'PENDING'} [extractType] if methodType is &#x60;upload&#x60;, extractType is required.
     * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_GENERATION'} [generateType] if methodType is &#x60;download&#x60;, generateType is required.
     * @param {WorkflowType} [workflowType] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowType is required
     * @param {Step} [workflowStep] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowStep is required
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    fileGeneratePresignedURLGet(
      authorization: string,
      methodType: "download" | "upload",
      fileName: string,
      category: "MASTER" | "WORKFLOW",
      extractType?:
        | "PE_MASTER"
        | "ITEM_DEFINITION_MASTER"
        | "USER_ACTION_MASTER"
        | "1PE1FILE_TEMPLATE"
        | "SUMMARY_SHEET_TEMPLATE"
        | "ZIPPED_1PE1FILE_TEMPLATE"
        | "STANDARD_CSV"
        | "NO_EXTRACTION"
        | "PENDING",
      generateType?:
        | "PE_MASTER"
        | "ITEM_DEFINITION_MASTER"
        | "USER_ACTION_MASTER"
        | "1PE1FILE_TEMPLATE"
        | "SUMMARY_SHEET_TEMPLATE"
        | "ZIPPED_1PE1FILE_TEMPLATE"
        | "STANDARD_CSV"
        | "NO_GENERATION",
      workflowType?: WorkflowType,
      workflowStep?: Step,
      jobId?: string,
      options?: any
    ): AxiosPromise<PresignedURLResponse> {
      return localVarFp
        .fileGeneratePresignedURLGet(
          authorization,
          methodType,
          fileName,
          category,
          extractType,
          generateType,
          workflowType,
          workflowStep,
          jobId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {number} [fiscalYear]
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {WorkflowType} [workflowType]
     * @param {string} [updateDateFrom]
     * @param {string} [updateDateTo]
     * @param {string} [fileType]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    fileListGet(
      authorization: string,
      fiscalYear?: number,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      workflowType?: WorkflowType,
      updateDateFrom?: string,
      updateDateTo?: string,
      fileType?: string,
      options?: any
    ): AxiosPromise<Array<FileListResponseInner>> {
      return localVarFp
        .fileListGet(
          authorization,
          fiscalYear,
          groupCode,
          companyCode,
          branchCode,
          country,
          companyName,
          workflowType,
          updateDateFrom,
          updateDateTo,
          fileType,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {'1PE1FILE' | 'SUMMARY_SHEET' | 'ZIPPED_1PE1FILE' | 'CHECK_RESULT'} fileType file type
     * @param {string} jobId
     * @param {string} [pECode]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    filePrepareGet(
      authorization: string,
      fileType: "1PE1FILE" | "SUMMARY_SHEET" | "ZIPPED_1PE1FILE" | "CHECK_RESULT",
      jobId: string,
      pECode?: string,
      options?: any
    ): AxiosPromise<PrepareResponse> {
      return localVarFp
        .filePrepareGet(authorization, fileType, jobId, pECode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModuleFileManagementApi - object-oriented interface
 * @export
 * @class ModuleFileManagementApi
 * @extends {BaseAPI}
 */
export class ModuleFileManagementApi extends BaseAPI {
  /**
   *
   * @param {string} authorization ID Token
   * @param {string} filePath
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleFileManagementApi
   */
  public fileFilePathDelete(authorization: string, filePath: string, options?: AxiosRequestConfig) {
    return ModuleFileManagementApiFp(this.configuration)
      .fileFilePathDelete(authorization, filePath, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {'download' | 'upload'} methodType
   * @param {string} fileName file name
   * @param {'MASTER' | 'WORKFLOW'} category Category
   * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_EXTRACTION' | 'PENDING'} [extractType] if methodType is &#x60;upload&#x60;, extractType is required.
   * @param {'PE_MASTER' | 'ITEM_DEFINITION_MASTER' | 'USER_ACTION_MASTER' | '1PE1FILE_TEMPLATE' | 'SUMMARY_SHEET_TEMPLATE' | 'ZIPPED_1PE1FILE_TEMPLATE' | 'STANDARD_CSV' | 'NO_GENERATION'} [generateType] if methodType is &#x60;download&#x60;, generateType is required.
   * @param {WorkflowType} [workflowType] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowType is required
   * @param {Step} [workflowStep] if fileType is &#x60;WORKFLOW&#x60;, jobId or workflowStep is required
   * @param {string} [jobId]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleFileManagementApi
   */
  public fileGeneratePresignedURLGet(
    authorization: string,
    methodType: "download" | "upload",
    fileName: string,
    category: "MASTER" | "WORKFLOW",
    extractType?:
      | "PE_MASTER"
      | "ITEM_DEFINITION_MASTER"
      | "USER_ACTION_MASTER"
      | "1PE1FILE_TEMPLATE"
      | "SUMMARY_SHEET_TEMPLATE"
      | "ZIPPED_1PE1FILE_TEMPLATE"
      | "STANDARD_CSV"
      | "NO_EXTRACTION"
      | "PENDING",
    generateType?:
      | "PE_MASTER"
      | "ITEM_DEFINITION_MASTER"
      | "USER_ACTION_MASTER"
      | "1PE1FILE_TEMPLATE"
      | "SUMMARY_SHEET_TEMPLATE"
      | "ZIPPED_1PE1FILE_TEMPLATE"
      | "STANDARD_CSV"
      | "NO_GENERATION",
    workflowType?: WorkflowType,
    workflowStep?: Step,
    jobId?: string,
    options?: AxiosRequestConfig
  ) {
    return ModuleFileManagementApiFp(this.configuration)
      .fileGeneratePresignedURLGet(
        authorization,
        methodType,
        fileName,
        category,
        extractType,
        generateType,
        workflowType,
        workflowStep,
        jobId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {number} [fiscalYear]
   * @param {string} [groupCode] groupCode
   * @param {string} [companyCode] companyCode
   * @param {string} [branchCode] branchCode
   * @param {string} [country] country
   * @param {string} [companyName] companyName
   * @param {WorkflowType} [workflowType]
   * @param {string} [updateDateFrom]
   * @param {string} [updateDateTo]
   * @param {string} [fileType]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleFileManagementApi
   */
  public fileListGet(
    authorization: string,
    fiscalYear?: number,
    groupCode?: string,
    companyCode?: string,
    branchCode?: string,
    country?: string,
    companyName?: string,
    workflowType?: WorkflowType,
    updateDateFrom?: string,
    updateDateTo?: string,
    fileType?: string,
    options?: AxiosRequestConfig
  ) {
    return ModuleFileManagementApiFp(this.configuration)
      .fileListGet(
        authorization,
        fiscalYear,
        groupCode,
        companyCode,
        branchCode,
        country,
        companyName,
        workflowType,
        updateDateFrom,
        updateDateTo,
        fileType,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {'1PE1FILE' | 'SUMMARY_SHEET' | 'ZIPPED_1PE1FILE' | 'CHECK_RESULT'} fileType file type
   * @param {string} jobId
   * @param {string} [pECode]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleFileManagementApi
   */
  public filePrepareGet(
    authorization: string,
    fileType: "1PE1FILE" | "SUMMARY_SHEET" | "ZIPPED_1PE1FILE" | "CHECK_RESULT",
    jobId: string,
    pECode?: string,
    options?: AxiosRequestConfig
  ) {
    return ModuleFileManagementApiFp(this.configuration)
      .filePrepareGet(authorization, fileType, jobId, pECode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModuleGeneralApi - axios parameter creator
 * @export
 */
export const ModuleGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constActionListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("constActionListGet", "authorization", authorization);
      const localVarPath = `/const/actionList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constCompanyCategoryListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("constCompanyCategoryListGet", "authorization", authorization);
      const localVarPath = `/const/companyCategoryList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constCountryListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("constCountryListGet", "authorization", authorization);
      const localVarPath = `/const/countryList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constPEStatusListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("constPEStatusListGet", "authorization", authorization);
      const localVarPath = `/const/PEStatusList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    masterListGet: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("masterListGet", "authorization", authorization);
      const localVarPath = `/master/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} pECode
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    pEPECodeGet: async (
      authorization: string,
      pECode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("pEPECodeGet", "authorization", authorization);
      // verify required parameter 'pECode' is not null or undefined
      assertParamExists("pEPECodeGet", "pECode", pECode);
      const localVarPath = `/PE/{PECode}`.replace(
        `{${"PECode"}}`,
        encodeURIComponent(String(pECode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} pECode
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    pEPECodeHistoryGet: async (
      authorization: string,
      pECode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("pEPECodeHistoryGet", "authorization", authorization);
      // verify required parameter 'pECode' is not null or undefined
      assertParamExists("pEPECodeHistoryGet", "pECode", pECode);
      const localVarPath = `/PE/{PECode}/history`.replace(
        `{${"PECode"}}`,
        encodeURIComponent(String(pECode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {CompanyCategory} [companyCategory]
     * @param {WorkflowType} [workflowType]
     * @param {Step} [workflowStep]
     * @param {PEStatus} [status]
     * @param {number} [fiscalYear]
     * @param {string} [version]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    pESearchGet: async (
      authorization: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      companyCategory?: CompanyCategory,
      workflowType?: WorkflowType,
      workflowStep?: Step,
      status?: PEStatus,
      fiscalYear?: number,
      version?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("pESearchGet", "authorization", authorization);
      const localVarPath = `/PE/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupCode !== undefined) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (companyCode !== undefined) {
        localVarQueryParameter["companyCode"] = companyCode;
      }

      if (branchCode !== undefined) {
        localVarQueryParameter["branchCode"] = branchCode;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (companyName !== undefined) {
        localVarQueryParameter["companyName"] = companyName;
      }

      if (companyCategory !== undefined) {
        localVarQueryParameter["companyCategory"] = companyCategory;
      }

      if (workflowType !== undefined) {
        localVarQueryParameter["workflowType"] = workflowType;
      }

      if (workflowStep !== undefined) {
        localVarQueryParameter["workflowStep"] = workflowStep;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fiscalYear !== undefined) {
        localVarQueryParameter["fiscalYear"] = fiscalYear;
      }

      if (version !== undefined) {
        localVarQueryParameter["version"] = version;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModuleGeneralApi - functional programming interface
 * @export
 */
export const ModuleGeneralApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModuleGeneralApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async constActionListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.constActionListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async constCompanyCategoryListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CompanyCategoryListResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.constCompanyCategoryListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async constCountryListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.constCountryListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async constPEStatusListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PEStatusListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.constPEStatusListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async masterListGet(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MasterListResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.masterListGet(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} pECode
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async pEPECodeGet(
      authorization: string,
      pECode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PEResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pEPECodeGet(
        authorization,
        pECode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} pECode
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async pEPECodeHistoryGet(
      authorization: string,
      pECode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PEHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pEPECodeHistoryGet(
        authorization,
        pECode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {CompanyCategory} [companyCategory]
     * @param {WorkflowType} [workflowType]
     * @param {Step} [workflowStep]
     * @param {PEStatus} [status]
     * @param {number} [fiscalYear]
     * @param {string} [version]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async pESearchGet(
      authorization: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      companyCategory?: CompanyCategory,
      workflowType?: WorkflowType,
      workflowStep?: Step,
      status?: PEStatus,
      fiscalYear?: number,
      version?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PESearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pESearchGet(
        authorization,
        groupCode,
        companyCode,
        branchCode,
        country,
        companyName,
        companyCategory,
        workflowType,
        workflowStep,
        status,
        fiscalYear,
        version,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModuleGeneralApi - factory interface
 * @export
 */
export const ModuleGeneralApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModuleGeneralApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constActionListGet(authorization: string, options?: any): AxiosPromise<ActionListResponse> {
      return localVarFp
        .constActionListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constCompanyCategoryListGet(
      authorization: string,
      options?: any
    ): AxiosPromise<Array<CompanyCategoryListResponseInner>> {
      return localVarFp
        .constCompanyCategoryListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constCountryListGet(authorization: string, options?: any): AxiosPromise<CountryListResponse> {
      return localVarFp
        .constCountryListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    constPEStatusListGet(authorization: string, options?: any): AxiosPromise<PEStatusListResponse> {
      return localVarFp
        .constPEStatusListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    masterListGet(
      authorization: string,
      options?: any
    ): AxiosPromise<Array<MasterListResponseInner>> {
      return localVarFp
        .masterListGet(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} pECode
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    pEPECodeGet(authorization: string, pECode: string, options?: any): AxiosPromise<PEResponse> {
      return localVarFp
        .pEPECodeGet(authorization, pECode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} pECode
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    pEPECodeHistoryGet(
      authorization: string,
      pECode: string,
      options?: any
    ): AxiosPromise<PEHistoryResponse> {
      return localVarFp
        .pEPECodeHistoryGet(authorization, pECode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {CompanyCategory} [companyCategory]
     * @param {WorkflowType} [workflowType]
     * @param {Step} [workflowStep]
     * @param {PEStatus} [status]
     * @param {number} [fiscalYear]
     * @param {string} [version]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    pESearchGet(
      authorization: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      companyCategory?: CompanyCategory,
      workflowType?: WorkflowType,
      workflowStep?: Step,
      status?: PEStatus,
      fiscalYear?: number,
      version?: string,
      options?: any
    ): AxiosPromise<PESearchResponse> {
      return localVarFp
        .pESearchGet(
          authorization,
          groupCode,
          companyCode,
          branchCode,
          country,
          companyName,
          companyCategory,
          workflowType,
          workflowStep,
          status,
          fiscalYear,
          version,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModuleGeneralApi - object-oriented interface
 * @export
 * @class ModuleGeneralApi
 * @extends {BaseAPI}
 */
export class ModuleGeneralApi extends BaseAPI {
  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public constActionListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .constActionListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public constCompanyCategoryListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .constCompanyCategoryListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public constCountryListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .constCountryListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public constPEStatusListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .constPEStatusListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public masterListGet(authorization: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .masterListGet(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} pECode
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public pEPECodeGet(authorization: string, pECode: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .pEPECodeGet(authorization, pECode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} pECode
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public pEPECodeHistoryGet(authorization: string, pECode: string, options?: AxiosRequestConfig) {
    return ModuleGeneralApiFp(this.configuration)
      .pEPECodeHistoryGet(authorization, pECode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} [groupCode] groupCode
   * @param {string} [companyCode] companyCode
   * @param {string} [branchCode] branchCode
   * @param {string} [country] country
   * @param {string} [companyName] companyName
   * @param {CompanyCategory} [companyCategory]
   * @param {WorkflowType} [workflowType]
   * @param {Step} [workflowStep]
   * @param {PEStatus} [status]
   * @param {number} [fiscalYear]
   * @param {string} [version]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleGeneralApi
   */
  public pESearchGet(
    authorization: string,
    groupCode?: string,
    companyCode?: string,
    branchCode?: string,
    country?: string,
    companyName?: string,
    companyCategory?: CompanyCategory,
    workflowType?: WorkflowType,
    workflowStep?: Step,
    status?: PEStatus,
    fiscalYear?: number,
    version?: string,
    options?: AxiosRequestConfig
  ) {
    return ModuleGeneralApiFp(this.configuration)
      .pESearchGet(
        authorization,
        groupCode,
        companyCode,
        branchCode,
        country,
        companyName,
        companyCategory,
        workflowType,
        workflowStep,
        status,
        fiscalYear,
        version,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModuleMailApi - axios parameter creator
 * @export
 */
export const ModuleMailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary メールテンプレート取得
     * @param {string} authorization ID Token
     * @param {string} mailType メール種別
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getMailTemplate: async (
      authorization: string,
      mailType: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getMailTemplate", "authorization", authorization);
      // verify required parameter 'mailType' is not null or undefined
      assertParamExists("getMailTemplate", "mailType", mailType);
      const localVarPath = `/mail/{mailType}`.replace(
        `{${"mailType"}}`,
        encodeURIComponent(String(mailType))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary メールテンプレート一覧取得
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getMailTemplateList: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getMailTemplateList", "authorization", authorization);
      const localVarPath = `/mail/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary メールテンプレート登録
     * @param {string} authorization ID Token
     * @param {MailTemplate} mailTemplate メールテンプレート
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    postMailTemplate: async (
      authorization: string,
      mailTemplate: MailTemplate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("postMailTemplate", "authorization", authorization);
      // verify required parameter 'mailTemplate' is not null or undefined
      assertParamExists("postMailTemplate", "mailTemplate", mailTemplate);
      const localVarPath = `/mail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mailTemplate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary メール送信
     * @param {string} authorization ID Token
     * @param {Mail} mail メール
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    sendMail: async (
      authorization: string,
      mail: Mail,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("sendMail", "authorization", authorization);
      // verify required parameter 'mail' is not null or undefined
      assertParamExists("sendMail", "mail", mail);
      const localVarPath = `/mail/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mail,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModuleMailApi - functional programming interface
 * @export
 */
export const ModuleMailApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModuleMailApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary メールテンプレート取得
     * @param {string} authorization ID Token
     * @param {string} mailType メール種別
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async getMailTemplate(
      authorization: string,
      mailType: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplate(
        authorization,
        mailType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary メールテンプレート一覧取得
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async getMailTemplateList(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplateListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplateList(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary メールテンプレート登録
     * @param {string} authorization ID Token
     * @param {MailTemplate} mailTemplate メールテンプレート
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async postMailTemplate(
      authorization: string,
      mailTemplate: MailTemplate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMailTemplate(
        authorization,
        mailTemplate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary メール送信
     * @param {string} authorization ID Token
     * @param {Mail} mail メール
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async sendMail(
      authorization: string,
      mail: Mail,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendMail(
        authorization,
        mail,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModuleMailApi - factory interface
 * @export
 */
export const ModuleMailApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModuleMailApiFp(configuration);
  return {
    /**
     *
     * @summary メールテンプレート取得
     * @param {string} authorization ID Token
     * @param {string} mailType メール種別
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getMailTemplate(
      authorization: string,
      mailType: string,
      options?: any
    ): AxiosPromise<MailTemplate> {
      return localVarFp
        .getMailTemplate(authorization, mailType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary メールテンプレート一覧取得
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getMailTemplateList(
      authorization: string,
      options?: any
    ): AxiosPromise<MailTemplateListResponse> {
      return localVarFp
        .getMailTemplateList(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary メールテンプレート登録
     * @param {string} authorization ID Token
     * @param {MailTemplate} mailTemplate メールテンプレート
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    postMailTemplate(
      authorization: string,
      mailTemplate: MailTemplate,
      options?: any
    ): AxiosPromise<MailTemplate> {
      return localVarFp
        .postMailTemplate(authorization, mailTemplate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary メール送信
     * @param {string} authorization ID Token
     * @param {Mail} mail メール
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    sendMail(authorization: string, mail: Mail, options?: any): AxiosPromise<ActionResult> {
      return localVarFp
        .sendMail(authorization, mail, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModuleMailApi - object-oriented interface
 * @export
 * @class ModuleMailApi
 * @extends {BaseAPI}
 */
export class ModuleMailApi extends BaseAPI {
  /**
   *
   * @summary メールテンプレート取得
   * @param {string} authorization ID Token
   * @param {string} mailType メール種別
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleMailApi
   */
  public getMailTemplate(authorization: string, mailType: string, options?: AxiosRequestConfig) {
    return ModuleMailApiFp(this.configuration)
      .getMailTemplate(authorization, mailType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary メールテンプレート一覧取得
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleMailApi
   */
  public getMailTemplateList(authorization: string, options?: AxiosRequestConfig) {
    return ModuleMailApiFp(this.configuration)
      .getMailTemplateList(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary メールテンプレート登録
   * @param {string} authorization ID Token
   * @param {MailTemplate} mailTemplate メールテンプレート
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleMailApi
   */
  public postMailTemplate(
    authorization: string,
    mailTemplate: MailTemplate,
    options?: AxiosRequestConfig
  ) {
    return ModuleMailApiFp(this.configuration)
      .postMailTemplate(authorization, mailTemplate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary メール送信
   * @param {string} authorization ID Token
   * @param {Mail} mail メール
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleMailApi
   */
  public sendMail(authorization: string, mail: Mail, options?: AxiosRequestConfig) {
    return ModuleMailApiFp(this.configuration)
      .sendMail(authorization, mail, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModuleWorkflowApi - axios parameter creator
 * @export
 */
export const ModuleWorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary 提出ファイル作成ワークフロー作成
     * @param {string} authorization ID Token
     * @param {CreateSubmittingFileWorkflowRequest} [createSubmittingFileWorkflowRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    createSubmittingFileWorkflow: async (
      authorization: string,
      createSubmittingFileWorkflowRequest?: CreateSubmittingFileWorkflowRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("createSubmittingFileWorkflow", "authorization", authorization);
      const localVarPath = `/workflow/createSubmittingFileWorkflow`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSubmittingFileWorkflowRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create fix version.
     * @param {string} authorization ID Token
     * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    createWorkflowFixVersion: async (
      authorization: string,
      createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("createWorkflowFixVersion", "authorization", authorization);
      const localVarPath = `/workflow/createFixVersion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkflowFixVersionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete fix version.
     * @param {string} authorization ID Token
     * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    deleteWorkflowFixVersion: async (
      authorization: string,
      createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("deleteWorkflowFixVersion", "authorization", authorization);
      const localVarPath = `/workflow/deleteFixVersion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkflowFixVersionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ワークフロー取得
     * @param {string} authorization ID Token
     * @param {WorkflowType} workflowType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getWorkflow: async (
      authorization: string,
      workflowType: WorkflowType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getWorkflow", "authorization", authorization);
      // verify required parameter 'workflowType' is not null or undefined
      assertParamExists("getWorkflow", "workflowType", workflowType);
      const localVarPath = `/workflow/{workflowType}`.replace(
        `{${"workflowType"}}`,
        encodeURIComponent(String(workflowType))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ワークフロー状態取得
     * @param {string} authorization ID Token
     * @param {WorkflowType} workflowType
     * @param {number} fiscalYear
     * @param {string} version
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {CompanyCategory} [companyCategory]
     * @param {WorkflowType} [workflowType2]
     * @param {Step} [workflowStep]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getWorkflowStatus: async (
      authorization: string,
      workflowType: WorkflowType,
      fiscalYear: number,
      version: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      companyCategory?: CompanyCategory,
      workflowType2?: WorkflowType,
      workflowStep?: Step,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getWorkflowStatus", "authorization", authorization);
      // verify required parameter 'workflowType' is not null or undefined
      assertParamExists("getWorkflowStatus", "workflowType", workflowType);
      // verify required parameter 'fiscalYear' is not null or undefined
      assertParamExists("getWorkflowStatus", "fiscalYear", fiscalYear);
      // verify required parameter 'version' is not null or undefined
      assertParamExists("getWorkflowStatus", "version", version);
      const localVarPath = `/workflow/{workflowType}/status`.replace(
        `{${"workflowType"}}`,
        encodeURIComponent(String(workflowType))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fiscalYear !== undefined) {
        localVarQueryParameter["fiscalYear"] = fiscalYear;
      }

      if (version !== undefined) {
        localVarQueryParameter["version"] = version;
      }

      if (groupCode !== undefined) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (companyCode !== undefined) {
        localVarQueryParameter["companyCode"] = companyCode;
      }

      if (branchCode !== undefined) {
        localVarQueryParameter["branchCode"] = branchCode;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (companyName !== undefined) {
        localVarQueryParameter["companyName"] = companyName;
      }

      if (companyCategory !== undefined) {
        localVarQueryParameter["companyCategory"] = companyCategory;
      }

      if (workflowType2 !== undefined) {
        localVarQueryParameter["workflowType"] = workflowType2;
      }

      if (workflowStep !== undefined) {
        localVarQueryParameter["workflowStep"] = workflowStep;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ジョブ作成
     * @param {string} authorization ID Token
     * @param {InitiateJobRequest} [initiateJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    initiateJob: async (
      authorization: string,
      initiateJobRequest?: InitiateJobRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("initiateJob", "authorization", authorization);
      const localVarPath = `/job/initiate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        initiateJobRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdAbortPostRequest} [jobJobIdAbortPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdAbortPost: async (
      authorization: string,
      jobId: string,
      jobJobIdAbortPostRequest?: JobJobIdAbortPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("jobJobIdAbortPost", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("jobJobIdAbortPost", "jobId", jobId);
      const localVarPath = `/job/{jobId}/abort`.replace(
        `{${"jobId"}}`,
        encodeURIComponent(String(jobId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobJobIdAbortPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdApprovePostRequest} [jobJobIdApprovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdApprovePost: async (
      authorization: string,
      jobId: string,
      jobJobIdApprovePostRequest?: JobJobIdApprovePostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("jobJobIdApprovePost", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("jobJobIdApprovePost", "jobId", jobId);
      const localVarPath = `/job/{jobId}/approve`.replace(
        `{${"jobId"}}`,
        encodeURIComponent(String(jobId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobJobIdApprovePostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ジョブ取得
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [companyName] companyName
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdGet: async (
      authorization: string,
      jobId: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      companyName?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("jobJobIdGet", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("jobJobIdGet", "jobId", jobId);
      const localVarPath = `/job/{jobId}`.replace(
        `{${"jobId"}}`,
        encodeURIComponent(String(jobId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupCode !== undefined) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (companyCode !== undefined) {
        localVarQueryParameter["companyCode"] = companyCode;
      }

      if (branchCode !== undefined) {
        localVarQueryParameter["branchCode"] = branchCode;
      }

      if (companyName !== undefined) {
        localVarQueryParameter["companyName"] = companyName;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ジョブの次のアクション一覧取得
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdNextActionListGet: async (
      authorization: string,
      jobId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("jobJobIdNextActionListGet", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("jobJobIdNextActionListGet", "jobId", jobId);
      const localVarPath = `/job/{jobId}/nextActionList`.replace(
        `{${"jobId"}}`,
        encodeURIComponent(String(jobId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdSendbackPostRequest} [jobJobIdSendbackPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdSendbackPost: async (
      authorization: string,
      jobId: string,
      jobJobIdSendbackPostRequest?: JobJobIdSendbackPostRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("jobJobIdSendbackPost", "authorization", authorization);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("jobJobIdSendbackPost", "jobId", jobId);
      const localVarPath = `/job/{jobId}/sendback`.replace(
        `{${"jobId"}}`,
        encodeURIComponent(String(jobId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobJobIdSendbackPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ジョブ一覧取得
     * @param {string} authorization ID Token
     * @param {JobType} [jobType]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    listJob: async (
      authorization: string,
      jobType?: JobType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("listJob", "authorization", authorization);
      const localVarPath = `/job/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobType !== undefined) {
        localVarQueryParameter["JobType"] = jobType;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ワークフロー一覧取得
     * @param {string} authorization ID Token
     * @param {boolean} [submittingFileOnly]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    listWorkflow: async (
      authorization: string,
      submittingFileOnly?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("listWorkflow", "authorization", authorization);
      const localVarPath = `/workflow/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (submittingFileOnly !== undefined) {
        localVarQueryParameter["submittingFileOnly"] = submittingFileOnly;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ワークフロー年度一覧取得
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    listWorkflowFiscalYear: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("listWorkflowFiscalYear", "authorization", authorization);
      const localVarPath = `/workflow/{fiscalYear}`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary ワークフロー状態更新
     * @param {string} authorization ID Token
     * @param {UpdateWorkflowStatusRequest} [updateWorkflowStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    updateWorkflowStatus: async (
      authorization: string,
      updateWorkflowStatusRequest?: UpdateWorkflowStatusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("updateWorkflowStatus", "authorization", authorization);
      const localVarPath = `/workflow/updateStatus`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateWorkflowStatusRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModuleWorkflowApi - functional programming interface
 * @export
 */
export const ModuleWorkflowApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModuleWorkflowApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary 提出ファイル作成ワークフロー作成
     * @param {string} authorization ID Token
     * @param {CreateSubmittingFileWorkflowRequest} [createSubmittingFileWorkflowRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async createSubmittingFileWorkflow(
      authorization: string,
      createSubmittingFileWorkflowRequest?: CreateSubmittingFileWorkflowRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSubmittingFileWorkflow(
        authorization,
        createSubmittingFileWorkflowRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create fix version.
     * @param {string} authorization ID Token
     * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async createWorkflowFixVersion(
      authorization: string,
      createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowFixVersion(
        authorization,
        createWorkflowFixVersionRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete fix version.
     * @param {string} authorization ID Token
     * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async deleteWorkflowFixVersion(
      authorization: string,
      createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowFixVersion(
        authorization,
        createWorkflowFixVersionRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ワークフロー取得
     * @param {string} authorization ID Token
     * @param {WorkflowType} workflowType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async getWorkflow(
      authorization: string,
      workflowType: WorkflowType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflow(
        authorization,
        workflowType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ワークフロー状態取得
     * @param {string} authorization ID Token
     * @param {WorkflowType} workflowType
     * @param {number} fiscalYear
     * @param {string} version
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {CompanyCategory} [companyCategory]
     * @param {WorkflowType} [workflowType2]
     * @param {Step} [workflowStep]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async getWorkflowStatus(
      authorization: string,
      workflowType: WorkflowType,
      fiscalYear: number,
      version: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      companyCategory?: CompanyCategory,
      workflowType2?: WorkflowType,
      workflowStep?: Step,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowStatusResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowStatus(
        authorization,
        workflowType,
        fiscalYear,
        version,
        groupCode,
        companyCode,
        branchCode,
        country,
        companyName,
        companyCategory,
        workflowType2,
        workflowStep,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ジョブ作成
     * @param {string} authorization ID Token
     * @param {InitiateJobRequest} [initiateJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async initiateJob(
      authorization: string,
      initiateJobRequest?: InitiateJobRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateJobResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initiateJob(
        authorization,
        initiateJobRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdAbortPostRequest} [jobJobIdAbortPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async jobJobIdAbortPost(
      authorization: string,
      jobId: string,
      jobJobIdAbortPostRequest?: JobJobIdAbortPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateJobResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobJobIdAbortPost(
        authorization,
        jobId,
        jobJobIdAbortPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdApprovePostRequest} [jobJobIdApprovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async jobJobIdApprovePost(
      authorization: string,
      jobId: string,
      jobJobIdApprovePostRequest?: JobJobIdApprovePostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobJobIdApprovePost(
        authorization,
        jobId,
        jobJobIdApprovePostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ジョブ取得
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [companyName] companyName
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async jobJobIdGet(
      authorization: string,
      jobId: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      companyName?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobJobIdGet(
        authorization,
        jobId,
        groupCode,
        companyCode,
        branchCode,
        companyName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ジョブの次のアクション一覧取得
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async jobJobIdNextActionListGet(
      authorization: string,
      jobId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NextActionListResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobJobIdNextActionListGet(
        authorization,
        jobId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdSendbackPostRequest} [jobJobIdSendbackPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async jobJobIdSendbackPost(
      authorization: string,
      jobId: string,
      jobJobIdSendbackPostRequest?: JobJobIdSendbackPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobJobIdSendbackPost(
        authorization,
        jobId,
        jobJobIdSendbackPostRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ジョブ一覧取得
     * @param {string} authorization ID Token
     * @param {JobType} [jobType]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async listJob(
      authorization: string,
      jobType?: JobType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listJob(
        authorization,
        jobType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ワークフロー一覧取得
     * @param {string} authorization ID Token
     * @param {boolean} [submittingFileOnly]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async listWorkflow(
      authorization: string,
      submittingFileOnly?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkflow(
        authorization,
        submittingFileOnly,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ワークフロー年度一覧取得
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async listWorkflowFiscalYear(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<WorkflowManagementResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkflowFiscalYear(
        authorization,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary ワークフロー状態更新
     * @param {string} authorization ID Token
     * @param {UpdateWorkflowStatusRequest} [updateWorkflowStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    async updateWorkflowStatus(
      authorization: string,
      updateWorkflowStatusRequest?: UpdateWorkflowStatusRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowStatus(
        authorization,
        updateWorkflowStatusRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModuleWorkflowApi - factory interface
 * @export
 */
export const ModuleWorkflowApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModuleWorkflowApiFp(configuration);
  return {
    /**
     *
     * @summary 提出ファイル作成ワークフロー作成
     * @param {string} authorization ID Token
     * @param {CreateSubmittingFileWorkflowRequest} [createSubmittingFileWorkflowRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    createSubmittingFileWorkflow(
      authorization: string,
      createSubmittingFileWorkflowRequest?: CreateSubmittingFileWorkflowRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .createSubmittingFileWorkflow(authorization, createSubmittingFileWorkflowRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create fix version.
     * @param {string} authorization ID Token
     * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    createWorkflowFixVersion(
      authorization: string,
      createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .createWorkflowFixVersion(authorization, createWorkflowFixVersionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete fix version.
     * @param {string} authorization ID Token
     * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    deleteWorkflowFixVersion(
      authorization: string,
      createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .deleteWorkflowFixVersion(authorization, createWorkflowFixVersionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ワークフロー取得
     * @param {string} authorization ID Token
     * @param {WorkflowType} workflowType
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getWorkflow(
      authorization: string,
      workflowType: WorkflowType,
      options?: any
    ): AxiosPromise<WorkflowResponse> {
      return localVarFp
        .getWorkflow(authorization, workflowType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ワークフロー状態取得
     * @param {string} authorization ID Token
     * @param {WorkflowType} workflowType
     * @param {number} fiscalYear
     * @param {string} version
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [country] country
     * @param {string} [companyName] companyName
     * @param {CompanyCategory} [companyCategory]
     * @param {WorkflowType} [workflowType2]
     * @param {Step} [workflowStep]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    getWorkflowStatus(
      authorization: string,
      workflowType: WorkflowType,
      fiscalYear: number,
      version: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      country?: string,
      companyName?: string,
      companyCategory?: CompanyCategory,
      workflowType2?: WorkflowType,
      workflowStep?: Step,
      options?: any
    ): AxiosPromise<WorkflowStatusResponse> {
      return localVarFp
        .getWorkflowStatus(
          authorization,
          workflowType,
          fiscalYear,
          version,
          groupCode,
          companyCode,
          branchCode,
          country,
          companyName,
          companyCategory,
          workflowType2,
          workflowStep,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ジョブ作成
     * @param {string} authorization ID Token
     * @param {InitiateJobRequest} [initiateJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    initiateJob(
      authorization: string,
      initiateJobRequest?: InitiateJobRequest,
      options?: any
    ): AxiosPromise<UpdateJobResponse> {
      return localVarFp
        .initiateJob(authorization, initiateJobRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdAbortPostRequest} [jobJobIdAbortPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdAbortPost(
      authorization: string,
      jobId: string,
      jobJobIdAbortPostRequest?: JobJobIdAbortPostRequest,
      options?: any
    ): AxiosPromise<UpdateJobResponse> {
      return localVarFp
        .jobJobIdAbortPost(authorization, jobId, jobJobIdAbortPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdApprovePostRequest} [jobJobIdApprovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdApprovePost(
      authorization: string,
      jobId: string,
      jobJobIdApprovePostRequest?: JobJobIdApprovePostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .jobJobIdApprovePost(authorization, jobId, jobJobIdApprovePostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ジョブ取得
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {string} [groupCode] groupCode
     * @param {string} [companyCode] companyCode
     * @param {string} [branchCode] branchCode
     * @param {string} [companyName] companyName
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdGet(
      authorization: string,
      jobId: string,
      groupCode?: string,
      companyCode?: string,
      branchCode?: string,
      companyName?: string,
      options?: any
    ): AxiosPromise<Job> {
      return localVarFp
        .jobJobIdGet(authorization, jobId, groupCode, companyCode, branchCode, companyName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ジョブの次のアクション一覧取得
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdNextActionListGet(
      authorization: string,
      jobId: string,
      options?: any
    ): AxiosPromise<Array<NextActionListResponseInner>> {
      return localVarFp
        .jobJobIdNextActionListGet(authorization, jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization ID Token
     * @param {string} jobId Job ID
     * @param {JobJobIdSendbackPostRequest} [jobJobIdSendbackPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    jobJobIdSendbackPost(
      authorization: string,
      jobId: string,
      jobJobIdSendbackPostRequest?: JobJobIdSendbackPostRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .jobJobIdSendbackPost(authorization, jobId, jobJobIdSendbackPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ジョブ一覧取得
     * @param {string} authorization ID Token
     * @param {JobType} [jobType]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    listJob(
      authorization: string,
      jobType?: JobType,
      options?: any
    ): AxiosPromise<JobListResponse> {
      return localVarFp
        .listJob(authorization, jobType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ワークフロー一覧取得
     * @param {string} authorization ID Token
     * @param {boolean} [submittingFileOnly]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    listWorkflow(
      authorization: string,
      submittingFileOnly?: boolean,
      options?: any
    ): AxiosPromise<WorkflowListResponse> {
      return localVarFp
        .listWorkflow(authorization, submittingFileOnly, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ワークフロー年度一覧取得
     * @param {string} authorization ID Token
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    listWorkflowFiscalYear(
      authorization: string,
      options?: any
    ): AxiosPromise<Array<WorkflowManagementResponseInner>> {
      return localVarFp
        .listWorkflowFiscalYear(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ワークフロー状態更新
     * @param {string} authorization ID Token
     * @param {UpdateWorkflowStatusRequest} [updateWorkflowStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {MandatoryFieldError}
     */
    updateWorkflowStatus(
      authorization: string,
      updateWorkflowStatusRequest?: UpdateWorkflowStatusRequest,
      options?: any
    ): AxiosPromise<ActionResult> {
      return localVarFp
        .updateWorkflowStatus(authorization, updateWorkflowStatusRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModuleWorkflowApi - object-oriented interface
 * @export
 * @class ModuleWorkflowApi
 * @extends {BaseAPI}
 */
export class ModuleWorkflowApi extends BaseAPI {
  /**
   *
   * @summary 提出ファイル作成ワークフロー作成
   * @param {string} authorization ID Token
   * @param {CreateSubmittingFileWorkflowRequest} [createSubmittingFileWorkflowRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public createSubmittingFileWorkflow(
    authorization: string,
    createSubmittingFileWorkflowRequest?: CreateSubmittingFileWorkflowRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .createSubmittingFileWorkflow(authorization, createSubmittingFileWorkflowRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create fix version.
   * @param {string} authorization ID Token
   * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public createWorkflowFixVersion(
    authorization: string,
    createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .createWorkflowFixVersion(authorization, createWorkflowFixVersionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete fix version.
   * @param {string} authorization ID Token
   * @param {CreateWorkflowFixVersionRequest} [createWorkflowFixVersionRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public deleteWorkflowFixVersion(
    authorization: string,
    createWorkflowFixVersionRequest?: CreateWorkflowFixVersionRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .deleteWorkflowFixVersion(authorization, createWorkflowFixVersionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ワークフロー取得
   * @param {string} authorization ID Token
   * @param {WorkflowType} workflowType
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public getWorkflow(
    authorization: string,
    workflowType: WorkflowType,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .getWorkflow(authorization, workflowType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ワークフロー状態取得
   * @param {string} authorization ID Token
   * @param {WorkflowType} workflowType
   * @param {number} fiscalYear
   * @param {string} version
   * @param {string} [groupCode] groupCode
   * @param {string} [companyCode] companyCode
   * @param {string} [branchCode] branchCode
   * @param {string} [country] country
   * @param {string} [companyName] companyName
   * @param {CompanyCategory} [companyCategory]
   * @param {WorkflowType} [workflowType2]
   * @param {Step} [workflowStep]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public getWorkflowStatus(
    authorization: string,
    workflowType: WorkflowType,
    fiscalYear: number,
    version: string,
    groupCode?: string,
    companyCode?: string,
    branchCode?: string,
    country?: string,
    companyName?: string,
    companyCategory?: CompanyCategory,
    workflowType2?: WorkflowType,
    workflowStep?: Step,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .getWorkflowStatus(
        authorization,
        workflowType,
        fiscalYear,
        version,
        groupCode,
        companyCode,
        branchCode,
        country,
        companyName,
        companyCategory,
        workflowType2,
        workflowStep,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ジョブ作成
   * @param {string} authorization ID Token
   * @param {InitiateJobRequest} [initiateJobRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public initiateJob(
    authorization: string,
    initiateJobRequest?: InitiateJobRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .initiateJob(authorization, initiateJobRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} jobId Job ID
   * @param {JobJobIdAbortPostRequest} [jobJobIdAbortPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public jobJobIdAbortPost(
    authorization: string,
    jobId: string,
    jobJobIdAbortPostRequest?: JobJobIdAbortPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .jobJobIdAbortPost(authorization, jobId, jobJobIdAbortPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} jobId Job ID
   * @param {JobJobIdApprovePostRequest} [jobJobIdApprovePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public jobJobIdApprovePost(
    authorization: string,
    jobId: string,
    jobJobIdApprovePostRequest?: JobJobIdApprovePostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .jobJobIdApprovePost(authorization, jobId, jobJobIdApprovePostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ジョブ取得
   * @param {string} authorization ID Token
   * @param {string} jobId Job ID
   * @param {string} [groupCode] groupCode
   * @param {string} [companyCode] companyCode
   * @param {string} [branchCode] branchCode
   * @param {string} [companyName] companyName
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public jobJobIdGet(
    authorization: string,
    jobId: string,
    groupCode?: string,
    companyCode?: string,
    branchCode?: string,
    companyName?: string,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .jobJobIdGet(authorization, jobId, groupCode, companyCode, branchCode, companyName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ジョブの次のアクション一覧取得
   * @param {string} authorization ID Token
   * @param {string} jobId Job ID
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public jobJobIdNextActionListGet(
    authorization: string,
    jobId: string,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .jobJobIdNextActionListGet(authorization, jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization ID Token
   * @param {string} jobId Job ID
   * @param {JobJobIdSendbackPostRequest} [jobJobIdSendbackPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public jobJobIdSendbackPost(
    authorization: string,
    jobId: string,
    jobJobIdSendbackPostRequest?: JobJobIdSendbackPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .jobJobIdSendbackPost(authorization, jobId, jobJobIdSendbackPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ジョブ一覧取得
   * @param {string} authorization ID Token
   * @param {JobType} [jobType]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public listJob(authorization: string, jobType?: JobType, options?: AxiosRequestConfig) {
    return ModuleWorkflowApiFp(this.configuration)
      .listJob(authorization, jobType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ワークフロー一覧取得
   * @param {string} authorization ID Token
   * @param {boolean} [submittingFileOnly]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public listWorkflow(
    authorization: string,
    submittingFileOnly?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .listWorkflow(authorization, submittingFileOnly, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ワークフロー年度一覧取得
   * @param {string} authorization ID Token
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public listWorkflowFiscalYear(authorization: string, options?: AxiosRequestConfig) {
    return ModuleWorkflowApiFp(this.configuration)
      .listWorkflowFiscalYear(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary ワークフロー状態更新
   * @param {string} authorization ID Token
   * @param {UpdateWorkflowStatusRequest} [updateWorkflowStatusRequest]
   * @param {*} [options] Override http request option.
   * @throws {MandatoryFieldError}
   * @memberof ModuleWorkflowApi
   */
  public updateWorkflowStatus(
    authorization: string,
    updateWorkflowStatusRequest?: UpdateWorkflowStatusRequest,
    options?: AxiosRequestConfig
  ) {
    return ModuleWorkflowApiFp(this.configuration)
      .updateWorkflowStatus(authorization, updateWorkflowStatusRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
