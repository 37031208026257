import { Typography } from "@mui/material";

import type { FC, ReactElement } from "react";

type AdcTitleProps = {
  children: ReactElement | ReactElement[] | string;
  [key: string]: unknown;
};
const AdcTitle1: FC<AdcTitleProps> = ({ children, ...others }) => (
  <Typography variant="h3" {...others}>
    {children}
  </Typography>
);
const AdcTitle2: FC<AdcTitleProps> = ({ children, ...others }) => (
  <Typography variant="h5" {...others}>
    {children}
  </Typography>
);

const AdcTitle3: FC<AdcTitleProps> = ({ children, ...others }) => (
  <Typography variant="h5" sx={{ fontSize: "15px" }} {...others}>
    {children}
  </Typography>
);

export { AdcTitle1, AdcTitle2, AdcTitle3 };
