import { Paper, Link, Button, Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { NoneWrapper } from "../../components/atoms/NoneWrapper";
import { useUser } from "../../hooks/UserContext";

const SignInCorpPage = () => {
  const [isRefuse, setRefuse] = useState(true);
  const { info, setGlobalInfo } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === "ACCESS_TEST" &&
      process.env.REACT_APP_STAGE_FLAG !== "UNIT_TEST"
    ) {
      if (info?.transfer === "AzureAD") {
        setRefuse(false);
      } else if (isRefuse) {
        navigate("/login");
      }
      setGlobalInfo({ transfer: "" });
    }
  }, [info?.transfer, isRefuse, navigate, setGlobalInfo]);

  const handleSignIn = async () => {
    // navigate(`https://${process.env.REACT_APP_COGNITO_USERPOOL_ENDPOINT}/oauth2/authorize`);
    const currentOrigin = window.location.origin;
    const cognitoUrl = new URL(
      `${process.env.REACT_APP_COGNITO_USERPOOL_ENDPOINT}/oauth2/authorize`
    );

    const params = new URLSearchParams(cognitoUrl.search);

    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const scope = "aws.cognito.signin.user.admin email openid phone profile";

    params.set("identity_provider", "AzureAD");
    params.set("redirect_uri", `${currentOrigin}/callback`);
    params.set("response_type", "CODE");
    params.set("client_id", clientId!);
    params.set("scope", scope!);
    cognitoUrl.search = params.toString();

    window.location.href = cognitoUrl.href;
  };

  return (
    <Container component="main" fixed sx={{ my: 2, py: 1 }}>
      <Paper sx={{ mx: "auto", p: 5, maxWidth: 560 }}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              ■ Corporate Sign in
            </Typography>
          </Grid>
          <NoneWrapper show={false}>
            <Grid xs={12} sm={12}>
              <Typography color="secondary" sx={{ mt: 2 }}>
                Click ”MICROSOFT(AZURE AD)”
              </Typography>
            </Grid>
          </NoneWrapper>
          <Grid xs={12} sm={12} sx={{ ml: 2 }}>
            <Typography sx={{ mt: 2 }}>Click ”MICROSOFT(AZURE AD)”</Typography>
          </Grid>
          <Grid xs={12} sm={12} sx={{ ml: 2, display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleSignIn}
              variant="contained"
              color="primary"
              size="large"
              sx={{ mx: "auto", mb: 2, maxWidth: 560 }}
            >
              Microsoft(Azure AD)
            </Button>
          </Grid>
          {process.env.REACT_APP_ENV !== "ACCESS_TEST" && (
            <Grid xs={12} sm={12}>
              <Typography sx={{ mt: 5, ml: 2 }}>
                … or continue with <Link href="/login/adc">ADC ID Sign In</Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default SignInCorpPage;
