import { Box, Button, Paper, Stack } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useState, useMemo } from "react";

import { LabelInput } from "../../../components/molecular/LabelInput";

import type { ActionCB, JobDetail } from "../../../common/type_defs";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC } from "react";

export type JobHistoryFilter = Partial<{
  companyName: string;
  companyCode: string;
  groupCode: string;
  branchCode: string;
}>;

type JobHistoryFilterPanelProps = {
  onApply: (filter: JobHistoryFilter) => void;
  onReset: () => void;
};

export const JobHistoryFilterPanel: FC<JobHistoryFilterPanelProps> = ({ onApply, onReset }) => {
  const [companyName, setCompanyName] = useState<string>("");
  const [groupCode, setGroupCode] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [branchCode, setBranchCode] = useState<string>("");

  const onFieldChange = (
    target: "CompanyName" | "CompanyCode" | "GroupCode" | "BranchCode",
    value: string
  ) => {
    if (target === "CompanyName") {
      setCompanyName(value);
    } else if (target === "CompanyCode") {
      setCompanyCode(value);
    } else if (target === "GroupCode") {
      setGroupCode(value);
    } else if (target === "BranchCode") {
      setBranchCode(value);
    }
  };

  const handleOnApply = () => {
    onApply({
      companyName,
      companyCode,
      groupCode,
      branchCode,
    });
  };

  const handleOnReset = () => {
    setCompanyName("");
    setCompanyCode("");
    setGroupCode("");
    setBranchCode("");
    onReset();
  };

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", gap: 1, justifyContent: "flex-start" }}>
        <LabelInput
          key="group_code"
          name="group_code"
          label="Group Code"
          value={groupCode}
          onChanged={(text) => onFieldChange("GroupCode", text)}
          sx={{ minWidth: "120px" }}
          size="small"
        />
        <LabelInput
          key="company_code"
          name="company_code"
          label="Company Code"
          value={companyCode}
          onChanged={(text) => onFieldChange("CompanyCode", text)}
          sx={{ minWidth: "120px" }}
          size="small"
        />
        <LabelInput
          key="branch_code"
          name="branch_code"
          label="Branch Code"
          value={branchCode}
          onChanged={(text) => onFieldChange("BranchCode", text)}
          sx={{ minWidth: "120px" }}
          size="small"
        />
        <LabelInput
          key="company_name"
          name="company_name"
          label="Company Name"
          value={companyName}
          onChanged={(text) => onFieldChange("CompanyName", text)}
          sx={{ minWidth: "120px" }}
          size="small"
        />
      </Stack>
      <Stack
        direction="row"
        sx={{
          mt: 1,
          width: "100%",
          gap: "0.5rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Button variant="outlined" onClick={handleOnApply}>
          Apply
        </Button>
        <Button variant="outlined" onClick={handleOnReset}>
          Reset
        </Button>
      </Stack>
    </Box>
  );
};

const getColumnDef = (handleAction: ActionCB): MRT_ColumnDef<JobDetail>[] => [
  {
    accessorKey: "companyName",
    header: "Company",
    size: 180,
  },
  {
    accessorKey: "PECode",
    header: "CE Code",
    size: 150,
  },
  {
    accessorKey: "next_action",
    header: "Job",
    size: 180,
  },
  {
    accessorKey: "file_type",
    header: "File Type",
    size: 150,
  },
  {
    accessorKey: "timestamp",
    header: "Time Stamp",
    size: 150,
  },
  {
    accessorKey: "PEStatus",
    header: "Action",
    size: 130,
    Cell: ({ cell, row }) => (
      <Button onClick={() => handleAction("pe-status", row.original)}>CE Status</Button>
    ),
  },
];
type JobHistoryListProps = {
  minHeight: number;
  maxHeight: number;
  data: JobDetail[];
  onAction: (act: string, data: unknown) => void;
};

export const JobHistoryList: FC<JobHistoryListProps> = ({
  data,
  onAction,
  minHeight,
  maxHeight,
}) => {
  const columns = useMemo<MRT_ColumnDef<JobDetail>[]>(() => getColumnDef(onAction), [onAction]);
  // const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  return (
    <Paper>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        // enableRowSelection
        getRowId={(jd, index: number, parentRow: MRT_Row<JobDetail>) => jd.PECode ?? ""}
        // onRowSelectionChange={setRowSelection}
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        // state={{ rowSelection }}
        muiTableContainerProps={{ sx: { height: 500 } }}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};
