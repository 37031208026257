import { mainApi } from "./base";

import type { ActionResult } from "api";

export type MailTemplate = {
  subject: string;
  content: string;
  mailType: string;
};

export type SendMailRequest = {
  subject: string;
  content: string;
  targetGroup?: string[];
  targetPE?: string[];
  workflowType?: string;
  targetStep?: string;
  targetStatus?: string;

  // バックエンドでは使用されてない可能性あり
  remindType?: number;
  remindCount?: number;
  remindInterval?: number;
  fiscalYear?: string;
};

export const mailApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMailTemplateList: builder.query<{ mailTypeList: MailTemplate[] }, void>({
      query: () => ({
        url: "mail/list",
      }),
      providesTags: ["MailTemplate"],
    }),
    saveMailTemplate: builder.mutation<MailTemplate, MailTemplate>({
      query: (body) => ({
        url: "mail",
        body,
        method: "POST",
      }),
      invalidatesTags: ["MailTemplate"],
    }),
    sendMail: builder.mutation<ActionResult, SendMailRequest>({
      query: (body) => ({
        url: "mail/send",
        body,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetMailTemplateListQuery, useSaveMailTemplateMutation, useSendMailMutation } =
  mailApi;
