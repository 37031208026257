import { mainApi } from "./base";

type Response = {
  success: boolean;
};

interface ReportFilter {
  filters: any[];
  name: string;
}
const reportApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportList: builder.query<
      {
        success: boolean;
        report_list: string[];
      },
      void
    >({
      query: () => ({
        url: "report/list",
      }),
    }),
    getReportFilterList: builder.query<ReportFilter[], void>({
      query: () => ({
        url: "report/filterList",
      }),
      providesTags: ["ReportFilter"],
    }),
    getReportFilterOptions: builder.query<any[], void>({
      query: () => ({
        url: "report/filterOptions",
      }),
    }),
    updateReportFilter: builder.mutation<Response, ReportFilter>({
      query: (body) => ({
        url: "report/updateFilter",
        body,
        method: "POST",
      }),
      invalidatesTags: ["ReportFilter"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportFilterListQuery,
  useGetReportFilterOptionsQuery,
  useGetReportListQuery,
  useUpdateReportFilterMutation,
} = reportApi;
