import { isJob } from "util/type-guard";

import { Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { isApiFailedError } from "services/common/type-guard";
import { useStartCheckProcessMutation } from "services/data";
import { useListJobsQuery } from "services/job";
import { useLazySearchPEQuery } from "services/pe";

import { JobAttributeEnum, JobType, PEStatus } from "../../api/api";
import { defs, POLLING_INTERVAL } from "../../common/const_defs";
import { AdcTitle1, AdcTitle2 } from "../../components/atoms/AdcTitle";
import { useDlgCheckResult, useDlgPeWfStatus } from "../../hooks/DialogContext";

import { JobInProgressList, JobNotStartList, JobDoneList } from "./components/JobListCard";
import { JobDialog, JobDialogType } from "./JobDialog";

import type { JobDialogInstance } from "./JobDialog";
import type { Job, PE } from "../../api/api";
import type { ActionCB } from "../../common/const_defs";

const JobListPage = () => {
  const navigate = useNavigate();
  const refJobDlg = useRef<JobDialogInstance | null>(null);
  const { openCheckResult } = useDlgCheckResult();
  const { openPeWfStatus } = useDlgPeWfStatus();
  const { enqueueSnackbar } = useSnackbar();
  // const [aryTest, setAryTest] = useState<string[]>([]);

  const { data: inProgressJobListRes } = useListJobsQuery(
    { type: JobType.InProgress },
    {
      pollingInterval: POLLING_INTERVAL,
    }
  );
  const { data: notStartedJobListRes } = useListJobsQuery(
    { type: JobType.NotStarted },
    {
      pollingInterval: POLLING_INTERVAL,
    }
  );
  const { data: doneJobListRes } = useListJobsQuery(
    { type: JobType.Done },
    {
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const [searchPE] = useLazySearchPEQuery();
  const [startCheckProcess] = useStartCheckProcessMutation();

  const handleAction: ActionCB = async (act, vd) => {
    if (act === defs.kAct_JobDataInit && isJob(vd)) {
      let peList: PE[] | undefined;
      try {
        const res = await searchPE({
          status: vd.attribute === JobAttributeEnum.Submit ? "INPUT_WAITING" : "APPROVE_WAITING",
          workflowType: vd.workflowType,
          workflowStep: vd.workflowStep,
          fiscalYear: vd.fiscalYear,
          fixVersion: vd.fixVersion,
        }).unwrap();
        peList = res.PEList;
      } catch (error) {
        enqueueSnackbar("Get CE list failed", { variant: "warning" });
        return;
      }
      const newJob = {
        ...vd,
        JobDetails:
          peList?.map((pe) => ({
            PECode: pe.PECode,
            companyName: pe.companyName,
            PEStatus: PEStatus.NotStarted,
          })) ?? [],
      };
      // setCursorJob(newJob);
      refJobDlg.current?.openJobDialog(JobDialogType.kStart, newJob);
    } else if (act === "start-check-process") {
      if (!isJob(vd)) {
        return;
      }
      try {
        await startCheckProcess({
          jobId: vd.id,
        }).unwrap();
        enqueueSnackbar("Request is queued. Please wait a few minutes for processing", {
          variant: "success",
        });
        openCheckResult((vd as Job).id, handleAction);
      } catch (error) {
        if (isApiFailedError(error)) {
          enqueueSnackbar(error.data.message, { variant: "error" });
          return;
        }
        enqueueSnackbar("Failed start check process.", { variant: "error" });
      }
    } else if (act === "open-check-result") {
      openCheckResult((vd as Job).id, handleAction);
    } else if (act === defs.kAct_JobCheckData) {
      alert("assert:job-check-data");
    } else if (act === defs.kAct_JobProcess && isJob(vd)) {
      refJobDlg.current?.openJobDialog(JobDialogType.kStart, vd);
    } else if (act === defs.kAct_ViewPeStatus) {
      const peCode = vd as string;
      openPeWfStatus(peCode, handleAction);
    } else if (act === "history") {
      const jobData = vd as Job;
      navigate(`/job/history?jobId=${jobData.id}`);
    } else {
      alert(act);
    }
    // toast(
    //   `This is a big toast for ${act}.\n\nIt's larger than you expected.`,
    //   {
    //     duration: 3000,
    //   }
    // );
  };

  // const handleMultiSelectChanged = (newData: SelectNode[]) => {
  //   const ary = newData.map(d => d.value);
  //   setAryTest(ary);
  //   <CommonMultiSelect
  //   value={aryTest}
  //   options={kOpts_FiscalYear}
  //   onChanged={handleMultiSelectChanged}
  //   sxx={{ minWidth: 150 }}
  // />
  // }

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ p: 2, fontSize: "12px" }}
        spacing={2}
      >
        <Grid xs={12} sm={12}>
          <AdcTitle1 sx={{ mt: 0 }}>Action Required</AdcTitle1>
        </Grid>
        <Grid xs={12} sm={12} sx={{ px: 3 }}>
          <AdcTitle2>In Progress</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ px: 3 }}>
          <JobInProgressList
            jobs={inProgressJobListRes?.jobs ?? []}
            onAction={handleAction}
            maxHeight={300}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ px: 3 }}>
          <AdcTitle2>Not Started</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ px: 3 }}>
          <JobNotStartList jobs={notStartedJobListRes?.jobs ?? []} onAction={handleAction} />
        </Grid>
        <Grid xs={12} sm={12}>
          <AdcTitle1 sx={{ mt: 2 }}>Done</AdcTitle1>
        </Grid>
        <Grid xs={12} sm={12} sx={{ px: 3 }}>
          <JobDoneList jobs={doneJobListRes?.jobs ?? []} onAction={handleAction} />
        </Grid>
      </Grid>
      <JobDialog ref={refJobDlg} onAction={handleAction} />
    </Paper>
  );
};

export default JobListPage;
