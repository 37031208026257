import { mainApi } from "./base";

import type {
  FixDataInfo,
  WorkflowAddData,
  WorkflowDelVerData,
  WorkflowFileType,
  WorkflowStatusData,
} from "common/type_defs";

/**
 * 型情報があっているか分からないがAPIのレスポンスはこうなってた。
 */
export type Workflow = {
  workflowType: string;
  id: string;
  workflowStatus: string;
  actions: {
    detailAction: boolean;
    notifyAction: boolean;
    openAction: boolean;
    closeAction: boolean;
    reopenAction: boolean;
    deleteAction: boolean;
    reportAction: boolean;
  };
  submittingFileCategory?: string;
  fileTypes?: string[];
};

export const workflowApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowFileType: builder.query<
      WorkflowFileType[],
      { workflowType: string; workflowStep: string }
    >({
      query: (params) => ({
        url: "workflow/fileType",
        params,
        providesTags: ["Workflow"],
      }),
    }),
    getWorkflowList: builder.query<Workflow[], { fiscalYear: number; workflowType?: string }>({
      query: ({ fiscalYear, ...params }) => ({
        url: `workflow/${fiscalYear}`,
        params,
        providesTags: ["Workflow"],
      }),
    }),
    getWorkflowTypeList: builder.query<string[], { wfType?: string; status?: string }>({
      query: (params) => ({
        url: `workflow/list`,
        params,
      }),
    }),
    getWorkflowStep: builder.query<string[], { workflowType: string }>({
      query: (params) => ({
        url: `workflow/step`,
        params,
      }),
    }),
    createSubmittingFileWorkflow: builder.mutation<{ success: boolean }, WorkflowAddData>({
      query: (body) => ({
        url: "workflow/createSubmittingFileWorkflow",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Workflow"],
    }),
    createFixWorkflowVersion: builder.mutation<{ success: boolean }, FixDataInfo>({
      query: (body) => ({
        url: "workflow/createFixVersion",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Workflow"],
    }),
    deleteFixWorkflowVersion: builder.mutation<{ success: boolean }, WorkflowDelVerData>({
      query: (body) => ({
        url: "workflow/deleteFixVersion",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Workflow"],
    }),
    updateWorkflowStatus: builder.mutation<{ success: boolean }, WorkflowStatusData>({
      query: (body) => ({
        url: "workflow/updateStatus",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Workflow"],
    }),
    getFixVersions: builder.query<string[], { fiscalYear: number }>({
      query: (params) => ({
        url: "workflow/versions",
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWorkflowFileTypeQuery,
  useGetWorkflowListQuery,
  useGetWorkflowStepQuery,
  useGetWorkflowTypeListQuery,
  useCreateFixWorkflowVersionMutation,
  useCreateSubmittingFileWorkflowMutation,
  useDeleteFixWorkflowVersionMutation,
  useUpdateWorkflowStatusMutation,
  useGetFixVersionsQuery,
} = workflowApi;
