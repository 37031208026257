import { Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useGenerateDownloadURLMutation } from "services/file";
import { useGetPEHistoryQuery } from "services/pe";

import { AdcTitle2 } from "../../components/atoms/AdcTitle";
import ModalDialog from "../../components/molecular/ModalDialog";
import { downloadFile } from "../../util/utils";

import { PeHistoryList } from "./components/PeHistoryList";

import type { PEHistory, ActionCB } from "../../common/type_defs";
import type { FC } from "react";

type PeWfStatusProps = {
  open: boolean;
  peCode: string;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const PeWfStatusDialog: FC<PeWfStatusProps> = ({ peCode, open, onClose, onAction }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [generateDownloadURL] = useGenerateDownloadURLMutation();
  const { data: peHistory } = useGetPEHistoryQuery({ peCode }, { skip: open === false });

  const handleCancel = () => {
    onClose("close");
  };
  const handleAction: ActionCB = async (act: string, vd: unknown) => {
    if (act === "download") {
      try {
        const data: PEHistory = vd as PEHistory;
        const joken = {
          methodType: "download",
          fileName: data.filePath,
          category: "WORKFLOW",
        } as any; // FIXME: おそらくこの条件ではエラーになると思うけど。古いままなのか、要確認。
        let url: string;
        try {
          const generateDownloadURLResponse = await generateDownloadURL(joken).unwrap();
          url = generateDownloadURLResponse.url;
        } catch (error) {
          enqueueSnackbar("Failed to download.", { variant: "error" });
          return;
        }
        downloadFile(url, data.filePath as string);
        // const urlInfo = await fetch_generate_presigned_url(token, joken);
        // if (urlInfo.url) {
        //   downloadFile(urlInfo.url, data.filePath as string);
        // } else {
        //   enqueueSnackbar("presign download url error!", { variant: "error" });
        // }
      } catch (e) {}
    } else {
      onAction(act, vd);
    }
  };

  return (
    <ModalDialog
      open={open}
      title="CE Workflow Status"
      content={
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} sx={{}}>
            <AdcTitle2>Workflow Status</AdcTitle2>
          </Grid>
          <Grid xs={12} sm={12} sx={{ mx: 2 }}>
            <Stack
              direction="row"
              sx={{
                mt: 0,
                width: "100%",
                gap: "1rem",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <AdcTitle2>CE code</AdcTitle2>
              <Typography variant="body2">{peCode}</Typography>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <PeHistoryList data={peHistory ?? []} onAction={handleAction} isLoading={false} />
          </Grid>
        </Grid>
      }
      buttons={
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Close
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="large"
    />
  );
};

export default PeWfStatusDialog;
export { PeWfStatusDialog };
