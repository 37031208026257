import { Paper, Typography, Button, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

const kTxt_HeaderAdc = "■ For other users";
const kTxt_ButtonAdc = "Go to ADC ID Sign in";
const CorpSignInEntry = () => {
  const navigate = useNavigate();

  const handleSignIn = async () => {
    navigate("/login/adc");
  };

  return (
    <Paper sx={{ p: 5 }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12}>
          <Typography variant="h5" sx={{ mt: 2 }}>
            {kTxt_HeaderAdc}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ m: 5, px: 2 }}>
          <Button
            fullWidth
            onClick={handleSignIn}
            variant="contained"
            color="primary"
            size="large"
            sx={{ my: 2 }}
          >
            {kTxt_ButtonAdc}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, px: 2 }} />
    </Paper>
  );
};

export default CorpSignInEntry;
export { kTxt_HeaderAdc, kTxt_ButtonAdc };
