import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";

import type { PaperProps } from "@mui/material/Paper";
import type { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import type { FC, ReactElement, SyntheticEvent } from "react";
import type { ResizeCallbackData } from "react-resizable";

import "react-resizable/css/styles.css";

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const BootstrapDialogTitle: FC<{
  children: ReactElement | ReactElement[] | string;
  onClose?: () => void;
}> = ({ children, onClose, ...other }) => (
  <DialogTitle
    id="draggable-dialog-title"
    sx={{ m: 0, px: 2, py: 1, backgroundColor: "#f0f0f0" }}
    {...other}
  >
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
);

type DialogSize = "small" | "medium" | "large";

const sizeMap: { [K in DialogSize]: { width: number; height: number } } = {
  small: {
    width: 500,
    height: 500,
  },
  medium: {
    width: 800,
    height: 550,
  },
  large: {
    width: 1100,
    height: 600,
  },
};

type ModalDialogProps = {
  open: boolean;
  title?: ReactElement | ReactElement[] | string;
  content: ReactElement | ReactElement[] | string;
  sxx?: SxProps;
  buttons?: ReactElement | ReactElement[];
  onClose: (id: string) => void;
  size: DialogSize;
};
const ModalDialog: FC<ModalDialogProps> = ({
  open,
  title,
  content,
  sxx,
  buttons,
  onClose,
  size,
}) => {
  const [width, setWidth] = useState<number>(sizeMap[size].width);
  const [height, setHeight] = useState<number>(sizeMap[size].height);
  const handleClose = (id: string): void => {
    if (onClose) {
      onClose(id);
    }
  };

  const handleMouseResize = (evt: SyntheticEvent, data: ResizeCallbackData) => {
    const deltaY = parseInt(Reflect.get(evt, "movementY"));
    const deltaX = parseInt(Reflect.get(evt, "movementX"));
    setHeight(height + deltaY);
    setWidth(width + deltaX);
  };
  const getTitle = (): ReactElement => {
    if (!title) {
      return <>Dialog</>;
    }
    if (typeof title === "string") {
      return <>{title}</>;
    }
    if (typeof title === "object" && "type" in title && "props" in title) {
      return title;
    }
    return <>{title}</>;
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth={false}
    >
      <Resizable height={height} width={width} onResize={handleMouseResize}>
        <>
          <BootstrapDialogTitle onClose={() => handleClose("sys-close")}>
            {getTitle()}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Box sx={sxx} height={`${height}px`} width={`${width}px`}>
              {content}
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: "1.25rem", py: 1, backgroundColor: "#f0f0f0" }}>
            {buttons || (
              <Button
                color="secondary"
                onClick={() => handleClose("btn-close")}
                variant="contained"
              >
                Close
              </Button>
            )}
          </DialogActions>
        </>
      </Resizable>
    </Dialog>
  );
};

export default ModalDialog;
