/* eslint-disable react/jsx-props-no-spreading */
import { RadioGroup } from "@mui/material";
import { useCallback } from "react";
import { useController } from "react-hook-form";

import type { RadioGroupProps } from "@mui/material";
import type { ReactElement } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

type AdcRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  children: ReactElement | ReactElement[] | string;
  /** Settings for MUI elements */
  muiProps?: {
    /**
     * Settings for Checkbox inside AdcCheckbox
     *
     * API: {@link https://mui.com/material-ui/api/radio-group/}
     */
    radioGroupProps?: Omit<RadioGroupProps, "onChange">;
  };
};

/**
 * MUI Checkbox component linked to React Hook Form.
 * Type arguments are optional, but specifying them provides powerful type checking and type inference.
 * @typeParam TFieldValues - Type of the form.
 * @typeParam TName - Field name.
 * @example
 * ```
 *
 * <AdcRadioGroup<FormData, 'rg_mode'>
 *   name='rg_mode'
 *   control={control}
 *   rules={{
 *     mandatory_field: 'Check the box'
 *   }}
 * />
 * ```
 */
export const AdcRadioGroup: <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: AdcRadioGroupProps<TFieldValues, TName>
) => ReactElement = (props) => {
  const { muiProps, children, ...others } = props;
  const { radioGroupProps } = muiProps ?? {};
  const { field, fieldState } = useController(others);

  const onChangeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const v = (ev.target as HTMLInputElement).value;
      field.onChange(parseInt(v, 10) as any);
    },
    [field]
  );

  return (
    <RadioGroup {...radioGroupProps} value={field.value} onChange={onChangeHandler}>
      {children}
    </RadioGroup>
  );
};
