import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { JobAttributeEnum } from "api";
import { kObj_NullJob } from "common/const_defs";
import { useSnackbar } from "notistack";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useStartCheckProcessMutation } from "services/data";
import { useGetJobQuery } from "services/job";

import ModalDialog from "../../components/molecular/ModalDialog";
import JobPanelUpload from "../job/components/JobActionUploadPanel";

import { CheckResultPanel } from "./components/CheckResultPanel";

import type { ActionCB, Job } from "../../common/type_defs";
import type { VerbInstance } from "../../components/atoms/CommonInstance";
import type { JobDialogInstance } from "../job/JobDialog";

type CheckResultWmRoleDialogProps = {
  open: boolean;
  jobId: string;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};

const CheckResultWmRoleDialog = forwardRef<VerbInstance, CheckResultWmRoleDialogProps>(
  ({ open, jobId, onClose, onAction }, ref) => {
    useImperativeHandle(ref, () => ({
      doVerb,
    }));
    const [fileUploadFlag, setFileUploadFlag] = useState(false);
    const refJobDlg = useRef<JobDialogInstance | null>(null);
    const [workJob, setWorkJob] = useState<Job>(kObj_NullJob);
    const [startCheckProcess] = useStartCheckProcessMutation();
    const { enqueueSnackbar } = useSnackbar();

    const { data: jobData, isSuccess: isGetJobSuccess } = useGetJobQuery(
      { jobId },
      { skip: jobId === undefined }
    );
    // TODO jobDataが更新されたときにのみworkJobを更新。useEffectなしだと無限ループでエラー。この解決法で良いか確認
    useEffect(() => {
      if (isGetJobSuccess && jobData !== undefined) {
        setWorkJob(jobData);
      }
    }, [jobData, isGetJobSuccess]);

    const doVerb = (act: string, vd: unknown): unknown => {
      if (act === "get-selected") {
        return null;
      }
      if (act === "update-sel") {
        return null;
      }
      return undefined;
    };

    const handleAction: ActionCB = (act, vd) => {
      onAction(act, vd);
    };

    const handleSetFileUploadFlag = () => {
      setFileUploadFlag(true);
    };

    const handleClickStartCheckProcess = async () => {
      try {
        await startCheckProcess({ jobId: workJob.id }).unwrap();
        enqueueSnackbar("Start check process success", { variant: "success" });
        setFileUploadFlag(false);
      } catch (error) {
        enqueueSnackbar("Start check process failed", { variant: "warning" });
      }
    };

    const isDisableFileUpload = () =>
      !(
        isGetJobSuccess &&
        jobData !== undefined &&
        jobData.attribute === JobAttributeEnum.Submit &&
        jobData.type === "IN_PROGRESS"
      );

    if (fileUploadFlag) {
      if (!isDisableFileUpload()) {
        return (
          <ModalDialog
            open={open}
            title="Workflow Job Step1"
            content={<JobPanelUpload job={workJob} setJob={setWorkJob} onAction={onAction} />}
            buttons={
              <Grid container spacing={2} sx={{ pl: 7, pr: 5, gap: 2 }}>
                <Grid>
                  <Button variant="contained" onClick={handleClickStartCheckProcess}>
                    Start Check Process
                  </Button>
                </Grid>
                <Grid>
                  <Button variant="contained" onClick={() => setFileUploadFlag(false)}>
                    Back
                  </Button>
                </Grid>
              </Grid>
            }
            onClose={() => onClose("close")}
            size="large"
          />
        );
      }
    }

    return (
      <ModalDialog
        open={open}
        title="Item Check Result"
        content={<CheckResultPanel jobId={jobId} onAction={onAction} />}
        buttons={
          <Grid container spacing={2} sx={{ pl: 7, pr: 5, gap: 2 }}>
            <Grid>
              <Button
                variant="contained"
                disabled={isDisableFileUpload()}
                onClick={() => {
                  handleSetFileUploadFlag();
                }}
              >
                Go to File Upload
              </Button>
            </Grid>
            <Grid>
              <Button variant="contained" onClick={() => onAction("check-result-close")}>
                Close
              </Button>
            </Grid>
          </Grid>
        }
        onClose={() => onClose("close")}
        size="large"
      />
    );
  }
);

export default CheckResultWmRoleDialog;
export { CheckResultWmRoleDialog };
