import { Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import { MaterialReactTable } from "material-react-table";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useMasterHistoryDialog } from "render-hooks/useMasterHistoryDialog";
import { isApiFailedError } from "services/common/type-guard";
import {
  useGenerateUploadURLMutation,
  usePrepareAndDownloadFileMutation,
  usePrepareFileMutation,
} from "services/file";
import { useGetMasterListQuery } from "services/master";

import { FileUploadButton } from "../../components/molecular/FileBrowseUpload";
import { useDlgMailSender } from "../../hooks/DialogContext";
import { uploadFile } from "../../util/utils";

import type { ActionCB, MasterInfo } from "../../common/type_defs";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC } from "react";

const getColumnDef = (onAction: ActionCB): MRT_ColumnDef<MasterInfo>[] => {
  const handleFileSelected = async (file: File) => {
    onAction("upload", file);
  };
  return [
    {
      accessorKey: "name",
      header: "Name",
      size: 150,
    },
    {
      accessorKey: "latestUpdated",
      header: "Latest Update Date",
      size: 120,
      // Cell: ({ cell, row }) => (
      //   <Typography variant="body2">
      //     {dayjs(row.original.lastModified).format("YYYY-MM-DD")}
      //   </Typography>
      // ),
    },
    {
      accessorKey: "id",
      header: "Action",
      Cell: ({ cell, row }) => (
        <>
          <FileUploadButton
            multi={false}
            size="small"
            color="primary"
            variant="outlined"
            aria-label="Upload"
            // onFileSelected={handleFileSelected}
            onFileSelected={(file: File) => onAction("upload", { ...row.original, file })}
          >
            Upload
          </FileUploadButton>

          {row.original.name === "CE Master" && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              aria-label="Preset"
              onClick={() => onAction("preset", row.original)}
              sx={{ ml: 1, minWidth: 35 }}
            >
              Preset
            </Button>
          )}

          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="Download"
            onClick={() => onAction("download", row.original)}
            sx={{ ml: 1, minWidth: 35 }}
          >
            Download
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="Upload request"
            onClick={() => onAction("upload-request", row.original)}
            sx={{ ml: 1, minWidth: 35 }}
          >
            Upload request
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-label="Open history"
            onClick={() => onAction("open-history", row.original)}
            sx={{ ml: 1, minWidth: 35 }}
          >
            History
          </Button>
        </>
      ),
    },
  ];
};
type MasterInfoListProps = {
  isLoading: boolean;
  data: MasterInfo[];
  onAction: ActionCB;
};

const MasterInfoList: FC<MasterInfoListProps> = ({ data, onAction, isLoading }) => {
  const columns = useMemo<MRT_ColumnDef<MasterInfo>[]>(() => getColumnDef(onAction), [onAction]);

  return (
    <Paper elevation={0} sx={{ pl: 5, pr: 2 }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        getRowId={(jd, index: number, parentRow: MRT_Row<MasterInfo>) => jd.id ?? ""}
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        state={{ isLoading }}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

const MasterManagementPage = () => {
  const { openMailSender } = useDlgMailSender();
  const { enqueueSnackbar } = useSnackbar();
  const { openMasterHistoryDialog, renderMasterHistoryDialog } = useMasterHistoryDialog();

  const [prepareFile] = usePrepareFileMutation();
  const [prepareAndDownloadFile] = usePrepareAndDownloadFileMutation();
  const [generateUploadURL] = useGenerateUploadURLMutation();
  const { data: masterList } = useGetMasterListQuery();

  const handleAction = async (act: string, vd: unknown) => {
    if (act === "upload-request") {
      // const wf = vd as Workflow;
      const params = {
        // workflowStatus: WorkflowStatus.Close,
        // workflowType: WorkflowType.Master,
        workflowType: "MASTER",
        // fiscalYear,
      };
      openMailSender(params, handleAction);
    } else if (act === "upload") {
      const masterInfo = vd as MasterInfo;
      const file = masterInfo.file as File;

      let url;
      let fields;
      try {
        const generateUploadURLResponse = await generateUploadURL({
          fileName: file.name,
          category: "MASTER",
          fileType: masterInfo.fileType,
        }).unwrap();
        url = generateUploadURLResponse.url;
        fields = generateUploadURLResponse.fields;
      } catch (error) {
        enqueueSnackbar("Failed to generate upload url.", { variant: "error" });
        return;
      }

      const rst = await uploadFile(url, file, fields);
      // if (!rst) {
      // const rst = await uploadFile(urlInfo.url, file);
      if (!rst.success) {
        enqueueSnackbar("upload file error!", { variant: "error" });
      } else {
        enqueueSnackbar("upload file success!", { variant: "success" });
      }
    } else if (act === "preset") {
      const { fileType } = vd as { fileType: string; name: string };

      try {
        await prepareFile({
          fileType,
          presetType: "NEW",
        }).unwrap();
        enqueueSnackbar(`Succeeded to prepare file.`, { variant: "success" });
      } catch (error) {
        if (isApiFailedError(error)) {
          enqueueSnackbar(error.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to prepare file.", { variant: "error" });
        }
      }
    } else if (act === "download") {
      const { fileType } = vd as { fileType: string; name: string };

      prepareAndDownloadFile({
        prepareRequest: { fileType },
        generateDownloadURLRequest: {
          category: "MASTER",
          fileName: (vd as MasterInfo).name,
          fileType,
        },
      }).catch();
    } else if (act === "open-history") {
      const { fileType } = vd as MasterInfo;
      if (fileType === undefined) {
        enqueueSnackbar("Unexpected error", { variant: "error" });
        return;
      }
      openMasterHistoryDialog(fileType);
    }
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ p: 2, fontSize: "12px" }}
        spacing={2}
      >
        <Grid xs={12} sm={12}>
          <Typography variant="h4" sx={{ color: "text.secondary" }}>
            Master Data Management
          </Typography>
        </Grid>
        <Grid xs={12} sm={12}>
          <MasterInfoList data={masterList ?? []} onAction={handleAction} isLoading={false} />
        </Grid>
      </Grid>
      {renderMasterHistoryDialog()}
    </Paper>
  );
};

export default MasterManagementPage;
