import { mainApi } from "./base";

import type { CheckResultDetailNode, CheckResultNode } from "common/data_defs";

export const dataApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getProcessResult: builder.query<
      CheckResultNode[] | CheckResultDetailNode[],
      { filter: { level: "DP" | "PE" } & Record<string, unknown> }
    >({
      query: ({ filter }) => ({
        url: "data/getProcessResult",
        params: {
          ...filter,
        },
      }),
      providesTags: ["ProcessResult"],
      extraOptions: {
        maxRetries: 3,
      },
    }),
    startCheckProcess: builder.mutation<void, { jobId: string }>({
      query: (body) => ({
        url: "data/startCheckProcess",
        body,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetProcessResultQuery, useStartCheckProcessMutation } = dataApi;
