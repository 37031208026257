import { mainApi } from "./base";

export const constApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountryList: builder.query<{ countryList: string[] }, void>({
      query: () => ({
        url: "const/countryList",
      }),
    }),
    getWorkflowStatusList: builder.query<string[], void>({
      query: () => ({
        url: "const/workflowStatus",
      }),
    }),
    getActionList: builder.query<{ actionList: { name: string; displayName: string }[] }, void>({
      query: () => ({
        url: "const/actionList",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetActionListQuery, useGetCountryListQuery, useGetWorkflowStatusListQuery } =
  constApi;
