import type { FC, ReactElement } from "react";

type NoneWrapperProps = {
  show: boolean;
  children: ReactElement | ReactElement[] | string;
};
const NoneWrapper: FC<NoneWrapperProps> = ({ show, children }) => {
  if (!show) {
    return <></>;
  }
  return <>{children}</>;
};

export { NoneWrapper };
