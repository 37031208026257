import { Button, Paper, Typography, TextField, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { POLLING_INTERVAL } from "common/const_defs";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import {
  useGetReportFilterListQuery,
  useGetReportFilterOptionsQuery,
  useGetReportListQuery,
  useUpdateReportFilterMutation,
} from "services/report";

import { CommonSelect } from "../../../components/molecular/CommonSelect";
import FilterItemPanel from "../../../components/molecular/FilterItemsPanel";

import type { SelectNode, CondtItemData } from "../../../common/type_defs";
import type { FC } from "react";

const createSelectionFromTemplate = (template: { filters: any[]; name: string }): SelectNode => ({
  ...template,
  label: template.name,
  value: template.name,
});

type ReportExtractProps = {
  setReportType: (reportType: string) => void;
  items: any;
  setItems: any;
  reportType: string;
};

const ReportExtractPanel: FC<ReportExtractProps> = ({
  setReportType,
  items,
  setItems,
  reportType,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initializedRef = useRef(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [selectedFilterTemplate, setSelectedFilterTemplate] = useState<SelectNode>({
    value: "",
    label: "Loading",
  }); // [{value: "key", label: "displayName"}

  const { data: reportListData } = useGetReportListQuery();
  const { data: filterList } = useGetReportFilterListQuery(undefined, {
    pollingInterval: POLLING_INTERVAL,
  });
  const { data: filterOptions } = useGetReportFilterOptionsQuery();
  const [updateFilter] = useUpdateReportFilterMutation();
  const reportTypeSelectionList: SelectNode[] = useMemo(
    () =>
      reportListData?.report_list.map((reportType) => ({
        value: reportType,
        label: reportType,
      })) ?? [],
    [reportListData]
  );
  const filterTemplateSelectionList: SelectNode[] = useMemo(
    () => filterList?.map(createSelectionFromTemplate) ?? [],
    [filterList]
  );

  const [isFilterValid, setIsFilterValid] = useState(true);

  useEffect(() => {
    if (initializedRef.current === false && filterList !== undefined) {
      initializedRef.current = true;
      setSelectedFilterTemplate(createSelectionFromTemplate(filterList[0]));
      setItems(filterList[0].filters);
    }
  }, [filterList, setItems]);

  const hasMultipleFiscalYears = useCallback((items: CondtItemData[]) => {
    const fiscalYearItems = items.filter((item) => item.key === "fiscal_year");
    return fiscalYearItems.length > 1;
  }, []);
  const hasFixVersion = useCallback((items: CondtItemData[]) => {
    const fixVersionItems = items.filter((item) => item.key === "fix_version");
    return fixVersionItems.length > 0;
  }, []);
  const onItemChanged = useCallback(
    (items: CondtItemData[]) => {
      setItems(items);
      const isValid = !(hasMultipleFiscalYears(items) && hasFixVersion(items));
      if (isValid !== isFilterValid) {
        setIsFilterValid(isValid);
      }
    },
    [hasFixVersion, hasMultipleFiscalYears, isFilterValid, setItems]
  );

  const handleTemplateChanged = (nv: SelectNode) => {
    // console.log(nv);
    setSelectedFilterTemplate(nv);
    const filter = filterList?.find((v: any) => v.name === nv.value);
    // console.log(filter)
    if (filter) {
      setItems(filter.filters);
    }
  };

  const handleSaveButtonClicked = async () => {
    try {
      await updateFilter({ name: templateName, filters: items }).unwrap();
      enqueueSnackbar("Save success", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Save failed", { variant: "error" });
    }
  };

  const handleDownloadTypeChanged = (nv: SelectNode) => {
    // setFileType(nv);
    setReportType(nv.value);
  };

  const handleCancel = () => {};
  const handleCreate = () => {
    alert("handleCreate");
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ p: 2, fontSize: "12px" }}
        spacing={2}
      >
        <Grid xs={12} sm={12} sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
            ■ Report Type
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <CommonSelect
            value={{ value: reportType, label: reportType }}
            options={reportTypeSelectionList}
            onChanged={handleDownloadTypeChanged}
            sxx={{ minWidth: 300, maxWidth: 400 }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mt: 3 }}>
          <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
            ■ Filter
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <CommonSelect
            value={selectedFilterTemplate}
            options={filterTemplateSelectionList}
            onChanged={handleTemplateChanged}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <FilterItemPanel
            items={items}
            onChanged={onItemChanged}
            filterOptions={filterOptions ?? []}
          />
          <Typography
            variant="caption"
            sx={{ color: "red", display: isFilterValid ? "none" : "inline" }}
          >
            One or more Fix Version and two or more Fiscal Year cannot be specified at the same
            time.
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
          <Stack
            direction="row"
            sx={{
              gap: "0.5rem",
              alignItems: "center",
              width: "100%",
              display: "flex",
            }}
          >
            <Typography>Save filter conditions as template with the name</Typography>
            <TextField
              id="template_name"
              value={templateName}
              onChange={(e) => {
                setTemplateName(e.target.value);
              }}
              size="small"
              fullWidth
              sx={{ minWidth: 80, flex: 1 }}
            />
            <Button variant="contained" disabled={false} onClick={handleSaveButtonClicked}>
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReportExtractPanel;
