import { isString } from "util/type-guard";

import { Button, Paper } from "@mui/material";
import dayjs from "dayjs";
import { MaterialReactTable } from "material-react-table";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { infoState } from "redux-modules/info";
import { useSendBackJobDialog } from "render-hooks/useSendBackDialog";

import { NoneWrapper } from "../../../components/atoms/NoneWrapper";
import { useDlgCheckResult } from "../../../hooks/DialogContext";

import type { ActionCB, PEHistory } from "../../../common/type_defs";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC } from "react";

const getColumnDef = (
  lastJobId: string,
  onAction: ActionCB,
  handleSendBackClick: (jobId: string, ceCode?: string, workflowType?: string) => void,
  handleCheckResultClick: any
): MRT_ColumnDef<PEHistory>[] => [
  {
    accessorKey: "fiscalYear",
    header: "Year",
    size: 80,
  },
  {
    accessorKey: "fixVersion",
    header: "Version",
    size: 140,
  },
  {
    accessorKey: "workflowType",
    header: "Type",
    size: 200,
  },
  {
    accessorKey: "workflowStep",
    header: "Step",
    size: 180,
  },
  {
    accessorKey: "name",
    header: "JobName",
    size: 150,
  },
  {
    accessorKey: "user",
    header: "Submitter or Approver",
    size: 250,
  },
  {
    accessorKey: "lastModified",
    header: "Last modified",
    size: 200,
  },
  {
    accessorKey: "currentStatus",
    header: "Status",
    size: 180,
  },
  {
    accessorKey: "jobId",
    header: "Action",
    size: 250,
    Cell: ({ cell, row }) => (
      <>
        <Button
          size="small"
          color="primary"
          variant="text"
          aria-label="error information"
          onClick={() => handleCheckResultClick(row.original.jobId)}
          sx={{ m: 0, minWidth: 35 }}
        >
          Check Result
        </Button>
        <NoneWrapper show={!!row.original.filePath}>
          <Button
            size="small"
            color="primary"
            variant="text"
            aria-label="error information"
            onClick={() => onAction("download", row.original)}
            sx={{ m: 0, ml: 1, px: 1, minWidth: 35 }}
          >
            Download
          </Button>
        </NoneWrapper>
      </>
    ),
  },
];
type PeHistoryListProps = {
  isLoading: boolean;
  data: PEHistory[];
  onAction: ActionCB;
};

const PeHistoryList: FC<PeHistoryListProps> = ({ data, onAction, isLoading }) => {
  const [lastJobId, setLastJobId] = useState<string>("");
  const { openCheckResult } = useDlgCheckResult();
  const { openCheckResultWmRole } = useDlgCheckResult();
  const { openSendBackDialog, renderSendBackDialog } = useSendBackJobDialog();

  const { role } = useSelector(infoState);

  const handleSendBackClick = useCallback(
    (jobId: string, ceCode?: string, workflowType?: string) => {
      if (!isString(ceCode) || !isString(workflowType)) {
        return;
      }

      openSendBackDialog({
        targetCEList: [ceCode],
        targetJobId: jobId,
        workflowType,
      });
    },
    [openSendBackDialog]
  );

  const handleCheckResultClick = useCallback(
    (jobId: MRT_Row<PEHistory>) => {
      if (role?.hasWorkflowManagement) {
        openCheckResultWmRole(jobId, () => {});
      } else {
        openCheckResult(jobId, () => {});
      }
    },
    [openCheckResult, openCheckResultWmRole, role?.hasWorkflowManagement]
  );

  const columns = useMemo<MRT_ColumnDef<PEHistory>[]>(
    () => getColumnDef(lastJobId, onAction, handleSendBackClick, handleCheckResultClick),
    [handleCheckResultClick, handleSendBackClick, lastJobId, onAction]
  );

  useEffect(() => {
    let jobId = "";
    let lastDate = dayjs("1999-01-01");
    for (const node of data) {
      const newDate = dayjs(node.lastModified);
      if (newDate > lastDate) {
        jobId = node.jobId;
        lastDate = newDate;
      }
    }
    setLastJobId(jobId);
  }, [data]);

  return (
    <Paper elevation={0} sx={{ px: 2 }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters
        enablePagination={false}
        getRowId={(rd, index: number, parentRow: MRT_Row<PEHistory>) => rd.jobId + rd.workflowStep}
        enableSorting
        enableBottomToolbar={false}
        // enableTopToolbar={false}
        enableRowVirtualization
        state={{ isLoading }}
        muiTableContainerProps={{ sx: { height: 300 } }}
        muiTableBodyRowProps={{ hover: true }}
      />
      {renderSendBackDialog()}
    </Paper>
  );
};

export { PeHistoryList };
