import { Stack, Select, Typography, MenuItem, FormControl } from "@mui/material";

// data
import { findSelNode } from "../../common/const_defs";

import { CommonSelect } from "./CommonSelect";

import type { SelectNode } from "../../common/type_defs";
import type { SelectChangeEvent } from "@mui/material";
import type { FC } from "react";

type LabelSelectProp = {
  label?: string;
  value: SelectNode;
  options: SelectNode[];
  onChanged: (newData: SelectNode) => void;
  [key: string]: unknown;
};
const LabelSelect: FC<LabelSelectProp> = ({ label, value, options, onChanged }) => {
  const handleSelectChange = (ev: SelectChangeEvent<SelectNode>) => {
    if (!ev.target?.value) {
      onChanged({ value: "", label: "<None>" });
    } else {
      onChanged(findSelNode(ev.target?.value as string, options));
    }
  };

  if (label !== undefined) {
    return (
      <Stack direction="column">
        <Typography variant="body2" sx={{ color: "text.secondary", htmlFor: "label-select" }}>
          {label}
        </Typography>
        <Select
          id="label-select"
          value={value}
          onChange={handleSelectChange}
          size="small"
          sx={{ minWidth: 120 }}
        >
          {options.map((option) => (
            <MenuItem
              key={typeof option === "object" ? option.value : option}
              value={typeof option === "object" ? option.value : option}
            >
              {typeof option === "object" ? option.label ?? option.value : option}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    );
  }
  return (
    <FormControl sx={{ m: 0 }} size="small">
      <Select
        id="label-select"
        value={value}
        onChange={handleSelectChange}
        size="small"
        sx={{ minWidth: 120 }}
      >
        {options.map((option) => (
          <MenuItem
            key={typeof option === "object" ? option.value : option}
            value={typeof option === "object" ? option.value : option}
          >
            {typeof option === "object" ? option.label ?? option.value : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type LabelSelectProp2 = {
  label?: string;
  disabled?: boolean;
  value: SelectNode | string;
  options: SelectNode[];
  onChanged: (newData: SelectNode) => void;
  [key: string]: unknown;
};
const LabelSelect2: FC<LabelSelectProp2> = ({
  label,
  disabled,
  value,
  options,
  onChanged,
  ...others
}) => {
  const handleSelectChange = (v: SelectNode) => {
    if (!v) {
      onChanged({ value: "", label: "<None>" });
    } else {
      onChanged(findSelNode(v.value, options));
    }
  };

  if (label !== undefined) {
    return (
      <Stack direction="column">
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {label}
        </Typography>
        <CommonSelect
          id="label-select"
          value={value}
          options={options}
          onChanged={handleSelectChange}
          sxx={{ minWidth: 150 }}
          isDisabled={disabled}
        />
      </Stack>
    );
  }
  return (
    <FormControl sx={{ m: 0 }} size="small">
      <CommonSelect
        id="label-select"
        value={value}
        options={options}
        onChanged={handleSelectChange}
        sxx={{ minWidth: 150 }}
        isDisabled={disabled}
      />
    </FormControl>
  );
};

export { LabelSelect, LabelSelect2 };
