import { useDlgCheckResult, useDlgPeWfStatus, useDlgMailSender } from "../../hooks/DialogContext";
import { PeWfStatusDialog } from "../job/PeWfStatusDialog";

import { CheckResultDialog } from "./CheckResultDialog";
import { CheckResultWmRoleDialog } from "./CheckResultWmRoleDialog";
import { MailSendDialog } from "./MailSendDialog";

const DialogContainer = () => {
  const {
    closeCheckResult,
    state: stateCheckResult,
    closeCheckResultWmRole,
    stateWmRole: stateCheckResultWmRole,
  } = useDlgCheckResult();
  const { closePeWfStatus, state: statePeWfStatus } = useDlgPeWfStatus();
  const { closeMailSender, state: stateMailSender } = useDlgMailSender();

  const handleCloseCheckResult = () => {
    closeCheckResult();
  };
  const handleActionCheckResult = (act: string, vd: unknown) => {
    if (act === "check-result-close") {
      closeCheckResult();
    } else {
      stateCheckResult.onAction(act, vd);
    }
  };
  const handleActionCheckResultWmRole = (act: string, vd: unknown) => {
    if (act === "check-result-close") {
      closeCheckResultWmRole();
    } else {
      stateCheckResultWmRole.onAction(act, vd);
    }
  };

  // pe workflow status dialog
  const handleClosePeWfStatus = () => {
    closePeWfStatus();
  };
  const handleActionPeWfStatus = (act: string, vd: unknown) => {
    statePeWfStatus.onAction(act, vd);
  };
  // mail sender dialog
  const handleCloseMailSender = () => {
    closeMailSender();
  };
  const handleActionMailSender = (act: string, vd: unknown) => {
    stateMailSender.onAction(act, vd);
  };

  return (
    <>
      <CheckResultWmRoleDialog
        open={stateCheckResultWmRole.show}
        jobId={stateCheckResultWmRole.jobId}
        onClose={closeCheckResultWmRole}
        onAction={handleActionCheckResultWmRole}
      />
      <CheckResultDialog
        open={stateCheckResult.show}
        jobId={stateCheckResult.jobId}
        onClose={handleCloseCheckResult}
        onAction={handleActionCheckResult}
      />
      <PeWfStatusDialog
        open={statePeWfStatus.show}
        peCode={statePeWfStatus.peCode}
        onClose={handleClosePeWfStatus}
        onAction={handleActionPeWfStatus}
      />
      <MailSendDialog
        open={stateMailSender.show}
        params={stateMailSender.params}
        onClose={handleCloseMailSender}
        onAction={handleActionMailSender}
      />
    </>
  );
};
//* /

export default DialogContainer;
