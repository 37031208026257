import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSignOut } from "redux-modules/auth";

/**
 * SignOut用の一時的なページ
 * RequireAuth 内のページで `dispatch(setSignOut())` を行うと予期しないAPI呼び出しやエラーの発生するため、このページでログアウト処理を行う。
 */
export const SignOutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSignOut());
    navigate("/login");
  }, [dispatch, navigate]);

  return <p>Logout in progress.</p>;
};
