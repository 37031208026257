import { useSelector } from "react-redux";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";

import { AppContainer } from "./layout/AppContainer";
import MainLayout from "./layout/MainLayout";
import { ErrorPage } from "./pages/error/ErrorPage";
import FileDownloadPage from "./pages/file/FileDownloadPage";
import JobHistoryPage from "./pages/job/JobHistoryPage";
import JobListPage from "./pages/job/JobListPage";
import CallbackPage from "./pages/login/CallbackPage";
import ChangePasswordPage from "./pages/login/ChangePasswordPage";
import ForgetPasswordPage from "./pages/login/ForgetPasswordPage";
import MfaSetupPage from "./pages/login/MfaSetupPage";
import MfaSignInPage from "./pages/login/MfaSignInPage";
import { SignInAdcV2Page } from "./pages/login/SignInAdcV2Page";
import SignInCorpPage from "./pages/login/SignInCorpPage";
import SignInRouterPage from "./pages/login/SignInRouterPage";
import { SignOutPage } from "./pages/login/SignOutPage";
import MasterManagementPage from "./pages/master/MasterManagementPage";
import TestResultPage from "./pages/sign_test/TestResultPage";
import WfManagementPage from "./pages/workflow/WorkflowListPage";
import WorkflowStatusPage from "./pages/workflow/WorkflowStatusPage";
import { authState } from "./redux-modules/auth";

const RequireAuth = ({ children, isAuthenticated }) => {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

const MainRouter = () => {
  const { isAuthenticated } = useSelector(authState);

  return (
    <Routes>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/login/corporate" element={<SignInCorpPage />} />
      <Route path="/login/adc" element={<SignInAdcV2Page />} />
      <Route path="/login" element={<SignInRouterPage />} />
      <Route path="/login/mfa" element={<MfaSignInPage />} />
      <Route path="/login/mfa-setup" element={<MfaSetupPage />} />
      <Route path="/login/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/login/change-password" element={<ChangePasswordPage />} />
      <Route path="/logout" element={<SignOutPage />} />
      <Route path="/result" element={<TestResultPage />} />
      <Route
        element={
          <RequireAuth isAuthenticated={isAuthenticated}>
            <MainLayout />
          </RequireAuth>
        }
      >
        <Route path="/" element={<Navigate to="/job-list" />} />
        <Route path="/job-list" element={<JobListPage />} />
        <Route path="/job/history" element={<JobHistoryPage />} />
        <Route path="/workflow-list" element={<WfManagementPage />} />
        <Route path="/wf-status" element={<WorkflowStatusPage />} />
        <Route path="/file-download" element={<FileDownloadPage />} />
        <Route path="/master-management" element={<MasterManagementPage />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
const App = () => (
  // global

  <AppContainer>
    <MainRouter />
  </AppContainer>
);

export default App;
