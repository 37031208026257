import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "store";

import { DialogContextProvider } from "../hooks/DialogContext";
import { LayoutProvider } from "../hooks/LayoutContext";
import { UserProvider } from "../hooks/UserContext";
import DialogContainer from "../pages/common/DialogContainer";
import themes from "../themes";

import type { ReactElement } from "react";

// eslint-disable-next-line prefer-const
let persistor = persistStore(store);

const AppContainer = ({ children }: { children?: ReactElement | ReactElement[] | string }) => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <UserProvider>
        <DialogContextProvider>
          <LayoutProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes.default}>
                  <CssBaseline />
                  <SnackbarProvider maxSnack={3}>
                    <BrowserRouter>
                      <Toaster />
                      {children}
                      <DialogContainer />
                    </BrowserRouter>
                  </SnackbarProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </LayoutProvider>
        </DialogContextProvider>
      </UserProvider>
    </PersistGate>
  </ReduxProvider>
);

export default AppContainer;
export { AppContainer };
