import { FormControl } from "@mui/material";
import ReactSelect from "react-select";

import type { SelectNode } from "../../common/type_defs";
import type { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import type { FC } from "react";
import type { ActionMeta } from "react-select";

// data

const findSelNode = (value: string | number | SelectNode, ary: SelectNode[]): SelectNode => {
  let key = "";
  if (typeof value === "string") {
    key = value;
  } else if (typeof value === "number") {
    key = `${value}`;
  } else if (value?.value) {
    key = value.value;
  } else {
    key = `${value}`;
  }
  const strKey = `${key}`;
  for (const node of ary) {
    if (node.value === strKey) {
      return { ...node };
    }
  }
  return { value: "", label: "" };
};

type CommonSelectProp = {
  isDisabled?: boolean;
  value: string | number | SelectNode;
  options: SelectNode[];
  onChanged: (newData: SelectNode) => void;
  sxx?: SxProps;
  [key: string]: unknown;
};
const CommonSelect: FC<CommonSelectProp> = ({ value, isDisabled, options, onChanged, sxx }) => {
  const handleSelectChange = (newValue: SelectNode | null, action: ActionMeta<SelectNode>) => {
    if (!newValue) {
      onChanged({ value: "", label: "<None>" });
    } else {
      onChanged(newValue);
    }
  };
  const customStyles = {
    menu: (base: any) => ({
      ...base,
      zIndex: 999,
      width: "320px",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };
  return (
    <FormControl sx={{ m: 0, ...(sxx ?? {}) }} size="small">
      <ReactSelect
        isDisabled={isDisabled}
        options={options}
        menuPortalTarget={document.body}
        value={findSelNode(value, options)}
        onChange={handleSelectChange}
        backspaceRemovesValue
        styles={customStyles}
      />
    </FormControl>
  );
};
type CommonMultiSelectProp = {
  value: (string | number | SelectNode)[];
  options: SelectNode[];
  onChanged: (newData: SelectNode[]) => void;
  sxx?: SxProps;
  [key: string]: unknown;
};
const CommonMultiSelect: FC<CommonMultiSelectProp> = ({ value, options, onChanged, sxx }) => {
  const handleMultiSelectChange = (
    newValue: readonly SelectNode[],
    actionMeta: ActionMeta<SelectNode>
  ) => {
    if (!newValue) {
      onChanged([]);
    } else {
      onChanged([...newValue]);
    }
  };
  const calcValue = () => {
    const ary: SelectNode[] = [];
    for (const v of value) {
      ary.push(findSelNode(v, options));
    }
    return ary;
  };
  const customStyles = {
    menu: (base: any) => ({
      ...base,
      zIndex: 999,
      width: "320px",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };
  return (
    <FormControl sx={{ m: 0, ...(sxx ?? {}) }} size="small">
      <ReactSelect
        options={options}
        menuPortalTarget={document.body}
        isMulti
        value={calcValue()}
        onChange={handleMultiSelectChange}
        backspaceRemovesValue
        styles={customStyles}
      />
    </FormControl>
  );
};

export { CommonSelect, CommonMultiSelect };
