import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Box, Stack, Divider, IconButton, Typography, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";

import { kOpts_FileType, kOpt_SelNode_None, kOpts_WF_Limit } from "../../../common/const_defs";
import { CommonSelect } from "../../../components/molecular/CommonSelect";

import type { SelectNode } from "../../../common/type_defs";
import type { FC, ChangeEvent } from "react";

type FtypeItemsProps = {
  items: SelectNode[];
  onChanged: (ary: SelectNode[]) => void;
};
const FtypeItemsPanel: FC<FtypeItemsProps> = ({ items, onChanged }) => {
  const onDelete = (item: SelectNode) => {
    const ary = items.filter((node) => node.id !== item.id);
    onChanged(ary);
  };
  const onAdd = () => {
    let ary: SelectNode[] = [...items];
    if (!ary) {
      ary = [];
    }
    ary.push({ value: "", label: "" });
    onChanged(ary);
  };

  const handleItemSelChanged = (nv: SelectNode, rowId: string) => {
    // let ary: CondtItemData[] = [...items];
    // for (const node of ary) {
    //   if (node.id === rowId) {
    //     node.def = findCondtItem(nv.value);
    //     break;
    //   }
    // }
    // onChanged(ary)
  };

  return (
    <>
      {items.map((node) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            mb: 1,
            pl: 2,
          }}
          component="ul"
        >
          <IconButton onClick={() => onDelete(node)}>
            <RemoveCircle />
          </IconButton>
          <CommonSelect
            value={node}
            options={kOpts_FileType}
            onChanged={(v) => handleItemSelChanged(v, node.value)}
            sxx={{ minWidth: 150 }}
          />
        </Box>
      ))}
      <Divider variant="middle" sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexWrap: "wrap",
          listStyle: "none",
          m: 0,
          pl: 1,
        }}
        component="ul"
      >
        <IconButton onClick={onAdd} sx={{ ml: 1 }} color="success">
          <AddCircle />
        </IconButton>
      </Box>
    </>
  );
};

type WfCreateFileAttachProps = {
  onAction: (act: string, data: unknown) => void;
};

const WfCreateFileAttachPanel: FC<WfCreateFileAttachProps> = ({ onAction }) => {
  const [fileTypeName, setFileTypeName] = useState<string>("");
  const [limit, setLimit] = useState<SelectNode>(kOpt_SelNode_None);
  const [apprName, setApprName] = useState<string>("");
  const [items, setItems] = useState<SelectNode[]>([]);

  const handleFileTypeNameChanged = (txt: string) => {
    setFileTypeName(txt);
  };
  const handleApprNameChanged = (txt: string) => {
    setApprName(txt);
  };
  const handleLimitChanged = (nv: SelectNode) => {
    setLimit(nv);
  };
  const onItemChanged = (items: SelectNode[]) => {
    setItems(items);
  };

  const handleCancel = () => {};
  const handleCreate = () => {
    alert("handleCreate");
  };

  return (
    <Grid
      container
      flexDirection={{ xs: "column", sm: "row" }}
      sx={{ p: 2, fontSize: "12px" }}
      spacing={2}
    >
      <Grid xs={12} sm={12} sx={{ mt: 2 }}>
        <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
          ■ Workflow Name
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
        <TextField
          key="fk_name"
          name="fk_name"
          value={fileTypeName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileTypeNameChanged(e.target.value)}
          sx={{ maxWidth: "360px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
      </Grid>
      <Grid xs={12} sm={12} sx={{ mt: 1 }}>
        <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
          ■ Actions
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
        <Stack direction="row" sx={{ gap: 2, alignItems: "center" }}>
          <Typography variant="body2">Inputter:</Typography>
          <CommonSelect
            value={limit}
            options={kOpts_WF_Limit}
            onChanged={handleLimitChanged}
            sxx={{ minWidth: 120, maxWidth: 400 }}
          />
          <Typography variant="body2">Approver:</Typography>
          <TextField
            key="appr_name"
            name="appr_name"
            value={apprName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleApprNameChanged(e.target.value)}
            sx={{ maxWidth: "360px" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            size="small"
          />
        </Stack>
      </Grid>
      <Grid xs={12} sm={12} sx={{ mt: 1 }}>
        <Typography variant="h5" sx={{ color: "text.secondary", ml: 2 }}>
          ■ File type
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} sx={{ pl: 6, pr: 3 }}>
        <FtypeItemsPanel items={items} onChanged={onItemChanged} />
      </Grid>
    </Grid>
  );
};

export default WfCreateFileAttachPanel;
