import { Button, Paper, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useMemo } from "react";

import JobActionCell from "./JobActionCell";

import type { Job } from "../../../api/api";
import type { MRT_ColumnDef } from "material-react-table";
import type { FC } from "react";

const getColumnDef_InProgress = (
  handleVerb: (verb: string, job: Job) => void
): MRT_ColumnDef<Job>[] => [
  {
    accessorKey: "workflowType",
    header: "Workflow type",
    size: 120,
  },
  {
    accessorKey: "fiscalYear",
    header: "Fiscal Year",
    size: 120,
  },
  {
    accessorKey: "fixVersion",
    header: "Fix Version",
    size: 120,
  },
  {
    accessorKey: "workflowStep",
    header: "Step",
    size: 120,
    Cell: ({ cell, row }) => <Typography>{row.original.workflowStep}</Typography>,
  },
  {
    accessorKey: "name",
    header: "Job Name",
    size: 120,
  },
  {
    accessorKey: "completedTime",
    header: "Due Date",
    size: 150,
  },
  {
    accessorKey: "id",
    header: "Action",
    size: 160,
    Cell: ({ cell, row }) => <JobActionCell job={row.original} onAction={handleVerb} />,
  },
];

const getColumnDef_ActRequest = (
  handleVerb: (verb: string, job: Job) => void
): MRT_ColumnDef<Job>[] => [
  {
    accessorKey: "workflowType",
    header: "Workflow type",
    size: 120,
  },
  {
    accessorKey: "fiscalYear",
    header: "Fiscal Year",
    size: 120,
  },
  {
    accessorKey: "fixVersion",
    header: "Fix Version",
    size: 120,
  },
  {
    accessorKey: "workflowStep",
    header: "Step",
    size: 120,
    Cell: ({ cell, row }) => <Typography>{row.original.workflowStep}</Typography>,
  },
  {
    accessorKey: "completedTime",
    header: "Due Date",
    size: 150,
  },
  {
    accessorKey: "type",
    header: "Action",
    size: 160,
    Cell: ({ cell, row }) => <JobActionCell job={row.original} onAction={handleVerb} />,
  },
];
const getColumnDef_Done = (onAction: (verb: string, job: Job) => void): MRT_ColumnDef<Job>[] => [
  {
    accessorKey: "workflowType",
    header: "Workflow type",
    size: 120,
  },
  {
    accessorKey: "fiscalYear",
    header: "Fiscal Year",
    size: 120,
  },
  {
    accessorKey: "fixVersion",
    header: "Fix Version",
    size: 120,
  },
  {
    accessorKey: "workflowStep",
    header: "Step",
    size: 120,
    Cell: ({ cell, row }) => <Typography>{row.original.workflowStep as string}</Typography>,
  },
  {
    accessorKey: "name",
    header: "Job Name",
    size: 120,
  },
  {
    accessorKey: "completedTime",
    header: "Complementation Date",
    size: 150,
  },
  {
    accessorKey: "id",
    header: "Action",
    size: 160,
    Cell: ({ cell, row }) => (
      <Button
        size="small"
        variant="text"
        aria-label="error information"
        onClick={() => onAction("history", row.original)}
        sx={{ m: 0, ml: 1, p: 0, minWidth: 35 }}
      >
        History
      </Button>
    ),
  },
];

type JobListCardProps = {
  jobs: Job[];
  maxHeight?: number;
  onAction: (act: string, data: Job) => void;
};

const JobInProgressList: FC<JobListCardProps> = ({ jobs, maxHeight, onAction }) => {
  const columns = useMemo<MRT_ColumnDef<Job>[]>(
    () => getColumnDef_InProgress(onAction),
    [onAction]
  );

  return (
    <Paper id="job-list-in-progress" elevation={0}>
      <MaterialReactTable
        columns={columns}
        data={jobs}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

const JobNotStartList: FC<JobListCardProps> = ({ jobs, onAction, maxHeight }) => {
  const columns = useMemo<MRT_ColumnDef<Job>[]>(
    () => getColumnDef_ActRequest(onAction),
    [onAction]
  );

  return (
    <Paper id="job-list-not-start" elevation={0}>
      <MaterialReactTable
        columns={columns}
        data={jobs ?? []}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

const JobDoneList: FC<JobListCardProps> = ({ jobs, onAction, maxHeight }) => {
  const columns = useMemo<MRT_ColumnDef<Job>[]>(() => getColumnDef_Done(onAction), [onAction]);

  return (
    <Paper id="job-list-done" elevation={0}>
      <MaterialReactTable
        columns={columns}
        data={jobs}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

export { JobInProgressList, JobNotStartList, JobDoneList };
