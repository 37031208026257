import type { Job } from "api";

export function isObject<T extends object>(value: unknown): value is { [P in keyof T]?: unknown } {
  return typeof value === "object" && value !== null;
}

export const isString = (item: unknown): item is string => typeof item === "string";
export const isNumber = (item: unknown): item is number => typeof item === "number";
export const isBoolean = (item: unknown): item is boolean => typeof item === "boolean";

export const isError = (item: unknown): item is Error => item instanceof Error;

/**
 * Warning この関数は常に true を返します。
 * Jobの定義が不明のため any の変わりに利用します。
 * 時間があり、仕様に理解が深い人が修正してください。
 */
export const isJob = (item: unknown): item is Job => true;
