import { Paper } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useState, useMemo } from "react";

import type { ActionCB, FileDownloadItem } from "../../../common/type_defs";
import type { Updater, RowSelectionState } from "@tanstack/react-table";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { FC } from "react";

// const kClr_bg = "#FFF3E6";
const kClr_bg = "#FFFFFF";

const getColumnDef = (
  handleVerb: (verb: string, vd: FileDownloadItem) => void
): MRT_ColumnDef<FileDownloadItem>[] => [
  {
    accessorKey: "companyName",
    header: "Company Name",
    size: 150,
    Cell: ({ cell, row }) => row.original.companyName,
  },
  {
    accessorKey: "PECode",
    header: "CE Code",
    size: 150,
  },
  {
    accessorKey: "country",
    header: "country",
    size: 120,
  },
  {
    accessorKey: "upload_date",
    header: "Upload date",
    size: 70,
  },
  {
    accessorKey: "workflowType",
    header: "Workflow type",
    size: 120,
  },
  {
    accessorKey: "fileType",
    header: "File type",
    size: 120,
  },
];
type FileDownloadListProps = {
  isLoading: boolean;
  files: FileDownloadItem[];
  onAction: ActionCB;
  onSelChanged?: (sel: RowSelectionState) => void;
};

const FileDownloadList: FC<FileDownloadListProps> = ({
  files,
  onAction,
  onSelChanged,
  isLoading,
}) => {
  const columns = useMemo<MRT_ColumnDef<FileDownloadItem>[]>(
    () => getColumnDef(onAction),
    [onAction]
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleRowSelChanged = (rowSel: Updater<RowSelectionState>) => {
    const newSel = rowSel instanceof Function ? rowSel(rowSelection) : rowSel;
    setRowSelection(newSel);
    if (onSelChanged) {
      onSelChanged(newSel);
    }
  };

  return (
    <Paper elevation={0} sx={{ px: 2, bgcolor: kClr_bg }}>
      <MaterialReactTable
        columns={columns}
        data={files}
        enableRowSelection
        getRowId={(jd, index: number, parentRow: MRT_Row<FileDownloadItem>) =>
          `${jd.PECode ?? index}_${jd.path ?? index}`
        }
        onRowSelectionChange={handleRowSelChanged}
        state={{ isLoading, rowSelection }}
        muiTableContainerProps={{ sx: { height: 300 } }}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

export { FileDownloadList };
