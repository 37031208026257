import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Box, Divider, TextField, IconButton } from "@mui/material";
import { useState, useEffect } from "react";

import { makeUuid } from "../../util/utils";

import { CommonSelect } from "./CommonSelect";

import type { SelectNode, CondtItemData } from "../../common/type_defs";
import type { FC } from "react";

type FilterItemsProps = {
  items: CondtItemData[];
  onChanged: (ary: CondtItemData[]) => void;
  filterOptions: any[];
};
const FilterItemsPanel: FC<FilterItemsProps> = ({ items, onChanged, filterOptions = [] }) => {
  const [keyOptions, setKeyOptions] = useState<SelectNode[]>([]);
  const [keyInfo, setKeyInfo] = useState({}); // [{value: "key", label: "displayName"}

  useEffect(() => {
    const newKeyOptions = filterOptions.map((v) => ({
      value: v.key,
      label: v.displayName,
    }));
    setKeyOptions(newKeyOptions);

    if (filterOptions.length > 0) {
      console.log(filterOptions);
      const newKeyInfo = Object.fromEntries(
        structuredClone(filterOptions).map((option: any) => [option.key, option])
      );

      // const newKeyInfo = {};
      // for (const i in filterOptions) {
      //   const node = filterOptions[i];
      //   // @ts-ignore
      //   newKeyInfo[node.key] = node;
      // }

      // const newKeyInfo = filterOptions.reduce((acc, curr) => {
      //   // console.log(acc, curr)
      //   acc[curr.key] = curr;
      //   return acc;
      // })
      setKeyInfo(newKeyInfo);
    }
  }, [filterOptions]);

  const onDelete = (item: CondtItemData) => {
    const ary = items.filter((node) => node.id !== item.id);
    onChanged(ary);
  };
  const onAdd = () => {
    let ary: CondtItemData[] = [...items];
    if (!ary) {
      ary = [];
    }
    ary.push({
      id: makeUuid(),
      displayName: "",
      key: "",
      value: "",
      expression: "",
    });
    onChanged(ary);
  };

  const handleItemSelChanged = (nv: SelectNode, rowId: string) => {
    const ary: CondtItemData[] = structuredClone(items).map((item: any) => {
      if (item.id === rowId) {
        // @ts-expect-error filterOptionsの型がany[]となっておりkeyInfoの型が不明のため
        return { ...item, ...keyInfo[nv.value] };
      }
      return item;
    });
    // const ary: CondtItemData[] = [...items];
    // for (const i in ary) {
    //   const node = ary[i];

    //   if (node.id === rowId) {
    //     // @ts-ignore
    //     ary[i] = { ...node, ...keyInfo[nv.value] };
    //     break;
    //   }
    // }
    onChanged(ary);
  };

  const convertToExprSelectNode = (exprOptions: any) => {
    if (!exprOptions || exprOptions.length === 0) {
      return [];
    }
    return exprOptions.map((v: any) => ({
      value: v.key,
      label: v.displayName,
      type: v.type,
    }));
  };

  const handleExprsnSelChanged = (nv: SelectNode, rowId: string) => {
    const index = items.findIndex((node) => node.id === rowId);
    if (index === -1) {
      return;
    }
    const result = [
      items.slice(0, index),
      { ...items[index], expression: nv.value, type: nv.type },
      items.slice(index + 1),
    ].flat();
    onChanged(result);
  };
  const handleTxtChanged = (nv: string, rowId: string, fieldId: string) => {
    const index = items.findIndex((node) => node.id === rowId);

    if (index === -1) {
      return;
    }
    const result = [
      items.slice(0, index),
      { ...items[index], value: nv },
      items.slice(index + 1),
    ].flat();
    onChanged(result);
  };

  const getFilterValueComponent = (item: any) => {
    if (item.options && item.options.length > 0) {
      return (
        <CommonSelect
          value={item.value}
          options={item.options.map((v: any) => ({ label: v.displayName, value: v.name }))}
          onChanged={(v) => handleTxtChanged(v.value, item.id, "value")}
          sxx={{ mx: 1, my: 0, minWidth: 230 }}
        />
      );
    }

    return (
      <TextField
        sx={{ mx: 1, my: 0, minWidth: 230 }}
        size="small"
        label="Value"
        value={item.value}
        onChange={(evt) => handleTxtChanged(evt.target.value, item.id, "value")}
      />
    );
  };

  return (
    <>
      {items.map((node) => (
        <Box
          key={node.id}
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            mb: 1,
            pl: 2,
          }}
          component="ul"
        >
          <IconButton onClick={() => onDelete(node)}>
            <RemoveCircle />
          </IconButton>
          <CommonSelect
            value={{ value: node.key, label: node.displayName }}
            options={keyOptions}
            onChanged={(v) => handleItemSelChanged(v, node.id)}
            sxx={{ minWidth: 150 }}
          />
          {getFilterValueComponent(node)}
          <CommonSelect
            value={{ value: node.expression, label: node.expression }}
            options={convertToExprSelectNode((node as any).exprOptions)}
            onChanged={(v) => handleExprsnSelChanged(v, node.id)}
            sxx={{ minWidth: 150 }}
          />
        </Box>
      ))}
      <Divider variant="middle" sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexWrap: "wrap",
          listStyle: "none",
          m: 0,
          pl: 1,
        }}
        component="ul"
      >
        <IconButton data-testid="filter_item_add" onClick={onAdd} sx={{ ml: 1 }} color="success">
          <AddCircle />
        </IconButton>
      </Box>
    </>
  );
};
export default FilterItemsPanel;
