import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "redux-modules/auth";
import { infoSlice } from "redux-modules/info";
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { mainApi } from "services/base";

const reducers = combineReducers({
  [mainApi.reducerPath]: mainApi.reducer,
  [infoSlice.name]: infoSlice.reducer,
  [authSlice.name]: authSlice.reducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage: sessionStorage,
    blacklist: [mainApi.reducerPath],
  },
  reducers
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(mainApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
