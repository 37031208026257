import { isBoolean, isNumber, isObject, isString } from "util/type-guard";

import type { ApiFailedError } from "./types";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { AuthApiErrorResponse } from "services/auth";
import type { InitiateJobRequest } from "services/job";
import type { TimeoutError } from "types/api-error-type";

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
  isObject<FetchBaseQueryError>(error) &&
  (isNumber(error.status) ||
    (isString(error.status) &&
      (error.status === "FETCH_ERROR" ||
        error.status === "PARSING_ERROR" ||
        error.status === "CUSTOM_ERROR")));

export const isTimeoutError = (error: unknown): error is TimeoutError =>
  isObject<TimeoutError>(error) && error.name === "AbortError";

export const isInitiateJobRequest = (item: unknown): item is InitiateJobRequest =>
  isObject<InitiateJobRequest>(item) &&
  isString(item.attribute) &&
  item.attribute !== "" &&
  (isNumber(item.fiscalYear) || (isString(item.fiscalYear) && item.fiscalYear !== "")) &&
  isString(item.name) &&
  item.name !== "" &&
  isString(item.workflowStep) &&
  item.workflowStep !== "" &&
  isString(item.workflowType) &&
  item.workflowType !== "" &&
  Array.isArray(item.JobDetails) &&
  item.JobDetails.length > 0 &&
  item.JobDetails.every((d) => isObject<{ PECode: string }>(d) && isString(d.PECode));

export const isAuthResponseError = (item: unknown): item is AuthApiErrorResponse =>
  isObject<AuthApiErrorResponse>(item) && isString(item.code);

export const isApiFailedError = (error: unknown): error is ApiFailedError =>
  isFetchBaseQueryError(error) &&
  isObject<ApiFailedError["data"]>(error.data) &&
  isString(error.data.message) &&
  isBoolean(error.data.success);
