import { Paper, Typography, Button, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

const kTxt_HeaderCorp = "■ For users with Global ID and/or @sony.com";
const kTxt_ButtonCorp = "Go to Corporate Sign in";
const kTxt_CorpPrompt1 =
  "This system uses the AzureAD application authentication service. Please click the login button of “GO TO CORPORATE SIGN IN”.";
const kTxt_CorpPrompt2 =
  "If you are unable to log in, please register using the registration email address listed in the separately distributed “User Manual“.";
const kTxt_CorpPrompt3 = "After registration, please sign in from “GO TO ADC ID SIGN IN“.";

const CorpSignInEntry = () => {
  const navigate = useNavigate();

  const handleSignIn = async () => {
    navigate("/login/corporate");
  };

  return (
    <Paper sx={{ p: 5 }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12}>
          <Typography variant="h5" sx={{ mt: 2 }}>
            {kTxt_HeaderCorp}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ m: 5, px: 2 }}>
          <Button
            fullWidth
            onClick={handleSignIn}
            variant="contained"
            color="primary"
            size="large"
            sx={{ my: 2 }}
          >
            {kTxt_ButtonCorp}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, px: 2 }}>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {kTxt_CorpPrompt1}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {kTxt_CorpPrompt2}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {kTxt_CorpPrompt3}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CorpSignInEntry;
export { kTxt_HeaderCorp, kTxt_ButtonCorp, kTxt_CorpPrompt1, kTxt_CorpPrompt2, kTxt_CorpPrompt3 };
