import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import toast from "react-hot-toast";

import ModalDialog from "../../components/molecular/ModalDialog";

import WfCreateFileAttachPanel from "./components/FileAttachPanel";

import type { ActionCB } from "../../common/type_defs";
import type { FC } from "react";

type FileAttachmentProps = {
  open: boolean;
  onClose: (flag: string) => void;
  onAction: ActionCB;
};
const FileAttachmentDialog: FC<FileAttachmentProps> = ({ open, onClose, onAction }) => {
  const handleSearch = (v: Record<string, unknown>) => {
    // toast('search');
    toast.error(
      "This toast is super big. I don't think anyone could eat it in one bite.\n\nIt's larger than you expected. You eat it but it does not seem to get smaller.",
      {
        duration: 3000,
      }
    );
    // alert("search")
    // onAction('search', v)
  };
  const handleAction: ActionCB = (act, vd) => {
    onAction(act, vd);
  };

  const handleCancel = () => {
    onAction("file-attachment-cancel", null);
  };
  const handleCreate = () => {
    onAction("file-attachment-create", null);
  };

  return (
    <ModalDialog
      open={open}
      title="Creating Submitting File Workflow"
      content={<WfCreateFileAttachPanel onAction={handleAction} />}
      buttons={
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" onClick={handleCreate}>
              Create
            </Button>
          </Grid>
        </Grid>
      }
      onClose={() => onClose("close")}
      size="medium"
    />
  );
};

export default FileAttachmentDialog;
export { FileAttachmentDialog };
